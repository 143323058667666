import { Type } from 'class-transformer';
import {SORespProject} from './so-resp-project';
import {PluralProtocol} from 'lionheartland';
import {Project} from "../../dexie/db";

export class SOPluralProject implements PluralProtocol {
  @Type(() => SORespProject)
  objects: SORespProject[] = []
  nextOffset: number = null

    toProjects(): Project[] {
        return this.objects.map(project => project.toProject());
    }
}