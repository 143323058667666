import { SOGetUser } from '../user/so-get-user';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetDatafeed implements QueryProtocol {

  constructor(
    public uid: string = null,
    public user: SOGetUser = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDatafeed(
      'uid' in params ? params.uid : null,
      'user' in params ? params.user : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
