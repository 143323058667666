import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutConstraint } from './so-put-constraint';
import { SOGetConstraint } from './so-get-constraint';
import { SOPostConstraint } from './so-post-constraint';
import { SODeleteConstraint } from './so-delete-constraint';
import {SORespConstraint} from './so-resp-constraint';
import {SOPluralConstraint} from './so-plural-constraint';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOConstraintService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('constraint', entityCore);
  }

  /** GET Constraint by id. Will 404 if id not found */
  get(request: SOGetConstraint): Observable<SOPluralConstraint> {
    return this.httpClient.get<SOPluralConstraint>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralConstraint, resp)),
      tap(async (response: SOPluralConstraint) => {
        this.log(`fetched Constraint`);
        // After a successful server response, update IndexedDB
        const activities = response.toConstraints(); // Convert your response to an array of Constraint instances
        const promises = activities.map(constraint => db.constraints.put(constraint));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralConstraint>(`get Constraint`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralConstraint> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.constraints.toArray()).pipe(
                map((constraintsArray) => {
                    // Convert the array of plain objects to an array of SORespConstraint instances
                    const sorConstraints = constraintsArray.map(req => plainToClass(SORespConstraint, req));
                    // Create and return a new instance of SOPluralConstraint
                    const pluralConstraint = new SOPluralConstraint();
                    pluralConstraint.objects = sorConstraints;
                    pluralConstraint.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralConstraint;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processConstraints(constraintsArray) {
        const sorConstraints = constraintsArray.map(req => plainToClass(SORespConstraint, req));
        const pluralConstraint = new SOPluralConstraint();
        pluralConstraint.objects = sorConstraints;
        pluralConstraint.nextOffset = null;
        return pluralConstraint;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostConstraint): Observable<SORespConstraint> {
  return this.httpClient.post<SORespConstraint>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespConstraint, resp)),

    tap(async (response: SORespConstraint) => {
      this.log(`added Constraint w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.constraints.add(response.toConstraint());
    }),
    catchError(this.handleError<SORespConstraint>('Constraint post'))
  );
}


  put(request: SOPutConstraint): Observable<SORespConstraint> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespConstraint>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespConstraint, resp)),
      tap(async (response: SORespConstraint) => {
        this.log(`edited Constraint w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.constraints.put(response.toConstraint());
      }),
      catchError(this.handleError<SORespConstraint>('Constraint put'))
    );
  }


  delete(request: SODeleteConstraint): Observable<SORespConstraint> {
    return this.httpClient.delete<SORespConstraint>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Constraint uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.constraints.delete(request.uid);
      }),
      catchError(this.handleError<SORespConstraint>('Constraint delete'))
    );
  }


}
