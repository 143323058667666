import { SOGetPeriod } from '../period/so-get-period';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostPlan {

  constructor(
    public name: string,
    public period: SOGetPeriod,
    public project: SOGetProject,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPlan(
      'name' in params ? params.name : null,
      'period' in params ? params.period : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
