import {SOPutExportProject} from '../../core/export-project/so-put-export-project';
import {ExportProjectCore} from './export-project.core';
import {SOGetExportProject} from '../../core/export-project/so-get-export-project';
import {FormField, SelectionOption} from 'lionheartland';
import {putExportProjectFormFieldOptions} from './put-export-project-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutExportProject extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putExportProjectFormFieldOptions);

  RequestClass = SOPutExportProject

  constructor(
    public entityCore: ExportProjectCore
  ) {
    super();
    if (putExportProjectFormFieldOptions.project) {
      putExportProjectFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('reasonForExport'),
      this.getFormValueFromIdentifier('exportType'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}