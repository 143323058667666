import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutSimpleAttribute } from './so-put-simple-attribute';
import { SOGetSimpleAttribute } from './so-get-simple-attribute';
import { SOPostSimpleAttribute } from './so-post-simple-attribute';
import { SODeleteSimpleAttribute } from './so-delete-simple-attribute';
import {SORespSimpleAttribute} from './so-resp-simple-attribute';
import {SOPluralSimpleAttribute} from './so-plural-simple-attribute';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOSimpleAttributeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('simple_attribute', entityCore);
  }

  /** GET SimpleAttribute by id. Will 404 if id not found */
  get(request: SOGetSimpleAttribute): Observable<SOPluralSimpleAttribute> {
    return this.httpClient.get<SOPluralSimpleAttribute>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralSimpleAttribute, resp)),
      tap(async (response: SOPluralSimpleAttribute) => {
        this.log(`fetched SimpleAttribute`);
        // After a successful server response, update IndexedDB
        const activities = response.toSimpleAttributes(); // Convert your response to an array of SimpleAttribute instances
        const promises = activities.map(simpleAttribute => db.simpleAttributes.put(simpleAttribute));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralSimpleAttribute>(`get SimpleAttribute`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralSimpleAttribute> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'simpleAttribute' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.simpleAttributes.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "simpleAttributePermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('simpleAttributePermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "simpleAttributePermissionRules" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('simpleAttributePermissionRulesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "realizations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('realizationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "entity" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('entityUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "customLogicRequirement" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('customLogicRequirementUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "attributeSpecs" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('attributeSpecsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributes.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.simpleAttributes.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.simpleAttributes.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(simpleAttributesArray => this.processSimpleAttributes(simpleAttributesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.simpleAttributes.toArray()).pipe(
                map((simpleAttributesArray) => {
                    // Convert the array of plain objects to an array of SORespSimpleAttribute instances
                    const sorSimpleAttributes = simpleAttributesArray.map(req => plainToClass(SORespSimpleAttribute, req));
                    // Create and return a new instance of SOPluralSimpleAttribute
                    const pluralSimpleAttribute = new SOPluralSimpleAttribute();
                    pluralSimpleAttribute.objects = sorSimpleAttributes;
                    pluralSimpleAttribute.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralSimpleAttribute;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processSimpleAttributes(simpleAttributesArray) {
        const sorSimpleAttributes = simpleAttributesArray.map(req => plainToClass(SORespSimpleAttribute, req));
        const pluralSimpleAttribute = new SOPluralSimpleAttribute();
        pluralSimpleAttribute.objects = sorSimpleAttributes;
        pluralSimpleAttribute.nextOffset = null;
        return pluralSimpleAttribute;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostSimpleAttribute): Observable<SORespSimpleAttribute> {
  return this.httpClient.post<SORespSimpleAttribute>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespSimpleAttribute, resp)),

    tap(async (response: SORespSimpleAttribute) => {
      this.log(`added SimpleAttribute w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.simpleAttributes.add(response.toSimpleAttribute());
    }),
    catchError(this.handleError<SORespSimpleAttribute>('SimpleAttribute post'))
  );
}


  put(request: SOPutSimpleAttribute): Observable<SORespSimpleAttribute> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSimpleAttribute>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespSimpleAttribute, resp)),
      tap(async (response: SORespSimpleAttribute) => {
        this.log(`edited SimpleAttribute w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.simpleAttributes.put(response.toSimpleAttribute());
      }),
      catchError(this.handleError<SORespSimpleAttribute>('SimpleAttribute put'))
    );
  }


  delete(request: SODeleteSimpleAttribute): Observable<SORespSimpleAttribute> {
    return this.httpClient.delete<SORespSimpleAttribute>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted SimpleAttribute uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.simpleAttributes.delete(request.uid);
      }),
      catchError(this.handleError<SORespSimpleAttribute>('SimpleAttribute delete'))
    );
  }


}
