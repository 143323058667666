import { Type } from 'class-transformer';
import {SORespReport} from './so-resp-report';
import {PluralProtocol} from 'lionheartland';
import {Report} from "../../dexie/db";

export class SOPluralReport implements PluralProtocol {
  @Type(() => SORespReport)
  objects: SORespReport[] = []
  nextOffset: number = null

    toReports(): Report[] {
        return this.objects.map(report => report.toReport());
    }
}