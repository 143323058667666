import {SORespProcess} from '../../core/process/so-resp-process';
import {ProcessCore} from './process.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProcessFormFields} from './put-process-form-fields';
import {SOGetProcess} from '../../core/process/so-get-process';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEProcessAttributesBase {

    name: DetailEntry
    description: DetailEntry
    deficiencys: DetailEntry
    realizations: DetailEntry
    processRoles: DetailEntry
    project: DetailEntry
    processStepAssociations: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProcess,
        app: CommanderApp,
        formFields: PutProcessFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const deficiencysCore = app.makeCore( 'deficiency');
        deficiencysCore.soGet = SOGetDeficiency.construct({
          process: new SOGetProcess(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        deficiencysCore.soGet.queryOptions.sortKey = 'dateCreated';
        deficiencysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deficiencysCore
        )
        deficiencysCore.title = heading ? heading : deficiencysCore.title
        deficiencysCore.listTitle = 'Deficiencys'

        deficiencysCore.configureForDisplay(parentEntityViewSpec)

        this.deficiencys = new DetailEntry(
          heading ? heading : deficiencysCore.listTitle,
          null,
          'deficiencys',
          BaseDetailType.embeddedList,
          deficiencysCore,
          EditableType.never
        )

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          process: new SOGetProcess(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const processRolesCore = app.makeCore( 'processRole');
        processRolesCore.soGet = SOGetProcessRole.construct({
          process: new SOGetProcess(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processRolesCore.soGet.queryOptions.sortKey = 'dateCreated';
        processRolesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processRolesCore
        )
        processRolesCore.title = heading ? heading : processRolesCore.title
        processRolesCore.listTitle = 'Process roles'

        processRolesCore.configureForDisplay(parentEntityViewSpec)

        this.processRoles = new DetailEntry(
          heading ? heading : processRolesCore.listTitle,
          null,
          'processRoles',
          BaseDetailType.embeddedList,
          processRolesCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true

        const processStepAssociationsCore = app.makeCore( 'processStepAssociation');
        processStepAssociationsCore.soGet = SOGetProcessStepAssociation.construct({
          process: new SOGetProcess(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processStepAssociationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        processStepAssociationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processStepAssociationsCore
        )
        processStepAssociationsCore.title = heading ? heading : processStepAssociationsCore.title
        processStepAssociationsCore.listTitle = 'Process step associations'

        processStepAssociationsCore.configureForDisplay(parentEntityViewSpec)

        this.processStepAssociations = new DetailEntry(
          heading ? heading : processStepAssociationsCore.listTitle,
          null,
          'processStepAssociations',
          BaseDetailType.embeddedList,
          processStepAssociationsCore,
          EditableType.never
        )
    }
}