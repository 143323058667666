import { Type } from 'class-transformer';
import {SORespPlan} from './so-resp-plan';
import {PluralProtocol} from 'lionheartland';
import {Plan} from "../../dexie/db";

export class SOPluralPlan implements PluralProtocol {
  @Type(() => SORespPlan)
  objects: SORespPlan[] = []
  nextOffset: number = null

    toPlans(): Plan[] {
        return this.objects.map(plan => plan.toPlan());
    }
}