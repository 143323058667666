import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENRequirementStatus} from '../../enums';

export class PostCustomLogicRequirementFormFields {

    public name: FormField
    public description: FormField
    public active: FormField
    public currentStatus: FormField
    public parent: FormField
    public simpleAttributes: FormField
    public relationshipAttributes: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          true,
        )
        this.currentStatus = new FormField(
          'currentStatus',
          'Current status',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.currentStatus.enumeration = ENRequirementStatus
        this.parent = new FormField(
          'parent',
          'Parent',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttributes = new FormField(
          'simpleAttributes',
          'Simple attributes',
          'simpleAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttributes = new FormField(
          'relationshipAttributes',
          'Relationship attributes',
          'relationshipAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostCustomLogicRequirementFormFields()

export const postCustomLogicRequirementFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  active: fields.active,
  currentStatus: fields.currentStatus,
  parent: fields.parent,
  simpleAttributes: fields.simpleAttributes,
  relationshipAttributes: fields.relationshipAttributes,
  project: fields.project,
};