import {SORespViewGrant} from '../../core/view-grant/so-resp-view-grant';
import {ViewGrantCore} from './view-grant.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutViewGrantFormFields} from './put-view-grant-form-fields';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetView} from '../../core/view/so-get-view';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEViewGrantAttributesBase {

    role: DetailEntry
    view: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespViewGrant,
        app: CommanderApp,
        formFields: PutViewGrantFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        let roleCeProtocol;
        if (entity.role) {
            roleCeProtocol = createCEProtocol(
                roleCore.ceProtocol,
                parentEntityViewSpec,
                roleCore,
                entity.role,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        if (entity.role) {
            displayStringFactory.reconfigure(
                entity.role,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          roleCeProtocol ? roleCeProtocol.entityListView.fullHeading : null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.never
        )
        if (entity.role) {
          this.role.ceProtocol = roleCeProtocol;
          this.role.cachedListEntry = roleCore.makeListEntry(parentEntityViewSpec, entity.role, 0)
          roleCore.selectedEntity = entity.role;
        }
        this.role.singleFieldSelect = true

        const viewCore = app.makeCore( 'view');
        viewCore.soGet = SOGetView.construct({
        });
        let viewCeProtocol;
        if (entity.view) {
            viewCeProtocol = createCEProtocol(
                viewCore.ceProtocol,
                parentEntityViewSpec,
                viewCore,
                entity.view,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            viewCore
        )
        viewCore.title = heading ? heading : viewCore.title
        viewCore.listTitle = 'View'

        if (entity.view) {
            displayStringFactory.reconfigure(
                entity.view,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        viewCore.configureForDisplay(parentEntityViewSpec)

        this.view = new DetailEntry(
          heading ? heading : viewCore.listTitle,
          viewCeProtocol ? viewCeProtocol.entityListView.fullHeading : null,
          'view',
          BaseDetailType.pushedDetail,
          viewCore,
          EditableType.never
        )
        if (entity.view) {
          this.view.ceProtocol = viewCeProtocol;
          this.view.cachedListEntry = viewCore.makeListEntry(parentEntityViewSpec, entity.view, 0)
          viewCore.selectedEntity = entity.view;
        }
        this.view.singleFieldSelect = true
    }
}