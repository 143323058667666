import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostCertificateFormFields {

    public user: FormField

    constructor() {

        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostCertificateFormFields()

export const postCertificateFormFieldOptions: {[key: string]: FormField} = {
  user: fields.user,
};