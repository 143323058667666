import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostViewGrantFormFields {

    public role: FormField
    public view: FormField

    constructor() {

        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.view = new FormField(
          'view',
          'View',
          'view',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostViewGrantFormFields()

export const postViewGrantFormFieldOptions: {[key: string]: FormField} = {
  role: fields.role,
  view: fields.view,
};