import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOIngestService} from '../../core/ingest/eo-ingest';
import {SOGetIngest} from '../../core/ingest/so-get-ingest';
import {RAPutIngest} from './ra-put-ingest';
import {RAPostIngest} from './ra-post-ingest';
import {RADeleteIngest} from './ra-delete-ingest';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEIngest } from "./ce-ingest"


export class IngestCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'ingest';
  title = 'Ingest';
  ceProtocol: CEConstructor = CEIngest

  entityService: EOIngestService;
  soGetClass = SOGetIngest;
  soGet: SOGetIngest;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOIngestService,
      null,
    );
    this.adapters = [
      new RAPostIngest(this),
      new RAPutIngest(this),
      new RADeleteIngest(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetIngest();
    this.soGet.queryOptions.limit = this.limit;
  }


}