import {CommanderAppBase} from "./land-app-base";

export class CommanderApp extends CommanderAppBase {
    constructor() {
        super(
            [
                'organizationinvite',
                'organization',
                'user',
                'price',
                'subscription',
                'rolegrant',
                'media',
                'attachment',
            ]
        );
    }
}