import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutUser } from './so-put-user';
import { SOGetUser } from './so-get-user';
import { SOPostUser } from './so-post-user';
import { SODeleteUser } from './so-delete-user';
import {SORespUser} from './so-resp-user';
import {SOPluralUser} from './so-plural-user';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOUserService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('user', entityCore);
  }

  /** GET User by id. Will 404 if id not found */
  get(request: SOGetUser): Observable<SOPluralUser> {
    return this.httpClient.get<SOPluralUser>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralUser, resp)),
      tap(async (response: SOPluralUser) => {
        this.log(`fetched User`);
        // After a successful server response, update IndexedDB
        const activities = response.toUsers(); // Convert your response to an array of User instances
        const promises = activities.map(user => db.users.put(user));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralUser>(`get User`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralUser> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.users.toArray()).pipe(
                map((usersArray) => {
                    // Convert the array of plain objects to an array of SORespUser instances
                    const sorUsers = usersArray.map(req => plainToClass(SORespUser, req));
                    // Create and return a new instance of SOPluralUser
                    const pluralUser = new SOPluralUser();
                    pluralUser.objects = sorUsers;
                    pluralUser.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralUser;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processUsers(usersArray) {
        const sorUsers = usersArray.map(req => plainToClass(SORespUser, req));
        const pluralUser = new SOPluralUser();
        pluralUser.objects = sorUsers;
        pluralUser.nextOffset = null;
        return pluralUser;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostUser): Observable<SORespUser> {
  return this.httpClient.post<SORespUser>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespUser, resp)),

    tap(async (response: SORespUser) => {
      this.log(`added User w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.users.add(response.toUser());
    }),
    catchError(this.handleError<SORespUser>('User post'))
  );
}


  put(request: SOPutUser): Observable<SORespUser> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespUser>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespUser, resp)),
      tap(async (response: SORespUser) => {
        this.log(`edited User w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.users.put(response.toUser());
      }),
      catchError(this.handleError<SORespUser>('User put'))
    );
  }


  delete(request: SODeleteUser): Observable<SORespUser> {
    return this.httpClient.delete<SORespUser>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted User uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.users.delete(request.uid);
      }),
      catchError(this.handleError<SORespUser>('User delete'))
    );
  }


}
