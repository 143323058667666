import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORoleService} from '../../core/role/eo-role';
import {SOGetRole} from '../../core/role/so-get-role';
import {RAPutRole} from './ra-put-role';
import {RAPostRole} from './ra-post-role';
import {RADeleteRole} from './ra-delete-role';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERole } from "./ce-role"


export class RoleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'role';
  title = 'Role';
  ceProtocol: CEConstructor = CERole

  entityService: EORoleService;
  soGetClass = SOGetRole;
  soGet: SOGetRole;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORoleService,
      null,
    );
    this.adapters = [
      new RAPostRole(this),
      new RAPutRole(this),
      new RADeleteRole(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRole();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}