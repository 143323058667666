import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENMultiplicity} from '../../enums';

export class PutRelationshipAttributeFormFields {

    public name: FormField
    public description: FormField
    public multiplicity: FormField
    public editPermissions: FormField
    public relationshipAttributePermissions: FormField
    public relationshipAttributePermissionRules: FormField
    public entity: FormField
    public customLogicRequirement: FormField
    public relationship: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.multiplicity = new FormField(
          'multiplicity',
          'Multiplicity',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.multiplicity.enumeration = ENMultiplicity
        this.editPermissions = new FormField(
          'editPermissions',
          'Edit permissions',
          null,
          FormFieldType.boolean,
          false,
        )
        this.relationshipAttributePermissions = new FormField(
          'relationshipAttributePermissions',
          'Relationship attribute permissions',
          'relationshipAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttributePermissionRules = new FormField(
          'relationshipAttributePermissionRules',
          'Relationship attribute permission rules',
          'relationshipAttributePermissionRule',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.customLogicRequirement = new FormField(
          'customLogicRequirement',
          'Custom logic requirement',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationship = new FormField(
          'relationship',
          'Relationship',
          'relationship',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutRelationshipAttributeFormFields()

export const putRelationshipAttributeFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  multiplicity: fields.multiplicity,
  editPermissions: fields.editPermissions,
  relationshipAttributePermissions: fields.relationshipAttributePermissions,
  relationshipAttributePermissionRules: fields.relationshipAttributePermissionRules,
  entity: fields.entity,
  customLogicRequirement: fields.customLogicRequirement,
  relationship: fields.relationship,
  project: fields.project,
};