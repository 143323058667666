import {SOPostAssessmentSummary} from '../../core/assessment-summary/so-post-assessment-summary';
import {AssessmentSummaryCore} from './assessment-summary.core';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {FormField, SelectionOption} from 'lionheartland';
import {postAssessmentSummaryFormFieldOptions} from './post-assessment-summary-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AssessmentCore} from '../assessment/assessment.core';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAssessmentSummary extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAssessmentSummaryFormFieldOptions);

  RequestClass = SOPostAssessmentSummary

  constructor(
    public entityCore: AssessmentSummaryCore
  ) {
    super();
    if (postAssessmentSummaryFormFieldOptions.assessment) {
      postAssessmentSummaryFormFieldOptions.assessment.makeCore = true;
    }
    if (postAssessmentSummaryFormFieldOptions.project) {
      postAssessmentSummaryFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'assessment',
        SOGetAssessment,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}