import {SORespProcessStepAssociation} from '../../core/process-step-association/so-resp-process-step-association';
import {ProcessStepAssociationCore} from './process-step-association.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProcessStepAssociationFormFields} from './put-process-step-association-form-fields';
import { CEProcessStepAssociationAttributes } from './ce-process-step-association-attributes'


export class CEProcessStepAssociationBase extends CEBase {

    public details: CEProcessStepAssociationAttributes;
    public fromFields: PutProcessStepAssociationFormFields = new PutProcessStepAssociationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProcessStepAssociationCore,
        public entity: SORespProcessStepAssociation,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProcessStepAssociationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.processStepType)
        this.attributes.push(this.details.process)
        this.attributes.push(this.details.step)
        this.attributes.push(this.details.project)

    }
}