import {SORespPlanWorkAssociation} from '../../core/plan-work-association/so-resp-plan-work-association';
import {PlanWorkAssociationCore} from './plan-work-association.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPlanWorkAssociationFormFields} from './put-plan-work-association-form-fields';
import { CEPlanWorkAssociationAttributes } from './ce-plan-work-association-attributes'


export class CEPlanWorkAssociationBase extends CEBase {

    public details: CEPlanWorkAssociationAttributes;
    public fromFields: PutPlanWorkAssociationFormFields = new PutPlanWorkAssociationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PlanWorkAssociationCore,
        public entity: SORespPlanWorkAssociation,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPlanWorkAssociationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.reportSection)
        this.attributes.push(this.details.plan)
        this.attributes.push(this.details.work)

    }
}