import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAttributeSpecFormFields {

    public name: FormField
    public description: FormField
    public interphace: FormField
    public simpleAttribute: FormField
    public relationshipAttribute: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.interphace = new FormField(
          'interphace',
          'Interphace',
          'interphace',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttribute = new FormField(
          'simpleAttribute',
          'Simple attribute',
          'simpleAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttribute = new FormField(
          'relationshipAttribute',
          'Relationship attribute',
          'relationshipAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutAttributeSpecFormFields()

export const putAttributeSpecFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  interphace: fields.interphace,
  simpleAttribute: fields.simpleAttribute,
  relationshipAttribute: fields.relationshipAttribute,
  project: fields.project,
};