import { Type } from 'class-transformer';
import {SORespCustomLogicRequirement} from './so-resp-custom-logic-requirement';
import {PluralProtocol} from 'lionheartland';
import {CustomLogicRequirement} from "../../dexie/db";

export class SOPluralCustomLogicRequirement implements PluralProtocol {
  @Type(() => SORespCustomLogicRequirement)
  objects: SORespCustomLogicRequirement[] = []
  nextOffset: number = null

    toCustomLogicRequirements(): CustomLogicRequirement[] {
        return this.objects.map(customLogicRequirement => customLogicRequirement.toCustomLogicRequirement());
    }
}