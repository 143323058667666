import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAssessmentFormFields {

    public project: FormField

    constructor() {

        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutAssessmentFormFields()

export const putAssessmentFormFieldOptions: {[key: string]: FormField} = {
  project: fields.project,
};