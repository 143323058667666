import { SOGetProcess } from '../process/so-get-process';
import { SOGetRole } from '../role/so-get-role';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostProcessRole {

  constructor(
    public name: string,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public process: SOGetProcess = null,
    public role: SOGetRole = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostProcessRole(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'process' in params ? params.process : null,
      'role' in params ? params.role : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
