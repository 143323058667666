import {SOPutAssessment} from '../../core/assessment/so-put-assessment';
import {AssessmentCore} from './assessment.core';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {FormField, SelectionOption} from 'lionheartland';
import {putAssessmentFormFieldOptions} from './put-assessment-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutAssessment extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAssessmentFormFieldOptions);

  RequestClass = SOPutAssessment

  constructor(
    public entityCore: AssessmentCore
  ) {
    super();
    if (putAssessmentFormFieldOptions.project) {
      putAssessmentFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}