import { SOGetProcess } from '../process/so-get-process';
import { SOGetRole } from '../role/so-get-role';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutProcessRole {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public process: SOGetProcess = null,
    public role: SOGetRole = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutProcessRole(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'process' in params ? params.process : null,
      'role' in params ? params.role : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
