

export class SOPostProduct {

  constructor(
    public name: string,
    public sku: string = '',
    public exRef: string = null,
    public active: boolean = true,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostProduct(
      'name' in params ? params.name : null,
      'sku' in params ? params.sku : null,
      'exRef' in params ? params.exRef : null,
      'active' in params ? params.active : null,
    );
  }
}
