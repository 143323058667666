import {SOPostRequirement} from '../../core/requirement/so-post-requirement';
import {RequirementCore} from './requirement.core';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {FormField, SelectionOption} from 'lionheartland';
import {postRequirementFormFieldOptions} from './post-requirement-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostRequirement extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRequirementFormFieldOptions);

  RequestClass = SOPostRequirement

  constructor(
    public entityCore: RequirementCore
  ) {
    super();
    if (postRequirementFormFieldOptions.parent) {
      postRequirementFormFieldOptions.parent.makeCore = true;
    }
    if (postRequirementFormFieldOptions.project) {
      postRequirementFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier('currentStatus'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'parent',
        SOGetRequirement,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}