import {SOPostInvoice} from '../../core/invoice/so-post-invoice';
import {InvoiceCore} from './invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {FormField, SelectionOption} from 'lionheartland';
import {postInvoiceFormFieldOptions} from './post-invoice-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {SubscriptionCore} from '../subscription/subscription.core';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';

export class RAPostInvoice extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postInvoiceFormFieldOptions);

  RequestClass = SOPostInvoice

  constructor(
    public entityCore: InvoiceCore
  ) {
    super();
    if (postInvoiceFormFieldOptions.subscription) {
      postInvoiceFormFieldOptions.subscription.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('ref'),
      this.getFormValueFromIdentifier('amountDue'),
      this.getFormValueFromIdentifier('amountPaid'),
      this.getFormValueFromIdentifier('amountRemaining'),
      this.getFormValueFromIdentifier('dateDue'),
      this.getFormValueFromIdentifier('periodStart'),
      this.getFormValueFromIdentifier('periodEnd'),
      this.getFormValueFromIdentifier(
        'subscription',
        SOGetSubscription,
      ),
      this.getFormValueFromIdentifier('exRef'),
      this.getFormValueFromIdentifier('paid'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}