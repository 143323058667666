import { SOGetTopic } from '../topic/so-get-topic';
import { SOGetDatafeed } from '../datafeed/so-get-datafeed';


export class SOPutAlertSubscription {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public topic: SOGetTopic = null,
    public datafeed: SOGetDatafeed = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAlertSubscription(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'topic' in params ? params.topic : null,
      'datafeed' in params ? params.datafeed : null,
    );
  }
}
