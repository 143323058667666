import {SORespSesMessage} from '../../core/ses-message/so-resp-ses-message';
import {SesMessageCore} from './ses-message.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSesMessageFormFields} from './put-ses-message-form-fields';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';


export class CESesMessageAttributesBase {

    data: DetailEntry
    dateCreated: DetailEntry
    messageType: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSesMessage,
        app: CommanderApp,
        formFields: PutSesMessageFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.data = new DetailEntry(
            'Data',
            entity.data,
            'data',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.data
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.messageType = new DetailEntry(
            'Message type',
            entity.messageType,
            'messageType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.messageType
        )

    }
}