import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProcessRoleService} from '../../core/process-role/eo-process-role';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {RAPutProcessRole} from './ra-put-process-role';
import {RAPostProcessRole} from './ra-post-process-role';
import {RADeleteProcessRole} from './ra-delete-process-role';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEProcessRole } from "./ce-process-role"


export class ProcessRoleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'processRole';
  title = 'Process role';
  ceProtocol: CEConstructor = CEProcessRole

  entityService: EOProcessRoleService;
  soGetClass = SOGetProcessRole;
  soGet: SOGetProcessRole;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProcessRoleService,
      null,
    );
    this.adapters = [
      new RAPostProcessRole(this),
      new RAPutProcessRole(this),
      new RADeleteProcessRole(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProcessRole();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}