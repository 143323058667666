import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutProcessFormFields {

    public name: FormField
    public description: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutProcessFormFields()

export const putProcessFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  project: fields.project,
};