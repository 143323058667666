import { Type } from 'class-transformer';
import {SORespSimpleAttributePermissionRule} from './so-resp-simple-attribute-permission-rule';
import {PluralProtocol} from 'lionheartland';
import {SimpleAttributePermissionRule} from "../../dexie/db";

export class SOPluralSimpleAttributePermissionRule implements PluralProtocol {
  @Type(() => SORespSimpleAttributePermissionRule)
  objects: SORespSimpleAttributePermissionRule[] = []
  nextOffset: number = null

    toSimpleAttributePermissionRules(): SimpleAttributePermissionRule[] {
        return this.objects.map(simpleAttributePermissionRule => simpleAttributePermissionRule.toSimpleAttributePermissionRule());
    }
}