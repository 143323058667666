import {SORespOrganizationInvite} from '../../core/organization-invite/so-resp-organization-invite';
import {OrganizationInviteCore} from './organization-invite.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutOrganizationInviteFormFields} from './put-organization-invite-form-fields';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEOrganizationInviteAttributesBase {

    email: DetailEntry
    status: DetailEntry
    dateCreated: DetailEntry
    user: DetailEntry
    role: DetailEntry
    organization: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespOrganizationInvite,
        app: CommanderApp,
        formFields: PutOrganizationInviteFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.email = new DetailEntry(
            'Email',
            entity.email,
            'email',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.status
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.never
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true

        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        let roleCeProtocol;
        if (entity.role) {
            roleCeProtocol = createCEProtocol(
                roleCore.ceProtocol,
                parentEntityViewSpec,
                roleCore,
                entity.role,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        if (entity.role) {
            displayStringFactory.reconfigure(
                entity.role,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          roleCeProtocol ? roleCeProtocol.entityListView.fullHeading : null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.never
        )
        if (entity.role) {
          this.role.ceProtocol = roleCeProtocol;
          this.role.cachedListEntry = roleCore.makeListEntry(parentEntityViewSpec, entity.role, 0)
          roleCore.selectedEntity = entity.role;
        }
        this.role.singleFieldSelect = true

        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        let organizationCeProtocol;
        if (entity.organization) {
            organizationCeProtocol = createCEProtocol(
                organizationCore.ceProtocol,
                parentEntityViewSpec,
                organizationCore,
                entity.organization,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        if (entity.organization) {
            displayStringFactory.reconfigure(
                entity.organization,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          organizationCeProtocol ? organizationCeProtocol.entityListView.fullHeading : null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.never
        )
        if (entity.organization) {
          this.organization.ceProtocol = organizationCeProtocol;
          this.organization.cachedListEntry = organizationCore.makeListEntry(parentEntityViewSpec, entity.organization, 0)
          organizationCore.selectedEntity = entity.organization;
        }
        this.organization.singleFieldSelect = true
    }
}