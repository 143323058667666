

export class SOPostOrganization {

  constructor(
    public name: string,
    public identifier: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOrganization(
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
    );
  }
}
