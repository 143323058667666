import {SORespPeriod} from '../../core/period/so-resp-period';
import {PeriodCore} from './period.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPeriodFormFields} from './put-period-form-fields';
import { CEPeriodAttributes } from './ce-period-attributes'


export class CEPeriodBase extends CEBase {

    public details: CEPeriodAttributes;
    public fromFields: PutPeriodFormFields = new PutPeriodFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PeriodCore,
        public entity: SORespPeriod,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPeriodAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.numeral)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.plans)
        this.attributes.push(this.details.works)
        this.attributes.push(this.details.reports)
        this.attributes.push(this.details.periods)
        this.attributes.push(this.details.parent)
        this.attributes.push(this.details.project)

    }
}