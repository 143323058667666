import {SORespWork} from '../../core/work/so-resp-work';
import {WorkCore} from './work.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutWorkFormFields} from './put-work-form-fields';
import {SOGetWork} from '../../core/work/so-get-work';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {SOGetBacklog} from '../../core/backlog/so-get-backlog';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEWorkAttributesBase {

    name: DetailEntry
    number: DetailEntry
    description: DetailEntry
    active: DetailEntry
    deficiency: DetailEntry
    period: DetailEntry
    planWorkAssociations: DetailEntry
    backlog: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespWork,
        app: CommanderApp,
        formFields: PutWorkFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.number = new DetailEntry(
            'Number',
            entity.number,
            'number',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )


        const deficiencyCore = app.makeCore( 'deficiency');
        deficiencyCore.soGet = SOGetDeficiency.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        deficiencyCore.soGet.queryOptions.sortKey = 'dateCreated';
        deficiencyCore.soGet.queryOptions.descending = true;
        let deficiencyCeProtocol;
        if (entity.deficiency) {
            deficiencyCeProtocol = createCEProtocol(
                deficiencyCore.ceProtocol,
                parentEntityViewSpec,
                deficiencyCore,
                entity.deficiency,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deficiencyCore
        )
        deficiencyCore.title = heading ? heading : deficiencyCore.title
        deficiencyCore.listTitle = 'Deficiency'

        if (entity.deficiency) {
            displayStringFactory.reconfigure(
                entity.deficiency,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        deficiencyCore.configureForDisplay(parentEntityViewSpec)

        this.deficiency = new DetailEntry(
          heading ? heading : deficiencyCore.listTitle,
          deficiencyCeProtocol ? deficiencyCeProtocol.entityListView.fullHeading : null,
          'deficiency',
          BaseDetailType.pushedDetail,
          deficiencyCore,
          EditableType.always,
          formFields.deficiency
        )
        if (entity.deficiency) {
          this.deficiency.ceProtocol = deficiencyCeProtocol;
          this.deficiency.cachedListEntry = deficiencyCore.makeListEntry(parentEntityViewSpec, entity.deficiency, 0)
          deficiencyCore.selectedEntity = entity.deficiency;
        }
        this.deficiency.singleFieldSelect = true

        const periodCore = app.makeCore( 'period');
        periodCore.soGet = SOGetPeriod.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        periodCore.soGet.queryOptions.sortKey = 'dateCreated';
        periodCore.soGet.queryOptions.descending = true;
        let periodCeProtocol;
        if (entity.period) {
            periodCeProtocol = createCEProtocol(
                periodCore.ceProtocol,
                parentEntityViewSpec,
                periodCore,
                entity.period,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            periodCore
        )
        periodCore.title = heading ? heading : periodCore.title
        periodCore.listTitle = 'Period'

        if (entity.period) {
            displayStringFactory.reconfigure(
                entity.period,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        periodCore.configureForDisplay(parentEntityViewSpec)

        this.period = new DetailEntry(
          heading ? heading : periodCore.listTitle,
          periodCeProtocol ? periodCeProtocol.entityListView.fullHeading : null,
          'period',
          BaseDetailType.pushedDetail,
          periodCore,
          EditableType.never
        )
        if (entity.period) {
          this.period.ceProtocol = periodCeProtocol;
          this.period.cachedListEntry = periodCore.makeListEntry(parentEntityViewSpec, entity.period, 0)
          periodCore.selectedEntity = entity.period;
        }
        this.period.singleFieldSelect = true

        const planWorkAssociationsCore = app.makeCore( 'planWorkAssociation');
        planWorkAssociationsCore.soGet = SOGetPlanWorkAssociation.construct({
          work: new SOGetWork(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        planWorkAssociationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        planWorkAssociationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            planWorkAssociationsCore
        )
        planWorkAssociationsCore.title = heading ? heading : planWorkAssociationsCore.title
        planWorkAssociationsCore.listTitle = 'Plan work associations'

        planWorkAssociationsCore.configureForDisplay(parentEntityViewSpec)

        this.planWorkAssociations = new DetailEntry(
          heading ? heading : planWorkAssociationsCore.listTitle,
          null,
          'planWorkAssociations',
          BaseDetailType.embeddedList,
          planWorkAssociationsCore,
          EditableType.never
        )

        const backlogCore = app.makeCore( 'backlog');
        backlogCore.soGet = SOGetBacklog.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        backlogCore.soGet.queryOptions.sortKey = 'dateCreated';
        backlogCore.soGet.queryOptions.descending = true;
        let backlogCeProtocol;
        if (entity.backlog) {
            backlogCeProtocol = createCEProtocol(
                backlogCore.ceProtocol,
                parentEntityViewSpec,
                backlogCore,
                entity.backlog,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            backlogCore
        )
        backlogCore.title = heading ? heading : backlogCore.title
        backlogCore.listTitle = 'Backlog'

        if (entity.backlog) {
            displayStringFactory.reconfigure(
                entity.backlog,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        backlogCore.configureForDisplay(parentEntityViewSpec)

        this.backlog = new DetailEntry(
          heading ? heading : backlogCore.listTitle,
          backlogCeProtocol ? backlogCeProtocol.entityListView.fullHeading : null,
          'backlog',
          BaseDetailType.pushedDetail,
          backlogCore,
          EditableType.never
        )
        if (entity.backlog) {
          this.backlog.ceProtocol = backlogCeProtocol;
          this.backlog.cachedListEntry = backlogCore.makeListEntry(parentEntityViewSpec, entity.backlog, 0)
          backlogCore.selectedEntity = entity.backlog;
        }
        this.backlog.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}