import { Type } from 'class-transformer';
import {ENSesMessageType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {SesMessage} from "../../dexie/db";

export class SORespSesMessage implements ObjectiveProtocol {

  entityType: string = 'sesMessage';
  uid: string;
  data: string;
  @Type(() => Date)
  dateCreated: Date;
  messageType: ENSesMessageType;

    toSesMessage(): SesMessage {
        return {
            uid: this.uid,
            data: this.data,
            dateCreated: this.dateCreated,
            messageType: this.messageType,
}

        };
}