import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPaymentIntentService} from '../../core/payment-intent/eo-payment-intent';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {RAPutPaymentIntent} from './ra-put-payment-intent';
import {RAPostPaymentIntent} from './ra-post-payment-intent';
import {RADeletePaymentIntent} from './ra-delete-payment-intent';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEPaymentIntent } from "./ce-payment-intent"


export class PaymentIntentCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'paymentIntent';
  title = 'Payment intent';
  ceProtocol: CEConstructor = CEPaymentIntent

  entityService: EOPaymentIntentService;
  soGetClass = SOGetPaymentIntent;
  soGet: SOGetPaymentIntent;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPaymentIntentService,
      null,
    );
    this.adapters = [
      new RAPostPaymentIntent(this),
      new RAPutPaymentIntent(this),
      new RADeletePaymentIntent(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPaymentIntent();
    this.soGet.queryOptions.limit = this.limit;
  }


}