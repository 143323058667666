import {ENViewType} from '../../enums';


export class SOPostView {

  constructor(
    public name: string,
    public identifier: string,
    public type: ENViewType = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostView(
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'type' in params ? params.type : null,
    );
  }
}
