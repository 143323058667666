import { Type } from 'class-transformer';
import {SORespSimpleAttribute} from './so-resp-simple-attribute';
import {PluralProtocol} from 'lionheartland';
import {SimpleAttribute} from "../../dexie/db";

export class SOPluralSimpleAttribute implements PluralProtocol {
  @Type(() => SORespSimpleAttribute)
  objects: SORespSimpleAttribute[] = []
  nextOffset: number = null

    toSimpleAttributes(): SimpleAttribute[] {
        return this.objects.map(simpleAttribute => simpleAttribute.toSimpleAttribute());
    }
}