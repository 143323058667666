import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Message} from "../../dexie/db";

export class SORespMessage implements ObjectiveProtocol {

  entityType: string = 'message';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toMessage(): Message {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            notificationsUid: null,
            topicUid: null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}