import { Type } from 'class-transformer';
import { SORespPrice } from '../price/so-resp-price';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ObjectiveProtocol} from 'lionheartland';
import {InvoiceLine} from "../../dexie/db";

export class SORespInvoiceLine implements ObjectiveProtocol {

  entityType: string = 'invoiceLine';
  uid: string;
  amount: number;
  quantity: number;
  @Type(() => Date)
  dateCreated: Date;
  description: string = "";
  @Type(() => SORespPrice)
  price: SORespPrice;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;

    toInvoiceLine(): InvoiceLine {
        return {
            uid: this.uid,
            amount: this.amount,
            quantity: this.quantity,
            dateCreated: this.dateCreated,
            description: this.description,
            priceUid: this.price ? this.price.uid : null,
            invoiceUid: this.invoice ? this.invoice.uid : null,
}

        };
}