import { SOGetAssessment } from '../assessment/so-get-assessment';
import { SOGetAssessmentSummary } from '../assessment-summary/so-get-assessment-summary';
import { SOGetBlock } from '../block/so-get-block';
import { SOGetDeficiency } from '../deficiency/so-get-deficiency';
import { SOGetPeriod } from '../period/so-get-period';
import { SOGetReport } from '../report/so-get-report';
import { SOGetRequirement } from '../requirement/so-get-requirement';
import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetWork } from '../work/so-get-work';
import { SOGetRealization } from '../realization/so-get-realization';
import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetProcess } from '../process/so-get-process';
import { SOGetProject } from '../project/so-get-project';
import { SOGetInterphace } from '../interphace/so-get-interphace';
import { SOGetBacklog } from '../backlog/so-get-backlog';
import { SOGetAttributeSpec } from '../attribute-spec/so-get-attribute-spec';
import { SOGetExportProject } from '../export-project/so-get-export-project';
import { SOGetSimpleAttributePermission } from '../simple-attribute-permission/so-get-simple-attribute-permission';
import { SOGetSimpleAttributePermissionRule } from '../simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import { SOGetRelationshipAttributePermission } from '../relationship-attribute-permission/so-get-relationship-attribute-permission';
import { SOGetRelationshipAttributePermissionRule } from '../relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import { SOGetEntityPermission } from '../entity-permission/so-get-entity-permission';
import { SOGetPlan } from '../plan/so-get-plan';
import { SOGetPerson } from '../person/so-get-person';
import { SOGetAssignedProject } from '../assigned-project/so-get-assigned-project';
import { SOGetPlanWorkAssociation } from '../plan-work-association/so-get-plan-work-association';
import { SOGetRolePermission } from '../role-permission/so-get-role-permission';
import { SOGetRole } from '../role/so-get-role';
import { SOGetProcessRole } from '../process-role/so-get-process-role';
import { SOGetStep } from '../step/so-get-step';
import { SOGetProcessStepAssociation } from '../process-step-association/so-get-process-step-association';


export class SOPutOrganization {

  constructor(
    public uid: string,
    public name: string = null,
    public identifier: string = null,
    public assessment: SOGetAssessment[] = null,
    public assessmentSummary: SOGetAssessmentSummary[] = null,
    public block: SOGetBlock[] = null,
    public deficiency: SOGetDeficiency[] = null,
    public period: SOGetPeriod[] = null,
    public report: SOGetReport[] = null,
    public requirement: SOGetRequirement[] = null,
    public customLogicRequirement: SOGetCustomLogicRequirement[] = null,
    public work: SOGetWork[] = null,
    public realization: SOGetRealization[] = null,
    public entity: SOGetEntity[] = null,
    public relationship: SOGetRelationship[] = null,
    public relationshipAttribute: SOGetRelationshipAttribute[] = null,
    public simpleAttribute: SOGetSimpleAttribute[] = null,
    public process: SOGetProcess[] = null,
    public project: SOGetProject[] = null,
    public interphace: SOGetInterphace[] = null,
    public backlog: SOGetBacklog[] = null,
    public attributeSpec: SOGetAttributeSpec[] = null,
    public exportProject: SOGetExportProject[] = null,
    public simpleAttributePermission: SOGetSimpleAttributePermission[] = null,
    public simpleAttributePermissionRule: SOGetSimpleAttributePermissionRule[] = null,
    public relationshipAttributePermission: SOGetRelationshipAttributePermission[] = null,
    public relationshipAttributePermissionRule: SOGetRelationshipAttributePermissionRule[] = null,
    public entityPermission: SOGetEntityPermission[] = null,
    public plan: SOGetPlan[] = null,
    public person: SOGetPerson[] = null,
    public assignedProject: SOGetAssignedProject[] = null,
    public planWorkAssociation: SOGetPlanWorkAssociation[] = null,
    public rolePermission: SOGetRolePermission[] = null,
    public role: SOGetRole[] = null,
    public processRole: SOGetProcessRole[] = null,
    public step: SOGetStep[] = null,
    public processStepAssociation: SOGetProcessStepAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOrganization(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'assessment' in params ? params.assessment : null,
      'assessmentSummary' in params ? params.assessmentSummary : null,
      'block' in params ? params.block : null,
      'deficiency' in params ? params.deficiency : null,
      'period' in params ? params.period : null,
      'report' in params ? params.report : null,
      'requirement' in params ? params.requirement : null,
      'customLogicRequirement' in params ? params.customLogicRequirement : null,
      'work' in params ? params.work : null,
      'realization' in params ? params.realization : null,
      'entity' in params ? params.entity : null,
      'relationship' in params ? params.relationship : null,
      'relationshipAttribute' in params ? params.relationshipAttribute : null,
      'simpleAttribute' in params ? params.simpleAttribute : null,
      'process' in params ? params.process : null,
      'project' in params ? params.project : null,
      'interphace' in params ? params.interphace : null,
      'backlog' in params ? params.backlog : null,
      'attributeSpec' in params ? params.attributeSpec : null,
      'exportProject' in params ? params.exportProject : null,
      'simpleAttributePermission' in params ? params.simpleAttributePermission : null,
      'simpleAttributePermissionRule' in params ? params.simpleAttributePermissionRule : null,
      'relationshipAttributePermission' in params ? params.relationshipAttributePermission : null,
      'relationshipAttributePermissionRule' in params ? params.relationshipAttributePermissionRule : null,
      'entityPermission' in params ? params.entityPermission : null,
      'plan' in params ? params.plan : null,
      'person' in params ? params.person : null,
      'assignedProject' in params ? params.assignedProject : null,
      'planWorkAssociation' in params ? params.planWorkAssociation : null,
      'rolePermission' in params ? params.rolePermission : null,
      'role' in params ? params.role : null,
      'processRole' in params ? params.processRole : null,
      'step' in params ? params.step : null,
      'processStepAssociation' in params ? params.processStepAssociation : null,
    );
  }
}
