import { Type } from 'class-transformer';
import {SORespAssignedProject} from './so-resp-assigned-project';
import {PluralProtocol} from 'lionheartland';
import {AssignedProject} from "../../dexie/db";

export class SOPluralAssignedProject implements PluralProtocol {
  @Type(() => SORespAssignedProject)
  objects: SORespAssignedProject[] = []
  nextOffset: number = null

    toAssignedProjects(): AssignedProject[] {
        return this.objects.map(assignedProject => assignedProject.toAssignedProject());
    }
}