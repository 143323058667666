import {SORespSimpleAttributePermission} from '../../core/simple-attribute-permission/so-resp-simple-attribute-permission';
import {SimpleAttributePermissionCore} from './simple-attribute-permission.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSimpleAttributePermissionFormFields} from './put-simple-attribute-permission-form-fields';
import { CESimpleAttributePermissionAttributes } from './ce-simple-attribute-permission-attributes'


export class CESimpleAttributePermissionBase extends CEBase {

    public details: CESimpleAttributePermissionAttributes;
    public fromFields: PutSimpleAttributePermissionFormFields = new PutSimpleAttributePermissionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SimpleAttributePermissionCore,
        public entity: SORespSimpleAttributePermission,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESimpleAttributePermissionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.whiteList)
        this.attributes.push(this.details.permissionType)
        this.attributes.push(this.details.simpleAttribute)
        this.attributes.push(this.details.simpleAttributePermissionRules)
        this.attributes.push(this.details.rolePermissions)
        this.attributes.push(this.details.project)

    }
}