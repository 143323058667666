import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutPlanWorkAssociation } from './so-put-plan-work-association';
import { SOGetPlanWorkAssociation } from './so-get-plan-work-association';
import { SOPostPlanWorkAssociation } from './so-post-plan-work-association';
import { SODeletePlanWorkAssociation } from './so-delete-plan-work-association';
import {SORespPlanWorkAssociation} from './so-resp-plan-work-association';
import {SOPluralPlanWorkAssociation} from './so-plural-plan-work-association';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOPlanWorkAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('plan_work_association', entityCore);
  }

  /** GET PlanWorkAssociation by id. Will 404 if id not found */
  get(request: SOGetPlanWorkAssociation): Observable<SOPluralPlanWorkAssociation> {
    return this.httpClient.get<SOPluralPlanWorkAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralPlanWorkAssociation, resp)),
      tap(async (response: SOPluralPlanWorkAssociation) => {
        this.log(`fetched PlanWorkAssociation`);
        // After a successful server response, update IndexedDB
        const activities = response.toPlanWorkAssociations(); // Convert your response to an array of PlanWorkAssociation instances
        const promises = activities.map(planWorkAssociation => db.planWorkAssociations.put(planWorkAssociation));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralPlanWorkAssociation>(`get PlanWorkAssociation`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralPlanWorkAssociation> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'planWorkAssociation' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.planWorkAssociations.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(planWorkAssociationsArray => this.processPlanWorkAssociations(planWorkAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "plan" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.planWorkAssociations.where('planUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(planWorkAssociationsArray => this.processPlanWorkAssociations(planWorkAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "work" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.planWorkAssociations.where('workUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(planWorkAssociationsArray => this.processPlanWorkAssociations(planWorkAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.planWorkAssociations.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(planWorkAssociationsArray => this.processPlanWorkAssociations(planWorkAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.planWorkAssociations.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(planWorkAssociationsArray => this.processPlanWorkAssociations(planWorkAssociationsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.planWorkAssociations.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(planWorkAssociationsArray => this.processPlanWorkAssociations(planWorkAssociationsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.planWorkAssociations.toArray()).pipe(
                map((planWorkAssociationsArray) => {
                    // Convert the array of plain objects to an array of SORespPlanWorkAssociation instances
                    const sorPlanWorkAssociations = planWorkAssociationsArray.map(req => plainToClass(SORespPlanWorkAssociation, req));
                    // Create and return a new instance of SOPluralPlanWorkAssociation
                    const pluralPlanWorkAssociation = new SOPluralPlanWorkAssociation();
                    pluralPlanWorkAssociation.objects = sorPlanWorkAssociations;
                    pluralPlanWorkAssociation.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralPlanWorkAssociation;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processPlanWorkAssociations(planWorkAssociationsArray) {
        const sorPlanWorkAssociations = planWorkAssociationsArray.map(req => plainToClass(SORespPlanWorkAssociation, req));
        const pluralPlanWorkAssociation = new SOPluralPlanWorkAssociation();
        pluralPlanWorkAssociation.objects = sorPlanWorkAssociations;
        pluralPlanWorkAssociation.nextOffset = null;
        return pluralPlanWorkAssociation;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostPlanWorkAssociation): Observable<SORespPlanWorkAssociation> {
  return this.httpClient.post<SORespPlanWorkAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespPlanWorkAssociation, resp)),

    tap(async (response: SORespPlanWorkAssociation) => {
      this.log(`added PlanWorkAssociation w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.planWorkAssociations.add(response.toPlanWorkAssociation());
    }),
    catchError(this.handleError<SORespPlanWorkAssociation>('PlanWorkAssociation post'))
  );
}


  put(request: SOPutPlanWorkAssociation): Observable<SORespPlanWorkAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPlanWorkAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespPlanWorkAssociation, resp)),
      tap(async (response: SORespPlanWorkAssociation) => {
        this.log(`edited PlanWorkAssociation w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.planWorkAssociations.put(response.toPlanWorkAssociation());
      }),
      catchError(this.handleError<SORespPlanWorkAssociation>('PlanWorkAssociation put'))
    );
  }


  delete(request: SODeletePlanWorkAssociation): Observable<SORespPlanWorkAssociation> {
    return this.httpClient.delete<SORespPlanWorkAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted PlanWorkAssociation uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.planWorkAssociations.delete(request.uid);
      }),
      catchError(this.handleError<SORespPlanWorkAssociation>('PlanWorkAssociation delete'))
    );
  }


}
