import {SORespRelationship} from '../../core/relationship/so-resp-relationship';
import {RelationshipCore} from './relationship.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipFormFields} from './put-relationship-form-fields';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERelationshipAttributesBase {

    name: DetailEntry
    description: DetailEntry
    relationshipAttributes: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRelationship,
        app: CommanderApp,
        formFields: PutRelationshipFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const relationshipAttributesCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributesCore.soGet = SOGetRelationshipAttribute.construct({
          relationship: new SOGetRelationship(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributesCore
        )
        relationshipAttributesCore.title = heading ? heading : relationshipAttributesCore.title
        relationshipAttributesCore.listTitle = 'Relationship attributes'

        const relationshipAttributesSelectCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributesSelectCore.soGet = SOGetRelationshipAttribute.construct({
          relationship: new SOGetRelationship(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributesSelectCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributesSelectCore.soGet.queryOptions.descending = true;
        relationshipAttributesSelectCore.soGet.predicate = 'listSelect'
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributesSelectCore
        )
        relationshipAttributesSelectCore.title = heading ? heading : relationshipAttributesSelectCore.title
        relationshipAttributesSelectCore.listTitle = 'Relationship attributes'
        relationshipAttributesSelectCore.selectItemFromCoreParentGet = new SOGetRelationship(entity.uid)
        relationshipAttributesSelectCore.selectItemFromCoreParentGetIdentifier = 'relationship'
        relationshipAttributesSelectCore.checklistAttributeName = 'relationship'
        relationshipAttributesCore.selectItemFromCore = relationshipAttributesSelectCore
        relationshipAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributes = new DetailEntry(
          heading ? heading : relationshipAttributesCore.listTitle,
          null,
          'relationshipAttributes',
          BaseDetailType.embeddedList,
          relationshipAttributesCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}