import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutCustomLogicRequirement } from './so-put-custom-logic-requirement';
import { SOGetCustomLogicRequirement } from './so-get-custom-logic-requirement';
import { SOPostCustomLogicRequirement } from './so-post-custom-logic-requirement';
import { SODeleteCustomLogicRequirement } from './so-delete-custom-logic-requirement';
import {SORespCustomLogicRequirement} from './so-resp-custom-logic-requirement';
import {SOPluralCustomLogicRequirement} from './so-plural-custom-logic-requirement';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOCustomLogicRequirementService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('custom_logic_requirement', entityCore);
  }

  /** GET CustomLogicRequirement by id. Will 404 if id not found */
  get(request: SOGetCustomLogicRequirement): Observable<SOPluralCustomLogicRequirement> {
    return this.httpClient.get<SOPluralCustomLogicRequirement>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralCustomLogicRequirement, resp)),
      tap(async (response: SOPluralCustomLogicRequirement) => {
        this.log(`fetched CustomLogicRequirement`);
        // After a successful server response, update IndexedDB
        const activities = response.toCustomLogicRequirements(); // Convert your response to an array of CustomLogicRequirement instances
        const promises = activities.map(customLogicRequirement => db.customLogicRequirements.put(customLogicRequirement));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralCustomLogicRequirement>(`get CustomLogicRequirement`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralCustomLogicRequirement> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'customLogicRequirement' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.customLogicRequirements.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "customLogicRequirements" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('customLogicRequirementsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "parent" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "blocks" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('blocksUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "simpleAttributes" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('simpleAttributesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationshipAttributes" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('relationshipAttributesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.customLogicRequirements.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.customLogicRequirements.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.customLogicRequirements.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(customLogicRequirementsArray => this.processCustomLogicRequirements(customLogicRequirementsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.customLogicRequirements.toArray()).pipe(
                map((customLogicRequirementsArray) => {
                    // Convert the array of plain objects to an array of SORespCustomLogicRequirement instances
                    const sorCustomLogicRequirements = customLogicRequirementsArray.map(req => plainToClass(SORespCustomLogicRequirement, req));
                    // Create and return a new instance of SOPluralCustomLogicRequirement
                    const pluralCustomLogicRequirement = new SOPluralCustomLogicRequirement();
                    pluralCustomLogicRequirement.objects = sorCustomLogicRequirements;
                    pluralCustomLogicRequirement.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralCustomLogicRequirement;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processCustomLogicRequirements(customLogicRequirementsArray) {
        const sorCustomLogicRequirements = customLogicRequirementsArray.map(req => plainToClass(SORespCustomLogicRequirement, req));
        const pluralCustomLogicRequirement = new SOPluralCustomLogicRequirement();
        pluralCustomLogicRequirement.objects = sorCustomLogicRequirements;
        pluralCustomLogicRequirement.nextOffset = null;
        return pluralCustomLogicRequirement;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostCustomLogicRequirement): Observable<SORespCustomLogicRequirement> {
  return this.httpClient.post<SORespCustomLogicRequirement>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespCustomLogicRequirement, resp)),

    tap(async (response: SORespCustomLogicRequirement) => {
      this.log(`added CustomLogicRequirement w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.customLogicRequirements.add(response.toCustomLogicRequirement());
    }),
    catchError(this.handleError<SORespCustomLogicRequirement>('CustomLogicRequirement post'))
  );
}


  put(request: SOPutCustomLogicRequirement): Observable<SORespCustomLogicRequirement> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCustomLogicRequirement>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespCustomLogicRequirement, resp)),
      tap(async (response: SORespCustomLogicRequirement) => {
        this.log(`edited CustomLogicRequirement w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.customLogicRequirements.put(response.toCustomLogicRequirement());
      }),
      catchError(this.handleError<SORespCustomLogicRequirement>('CustomLogicRequirement put'))
    );
  }


  delete(request: SODeleteCustomLogicRequirement): Observable<SORespCustomLogicRequirement> {
    return this.httpClient.delete<SORespCustomLogicRequirement>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted CustomLogicRequirement uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.customLogicRequirements.delete(request.uid);
      }),
      catchError(this.handleError<SORespCustomLogicRequirement>('CustomLogicRequirement delete'))
    );
  }


}
