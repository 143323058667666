import {SORespSubscription} from '../../core/subscription/so-resp-subscription';
import {SubscriptionCore} from './subscription.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSubscriptionFormFields} from './put-subscription-form-fields';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';


export class CESubscriptionAttributesBase {

    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    dateExpires: DetailEntry
    exRef: DetailEntry
    organization: DetailEntry
    price: DetailEntry
    status: DetailEntry
    invoices: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSubscription,
        app: CommanderApp,
        formFields: PutSubscriptionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateExpires = new DetailEntry(
            'Date expires',
            app.activeUserService.time.dateAsString(entity.dateExpires),
            'dateExpires',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateExpires
        )

        this.exRef = new DetailEntry(
            'Ex ref',
            entity.exRef,
            'exRef',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.status
        )


        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        let organizationCeProtocol;
        if (entity.organization) {
            organizationCeProtocol = createCEProtocol(
                organizationCore.ceProtocol,
                parentEntityViewSpec,
                organizationCore,
                entity.organization,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        if (entity.organization) {
            displayStringFactory.reconfigure(
                entity.organization,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          organizationCeProtocol ? organizationCeProtocol.entityListView.fullHeading : null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.never
        )
        if (entity.organization) {
          this.organization.ceProtocol = organizationCeProtocol;
          this.organization.cachedListEntry = organizationCore.makeListEntry(parentEntityViewSpec, entity.organization, 0)
          organizationCore.selectedEntity = entity.organization;
        }
        this.organization.singleFieldSelect = true

        const priceCore = app.makeCore( 'price');
        priceCore.soGet = SOGetPrice.construct({
        });
        let priceCeProtocol;
        if (entity.price) {
            priceCeProtocol = createCEProtocol(
                priceCore.ceProtocol,
                parentEntityViewSpec,
                priceCore,
                entity.price,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            priceCore
        )
        priceCore.title = heading ? heading : priceCore.title
        priceCore.listTitle = 'Price'

        if (entity.price) {
            displayStringFactory.reconfigure(
                entity.price,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        priceCore.configureForDisplay(parentEntityViewSpec)

        this.price = new DetailEntry(
          heading ? heading : priceCore.listTitle,
          priceCeProtocol ? priceCeProtocol.entityListView.fullHeading : null,
          'price',
          BaseDetailType.pushedDetail,
          priceCore,
          EditableType.never
        )
        if (entity.price) {
          this.price.ceProtocol = priceCeProtocol;
          this.price.cachedListEntry = priceCore.makeListEntry(parentEntityViewSpec, entity.price, 0)
          priceCore.selectedEntity = entity.price;
        }
        this.price.singleFieldSelect = true

        const invoicesCore = app.makeCore( 'invoice');
        invoicesCore.soGet = SOGetInvoice.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoicesCore
        )
        invoicesCore.title = heading ? heading : invoicesCore.title
        invoicesCore.listTitle = 'Invoices'

        invoicesCore.configureForDisplay(parentEntityViewSpec)

        this.invoices = new DetailEntry(
          heading ? heading : invoicesCore.listTitle,
          null,
          'invoices',
          BaseDetailType.embeddedList,
          invoicesCore,
          EditableType.never
        )
    }
}