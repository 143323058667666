import { Type } from 'class-transformer';
import {SORespPeriod} from './so-resp-period';
import {PluralProtocol} from 'lionheartland';
import {Period} from "../../dexie/db";

export class SOPluralPeriod implements PluralProtocol {
  @Type(() => SORespPeriod)
  objects: SORespPeriod[] = []
  nextOffset: number = null

    toPeriods(): Period[] {
        return this.objects.map(period => period.toPeriod());
    }
}