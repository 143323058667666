import { SOGetMedia } from '../media/so-get-media';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAttachment {

  constructor(
    public media: SOGetMedia,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAttachment(
      'media' in params ? params.media : null,
      'organization' in params ? params.organization : null,
    );
  }
}
