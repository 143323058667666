import {SORespOrganizationInvite} from '../../core/organization-invite/so-resp-organization-invite';
import {OrganizationInviteCore} from './organization-invite.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutOrganizationInviteFormFields} from './put-organization-invite-form-fields';
import { CEOrganizationInviteAttributes } from './ce-organization-invite-attributes'


export class CEOrganizationInviteBase extends CEBase {

    public details: CEOrganizationInviteAttributes;
    public fromFields: PutOrganizationInviteFormFields = new PutOrganizationInviteFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: OrganizationInviteCore,
        public entity: SORespOrganizationInvite,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEOrganizationInviteAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.email)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.user)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.organization)

    }
}