import { SOGetPerson } from '../person/so-get-person';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutAssignedProject {

  constructor(
    public uid: string,
    public name: string = null,
    public person: SOGetPerson = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAssignedProject(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'person' in params ? params.person : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
