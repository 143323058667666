import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostIngest {

  constructor(
    public entityName: string,
    public data: any,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostIngest(
      'entityName' in params ? params.entityName : null,
      'data' in params ? params.data : null,
      'organization' in params ? params.organization : null,
    );
  }
}
