import { Type } from 'class-transformer';
import {SORespSesMessage} from './so-resp-ses-message';
import {PluralProtocol} from 'lionheartland';
import {SesMessage} from "../../dexie/db";

export class SOPluralSesMessage implements PluralProtocol {
  @Type(() => SORespSesMessage)
  objects: SORespSesMessage[] = []
  nextOffset: number = null

    toSesMessages(): SesMessage[] {
        return this.objects.map(sesMessage => sesMessage.toSesMessage());
    }
}