import {SOPutTopic} from '../../core/topic/so-put-topic';
import {TopicCore} from './topic.core';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {FormField, SelectionOption} from 'lionheartland';
import {putTopicFormFieldOptions} from './put-topic-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutTopic extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTopicFormFieldOptions);

  RequestClass = SOPutTopic

  constructor(
    public entityCore: TopicCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}