import {SOPutPlanWorkAssociation} from '../../core/plan-work-association/so-put-plan-work-association';
import {PlanWorkAssociationCore} from './plan-work-association.core';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {FormField, SelectionOption} from 'lionheartland';
import {putPlanWorkAssociationFormFieldOptions} from './put-plan-work-association-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {PlanCore} from '../plan/plan.core';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {WorkCore} from '../work/work.core';
import {SOGetWork} from '../../core/work/so-get-work';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutPlanWorkAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putPlanWorkAssociationFormFieldOptions);

  RequestClass = SOPutPlanWorkAssociation

  constructor(
    public entityCore: PlanWorkAssociationCore
  ) {
    super();
    if (putPlanWorkAssociationFormFieldOptions.plan) {
      putPlanWorkAssociationFormFieldOptions.plan.makeCore = true;
    }
    if (putPlanWorkAssociationFormFieldOptions.work) {
      putPlanWorkAssociationFormFieldOptions.work.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('reportSection'),
      this.getFormValueFromIdentifier(
        'plan',
        SOGetPlan,
      ),
      this.getFormValueFromIdentifier(
        'work',
        SOGetWork,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}