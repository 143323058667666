import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutAppliedConstraint } from './so-put-applied-constraint';
import { SOGetAppliedConstraint } from './so-get-applied-constraint';
import { SOPostAppliedConstraint } from './so-post-applied-constraint';
import { SODeleteAppliedConstraint } from './so-delete-applied-constraint';
import {SORespAppliedConstraint} from './so-resp-applied-constraint';
import {SOPluralAppliedConstraint} from './so-plural-applied-constraint';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOAppliedConstraintService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('applied_constraint', entityCore);
  }

  /** GET AppliedConstraint by id. Will 404 if id not found */
  get(request: SOGetAppliedConstraint): Observable<SOPluralAppliedConstraint> {
    return this.httpClient.get<SOPluralAppliedConstraint>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralAppliedConstraint, resp)),
      tap(async (response: SOPluralAppliedConstraint) => {
        this.log(`fetched AppliedConstraint`);
        // After a successful server response, update IndexedDB
        const activities = response.toAppliedConstraints(); // Convert your response to an array of AppliedConstraint instances
        const promises = activities.map(appliedConstraint => db.appliedConstraints.put(appliedConstraint));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralAppliedConstraint>(`get AppliedConstraint`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralAppliedConstraint> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.appliedConstraints.toArray()).pipe(
                map((appliedConstraintsArray) => {
                    // Convert the array of plain objects to an array of SORespAppliedConstraint instances
                    const sorAppliedConstraints = appliedConstraintsArray.map(req => plainToClass(SORespAppliedConstraint, req));
                    // Create and return a new instance of SOPluralAppliedConstraint
                    const pluralAppliedConstraint = new SOPluralAppliedConstraint();
                    pluralAppliedConstraint.objects = sorAppliedConstraints;
                    pluralAppliedConstraint.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralAppliedConstraint;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processAppliedConstraints(appliedConstraintsArray) {
        const sorAppliedConstraints = appliedConstraintsArray.map(req => plainToClass(SORespAppliedConstraint, req));
        const pluralAppliedConstraint = new SOPluralAppliedConstraint();
        pluralAppliedConstraint.objects = sorAppliedConstraints;
        pluralAppliedConstraint.nextOffset = null;
        return pluralAppliedConstraint;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostAppliedConstraint): Observable<SORespAppliedConstraint> {
  return this.httpClient.post<SORespAppliedConstraint>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespAppliedConstraint, resp)),

    tap(async (response: SORespAppliedConstraint) => {
      this.log(`added AppliedConstraint w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.appliedConstraints.add(response.toAppliedConstraint());
    }),
    catchError(this.handleError<SORespAppliedConstraint>('AppliedConstraint post'))
  );
}


  put(request: SOPutAppliedConstraint): Observable<SORespAppliedConstraint> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAppliedConstraint>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespAppliedConstraint, resp)),
      tap(async (response: SORespAppliedConstraint) => {
        this.log(`edited AppliedConstraint w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.appliedConstraints.put(response.toAppliedConstraint());
      }),
      catchError(this.handleError<SORespAppliedConstraint>('AppliedConstraint put'))
    );
  }


  delete(request: SODeleteAppliedConstraint): Observable<SORespAppliedConstraint> {
    return this.httpClient.delete<SORespAppliedConstraint>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted AppliedConstraint uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.appliedConstraints.delete(request.uid);
      }),
      catchError(this.handleError<SORespAppliedConstraint>('AppliedConstraint delete'))
    );
  }


}
