import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOUserService} from '../../core/user/eo-user';
import {SOGetUser} from '../../core/user/so-get-user';
import {RAPutUser} from './ra-put-user';
import {RAPostUser} from './ra-post-user';
import {RADeleteUser} from './ra-delete-user';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEUser } from "./ce-user"


export class UserCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'user';
  title = 'User';
  ceProtocol: CEConstructor = CEUser

  entityService: EOUserService;
  soGetClass = SOGetUser;
  soGet: SOGetUser;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOUserService,
      null,
    );
    this.adapters = [
      new RAPostUser(this),
      new RAPutUser(this),
      new RADeleteUser(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetUser();
    this.soGet.queryOptions.limit = this.limit;
  }


}