import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Report} from "../../dexie/db";

export class SORespReport implements ObjectiveProtocol {

  entityType: string = 'report';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => Date)
  dateCreated: Date;
  active: boolean;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toReport(): Report {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: this.dateCreated,
            active: this.active,
            periodUid: null,
            assessmentsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}