import {SORespRealization} from '../../core/realization/so-resp-realization';
import {RealizationCore} from './realization.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRealizationFormFields} from './put-realization-form-fields';
import { CERealizationAttributes } from './ce-realization-attributes'


export class CERealizationBase extends CEBase {

    public details: CERealizationAttributes;
    public fromFields: PutRealizationFormFields = new PutRealizationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RealizationCore,
        public entity: SORespRealization,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERealizationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.entity)
        this.attributes.push(this.details.simpleAttribute)
        this.attributes.push(this.details.relationshipAttribute)
        this.attributes.push(this.details.requirement)
        this.attributes.push(this.details.process)
        this.attributes.push(this.details.attributeSpec)
        this.attributes.push(this.details.project)

    }
}