import { Type } from 'class-transformer';
import {SORespBlock} from './so-resp-block';
import {PluralProtocol} from 'lionheartland';
import {Block} from "../../dexie/db";

export class SOPluralBlock implements PluralProtocol {
  @Type(() => SORespBlock)
  objects: SORespBlock[] = []
  nextOffset: number = null

    toBlocks(): Block[] {
        return this.objects.map(block => block.toBlock());
    }
}