import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutMedia } from './so-put-media';
import { SOGetMedia } from './so-get-media';
import { SOPostMedia } from './so-post-media';
import { SODeleteMedia } from './so-delete-media';
import {SORespMedia} from './so-resp-media';
import {SOPluralMedia} from './so-plural-media';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOMediaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('media', entityCore);
  }

  /** GET Media by id. Will 404 if id not found */
  get(request: SOGetMedia): Observable<SOPluralMedia> {
    return this.httpClient.get<SOPluralMedia>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralMedia, resp)),
      tap(async (response: SOPluralMedia) => {
        this.log(`fetched Media`);
        // After a successful server response, update IndexedDB
        const activities = response.toMedias(); // Convert your response to an array of Media instances
        const promises = activities.map(media => db.medias.put(media));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralMedia>(`get Media`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralMedia> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'media' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.medias.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(mediasArray => this.processMedias(mediasArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "attachments" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.medias.where('attachmentsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(mediasArray => this.processMedias(mediasArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.medias.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(mediasArray => this.processMedias(mediasArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.medias.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(mediasArray => this.processMedias(mediasArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.medias.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(mediasArray => this.processMedias(mediasArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.medias.toArray()).pipe(
                map((mediasArray) => {
                    // Convert the array of plain objects to an array of SORespMedia instances
                    const sorMedias = mediasArray.map(req => plainToClass(SORespMedia, req));
                    // Create and return a new instance of SOPluralMedia
                    const pluralMedia = new SOPluralMedia();
                    pluralMedia.objects = sorMedias;
                    pluralMedia.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralMedia;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processMedias(mediasArray) {
        const sorMedias = mediasArray.map(req => plainToClass(SORespMedia, req));
        const pluralMedia = new SOPluralMedia();
        pluralMedia.objects = sorMedias;
        pluralMedia.nextOffset = null;
        return pluralMedia;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostMedia): Observable<SORespMedia> {
  return this.httpClient.post<SORespMedia>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespMedia, resp)),

    tap(async (response: SORespMedia) => {
      this.log(`added Media w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.medias.add(response.toMedia());
    }),
    catchError(this.handleError<SORespMedia>('Media post'))
  );
}


  put(request: SOPutMedia): Observable<SORespMedia> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespMedia>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespMedia, resp)),
      tap(async (response: SORespMedia) => {
        this.log(`edited Media w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.medias.put(response.toMedia());
      }),
      catchError(this.handleError<SORespMedia>('Media put'))
    );
  }


  delete(request: SODeleteMedia): Observable<SORespMedia> {
    return this.httpClient.delete<SORespMedia>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Media uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.medias.delete(request.uid);
      }),
      catchError(this.handleError<SORespMedia>('Media delete'))
    );
  }



/** POST: add a new File to the server */
  postFile(request: SOPostMedia, file: File): Observable<SORespMedia> {

    let formData = new FormData();
    formData.append('request', JSON.stringify(classToPlain(request)))
    formData.append('file', file);

    const options = this.httpOptions
    options.headers.delete('Content-Type')

    return this.httpClient.post<SORespMedia>(this.coreUrl + this.route, formData, options).pipe(
      map(resp => { return plainToClass(SORespMedia, resp) }),
      tap((response: SORespMedia) => this.log(`added Media w/ id=${response.uid}`)),
      catchError(this.handleError<SORespMedia>('Media post'))
    );
  }
}
