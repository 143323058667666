import { Type } from 'class-transformer';
import {SORespIngest} from './so-resp-ingest';
import {PluralProtocol} from 'lionheartland';
import {Ingest} from "../../dexie/db";

export class SOPluralIngest implements PluralProtocol {
  @Type(() => SORespIngest)
  objects: SORespIngest[] = []
  nextOffset: number = null

    toIngests(): Ingest[] {
        return this.objects.map(ingest => ingest.toIngest());
    }
}