import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODatafeedService} from '../../core/datafeed/eo-datafeed';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {RAPutDatafeed} from './ra-put-datafeed';
import {RAPostDatafeed} from './ra-post-datafeed';
import {RADeleteDatafeed} from './ra-delete-datafeed';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetUser} from '../../core/user/so-get-user'
import { CEDatafeed } from "./ce-datafeed"


export class DatafeedCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'datafeed';
  title = 'Datafeed';
  ceProtocol: CEConstructor = CEDatafeed

  entityService: EODatafeedService;
  soGetClass = SOGetDatafeed;
  soGet: SOGetDatafeed;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODatafeedService,
      null,
    );
    this.adapters = [
      new RAPostDatafeed(this),
      new RAPutDatafeed(this),
      new RADeleteDatafeed(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDatafeed();
    if (this.activeUserService.getScope('user')) {
        this.soGet.user = new SOGetUser(
          this.activeUserService.getScope('user').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}