import { SOGetUser } from '../user/so-get-user';
import { SOGetRole } from '../role/so-get-role';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRoleGrant {

  constructor(
    public user: SOGetUser,
    public role: SOGetRole,
    public isDefault: boolean = false,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRoleGrant(
      'isDefault' in params ? params.isDefault : null,
      'user' in params ? params.user : null,
      'role' in params ? params.role : null,
      'organization' in params ? params.organization : null,
    );
  }
}
