import {SORespProject} from '../../core/project/so-resp-project';
import {ProjectCore} from './project.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProjectFormFields} from './put-project-form-fields';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {SOGetBlock} from '../../core/block/so-get-block';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetReport} from '../../core/report/so-get-report';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetWork} from '../../core/work/so-get-work';
import {SOGetProcess} from '../../core/process/so-get-process';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SOGetBacklog} from '../../core/backlog/so-get-backlog';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {SOGetExportProject} from '../../core/export-project/so-get-export-project';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {SOGetRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {SOGetAssignedProject} from '../../core/assigned-project/so-get-assigned-project';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {SOGetStep} from '../../core/step/so-get-step';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEProjectAttributesBase {

    name: DetailEntry
    description: DetailEntry
    assessments: DetailEntry
    assessmentSummarys: DetailEntry
    blocks: DetailEntry
    deficiencys: DetailEntry
    entitys: DetailEntry
    periods: DetailEntry
    realizations: DetailEntry
    relationships: DetailEntry
    relationshipAttributes: DetailEntry
    reports: DetailEntry
    requirements: DetailEntry
    customLogicRequirements: DetailEntry
    simpleAttributes: DetailEntry
    works: DetailEntry
    processes: DetailEntry
    interphaces: DetailEntry
    backlogs: DetailEntry
    attributeSpec: DetailEntry
    exportProject: DetailEntry
    simpleAttributePermission: DetailEntry
    simpleAttributePermissionRule: DetailEntry
    relationshipAttributePermission: DetailEntry
    relationshipAttributePermissionRule: DetailEntry
    entityPermission: DetailEntry
    plans: DetailEntry
    assignedProjects: DetailEntry
    rolePermissions: DetailEntry
    processRoles: DetailEntry
    steps: DetailEntry
    processStepAssociations: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProject,
        app: CommanderApp,
        formFields: PutProjectFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const assessmentsCore = app.makeCore( 'assessment');
        assessmentsCore.soGet = SOGetAssessment.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        assessmentsCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentsCore
        )
        assessmentsCore.title = heading ? heading : assessmentsCore.title
        assessmentsCore.listTitle = 'Assessments'

        assessmentsCore.configureForDisplay(parentEntityViewSpec)

        this.assessments = new DetailEntry(
          heading ? heading : assessmentsCore.listTitle,
          null,
          'assessments',
          BaseDetailType.embeddedList,
          assessmentsCore,
          EditableType.never
        )

        const assessmentSummarysCore = app.makeCore( 'assessmentSummary');
        assessmentSummarysCore.soGet = SOGetAssessmentSummary.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        assessmentSummarysCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentSummarysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentSummarysCore
        )
        assessmentSummarysCore.title = heading ? heading : assessmentSummarysCore.title
        assessmentSummarysCore.listTitle = 'Assessment summarys'

        assessmentSummarysCore.configureForDisplay(parentEntityViewSpec)

        this.assessmentSummarys = new DetailEntry(
          heading ? heading : assessmentSummarysCore.listTitle,
          null,
          'assessmentSummarys',
          BaseDetailType.embeddedList,
          assessmentSummarysCore,
          EditableType.never
        )

        const blocksCore = app.makeCore( 'block');
        blocksCore.soGet = SOGetBlock.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        blocksCore.soGet.queryOptions.sortKey = 'dateCreated';
        blocksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            blocksCore
        )
        blocksCore.title = heading ? heading : blocksCore.title
        blocksCore.listTitle = 'Blocks'

        blocksCore.configureForDisplay(parentEntityViewSpec)

        this.blocks = new DetailEntry(
          heading ? heading : blocksCore.listTitle,
          null,
          'blocks',
          BaseDetailType.embeddedList,
          blocksCore,
          EditableType.never
        )

        const deficiencysCore = app.makeCore( 'deficiency');
        deficiencysCore.soGet = SOGetDeficiency.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        deficiencysCore.soGet.queryOptions.sortKey = 'dateCreated';
        deficiencysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deficiencysCore
        )
        deficiencysCore.title = heading ? heading : deficiencysCore.title
        deficiencysCore.listTitle = 'Deficiencys'

        deficiencysCore.configureForDisplay(parentEntityViewSpec)

        this.deficiencys = new DetailEntry(
          heading ? heading : deficiencysCore.listTitle,
          null,
          'deficiencys',
          BaseDetailType.embeddedList,
          deficiencysCore,
          EditableType.never
        )

        const entitysCore = app.makeCore( 'entity');
        entitysCore.soGet = SOGetEntity.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entitysCore.soGet.queryOptions.sortKey = 'dateCreated';
        entitysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entitysCore
        )
        entitysCore.title = heading ? heading : entitysCore.title
        entitysCore.listTitle = 'Entitys'

        entitysCore.configureForDisplay(parentEntityViewSpec)

        this.entitys = new DetailEntry(
          heading ? heading : entitysCore.listTitle,
          null,
          'entitys',
          BaseDetailType.embeddedList,
          entitysCore,
          EditableType.never
        )

        const periodsCore = app.makeCore( 'period');
        periodsCore.soGet = SOGetPeriod.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        periodsCore.soGet.queryOptions.sortKey = 'dateCreated';
        periodsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            periodsCore
        )
        periodsCore.title = heading ? heading : periodsCore.title
        periodsCore.listTitle = 'Periods'

        periodsCore.configureForDisplay(parentEntityViewSpec)

        this.periods = new DetailEntry(
          heading ? heading : periodsCore.listTitle,
          null,
          'periods',
          BaseDetailType.embeddedList,
          periodsCore,
          EditableType.never
        )

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const relationshipsCore = app.makeCore( 'relationship');
        relationshipsCore.soGet = SOGetRelationship.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipsCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipsCore
        )
        relationshipsCore.title = heading ? heading : relationshipsCore.title
        relationshipsCore.listTitle = 'Relationships'

        relationshipsCore.configureForDisplay(parentEntityViewSpec)

        this.relationships = new DetailEntry(
          heading ? heading : relationshipsCore.listTitle,
          null,
          'relationships',
          BaseDetailType.embeddedList,
          relationshipsCore,
          EditableType.never
        )

        const relationshipAttributesCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributesCore.soGet = SOGetRelationshipAttribute.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributesCore
        )
        relationshipAttributesCore.title = heading ? heading : relationshipAttributesCore.title
        relationshipAttributesCore.listTitle = 'Relationship attributes'

        relationshipAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributes = new DetailEntry(
          heading ? heading : relationshipAttributesCore.listTitle,
          null,
          'relationshipAttributes',
          BaseDetailType.embeddedList,
          relationshipAttributesCore,
          EditableType.never
        )

        const reportsCore = app.makeCore( 'report');
        reportsCore.soGet = SOGetReport.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        reportsCore.soGet.queryOptions.sortKey = 'dateCreated';
        reportsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reportsCore
        )
        reportsCore.title = heading ? heading : reportsCore.title
        reportsCore.listTitle = 'Reports'

        reportsCore.configureForDisplay(parentEntityViewSpec)

        this.reports = new DetailEntry(
          heading ? heading : reportsCore.listTitle,
          null,
          'reports',
          BaseDetailType.embeddedList,
          reportsCore,
          EditableType.never
        )

        const requirementsCore = app.makeCore( 'requirement');
        requirementsCore.soGet = SOGetRequirement.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        requirementsCore.soGet.queryOptions.sortKey = 'dateCreated';
        requirementsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            requirementsCore
        )
        requirementsCore.title = heading ? heading : requirementsCore.title
        requirementsCore.listTitle = 'Requirements'

        requirementsCore.configureForDisplay(parentEntityViewSpec)

        this.requirements = new DetailEntry(
          heading ? heading : requirementsCore.listTitle,
          null,
          'requirements',
          BaseDetailType.embeddedList,
          requirementsCore,
          EditableType.never
        )

        const customLogicRequirementsCore = app.makeCore( 'customLogicRequirement');
        customLogicRequirementsCore.soGet = SOGetCustomLogicRequirement.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        customLogicRequirementsCore.soGet.queryOptions.sortKey = 'dateCreated';
        customLogicRequirementsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customLogicRequirementsCore
        )
        customLogicRequirementsCore.title = heading ? heading : customLogicRequirementsCore.title
        customLogicRequirementsCore.listTitle = 'Custom logic requirements'

        customLogicRequirementsCore.configureForDisplay(parentEntityViewSpec)

        this.customLogicRequirements = new DetailEntry(
          heading ? heading : customLogicRequirementsCore.listTitle,
          null,
          'customLogicRequirements',
          BaseDetailType.embeddedList,
          customLogicRequirementsCore,
          EditableType.never
        )

        const simpleAttributesCore = app.makeCore( 'simpleAttribute');
        simpleAttributesCore.soGet = SOGetSimpleAttribute.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributesCore
        )
        simpleAttributesCore.title = heading ? heading : simpleAttributesCore.title
        simpleAttributesCore.listTitle = 'Simple attributes'

        simpleAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributes = new DetailEntry(
          heading ? heading : simpleAttributesCore.listTitle,
          null,
          'simpleAttributes',
          BaseDetailType.embeddedList,
          simpleAttributesCore,
          EditableType.never
        )

        const worksCore = app.makeCore( 'work');
        worksCore.soGet = SOGetWork.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        worksCore.soGet.queryOptions.sortKey = 'dateCreated';
        worksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            worksCore
        )
        worksCore.title = heading ? heading : worksCore.title
        worksCore.listTitle = 'Works'

        worksCore.configureForDisplay(parentEntityViewSpec)

        this.works = new DetailEntry(
          heading ? heading : worksCore.listTitle,
          null,
          'works',
          BaseDetailType.embeddedList,
          worksCore,
          EditableType.never
        )

        const processesCore = app.makeCore( 'process');
        processesCore.soGet = SOGetProcess.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processesCore.soGet.queryOptions.sortKey = 'dateCreated';
        processesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processesCore
        )
        processesCore.title = heading ? heading : processesCore.title
        processesCore.listTitle = 'Processes'

        processesCore.configureForDisplay(parentEntityViewSpec)

        this.processes = new DetailEntry(
          heading ? heading : processesCore.listTitle,
          null,
          'processes',
          BaseDetailType.embeddedList,
          processesCore,
          EditableType.never
        )

        const interphacesCore = app.makeCore( 'interphace');
        interphacesCore.soGet = SOGetInterphace.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        interphacesCore.soGet.queryOptions.sortKey = 'dateCreated';
        interphacesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            interphacesCore
        )
        interphacesCore.title = heading ? heading : interphacesCore.title
        interphacesCore.listTitle = 'Interphaces'

        interphacesCore.configureForDisplay(parentEntityViewSpec)

        this.interphaces = new DetailEntry(
          heading ? heading : interphacesCore.listTitle,
          null,
          'interphaces',
          BaseDetailType.embeddedList,
          interphacesCore,
          EditableType.never
        )

        const backlogsCore = app.makeCore( 'backlog');
        backlogsCore.soGet = SOGetBacklog.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        backlogsCore.soGet.queryOptions.sortKey = 'dateCreated';
        backlogsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            backlogsCore
        )
        backlogsCore.title = heading ? heading : backlogsCore.title
        backlogsCore.listTitle = 'Backlogs'

        backlogsCore.configureForDisplay(parentEntityViewSpec)

        this.backlogs = new DetailEntry(
          heading ? heading : backlogsCore.listTitle,
          null,
          'backlogs',
          BaseDetailType.embeddedList,
          backlogsCore,
          EditableType.never
        )

        const attributeSpecCore = app.makeCore( 'attributeSpec');
        attributeSpecCore.soGet = SOGetAttributeSpec.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        attributeSpecCore.soGet.queryOptions.sortKey = 'dateCreated';
        attributeSpecCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attributeSpecCore
        )
        attributeSpecCore.title = heading ? heading : attributeSpecCore.title
        attributeSpecCore.listTitle = 'Attribute spec'

        attributeSpecCore.configureForDisplay(parentEntityViewSpec)

        this.attributeSpec = new DetailEntry(
          heading ? heading : attributeSpecCore.listTitle,
          null,
          'attributeSpec',
          BaseDetailType.embeddedList,
          attributeSpecCore,
          EditableType.never
        )

        const exportProjectCore = app.makeCore( 'exportProject');
        exportProjectCore.soGet = SOGetExportProject.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        exportProjectCore.soGet.queryOptions.sortKey = 'dateCreated';
        exportProjectCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            exportProjectCore
        )
        exportProjectCore.title = heading ? heading : exportProjectCore.title
        exportProjectCore.listTitle = 'Export project'

        exportProjectCore.configureForDisplay(parentEntityViewSpec)

        this.exportProject = new DetailEntry(
          heading ? heading : exportProjectCore.listTitle,
          null,
          'exportProject',
          BaseDetailType.embeddedList,
          exportProjectCore,
          EditableType.never
        )

        const simpleAttributePermissionCore = app.makeCore( 'simpleAttributePermission');
        simpleAttributePermissionCore.soGet = SOGetSimpleAttributePermission.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionCore
        )
        simpleAttributePermissionCore.title = heading ? heading : simpleAttributePermissionCore.title
        simpleAttributePermissionCore.listTitle = 'Simple attribute permission'

        simpleAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermission = new DetailEntry(
          heading ? heading : simpleAttributePermissionCore.listTitle,
          null,
          'simpleAttributePermission',
          BaseDetailType.embeddedList,
          simpleAttributePermissionCore,
          EditableType.never
        )

        const simpleAttributePermissionRuleCore = app.makeCore( 'simpleAttributePermissionRule');
        simpleAttributePermissionRuleCore.soGet = SOGetSimpleAttributePermissionRule.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionRuleCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionRuleCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionRuleCore
        )
        simpleAttributePermissionRuleCore.title = heading ? heading : simpleAttributePermissionRuleCore.title
        simpleAttributePermissionRuleCore.listTitle = 'Simple attribute permission rule'

        simpleAttributePermissionRuleCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermissionRule = new DetailEntry(
          heading ? heading : simpleAttributePermissionRuleCore.listTitle,
          null,
          'simpleAttributePermissionRule',
          BaseDetailType.embeddedList,
          simpleAttributePermissionRuleCore,
          EditableType.never
        )

        const relationshipAttributePermissionCore = app.makeCore( 'relationshipAttributePermission');
        relationshipAttributePermissionCore.soGet = SOGetRelationshipAttributePermission.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionCore
        )
        relationshipAttributePermissionCore.title = heading ? heading : relationshipAttributePermissionCore.title
        relationshipAttributePermissionCore.listTitle = 'Relationship attribute permission'

        relationshipAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermission = new DetailEntry(
          heading ? heading : relationshipAttributePermissionCore.listTitle,
          null,
          'relationshipAttributePermission',
          BaseDetailType.embeddedList,
          relationshipAttributePermissionCore,
          EditableType.never
        )

        const relationshipAttributePermissionRuleCore = app.makeCore( 'relationshipAttributePermissionRule');
        relationshipAttributePermissionRuleCore.soGet = SOGetRelationshipAttributePermissionRule.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributePermissionRuleCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionRuleCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionRuleCore
        )
        relationshipAttributePermissionRuleCore.title = heading ? heading : relationshipAttributePermissionRuleCore.title
        relationshipAttributePermissionRuleCore.listTitle = 'Relationship attribute permission rule'

        relationshipAttributePermissionRuleCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermissionRule = new DetailEntry(
          heading ? heading : relationshipAttributePermissionRuleCore.listTitle,
          null,
          'relationshipAttributePermissionRule',
          BaseDetailType.embeddedList,
          relationshipAttributePermissionRuleCore,
          EditableType.never
        )

        const entityPermissionCore = app.makeCore( 'entityPermission');
        entityPermissionCore.soGet = SOGetEntityPermission.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityPermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityPermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityPermissionCore
        )
        entityPermissionCore.title = heading ? heading : entityPermissionCore.title
        entityPermissionCore.listTitle = 'Entity permission'

        entityPermissionCore.configureForDisplay(parentEntityViewSpec)

        this.entityPermission = new DetailEntry(
          heading ? heading : entityPermissionCore.listTitle,
          null,
          'entityPermission',
          BaseDetailType.embeddedList,
          entityPermissionCore,
          EditableType.never
        )

        const plansCore = app.makeCore( 'plan');
        plansCore.soGet = SOGetPlan.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        plansCore.soGet.queryOptions.sortKey = 'dateCreated';
        plansCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            plansCore
        )
        plansCore.title = heading ? heading : plansCore.title
        plansCore.listTitle = 'Plans'

        plansCore.configureForDisplay(parentEntityViewSpec)

        this.plans = new DetailEntry(
          heading ? heading : plansCore.listTitle,
          null,
          'plans',
          BaseDetailType.embeddedList,
          plansCore,
          EditableType.never
        )

        const assignedProjectsCore = app.makeCore( 'assignedProject');
        assignedProjectsCore.soGet = SOGetAssignedProject.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assignedProjectsCore
        )
        assignedProjectsCore.title = heading ? heading : assignedProjectsCore.title
        assignedProjectsCore.listTitle = 'Assigned projects'

        assignedProjectsCore.configureForDisplay(parentEntityViewSpec)

        this.assignedProjects = new DetailEntry(
          heading ? heading : assignedProjectsCore.listTitle,
          null,
          'assignedProjects',
          BaseDetailType.embeddedList,
          assignedProjectsCore,
          EditableType.never
        )

        const rolePermissionsCore = app.makeCore( 'rolePermission');
        rolePermissionsCore.soGet = SOGetRolePermission.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        rolePermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        rolePermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolePermissionsCore
        )
        rolePermissionsCore.title = heading ? heading : rolePermissionsCore.title
        rolePermissionsCore.listTitle = 'Role permissions'

        rolePermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.rolePermissions = new DetailEntry(
          heading ? heading : rolePermissionsCore.listTitle,
          null,
          'rolePermissions',
          BaseDetailType.embeddedList,
          rolePermissionsCore,
          EditableType.never
        )

        const processRolesCore = app.makeCore( 'processRole');
        processRolesCore.soGet = SOGetProcessRole.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processRolesCore.soGet.queryOptions.sortKey = 'dateCreated';
        processRolesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processRolesCore
        )
        processRolesCore.title = heading ? heading : processRolesCore.title
        processRolesCore.listTitle = 'Process roles'

        processRolesCore.configureForDisplay(parentEntityViewSpec)

        this.processRoles = new DetailEntry(
          heading ? heading : processRolesCore.listTitle,
          null,
          'processRoles',
          BaseDetailType.embeddedList,
          processRolesCore,
          EditableType.never
        )

        const stepsCore = app.makeCore( 'step');
        stepsCore.soGet = SOGetStep.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        stepsCore.soGet.queryOptions.sortKey = 'dateCreated';
        stepsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            stepsCore
        )
        stepsCore.title = heading ? heading : stepsCore.title
        stepsCore.listTitle = 'Steps'

        stepsCore.configureForDisplay(parentEntityViewSpec)

        this.steps = new DetailEntry(
          heading ? heading : stepsCore.listTitle,
          null,
          'steps',
          BaseDetailType.embeddedList,
          stepsCore,
          EditableType.never
        )

        const processStepAssociationsCore = app.makeCore( 'processStepAssociation');
        processStepAssociationsCore.soGet = SOGetProcessStepAssociation.construct({
          project: new SOGetProject(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processStepAssociationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        processStepAssociationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processStepAssociationsCore
        )
        processStepAssociationsCore.title = heading ? heading : processStepAssociationsCore.title
        processStepAssociationsCore.listTitle = 'Process step associations'

        processStepAssociationsCore.configureForDisplay(parentEntityViewSpec)

        this.processStepAssociations = new DetailEntry(
          heading ? heading : processStepAssociationsCore.listTitle,
          null,
          'processStepAssociations',
          BaseDetailType.embeddedList,
          processStepAssociationsCore,
          EditableType.never
        )
    }
}