import {SORespSubscription} from '../../core/subscription/so-resp-subscription';
import {SubscriptionCore} from './subscription.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSubscriptionFormFields} from './put-subscription-form-fields';
import { CESubscriptionAttributes } from './ce-subscription-attributes'


export class CESubscriptionBase extends CEBase {

    public details: CESubscriptionAttributes;
    public fromFields: PutSubscriptionFormFields = new PutSubscriptionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SubscriptionCore,
        public entity: SORespSubscription,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESubscriptionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.dateExpires)
        this.attributes.push(this.details.exRef)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.organization)
        this.attributes.push(this.details.price)
        this.attributes.push(this.details.invoices)

    }
}