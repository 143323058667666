import {SOPutOrganization} from '../../core/organization/so-put-organization';
import {OrganizationCore} from './organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {FormField, SelectionOption} from 'lionheartland';
import {putOrganizationFormFieldOptions} from './put-organization-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AssessmentCore} from '../assessment/assessment.core';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {AssessmentSummaryCore} from '../assessment-summary/assessment-summary.core';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {BlockCore} from '../block/block.core';
import {SOGetBlock} from '../../core/block/so-get-block';
import {DeficiencyCore} from '../deficiency/deficiency.core';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {PeriodCore} from '../period/period.core';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {ReportCore} from '../report/report.core';
import {SOGetReport} from '../../core/report/so-get-report';
import {RequirementCore} from '../requirement/requirement.core';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {CustomLogicRequirementCore} from '../custom-logic-requirement/custom-logic-requirement.core';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {WorkCore} from '../work/work.core';
import {SOGetWork} from '../../core/work/so-get-work';
import {RealizationCore} from '../realization/realization.core';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {EntityCore} from '../entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {RelationshipCore} from '../relationship/relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {RelationshipAttributeCore} from '../relationship-attribute/relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {ProcessCore} from '../process/process.core';
import {SOGetProcess} from '../../core/process/so-get-process';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {InterphaceCore} from '../interphace/interphace.core';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {BacklogCore} from '../backlog/backlog.core';
import {SOGetBacklog} from '../../core/backlog/so-get-backlog';
import {AttributeSpecCore} from '../attribute-spec/attribute-spec.core';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {ExportProjectCore} from '../export-project/export-project.core';
import {SOGetExportProject} from '../../core/export-project/so-get-export-project';
import {SimpleAttributePermissionCore} from '../simple-attribute-permission/simple-attribute-permission.core';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SimpleAttributePermissionRuleCore} from '../simple-attribute-permission-rule/simple-attribute-permission-rule.core';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {RelationshipAttributePermissionCore} from '../relationship-attribute-permission/relationship-attribute-permission.core';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {RelationshipAttributePermissionRuleCore} from '../relationship-attribute-permission-rule/relationship-attribute-permission-rule.core';
import {SOGetRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import {EntityPermissionCore} from '../entity-permission/entity-permission.core';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {PlanCore} from '../plan/plan.core';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {PersonCore} from '../person/person.core';
import {SOGetPerson} from '../../core/person/so-get-person';
import {AssignedProjectCore} from '../assigned-project/assigned-project.core';
import {SOGetAssignedProject} from '../../core/assigned-project/so-get-assigned-project';
import {PlanWorkAssociationCore} from '../plan-work-association/plan-work-association.core';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {RolePermissionCore} from '../role-permission/role-permission.core';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {RoleCore} from '../role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {ProcessRoleCore} from '../process-role/process-role.core';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {StepCore} from '../step/step.core';
import {SOGetStep} from '../../core/step/so-get-step';
import {ProcessStepAssociationCore} from '../process-step-association/process-step-association.core';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';

export class RAPutOrganization extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putOrganizationFormFieldOptions);

  RequestClass = SOPutOrganization

  constructor(
    public entityCore: OrganizationCore
  ) {
    super();
    if (putOrganizationFormFieldOptions.assessment) {
      putOrganizationFormFieldOptions.assessment.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.assessmentSummary) {
      putOrganizationFormFieldOptions.assessmentSummary.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.block) {
      putOrganizationFormFieldOptions.block.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.deficiency) {
      putOrganizationFormFieldOptions.deficiency.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.period) {
      putOrganizationFormFieldOptions.period.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.report) {
      putOrganizationFormFieldOptions.report.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.requirement) {
      putOrganizationFormFieldOptions.requirement.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.customLogicRequirement) {
      putOrganizationFormFieldOptions.customLogicRequirement.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.work) {
      putOrganizationFormFieldOptions.work.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.realization) {
      putOrganizationFormFieldOptions.realization.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.entity) {
      putOrganizationFormFieldOptions.entity.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.relationship) {
      putOrganizationFormFieldOptions.relationship.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.relationshipAttribute) {
      putOrganizationFormFieldOptions.relationshipAttribute.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.simpleAttribute) {
      putOrganizationFormFieldOptions.simpleAttribute.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.process) {
      putOrganizationFormFieldOptions.process.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.project) {
      putOrganizationFormFieldOptions.project.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.interphace) {
      putOrganizationFormFieldOptions.interphace.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.backlog) {
      putOrganizationFormFieldOptions.backlog.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.attributeSpec) {
      putOrganizationFormFieldOptions.attributeSpec.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.exportProject) {
      putOrganizationFormFieldOptions.exportProject.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.simpleAttributePermission) {
      putOrganizationFormFieldOptions.simpleAttributePermission.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.simpleAttributePermissionRule) {
      putOrganizationFormFieldOptions.simpleAttributePermissionRule.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.relationshipAttributePermission) {
      putOrganizationFormFieldOptions.relationshipAttributePermission.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.relationshipAttributePermissionRule) {
      putOrganizationFormFieldOptions.relationshipAttributePermissionRule.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.entityPermission) {
      putOrganizationFormFieldOptions.entityPermission.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.plan) {
      putOrganizationFormFieldOptions.plan.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.person) {
      putOrganizationFormFieldOptions.person.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.assignedProject) {
      putOrganizationFormFieldOptions.assignedProject.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.planWorkAssociation) {
      putOrganizationFormFieldOptions.planWorkAssociation.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.rolePermission) {
      putOrganizationFormFieldOptions.rolePermission.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.role) {
      putOrganizationFormFieldOptions.role.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.processRole) {
      putOrganizationFormFieldOptions.processRole.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.step) {
      putOrganizationFormFieldOptions.step.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.processStepAssociation) {
      putOrganizationFormFieldOptions.processStepAssociation.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      [this.getFormValueFromIdentifier('assessment', SOGetAssessment)],
      [this.getFormValueFromIdentifier('assessmentSummary', SOGetAssessmentSummary)],
      [this.getFormValueFromIdentifier('block', SOGetBlock)],
      [this.getFormValueFromIdentifier('deficiency', SOGetDeficiency)],
      [this.getFormValueFromIdentifier('period', SOGetPeriod)],
      [this.getFormValueFromIdentifier('report', SOGetReport)],
      [this.getFormValueFromIdentifier('requirement', SOGetRequirement)],
      [this.getFormValueFromIdentifier('customLogicRequirement', SOGetCustomLogicRequirement)],
      [this.getFormValueFromIdentifier('work', SOGetWork)],
      [this.getFormValueFromIdentifier('realization', SOGetRealization)],
      [this.getFormValueFromIdentifier('entity', SOGetEntity)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationship)],
      [this.getFormValueFromIdentifier('relationshipAttribute', SOGetRelationshipAttribute)],
      [this.getFormValueFromIdentifier('simpleAttribute', SOGetSimpleAttribute)],
      [this.getFormValueFromIdentifier('process', SOGetProcess)],
      [this.getFormValueFromIdentifier('project', SOGetProject)],
      [this.getFormValueFromIdentifier('interphace', SOGetInterphace)],
      [this.getFormValueFromIdentifier('backlog', SOGetBacklog)],
      [this.getFormValueFromIdentifier('attributeSpec', SOGetAttributeSpec)],
      [this.getFormValueFromIdentifier('exportProject', SOGetExportProject)],
      [this.getFormValueFromIdentifier('simpleAttributePermission', SOGetSimpleAttributePermission)],
      [this.getFormValueFromIdentifier('simpleAttributePermissionRule', SOGetSimpleAttributePermissionRule)],
      [this.getFormValueFromIdentifier('relationshipAttributePermission', SOGetRelationshipAttributePermission)],
      [this.getFormValueFromIdentifier('relationshipAttributePermissionRule', SOGetRelationshipAttributePermissionRule)],
      [this.getFormValueFromIdentifier('entityPermission', SOGetEntityPermission)],
      [this.getFormValueFromIdentifier('plan', SOGetPlan)],
      [this.getFormValueFromIdentifier('person', SOGetPerson)],
      [this.getFormValueFromIdentifier('assignedProject', SOGetAssignedProject)],
      [this.getFormValueFromIdentifier('planWorkAssociation', SOGetPlanWorkAssociation)],
      [this.getFormValueFromIdentifier('rolePermission', SOGetRolePermission)],
      [this.getFormValueFromIdentifier('role', SOGetRole)],
      [this.getFormValueFromIdentifier('processRole', SOGetProcessRole)],
      [this.getFormValueFromIdentifier('step', SOGetStep)],
      [this.getFormValueFromIdentifier('processStepAssociation', SOGetProcessStepAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}