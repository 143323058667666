import { Type } from 'class-transformer';
import {SORespPrice} from './so-resp-price';
import {PluralProtocol} from 'lionheartland';
import {Price} from "../../dexie/db";

export class SOPluralPrice implements PluralProtocol {
  @Type(() => SORespPrice)
  objects: SORespPrice[] = []
  nextOffset: number = null

    toPrices(): Price[] {
        return this.objects.map(price => price.toPrice());
    }
}