import {SOPutAttributeSpec} from '../../core/attribute-spec/so-put-attribute-spec';
import {AttributeSpecCore} from './attribute-spec.core';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {FormField, SelectionOption} from 'lionheartland';
import {putAttributeSpecFormFieldOptions} from './put-attribute-spec-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {InterphaceCore} from '../interphace/interphace.core';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {RelationshipAttributeCore} from '../relationship-attribute/relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutAttributeSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAttributeSpecFormFieldOptions);

  RequestClass = SOPutAttributeSpec

  constructor(
    public entityCore: AttributeSpecCore
  ) {
    super();
    if (putAttributeSpecFormFieldOptions.interphace) {
      putAttributeSpecFormFieldOptions.interphace.makeCore = true;
    }
    if (putAttributeSpecFormFieldOptions.simpleAttribute) {
      putAttributeSpecFormFieldOptions.simpleAttribute.makeCore = true;
    }
    if (putAttributeSpecFormFieldOptions.relationshipAttribute) {
      putAttributeSpecFormFieldOptions.relationshipAttribute.makeCore = true;
    }
    if (putAttributeSpecFormFieldOptions.project) {
      putAttributeSpecFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'interphace',
        SOGetInterphace,
      ),
      this.getFormValueFromIdentifier(
        'simpleAttribute',
        SOGetSimpleAttribute,
      ),
      this.getFormValueFromIdentifier(
        'relationshipAttribute',
        SOGetRelationshipAttribute,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}