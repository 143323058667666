import {SOPutCertificate} from '../../core/certificate/so-put-certificate';
import {CertificateCore} from './certificate.core';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {FormField, SelectionOption} from 'lionheartland';
import {putCertificateFormFieldOptions} from './put-certificate-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {TokenCore} from '../token/token.core';
import {SOGetToken} from '../../core/token/so-get-token';

export class RAPutCertificate extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putCertificateFormFieldOptions);

  RequestClass = SOPutCertificate

  constructor(
    public entityCore: CertificateCore
  ) {
    super();
    if (putCertificateFormFieldOptions.user) {
      putCertificateFormFieldOptions.user.makeCore = true;
    }
    if (putCertificateFormFieldOptions.tokens) {
      putCertificateFormFieldOptions.tokens.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
      [this.getFormValueFromIdentifier('tokens', SOGetToken)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}