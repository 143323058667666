import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostMediaFormFields {

    public name: FormField
    public description: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )

    }
}

const fields = new PostMediaFormFields()

export const postMediaFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
};