import { Type } from 'class-transformer';
import { SORespMedia } from '../media/so-resp-media';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Attachment} from "../../dexie/db";

export class SORespAttachment implements ObjectiveProtocol {

  entityType: string = 'attachment';
  uid: string;
  @Type(() => SORespMedia)
  media: SORespMedia;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toAttachment(): Attachment {
        return {
            uid: this.uid,
            mediaUid: this.media ? this.media.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}