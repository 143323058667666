import {SOPostRolePermission} from '../../core/role-permission/so-post-role-permission';
import {RolePermissionCore} from './role-permission.core';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {FormField, SelectionOption} from 'lionheartland';
import {postRolePermissionFormFieldOptions} from './post-role-permission-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {EntityPermissionCore} from '../entity-permission/entity-permission.core';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {RelationshipAttributePermissionCore} from '../relationship-attribute-permission/relationship-attribute-permission.core';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {SimpleAttributePermissionCore} from '../simple-attribute-permission/simple-attribute-permission.core';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {RoleCore} from '../role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostRolePermission extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRolePermissionFormFieldOptions);

  RequestClass = SOPostRolePermission

  constructor(
    public entityCore: RolePermissionCore
  ) {
    super();
    if (postRolePermissionFormFieldOptions.entityPermission) {
      postRolePermissionFormFieldOptions.entityPermission.makeCore = true;
    }
    if (postRolePermissionFormFieldOptions.relationshipAttributePermission) {
      postRolePermissionFormFieldOptions.relationshipAttributePermission.makeCore = true;
    }
    if (postRolePermissionFormFieldOptions.simpleAttributePermission) {
      postRolePermissionFormFieldOptions.simpleAttributePermission.makeCore = true;
    }
    if (postRolePermissionFormFieldOptions.role) {
      postRolePermissionFormFieldOptions.role.makeCore = true;
    }
    if (postRolePermissionFormFieldOptions.project) {
      postRolePermissionFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'entityPermission',
        SOGetEntityPermission,
      ),
      this.getFormValueFromIdentifier(
        'relationshipAttributePermission',
        SOGetRelationshipAttributePermission,
      ),
      this.getFormValueFromIdentifier(
        'simpleAttributePermission',
        SOGetSimpleAttributePermission,
      ),
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}