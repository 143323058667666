import { Type } from 'class-transformer';
import { SORespDatafeed } from '../datafeed/so-resp-datafeed';
import { SORespMessage } from '../message/so-resp-message';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENMessageStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Notification} from "../../dexie/db";

export class SORespNotification implements ObjectiveProtocol {

  entityType: string = 'notification';
  uid: string;
  name: string;
  description: string = "";
  status: ENMessageStatus = ENMessageStatus.unread;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => SORespDatafeed)
  datafeed: SORespDatafeed = null;
  @Type(() => SORespMessage)
  message: SORespMessage;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toNotification(): Notification {
        return {
            uid: this.uid,
            name: this.name,
            description: null,
            status: this.status,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            datafeedUid: this.datafeed ? this.datafeed.uid : null,
            messageUid: this.message ? this.message.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}