import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutBlock } from './so-put-block';
import { SOGetBlock } from './so-get-block';
import { SOPostBlock } from './so-post-block';
import { SODeleteBlock } from './so-delete-block';
import {SORespBlock} from './so-resp-block';
import {SOPluralBlock} from './so-plural-block';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOBlockService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('block', entityCore);
  }

  /** GET Block by id. Will 404 if id not found */
  get(request: SOGetBlock): Observable<SOPluralBlock> {
    return this.httpClient.get<SOPluralBlock>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralBlock, resp)),
      tap(async (response: SOPluralBlock) => {
        this.log(`fetched Block`);
        // After a successful server response, update IndexedDB
        const activities = response.toBlocks(); // Convert your response to an array of Block instances
        const promises = activities.map(block => db.blocks.put(block));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralBlock>(`get Block`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralBlock> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'block' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.blocks.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "blocks" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('blocksUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "parent" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "customLogicRequirement" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('customLogicRequirementUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "interphace" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('interphaceUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "deficiencys" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('deficiencysUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.blocks.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(blocksArray => this.processBlocks(blocksArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.blocks.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(blocksArray => this.processBlocks(blocksArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.blocks.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(blocksArray => this.processBlocks(blocksArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.blocks.toArray()).pipe(
                map((blocksArray) => {
                    // Convert the array of plain objects to an array of SORespBlock instances
                    const sorBlocks = blocksArray.map(req => plainToClass(SORespBlock, req));
                    // Create and return a new instance of SOPluralBlock
                    const pluralBlock = new SOPluralBlock();
                    pluralBlock.objects = sorBlocks;
                    pluralBlock.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralBlock;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processBlocks(blocksArray) {
        const sorBlocks = blocksArray.map(req => plainToClass(SORespBlock, req));
        const pluralBlock = new SOPluralBlock();
        pluralBlock.objects = sorBlocks;
        pluralBlock.nextOffset = null;
        return pluralBlock;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostBlock): Observable<SORespBlock> {
  return this.httpClient.post<SORespBlock>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespBlock, resp)),

    tap(async (response: SORespBlock) => {
      this.log(`added Block w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.blocks.add(response.toBlock());
    }),
    catchError(this.handleError<SORespBlock>('Block post'))
  );
}


  put(request: SOPutBlock): Observable<SORespBlock> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBlock>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespBlock, resp)),
      tap(async (response: SORespBlock) => {
        this.log(`edited Block w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.blocks.put(response.toBlock());
      }),
      catchError(this.handleError<SORespBlock>('Block put'))
    );
  }


  delete(request: SODeleteBlock): Observable<SORespBlock> {
    return this.httpClient.delete<SORespBlock>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Block uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.blocks.delete(request.uid);
      }),
      catchError(this.handleError<SORespBlock>('Block delete'))
    );
  }


}
