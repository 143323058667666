import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutView } from './so-put-view';
import { SOGetView } from './so-get-view';
import { SOPostView } from './so-post-view';
import { SODeleteView } from './so-delete-view';
import {SORespView} from './so-resp-view';
import {SOPluralView} from './so-plural-view';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOViewService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('view', entityCore);
  }

  /** GET View by id. Will 404 if id not found */
  get(request: SOGetView): Observable<SOPluralView> {
    return this.httpClient.get<SOPluralView>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralView, resp)),
      tap(async (response: SOPluralView) => {
        this.log(`fetched View`);
        // After a successful server response, update IndexedDB
        const activities = response.toViews(); // Convert your response to an array of View instances
        const promises = activities.map(view => db.views.put(view));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralView>(`get View`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralView> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.views.toArray()).pipe(
                map((viewsArray) => {
                    // Convert the array of plain objects to an array of SORespView instances
                    const sorViews = viewsArray.map(req => plainToClass(SORespView, req));
                    // Create and return a new instance of SOPluralView
                    const pluralView = new SOPluralView();
                    pluralView.objects = sorViews;
                    pluralView.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralView;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processViews(viewsArray) {
        const sorViews = viewsArray.map(req => plainToClass(SORespView, req));
        const pluralView = new SOPluralView();
        pluralView.objects = sorViews;
        pluralView.nextOffset = null;
        return pluralView;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostView): Observable<SORespView> {
  return this.httpClient.post<SORespView>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespView, resp)),

    tap(async (response: SORespView) => {
      this.log(`added View w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.views.add(response.toView());
    }),
    catchError(this.handleError<SORespView>('View post'))
  );
}


  put(request: SOPutView): Observable<SORespView> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespView>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespView, resp)),
      tap(async (response: SORespView) => {
        this.log(`edited View w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.views.put(response.toView());
      }),
      catchError(this.handleError<SORespView>('View put'))
    );
  }


  delete(request: SODeleteView): Observable<SORespView> {
    return this.httpClient.delete<SORespView>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted View uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.views.delete(request.uid);
      }),
      catchError(this.handleError<SORespView>('View delete'))
    );
  }


}
