import { SOGetInterphace } from '../interphace/so-get-interphace';
import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutAttributeSpec {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public interphace: SOGetInterphace = null,
    public simpleAttribute: SOGetSimpleAttribute = null,
    public relationshipAttribute: SOGetRelationshipAttribute = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAttributeSpec(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'interphace' in params ? params.interphace : null,
      'simpleAttribute' in params ? params.simpleAttribute : null,
      'relationshipAttribute' in params ? params.relationshipAttribute : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
