import {SORespBlock} from '../../core/block/so-resp-block';
import {BlockCore} from './block.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutBlockFormFields} from './put-block-form-fields';
import {SOGetBlock} from '../../core/block/so-get-block';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEBlockAttributesBase {

    name: DetailEntry
    description: DetailEntry
    active: DetailEntry
    type: DetailEntry
    blocks: DetailEntry
    parent: DetailEntry
    customLogicRequirement: DetailEntry
    interphace: DetailEntry
    deficiencys: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespBlock,
        app: CommanderApp,
        formFields: PutBlockFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.type
        )


        const blocksCore = app.makeCore( 'block');
        blocksCore.soGet = SOGetBlock.construct({
          parent: new SOGetBlock(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        blocksCore.soGet.queryOptions.sortKey = 'dateCreated';
        blocksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            blocksCore
        )
        blocksCore.title = heading ? heading : blocksCore.title
        blocksCore.listTitle = 'Blocks'

        blocksCore.configureForDisplay(parentEntityViewSpec)

        this.blocks = new DetailEntry(
          heading ? heading : blocksCore.listTitle,
          null,
          'blocks',
          BaseDetailType.embeddedList,
          blocksCore,
          EditableType.never
        )

        const parentCore = app.makeCore( 'block');
        parentCore.soGet = SOGetBlock.construct({
          parent: new SOGetBlock(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        parentCore.soGet.queryOptions.sortKey = 'dateCreated';
        parentCore.soGet.queryOptions.descending = true;
        let parentCeProtocol;
        if (entity.parent) {
            parentCeProtocol = createCEProtocol(
                parentCore.ceProtocol,
                parentEntityViewSpec,
                parentCore,
                entity.parent,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            parentCore
        )
        parentCore.title = heading ? heading : parentCore.title
        parentCore.listTitle = 'Parent'

        if (entity.parent) {
            displayStringFactory.reconfigure(
                entity.parent,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        parentCore.configureForDisplay(parentEntityViewSpec)

        this.parent = new DetailEntry(
          heading ? heading : parentCore.listTitle,
          parentCeProtocol ? parentCeProtocol.entityListView.fullHeading : null,
          'parent',
          BaseDetailType.pushedDetail,
          parentCore,
          EditableType.always,
          formFields.parent
        )
        if (entity.parent) {
          this.parent.ceProtocol = parentCeProtocol;
          this.parent.cachedListEntry = parentCore.makeListEntry(parentEntityViewSpec, entity.parent, 0)
          parentCore.selectedEntity = entity.parent;
        }
        this.parent.singleFieldSelect = true

        const customLogicRequirementCore = app.makeCore( 'customLogicRequirement');
        customLogicRequirementCore.soGet = SOGetCustomLogicRequirement.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        customLogicRequirementCore.soGet.queryOptions.sortKey = 'dateCreated';
        customLogicRequirementCore.soGet.queryOptions.descending = true;
        let customLogicRequirementCeProtocol;
        if (entity.customLogicRequirement) {
            customLogicRequirementCeProtocol = createCEProtocol(
                customLogicRequirementCore.ceProtocol,
                parentEntityViewSpec,
                customLogicRequirementCore,
                entity.customLogicRequirement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customLogicRequirementCore
        )
        customLogicRequirementCore.title = heading ? heading : customLogicRequirementCore.title
        customLogicRequirementCore.listTitle = 'Custom logic requirement'

        if (entity.customLogicRequirement) {
            displayStringFactory.reconfigure(
                entity.customLogicRequirement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        customLogicRequirementCore.configureForDisplay(parentEntityViewSpec)

        this.customLogicRequirement = new DetailEntry(
          heading ? heading : customLogicRequirementCore.listTitle,
          customLogicRequirementCeProtocol ? customLogicRequirementCeProtocol.entityListView.fullHeading : null,
          'customLogicRequirement',
          BaseDetailType.pushedDetail,
          customLogicRequirementCore,
          EditableType.never
        )
        if (entity.customLogicRequirement) {
          this.customLogicRequirement.ceProtocol = customLogicRequirementCeProtocol;
          this.customLogicRequirement.cachedListEntry = customLogicRequirementCore.makeListEntry(parentEntityViewSpec, entity.customLogicRequirement, 0)
          customLogicRequirementCore.selectedEntity = entity.customLogicRequirement;
        }
        this.customLogicRequirement.singleFieldSelect = true

        const interphaceCore = app.makeCore( 'interphace');
        interphaceCore.soGet = SOGetInterphace.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        interphaceCore.soGet.queryOptions.sortKey = 'dateCreated';
        interphaceCore.soGet.queryOptions.descending = true;
        let interphaceCeProtocol;
        if (entity.interphace) {
            interphaceCeProtocol = createCEProtocol(
                interphaceCore.ceProtocol,
                parentEntityViewSpec,
                interphaceCore,
                entity.interphace,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            interphaceCore
        )
        interphaceCore.title = heading ? heading : interphaceCore.title
        interphaceCore.listTitle = 'Interphace'

        if (entity.interphace) {
            displayStringFactory.reconfigure(
                entity.interphace,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        interphaceCore.configureForDisplay(parentEntityViewSpec)

        this.interphace = new DetailEntry(
          heading ? heading : interphaceCore.listTitle,
          interphaceCeProtocol ? interphaceCeProtocol.entityListView.fullHeading : null,
          'interphace',
          BaseDetailType.pushedDetail,
          interphaceCore,
          EditableType.never
        )
        if (entity.interphace) {
          this.interphace.ceProtocol = interphaceCeProtocol;
          this.interphace.cachedListEntry = interphaceCore.makeListEntry(parentEntityViewSpec, entity.interphace, 0)
          interphaceCore.selectedEntity = entity.interphace;
        }
        this.interphace.singleFieldSelect = true

        const deficiencysCore = app.makeCore( 'deficiency');
        deficiencysCore.soGet = SOGetDeficiency.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        deficiencysCore.soGet.queryOptions.sortKey = 'dateCreated';
        deficiencysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deficiencysCore
        )
        deficiencysCore.title = heading ? heading : deficiencysCore.title
        deficiencysCore.listTitle = 'Deficiencys'

        deficiencysCore.configureForDisplay(parentEntityViewSpec)

        this.deficiencys = new DetailEntry(
          heading ? heading : deficiencysCore.listTitle,
          null,
          'deficiencys',
          BaseDetailType.embeddedList,
          deficiencysCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}