import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOReportService} from '../../core/report/eo-report';
import {SOGetReport} from '../../core/report/so-get-report';
import {RAPutReport} from './ra-put-report';
import {RAPostReport} from './ra-post-report';
import {RADeleteReport} from './ra-delete-report';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEReport } from "./ce-report"


export class ReportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'report';
  title = 'Report';
  ceProtocol: CEConstructor = CEReport

  entityService: EOReportService;
  soGetClass = SOGetReport;
  soGet: SOGetReport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOReportService,
      null,
    );
    this.adapters = [
      new RAPostReport(this),
      new RAPutReport(this),
      new RADeleteReport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetReport();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}