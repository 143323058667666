import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutAssignedProject } from './so-put-assigned-project';
import { SOGetAssignedProject } from './so-get-assigned-project';
import { SOPostAssignedProject } from './so-post-assigned-project';
import { SODeleteAssignedProject } from './so-delete-assigned-project';
import {SORespAssignedProject} from './so-resp-assigned-project';
import {SOPluralAssignedProject} from './so-plural-assigned-project';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOAssignedProjectService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('assigned_project', entityCore);
  }

  /** GET AssignedProject by id. Will 404 if id not found */
  get(request: SOGetAssignedProject): Observable<SOPluralAssignedProject> {
    return this.httpClient.get<SOPluralAssignedProject>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralAssignedProject, resp)),
      tap(async (response: SOPluralAssignedProject) => {
        this.log(`fetched AssignedProject`);
        // After a successful server response, update IndexedDB
        const activities = response.toAssignedProjects(); // Convert your response to an array of AssignedProject instances
        const promises = activities.map(assignedProject => db.assignedProjects.put(assignedProject));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralAssignedProject>(`get AssignedProject`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralAssignedProject> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'assignedProject' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.assignedProjects.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assignedProjectsArray => this.processAssignedProjects(assignedProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "person" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assignedProjects.where('personUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assignedProjectsArray => this.processAssignedProjects(assignedProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assignedProjects.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assignedProjectsArray => this.processAssignedProjects(assignedProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assignedProjects.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assignedProjectsArray => this.processAssignedProjects(assignedProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.assignedProjects.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(assignedProjectsArray => this.processAssignedProjects(assignedProjectsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.assignedProjects.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(assignedProjectsArray => this.processAssignedProjects(assignedProjectsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.assignedProjects.toArray()).pipe(
                map((assignedProjectsArray) => {
                    // Convert the array of plain objects to an array of SORespAssignedProject instances
                    const sorAssignedProjects = assignedProjectsArray.map(req => plainToClass(SORespAssignedProject, req));
                    // Create and return a new instance of SOPluralAssignedProject
                    const pluralAssignedProject = new SOPluralAssignedProject();
                    pluralAssignedProject.objects = sorAssignedProjects;
                    pluralAssignedProject.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralAssignedProject;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processAssignedProjects(assignedProjectsArray) {
        const sorAssignedProjects = assignedProjectsArray.map(req => plainToClass(SORespAssignedProject, req));
        const pluralAssignedProject = new SOPluralAssignedProject();
        pluralAssignedProject.objects = sorAssignedProjects;
        pluralAssignedProject.nextOffset = null;
        return pluralAssignedProject;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostAssignedProject): Observable<SORespAssignedProject> {
  return this.httpClient.post<SORespAssignedProject>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespAssignedProject, resp)),

    tap(async (response: SORespAssignedProject) => {
      this.log(`added AssignedProject w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.assignedProjects.add(response.toAssignedProject());
    }),
    catchError(this.handleError<SORespAssignedProject>('AssignedProject post'))
  );
}


  put(request: SOPutAssignedProject): Observable<SORespAssignedProject> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAssignedProject>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespAssignedProject, resp)),
      tap(async (response: SORespAssignedProject) => {
        this.log(`edited AssignedProject w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.assignedProjects.put(response.toAssignedProject());
      }),
      catchError(this.handleError<SORespAssignedProject>('AssignedProject put'))
    );
  }


  delete(request: SODeleteAssignedProject): Observable<SORespAssignedProject> {
    return this.httpClient.delete<SORespAssignedProject>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted AssignedProject uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.assignedProjects.delete(request.uid);
      }),
      catchError(this.handleError<SORespAssignedProject>('AssignedProject delete'))
    );
  }


}
