import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSubscriptionStatus} from '../../enums';

export class PutSubscriptionFormFields {

    public dateExpires: FormField
    public status: FormField

    constructor() {

        this.dateExpires = new FormField(
          'dateExpires',
          'Date expires',
          null,
          FormFieldType.datetime,
          false,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.status.enumeration = ENSubscriptionStatus

    }
}

const fields = new PutSubscriptionFormFields()

export const putSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  dateExpires: fields.dateExpires,
  status: fields.status,
};