import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutViewGrantFormFields {


    constructor() {


    }
}

const fields = new PutViewGrantFormFields()

export const putViewGrantFormFieldOptions: {[key: string]: FormField} = {
};