import {SOPutAssignedProject} from '../../core/assigned-project/so-put-assigned-project';
import {AssignedProjectCore} from './assigned-project.core';
import {SOGetAssignedProject} from '../../core/assigned-project/so-get-assigned-project';
import {FormField, SelectionOption} from 'lionheartland';
import {putAssignedProjectFormFieldOptions} from './put-assigned-project-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {PersonCore} from '../person/person.core';
import {SOGetPerson} from '../../core/person/so-get-person';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutAssignedProject extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAssignedProjectFormFieldOptions);

  RequestClass = SOPutAssignedProject

  constructor(
    public entityCore: AssignedProjectCore
  ) {
    super();
    if (putAssignedProjectFormFieldOptions.person) {
      putAssignedProjectFormFieldOptions.person.makeCore = true;
    }
    if (putAssignedProjectFormFieldOptions.project) {
      putAssignedProjectFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'person',
        SOGetPerson,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}