import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutIngest } from './so-put-ingest';
import { SOGetIngest } from './so-get-ingest';
import { SOPostIngest } from './so-post-ingest';
import { SODeleteIngest } from './so-delete-ingest';
import {SORespIngest} from './so-resp-ingest';
import {SOPluralIngest} from './so-plural-ingest';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOIngestService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('ingest', entityCore);
  }

  /** GET Ingest by id. Will 404 if id not found */
  get(request: SOGetIngest): Observable<SOPluralIngest> {
    return this.httpClient.get<SOPluralIngest>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralIngest, resp)),
      tap(async (response: SOPluralIngest) => {
        this.log(`fetched Ingest`);
        // After a successful server response, update IndexedDB
        const activities = response.toIngests(); // Convert your response to an array of Ingest instances
        const promises = activities.map(ingest => db.ingests.put(ingest));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralIngest>(`get Ingest`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralIngest> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'ingest' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.ingests.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(ingestsArray => this.processIngests(ingestsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.ingests.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(ingestsArray => this.processIngests(ingestsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.ingests.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(ingestsArray => this.processIngests(ingestsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.ingests.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(ingestsArray => this.processIngests(ingestsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.ingests.toArray()).pipe(
                map((ingestsArray) => {
                    // Convert the array of plain objects to an array of SORespIngest instances
                    const sorIngests = ingestsArray.map(req => plainToClass(SORespIngest, req));
                    // Create and return a new instance of SOPluralIngest
                    const pluralIngest = new SOPluralIngest();
                    pluralIngest.objects = sorIngests;
                    pluralIngest.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralIngest;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processIngests(ingestsArray) {
        const sorIngests = ingestsArray.map(req => plainToClass(SORespIngest, req));
        const pluralIngest = new SOPluralIngest();
        pluralIngest.objects = sorIngests;
        pluralIngest.nextOffset = null;
        return pluralIngest;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostIngest): Observable<SORespIngest> {
  return this.httpClient.post<SORespIngest>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespIngest, resp)),

    tap(async (response: SORespIngest) => {
      this.log(`added Ingest w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.ingests.add(response.toIngest());
    }),
    catchError(this.handleError<SORespIngest>('Ingest post'))
  );
}


  put(request: SOPutIngest): Observable<SORespIngest> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespIngest>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespIngest, resp)),
      tap(async (response: SORespIngest) => {
        this.log(`edited Ingest w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.ingests.put(response.toIngest());
      }),
      catchError(this.handleError<SORespIngest>('Ingest put'))
    );
  }


  delete(request: SODeleteIngest): Observable<SORespIngest> {
    return this.httpClient.delete<SORespIngest>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Ingest uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.ingests.delete(request.uid);
      }),
      catchError(this.handleError<SORespIngest>('Ingest delete'))
    );
  }


}
