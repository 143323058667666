import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutIngest {

  constructor(
    public uid: string,
    public entityName: string = null,
    public data: any = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutIngest(
      'uid' in params ? params.uid : null,
      'entityName' in params ? params.entityName : null,
      'data' in params ? params.data : null,
      'organization' in params ? params.organization : null,
    );
  }
}
