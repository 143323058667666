import { Type } from 'class-transformer';
import {SORespAlertSubscription} from './so-resp-alert-subscription';
import {PluralProtocol} from 'lionheartland';
import {AlertSubscription} from "../../dexie/db";

export class SOPluralAlertSubscription implements PluralProtocol {
  @Type(() => SORespAlertSubscription)
  objects: SORespAlertSubscription[] = []
  nextOffset: number = null

    toAlertSubscriptions(): AlertSubscription[] {
        return this.objects.map(alertSubscription => alertSubscription.toAlertSubscription());
    }
}