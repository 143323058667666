import {LandApp} from "lionheartland";
import {environment} from "../environments/environment";
import {entityCoreMap} from "./entity-core-map";
import {APP_DISPLAY_OPTIONS} from "./app-display-options";
import {ALL_NAV_ITEMS} from "./nav-items-custom";

export class CommanderAppBase extends LandApp {

    constructor(
        useSpecificCustomProcessorPatterns: string[] = null
    ) {
        super(
            useSpecificCustomProcessorPatterns
        )
        this.home = 'requirement';
        this.navItems = ALL_NAV_ITEMS;
        this.coreApiUrl = environment.coreApiUrl;
        this.title = 'Commander';
        this.company = 'ACME CO.';
        this.summary = 'Making your life better';
        this.entityCoreMap = entityCoreMap;
        this.displayOptions = APP_DISPLAY_OPTIONS;
    }
}
