import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutSubscription } from './so-put-subscription';
import { SOGetSubscription } from './so-get-subscription';
import { SOPostSubscription } from './so-post-subscription';
import { SODeleteSubscription } from './so-delete-subscription';
import {SORespSubscription} from './so-resp-subscription';
import {SOPluralSubscription} from './so-plural-subscription';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOSubscriptionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('subscription', entityCore);
  }

  /** GET Subscription by id. Will 404 if id not found */
  get(request: SOGetSubscription): Observable<SOPluralSubscription> {
    return this.httpClient.get<SOPluralSubscription>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralSubscription, resp)),
      tap(async (response: SOPluralSubscription) => {
        this.log(`fetched Subscription`);
        // After a successful server response, update IndexedDB
        const activities = response.toSubscriptions(); // Convert your response to an array of Subscription instances
        const promises = activities.map(subscription => db.subscriptions.put(subscription));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralSubscription>(`get Subscription`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralSubscription> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'subscription' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.subscriptions.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(subscriptionsArray => this.processSubscriptions(subscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.subscriptions.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(subscriptionsArray => this.processSubscriptions(subscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "price" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.subscriptions.where('priceUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(subscriptionsArray => this.processSubscriptions(subscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "invoices" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.subscriptions.where('invoicesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(subscriptionsArray => this.processSubscriptions(subscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.subscriptions.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(subscriptionsArray => this.processSubscriptions(subscriptionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.subscriptions.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(subscriptionsArray => this.processSubscriptions(subscriptionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.subscriptions.toArray()).pipe(
                map((subscriptionsArray) => {
                    // Convert the array of plain objects to an array of SORespSubscription instances
                    const sorSubscriptions = subscriptionsArray.map(req => plainToClass(SORespSubscription, req));
                    // Create and return a new instance of SOPluralSubscription
                    const pluralSubscription = new SOPluralSubscription();
                    pluralSubscription.objects = sorSubscriptions;
                    pluralSubscription.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralSubscription;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processSubscriptions(subscriptionsArray) {
        const sorSubscriptions = subscriptionsArray.map(req => plainToClass(SORespSubscription, req));
        const pluralSubscription = new SOPluralSubscription();
        pluralSubscription.objects = sorSubscriptions;
        pluralSubscription.nextOffset = null;
        return pluralSubscription;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostSubscription): Observable<SORespSubscription> {
  return this.httpClient.post<SORespSubscription>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespSubscription, resp)),

    tap(async (response: SORespSubscription) => {
      this.log(`added Subscription w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.subscriptions.add(response.toSubscription());
    }),
    catchError(this.handleError<SORespSubscription>('Subscription post'))
  );
}


  put(request: SOPutSubscription): Observable<SORespSubscription> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSubscription>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespSubscription, resp)),
      tap(async (response: SORespSubscription) => {
        this.log(`edited Subscription w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.subscriptions.put(response.toSubscription());
      }),
      catchError(this.handleError<SORespSubscription>('Subscription put'))
    );
  }


  delete(request: SODeleteSubscription): Observable<SORespSubscription> {
    return this.httpClient.delete<SORespSubscription>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Subscription uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.subscriptions.delete(request.uid);
      }),
      catchError(this.handleError<SORespSubscription>('Subscription delete'))
    );
  }


}
