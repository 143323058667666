import {SORespRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-resp-relationship-attribute-permission-rule';
import {RelationshipAttributePermissionRuleCore} from './relationship-attribute-permission-rule.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipAttributePermissionRuleFormFields} from './put-relationship-attribute-permission-rule-form-fields';
import {SOGetRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERelationshipAttributePermissionRuleAttributesBase {

    name: DetailEntry
    description: DetailEntry
    whiteList: DetailEntry
    permissionType: DetailEntry
    relationshipAttribute: DetailEntry
    relationshipAttributePermission: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRelationshipAttributePermissionRule,
        app: CommanderApp,
        formFields: PutRelationshipAttributePermissionRuleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.whiteList = new DetailEntry(
            'White list',
            entity.whiteList,
            'whiteList',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.whiteList
        )

        this.permissionType = new DetailEntry(
            'Permission type',
            entity.permissionType,
            'permissionType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.permissionType
        )


        const relationshipAttributeCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributeCore.soGet = SOGetRelationshipAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributeCore.soGet.queryOptions.descending = true;
        let relationshipAttributeCeProtocol;
        if (entity.relationshipAttribute) {
            relationshipAttributeCeProtocol = createCEProtocol(
                relationshipAttributeCore.ceProtocol,
                parentEntityViewSpec,
                relationshipAttributeCore,
                entity.relationshipAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributeCore
        )
        relationshipAttributeCore.title = heading ? heading : relationshipAttributeCore.title
        relationshipAttributeCore.listTitle = 'Relationship attribute'

        if (entity.relationshipAttribute) {
            displayStringFactory.reconfigure(
                entity.relationshipAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        relationshipAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttribute = new DetailEntry(
          heading ? heading : relationshipAttributeCore.listTitle,
          relationshipAttributeCeProtocol ? relationshipAttributeCeProtocol.entityListView.fullHeading : null,
          'relationshipAttribute',
          BaseDetailType.pushedDetail,
          relationshipAttributeCore,
          EditableType.always,
          formFields.relationshipAttribute
        )
        if (entity.relationshipAttribute) {
          this.relationshipAttribute.ceProtocol = relationshipAttributeCeProtocol;
          this.relationshipAttribute.cachedListEntry = relationshipAttributeCore.makeListEntry(parentEntityViewSpec, entity.relationshipAttribute, 0)
          relationshipAttributeCore.selectedEntity = entity.relationshipAttribute;
        }
        this.relationshipAttribute.singleFieldSelect = true

        const relationshipAttributePermissionCore = app.makeCore( 'relationshipAttributePermission');
        relationshipAttributePermissionCore.soGet = SOGetRelationshipAttributePermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionCore.soGet.queryOptions.descending = true;
        let relationshipAttributePermissionCeProtocol;
        if (entity.relationshipAttributePermission) {
            relationshipAttributePermissionCeProtocol = createCEProtocol(
                relationshipAttributePermissionCore.ceProtocol,
                parentEntityViewSpec,
                relationshipAttributePermissionCore,
                entity.relationshipAttributePermission,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionCore
        )
        relationshipAttributePermissionCore.title = heading ? heading : relationshipAttributePermissionCore.title
        relationshipAttributePermissionCore.listTitle = 'Relationship attribute permission'

        if (entity.relationshipAttributePermission) {
            displayStringFactory.reconfigure(
                entity.relationshipAttributePermission,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        relationshipAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermission = new DetailEntry(
          heading ? heading : relationshipAttributePermissionCore.listTitle,
          relationshipAttributePermissionCeProtocol ? relationshipAttributePermissionCeProtocol.entityListView.fullHeading : null,
          'relationshipAttributePermission',
          BaseDetailType.pushedDetail,
          relationshipAttributePermissionCore,
          EditableType.always,
          formFields.relationshipAttributePermission
        )
        if (entity.relationshipAttributePermission) {
          this.relationshipAttributePermission.ceProtocol = relationshipAttributePermissionCeProtocol;
          this.relationshipAttributePermission.cachedListEntry = relationshipAttributePermissionCore.makeListEntry(parentEntityViewSpec, entity.relationshipAttributePermission, 0)
          relationshipAttributePermissionCore.selectedEntity = entity.relationshipAttributePermission;
        }
        this.relationshipAttributePermission.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}