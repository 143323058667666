import {SORespSimpleAttributePermission} from '../../core/simple-attribute-permission/so-resp-simple-attribute-permission';
import {SimpleAttributePermissionCore} from './simple-attribute-permission.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSimpleAttributePermissionFormFields} from './put-simple-attribute-permission-form-fields';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CESimpleAttributePermissionAttributesBase {

    name: DetailEntry
    description: DetailEntry
    whiteList: DetailEntry
    permissionType: DetailEntry
    simpleAttribute: DetailEntry
    simpleAttributePermissionRules: DetailEntry
    rolePermissions: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSimpleAttributePermission,
        app: CommanderApp,
        formFields: PutSimpleAttributePermissionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.whiteList = new DetailEntry(
            'White list',
            entity.whiteList,
            'whiteList',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.whiteList
        )

        this.permissionType = new DetailEntry(
            'Permission type',
            entity.permissionType,
            'permissionType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.permissionType
        )


        const simpleAttributeCore = app.makeCore( 'simpleAttribute');
        simpleAttributeCore.soGet = SOGetSimpleAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributeCore.soGet.queryOptions.descending = true;
        let simpleAttributeCeProtocol;
        if (entity.simpleAttribute) {
            simpleAttributeCeProtocol = createCEProtocol(
                simpleAttributeCore.ceProtocol,
                parentEntityViewSpec,
                simpleAttributeCore,
                entity.simpleAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributeCore
        )
        simpleAttributeCore.title = heading ? heading : simpleAttributeCore.title
        simpleAttributeCore.listTitle = 'Simple attribute'

        if (entity.simpleAttribute) {
            displayStringFactory.reconfigure(
                entity.simpleAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        simpleAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttribute = new DetailEntry(
          heading ? heading : simpleAttributeCore.listTitle,
          simpleAttributeCeProtocol ? simpleAttributeCeProtocol.entityListView.fullHeading : null,
          'simpleAttribute',
          BaseDetailType.pushedDetail,
          simpleAttributeCore,
          EditableType.always,
          formFields.simpleAttribute
        )
        if (entity.simpleAttribute) {
          this.simpleAttribute.ceProtocol = simpleAttributeCeProtocol;
          this.simpleAttribute.cachedListEntry = simpleAttributeCore.makeListEntry(parentEntityViewSpec, entity.simpleAttribute, 0)
          simpleAttributeCore.selectedEntity = entity.simpleAttribute;
        }
        this.simpleAttribute.singleFieldSelect = true

        const simpleAttributePermissionRulesCore = app.makeCore( 'simpleAttributePermissionRule');
        simpleAttributePermissionRulesCore.soGet = SOGetSimpleAttributePermissionRule.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionRulesCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionRulesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionRulesCore
        )
        simpleAttributePermissionRulesCore.title = heading ? heading : simpleAttributePermissionRulesCore.title
        simpleAttributePermissionRulesCore.listTitle = 'Simple attribute permission rules'

        simpleAttributePermissionRulesCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermissionRules = new DetailEntry(
          heading ? heading : simpleAttributePermissionRulesCore.listTitle,
          null,
          'simpleAttributePermissionRules',
          BaseDetailType.embeddedList,
          simpleAttributePermissionRulesCore,
          EditableType.never
        )

        const rolePermissionsCore = app.makeCore( 'rolePermission');
        rolePermissionsCore.soGet = SOGetRolePermission.construct({
          simpleAttributePermission: new SOGetSimpleAttributePermission(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        rolePermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        rolePermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolePermissionsCore
        )
        rolePermissionsCore.title = heading ? heading : rolePermissionsCore.title
        rolePermissionsCore.listTitle = 'Role permissions'

        rolePermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.rolePermissions = new DetailEntry(
          heading ? heading : rolePermissionsCore.listTitle,
          null,
          'rolePermissions',
          BaseDetailType.embeddedList,
          rolePermissionsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}