import { SOGetBlock } from '../block/so-get-block';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENBlockType} from '../../enums';


export class SOPutBlock {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public active: boolean = null,
    public type: ENBlockType = null,
    public parent: SOGetBlock = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutBlock(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'type' in params ? params.type : null,
      'parent' in params ? params.parent : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
