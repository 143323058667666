import {SORespSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-resp-simple-attribute-permission-rule';
import {SimpleAttributePermissionRuleCore} from './simple-attribute-permission-rule.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSimpleAttributePermissionRuleFormFields} from './put-simple-attribute-permission-rule-form-fields';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CESimpleAttributePermissionRuleAttributesBase {

    name: DetailEntry
    description: DetailEntry
    simpleAttribute: DetailEntry
    simpleAttributePermission: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSimpleAttributePermissionRule,
        app: CommanderApp,
        formFields: PutSimpleAttributePermissionRuleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const simpleAttributeCore = app.makeCore( 'simpleAttribute');
        simpleAttributeCore.soGet = SOGetSimpleAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributeCore.soGet.queryOptions.descending = true;
        let simpleAttributeCeProtocol;
        if (entity.simpleAttribute) {
            simpleAttributeCeProtocol = createCEProtocol(
                simpleAttributeCore.ceProtocol,
                parentEntityViewSpec,
                simpleAttributeCore,
                entity.simpleAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributeCore
        )
        simpleAttributeCore.title = heading ? heading : simpleAttributeCore.title
        simpleAttributeCore.listTitle = 'Simple attribute'

        if (entity.simpleAttribute) {
            displayStringFactory.reconfigure(
                entity.simpleAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        simpleAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttribute = new DetailEntry(
          heading ? heading : simpleAttributeCore.listTitle,
          simpleAttributeCeProtocol ? simpleAttributeCeProtocol.entityListView.fullHeading : null,
          'simpleAttribute',
          BaseDetailType.pushedDetail,
          simpleAttributeCore,
          EditableType.always,
          formFields.simpleAttribute
        )
        if (entity.simpleAttribute) {
          this.simpleAttribute.ceProtocol = simpleAttributeCeProtocol;
          this.simpleAttribute.cachedListEntry = simpleAttributeCore.makeListEntry(parentEntityViewSpec, entity.simpleAttribute, 0)
          simpleAttributeCore.selectedEntity = entity.simpleAttribute;
        }
        this.simpleAttribute.singleFieldSelect = true

        const simpleAttributePermissionCore = app.makeCore( 'simpleAttributePermission');
        simpleAttributePermissionCore.soGet = SOGetSimpleAttributePermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionCore.soGet.queryOptions.descending = true;
        let simpleAttributePermissionCeProtocol;
        if (entity.simpleAttributePermission) {
            simpleAttributePermissionCeProtocol = createCEProtocol(
                simpleAttributePermissionCore.ceProtocol,
                parentEntityViewSpec,
                simpleAttributePermissionCore,
                entity.simpleAttributePermission,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionCore
        )
        simpleAttributePermissionCore.title = heading ? heading : simpleAttributePermissionCore.title
        simpleAttributePermissionCore.listTitle = 'Simple attribute permission'

        if (entity.simpleAttributePermission) {
            displayStringFactory.reconfigure(
                entity.simpleAttributePermission,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        simpleAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermission = new DetailEntry(
          heading ? heading : simpleAttributePermissionCore.listTitle,
          simpleAttributePermissionCeProtocol ? simpleAttributePermissionCeProtocol.entityListView.fullHeading : null,
          'simpleAttributePermission',
          BaseDetailType.pushedDetail,
          simpleAttributePermissionCore,
          EditableType.always,
          formFields.simpleAttributePermission
        )
        if (entity.simpleAttributePermission) {
          this.simpleAttributePermission.ceProtocol = simpleAttributePermissionCeProtocol;
          this.simpleAttributePermission.cachedListEntry = simpleAttributePermissionCore.makeListEntry(parentEntityViewSpec, entity.simpleAttributePermission, 0)
          simpleAttributePermissionCore.selectedEntity = entity.simpleAttributePermission;
        }
        this.simpleAttributePermission.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}