import { Type } from 'class-transformer';
import { SORespInterphace } from '../interphace/so-resp-interphace';
import { SORespSimpleAttribute } from '../simple-attribute/so-resp-simple-attribute';
import { SORespRelationshipAttribute } from '../relationship-attribute/so-resp-relationship-attribute';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {AttributeSpec} from "../../dexie/db";

export class SORespAttributeSpec implements ObjectiveProtocol {

  entityType: string = 'attributeSpec';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespInterphace)
  interphace: SORespInterphace = null;
  @Type(() => SORespSimpleAttribute)
  simpleAttribute: SORespSimpleAttribute = null;
  @Type(() => SORespRelationshipAttribute)
  relationshipAttribute: SORespRelationshipAttribute = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toAttributeSpec(): AttributeSpec {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            interphaceUid: this.interphace ? this.interphace.uid : null,
            simpleAttributeUid: this.simpleAttribute ? this.simpleAttribute.uid : null,
            relationshipAttributeUid: this.relationshipAttribute ? this.relationshipAttribute.uid : null,
            realizationsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}