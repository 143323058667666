import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutRolePermissionFormFields {

    public name: FormField
    public entityPermission: FormField
    public relationshipAttributePermission: FormField
    public simpleAttributePermission: FormField
    public role: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.entityPermission = new FormField(
          'entityPermission',
          'Entity permission',
          'entityPermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttributePermission = new FormField(
          'relationshipAttributePermission',
          'Relationship attribute permission',
          'relationshipAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttributePermission = new FormField(
          'simpleAttributePermission',
          'Simple attribute permission',
          'simpleAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutRolePermissionFormFields()

export const putRolePermissionFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  entityPermission: fields.entityPermission,
  relationshipAttributePermission: fields.relationshipAttributePermission,
  simpleAttributePermission: fields.simpleAttributePermission,
  role: fields.role,
  project: fields.project,
};