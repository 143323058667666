import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostIdentityFormFields {

    public idToken: FormField
    public accessToken: FormField
    public provider: FormField

    constructor() {

        this.idToken = new FormField(
          'idToken',
          'Id token',
          null,
          FormFieldType.text,
          true,
        )
        this.accessToken = new FormField(
          'accessToken',
          'Access token',
          null,
          FormFieldType.text,
          true,
        )
        this.provider = new FormField(
          'provider',
          'Provider',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostIdentityFormFields()

export const postIdentityFormFieldOptions: {[key: string]: FormField} = {
  idToken: fields.idToken,
  accessToken: fields.accessToken,
  provider: fields.provider,
};