import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENPeriodType} from '../../enums';

export class PostPeriodFormFields {

    public name: FormField
    public numeral: FormField
    public type: FormField
    public parent: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.numeral = new FormField(
          'numeral',
          'Numeral',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.type.enumeration = ENPeriodType
        this.parent = new FormField(
          'parent',
          'Parent',
          'period',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostPeriodFormFields()

export const postPeriodFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  numeral: fields.numeral,
  type: fields.type,
  parent: fields.parent,
  project: fields.project,
};