import {SORespBacklog} from '../../core/backlog/so-resp-backlog';
import {BacklogCore} from './backlog.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutBacklogFormFields} from './put-backlog-form-fields';
import { CEBacklogAttributes } from './ce-backlog-attributes'


export class CEBacklogBase extends CEBase {

    public details: CEBacklogAttributes;
    public fromFields: PutBacklogFormFields = new PutBacklogFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: BacklogCore,
        public entity: SORespBacklog,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEBacklogAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.works)
        this.attributes.push(this.details.project)

    }
}