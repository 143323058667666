import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAppliedConstraintFormFields {

    public quantity: FormField

    constructor() {

        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutAppliedConstraintFormFields()

export const putAppliedConstraintFormFieldOptions: {[key: string]: FormField} = {
  quantity: fields.quantity,
};