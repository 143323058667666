import {SORespPaymentIntent} from '../../core/payment-intent/so-resp-payment-intent';
import {PaymentIntentCore} from './payment-intent.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPaymentIntentFormFields} from './put-payment-intent-form-fields';
import { CEPaymentIntentAttributes } from './ce-payment-intent-attributes'


export class CEPaymentIntentBase extends CEBase {

    public details: CEPaymentIntentAttributes;
    public fromFields: PutPaymentIntentFormFields = new PutPaymentIntentFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PaymentIntentCore,
        public entity: SORespPaymentIntent,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPaymentIntentAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.invoice)

    }
}