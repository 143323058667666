import {SORespDeficiency} from '../../core/deficiency/so-resp-deficiency';
import {DeficiencyCore} from './deficiency.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutDeficiencyFormFields} from './put-deficiency-form-fields';
import { CEDeficiencyAttributes } from './ce-deficiency-attributes'


export class CEDeficiencyBase extends CEBase {

    public details: CEDeficiencyAttributes;
    public fromFields: PutDeficiencyFormFields = new PutDeficiencyFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DeficiencyCore,
        public entity: SORespDeficiency,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDeficiencyAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.severity)
        this.attributes.push(this.details.works)
        this.attributes.push(this.details.assessment)
        this.attributes.push(this.details.block)
        this.attributes.push(this.details.process)
        this.attributes.push(this.details.project)

    }
}