import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORelationshipAttributePermissionRuleService} from '../../core/relationship-attribute-permission-rule/eo-relationship-attribute-permission-rule';
import {SOGetRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import {RAPutRelationshipAttributePermissionRule} from './ra-put-relationship-attribute-permission-rule';
import {RAPostRelationshipAttributePermissionRule} from './ra-post-relationship-attribute-permission-rule';
import {RADeleteRelationshipAttributePermissionRule} from './ra-delete-relationship-attribute-permission-rule';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERelationshipAttributePermissionRule } from "./ce-relationship-attribute-permission-rule"


export class RelationshipAttributePermissionRuleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipAttributePermissionRule';
  title = 'Relationship attribute permission rule';
  ceProtocol: CEConstructor = CERelationshipAttributePermissionRule

  entityService: EORelationshipAttributePermissionRuleService;
  soGetClass = SOGetRelationshipAttributePermissionRule;
  soGet: SOGetRelationshipAttributePermissionRule;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORelationshipAttributePermissionRuleService,
      null,
    );
    this.adapters = [
      new RAPostRelationshipAttributePermissionRule(this),
      new RAPutRelationshipAttributePermissionRule(this),
      new RADeleteRelationshipAttributePermissionRule(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipAttributePermissionRule();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}