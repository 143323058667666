import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBacklogService} from '../../core/backlog/eo-backlog';
import {SOGetBacklog} from '../../core/backlog/so-get-backlog';
import {RAPutBacklog} from './ra-put-backlog';
import {RAPostBacklog} from './ra-post-backlog';
import {RADeleteBacklog} from './ra-delete-backlog';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEBacklog } from "./ce-backlog"


export class BacklogCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'backlog';
  title = 'Backlog';
  ceProtocol: CEConstructor = CEBacklog

  entityService: EOBacklogService;
  soGetClass = SOGetBacklog;
  soGet: SOGetBacklog;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBacklogService,
      null,
    );
    this.adapters = [
      new RAPostBacklog(this),
      new RAPutBacklog(this),
      new RADeleteBacklog(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBacklog();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}