import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutProcess } from './so-put-process';
import { SOGetProcess } from './so-get-process';
import { SOPostProcess } from './so-post-process';
import { SODeleteProcess } from './so-delete-process';
import {SORespProcess} from './so-resp-process';
import {SOPluralProcess} from './so-plural-process';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOProcessService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('process', entityCore);
  }

  /** GET Process by id. Will 404 if id not found */
  get(request: SOGetProcess): Observable<SOPluralProcess> {
    return this.httpClient.get<SOPluralProcess>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralProcess, resp)),
      tap(async (response: SOPluralProcess) => {
        this.log(`fetched Process`);
        // After a successful server response, update IndexedDB
        const activities = response.toProcesss(); // Convert your response to an array of Process instances
        const promises = activities.map(process => db.processs.put(process));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralProcess>(`get Process`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralProcess> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'process' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.processs.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "deficiencys" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processs.where('deficiencysUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "realizations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processs.where('realizationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "processRoles" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processs.where('processRolesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processs.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "processStepAssociations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processs.where('processStepAssociationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processs.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processsArray => this.processProcesss(processsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.processs.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(processsArray => this.processProcesss(processsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.processs.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(processsArray => this.processProcesss(processsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.processs.toArray()).pipe(
                map((processsArray) => {
                    // Convert the array of plain objects to an array of SORespProcess instances
                    const sorProcesss = processsArray.map(req => plainToClass(SORespProcess, req));
                    // Create and return a new instance of SOPluralProcess
                    const pluralProcess = new SOPluralProcess();
                    pluralProcess.objects = sorProcesss;
                    pluralProcess.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralProcess;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processProcesss(processsArray) {
        const sorProcesss = processsArray.map(req => plainToClass(SORespProcess, req));
        const pluralProcess = new SOPluralProcess();
        pluralProcess.objects = sorProcesss;
        pluralProcess.nextOffset = null;
        return pluralProcess;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostProcess): Observable<SORespProcess> {
  return this.httpClient.post<SORespProcess>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespProcess, resp)),

    tap(async (response: SORespProcess) => {
      this.log(`added Process w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.processs.add(response.toProcess());
    }),
    catchError(this.handleError<SORespProcess>('Process post'))
  );
}


  put(request: SOPutProcess): Observable<SORespProcess> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespProcess>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespProcess, resp)),
      tap(async (response: SORespProcess) => {
        this.log(`edited Process w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.processs.put(response.toProcess());
      }),
      catchError(this.handleError<SORespProcess>('Process put'))
    );
  }


  delete(request: SODeleteProcess): Observable<SORespProcess> {
    return this.httpClient.delete<SORespProcess>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Process uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.processs.delete(request.uid);
      }),
      catchError(this.handleError<SORespProcess>('Process delete'))
    );
  }


}
