import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENTokenStatus} from '../../enums';

export class PutTokenFormFields {

    public status: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.status.enumeration = ENTokenStatus

    }
}

const fields = new PutTokenFormFields()

export const putTokenFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
};