import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutToken } from './so-put-token';
import { SOGetToken } from './so-get-token';
import { SOPostToken } from './so-post-token';
import { SODeleteToken } from './so-delete-token';
import {SORespToken} from './so-resp-token';
import {SOPluralToken} from './so-plural-token';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOTokenService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('token', entityCore);
  }

  /** GET Token by id. Will 404 if id not found */
  get(request: SOGetToken): Observable<SOPluralToken> {
    return this.httpClient.get<SOPluralToken>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralToken, resp)),
      tap(async (response: SOPluralToken) => {
        this.log(`fetched Token`);
        // After a successful server response, update IndexedDB
        const activities = response.toTokens(); // Convert your response to an array of Token instances
        const promises = activities.map(token => db.tokens.put(token));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralToken>(`get Token`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralToken> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.tokens.toArray()).pipe(
                map((tokensArray) => {
                    // Convert the array of plain objects to an array of SORespToken instances
                    const sorTokens = tokensArray.map(req => plainToClass(SORespToken, req));
                    // Create and return a new instance of SOPluralToken
                    const pluralToken = new SOPluralToken();
                    pluralToken.objects = sorTokens;
                    pluralToken.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralToken;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processTokens(tokensArray) {
        const sorTokens = tokensArray.map(req => plainToClass(SORespToken, req));
        const pluralToken = new SOPluralToken();
        pluralToken.objects = sorTokens;
        pluralToken.nextOffset = null;
        return pluralToken;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostToken): Observable<SORespToken> {
  return this.httpClient.post<SORespToken>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespToken, resp)),

    tap(async (response: SORespToken) => {
      this.log(`added Token w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.tokens.add(response.toToken());
    }),
    catchError(this.handleError<SORespToken>('Token post'))
  );
}


  put(request: SOPutToken): Observable<SORespToken> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespToken>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespToken, resp)),
      tap(async (response: SORespToken) => {
        this.log(`edited Token w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.tokens.put(response.toToken());
      }),
      catchError(this.handleError<SORespToken>('Token put'))
    );
  }


  delete(request: SODeleteToken): Observable<SORespToken> {
    return this.httpClient.delete<SORespToken>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Token uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.tokens.delete(request.uid);
      }),
      catchError(this.handleError<SORespToken>('Token delete'))
    );
  }


}
