import { SOGetPlan } from '../plan/so-get-plan';
import { SOGetWork } from '../work/so-get-work';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENWeeklyReportSection} from '../../enums';


export class SOPostPlanWorkAssociation {

  constructor(
    public name: string,
    public reportSection: ENWeeklyReportSection,
    public plan: SOGetPlan,
    public work: SOGetWork,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPlanWorkAssociation(
      'name' in params ? params.name : null,
      'reportSection' in params ? params.reportSection : null,
      'plan' in params ? params.plan : null,
      'work' in params ? params.work : null,
      'organization' in params ? params.organization : null,
    );
  }
}
