import { Type } from 'class-transformer';
import {SORespHello} from './so-resp-hello';
import {PluralProtocol} from 'lionheartland';
import {Hello} from "../../dexie/db";

export class SOPluralHello implements PluralProtocol {
  @Type(() => SORespHello)
  objects: SORespHello[] = []
  nextOffset: number = null

    toHellos(): Hello[] {
        return this.objects.map(hello => hello.toHello());
    }
}