import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Relationship} from "../../dexie/db";

export class SORespRelationship implements ObjectiveProtocol {

  entityType: string = 'relationship';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toRelationship(): Relationship {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            relationshipAttributesUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}