import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORelationshipAttributeService} from '../../core/relationship-attribute/eo-relationship-attribute';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {RAPutRelationshipAttribute} from './ra-put-relationship-attribute';
import {RAPostRelationshipAttribute} from './ra-post-relationship-attribute';
import {RADeleteRelationshipAttribute} from './ra-delete-relationship-attribute';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERelationshipAttribute } from "./ce-relationship-attribute"


export class RelationshipAttributeCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipAttribute';
  title = 'Relationship attribute';
  ceProtocol: CEConstructor = CERelationshipAttribute

  entityService: EORelationshipAttributeService;
  soGetClass = SOGetRelationshipAttribute;
  soGet: SOGetRelationshipAttribute;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORelationshipAttributeService,
      null,
    );
    this.adapters = [
      new RAPostRelationshipAttribute(this),
      new RAPutRelationshipAttribute(this),
      new RADeleteRelationshipAttribute(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipAttribute();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}