import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutAssessment } from './so-put-assessment';
import { SOGetAssessment } from './so-get-assessment';
import { SOPostAssessment } from './so-post-assessment';
import { SODeleteAssessment } from './so-delete-assessment';
import {SORespAssessment} from './so-resp-assessment';
import {SOPluralAssessment} from './so-plural-assessment';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOAssessmentService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('assessment', entityCore);
  }

  /** GET Assessment by id. Will 404 if id not found */
  get(request: SOGetAssessment): Observable<SOPluralAssessment> {
    return this.httpClient.get<SOPluralAssessment>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralAssessment, resp)),
      tap(async (response: SOPluralAssessment) => {
        this.log(`fetched Assessment`);
        // After a successful server response, update IndexedDB
        const activities = response.toAssessments(); // Convert your response to an array of Assessment instances
        const promises = activities.map(assessment => db.assessments.put(assessment));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralAssessment>(`get Assessment`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralAssessment> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'assessment' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.assessments.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assessmentsArray => this.processAssessments(assessmentsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "assessmentSummary" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assessments.where('assessmentSummaryUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assessmentsArray => this.processAssessments(assessmentsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "deficiency" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assessments.where('deficiencyUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assessmentsArray => this.processAssessments(assessmentsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "report" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assessments.where('reportUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assessmentsArray => this.processAssessments(assessmentsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assessments.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assessmentsArray => this.processAssessments(assessmentsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.assessments.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(assessmentsArray => this.processAssessments(assessmentsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.assessments.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(assessmentsArray => this.processAssessments(assessmentsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.assessments.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(assessmentsArray => this.processAssessments(assessmentsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.assessments.toArray()).pipe(
                map((assessmentsArray) => {
                    // Convert the array of plain objects to an array of SORespAssessment instances
                    const sorAssessments = assessmentsArray.map(req => plainToClass(SORespAssessment, req));
                    // Create and return a new instance of SOPluralAssessment
                    const pluralAssessment = new SOPluralAssessment();
                    pluralAssessment.objects = sorAssessments;
                    pluralAssessment.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralAssessment;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processAssessments(assessmentsArray) {
        const sorAssessments = assessmentsArray.map(req => plainToClass(SORespAssessment, req));
        const pluralAssessment = new SOPluralAssessment();
        pluralAssessment.objects = sorAssessments;
        pluralAssessment.nextOffset = null;
        return pluralAssessment;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostAssessment): Observable<SORespAssessment> {
  return this.httpClient.post<SORespAssessment>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespAssessment, resp)),

    tap(async (response: SORespAssessment) => {
      this.log(`added Assessment w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.assessments.add(response.toAssessment());
    }),
    catchError(this.handleError<SORespAssessment>('Assessment post'))
  );
}


  put(request: SOPutAssessment): Observable<SORespAssessment> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAssessment>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespAssessment, resp)),
      tap(async (response: SORespAssessment) => {
        this.log(`edited Assessment w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.assessments.put(response.toAssessment());
      }),
      catchError(this.handleError<SORespAssessment>('Assessment put'))
    );
  }


  delete(request: SODeleteAssessment): Observable<SORespAssessment> {
    return this.httpClient.delete<SORespAssessment>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Assessment uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.assessments.delete(request.uid);
      }),
      catchError(this.handleError<SORespAssessment>('Assessment delete'))
    );
  }


}
