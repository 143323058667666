import { SOGetMessage } from '../message/so-get-message';
import {ENMessageStatus} from '../../enums';


export class SOPutNotification {

  constructor(
    public uid: string,
    public name: string = null,
    public status: ENMessageStatus = null,
    public message: SOGetMessage = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutNotification(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'status' in params ? params.status : null,
      'message' in params ? params.message : null,
    );
  }
}
