import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENViewType} from '../../enums';

export class PutViewFormFields {

    public name: FormField
    public identifier: FormField
    public type: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          false,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.type.enumeration = ENViewType

    }
}

const fields = new PutViewFormFields()

export const putViewFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
  type: fields.type,
};