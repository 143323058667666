import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Project} from "../../dexie/db";

export class SORespProject implements ObjectiveProtocol {

  entityType: string = 'project';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toProject(): Project {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            assessmentsUid: null,
            assessmentSummarysUid: null,
            blocksUid: null,
            deficiencysUid: null,
            entitysUid: null,
            periodsUid: null,
            realizationsUid: null,
            relationshipsUid: null,
            relationshipAttributesUid: null,
            reportsUid: null,
            requirementsUid: null,
            customLogicRequirementsUid: null,
            simpleAttributesUid: null,
            worksUid: null,
            processesUid: null,
            interphacesUid: null,
            backlogsUid: null,
            attributeSpecUid: null,
            exportProjectUid: null,
            simpleAttributePermissionUid: null,
            simpleAttributePermissionRuleUid: null,
            relationshipAttributePermissionUid: null,
            relationshipAttributePermissionRuleUid: null,
            entityPermissionUid: null,
            plansUid: null,
            assignedProjectsUid: null,
            rolePermissionsUid: null,
            processRolesUid: null,
            stepsUid: null,
            processStepAssociationsUid: null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}