import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSimpleAttributePermissionService} from '../../core/simple-attribute-permission/eo-simple-attribute-permission';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {RAPutSimpleAttributePermission} from './ra-put-simple-attribute-permission';
import {RAPostSimpleAttributePermission} from './ra-post-simple-attribute-permission';
import {RADeleteSimpleAttributePermission} from './ra-delete-simple-attribute-permission';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CESimpleAttributePermission } from "./ce-simple-attribute-permission"


export class SimpleAttributePermissionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'simpleAttributePermission';
  title = 'Simple attribute permission';
  ceProtocol: CEConstructor = CESimpleAttributePermission

  entityService: EOSimpleAttributePermissionService;
  soGetClass = SOGetSimpleAttributePermission;
  soGet: SOGetSimpleAttributePermission;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSimpleAttributePermissionService,
      null,
    );
    this.adapters = [
      new RAPostSimpleAttributePermission(this),
      new RAPutSimpleAttributePermission(this),
      new RADeleteSimpleAttributePermission(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSimpleAttributePermission();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}