import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENEntityPermissionType} from '../../enums';


export class SOPutEntityPermission {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public whiteList: boolean = null,
    public permissionType: ENEntityPermissionType = null,
    public entity: SOGetEntity = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityPermission(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'whiteList' in params ? params.whiteList : null,
      'permissionType' in params ? params.permissionType : null,
      'entity' in params ? params.entity : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
