import {Component} from '@angular/core';
import {ActiveUserService, UserLoaded} from 'lionheartland';
import 'reflect-metadata';
import {CommanderApp} from "./land-app";
import { db } from "./dexie/db";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements UserLoaded {

  id = 'appComponent';

  constructor(public activeUserService: ActiveUserService) {
    activeUserService.initialize(new CommanderApp());
    this.activeUserService.registerUserLoadedView(this);
  }

  userLoaded() {
    this.clearIndexedDB()
  }

  userUnloaded() {
    this.clearIndexedDB()
  }

  clearIndexedDB() {
    db.open().then(() => {
      db.tables.forEach(table => {
        table.clear();
      });
    });
  }
}

// This is for clear on exit, pop up window to give time for async fxn

// import { Component } from '@angular/core';
// import { ActiveUserService, UserLoaded } from 'lionheartland';
// import Dexie from 'dexie';
// import 'reflect-metadata';
// import { CommanderApp } from "./land-app";
// import {db} from "./dexie/db";
//
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// exportProject class AppComponent implements UserLoaded {
//
//   id = 'appComponent';
//
//   constructor(public activeUserService: ActiveUserService) {
//     activeUserService.initialize(new CommanderApp());
//     this.activeUserService.registerUserLoadedView(this);
//
//     window.addEventListener('beforeunload', (event) => {
//       event.preventDefault();
//       event.returnValue = '';
//       this.clearIndexedDB();
//     });
//   }
//
//   userLoaded() {
//   }
//
//   userUnloaded() {
//   }
//
//   clearIndexedDB() {
//     db.open().then(() => {
//       db.tables.forEach(table => {
//         table.clear();
//       });
//     });
//   }
// }