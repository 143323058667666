import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutAlertSubscription } from './so-put-alert-subscription';
import { SOGetAlertSubscription } from './so-get-alert-subscription';
import { SOPostAlertSubscription } from './so-post-alert-subscription';
import { SODeleteAlertSubscription } from './so-delete-alert-subscription';
import {SORespAlertSubscription} from './so-resp-alert-subscription';
import {SOPluralAlertSubscription} from './so-plural-alert-subscription';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOAlertSubscriptionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('alert_subscription', entityCore);
  }

  /** GET AlertSubscription by id. Will 404 if id not found */
  get(request: SOGetAlertSubscription): Observable<SOPluralAlertSubscription> {
    return this.httpClient.get<SOPluralAlertSubscription>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralAlertSubscription, resp)),
      tap(async (response: SOPluralAlertSubscription) => {
        this.log(`fetched AlertSubscription`);
        // After a successful server response, update IndexedDB
        const activities = response.toAlertSubscriptions(); // Convert your response to an array of AlertSubscription instances
        const promises = activities.map(alertSubscription => db.alertSubscriptions.put(alertSubscription));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralAlertSubscription>(`get AlertSubscription`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralAlertSubscription> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'alertSubscription' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.alertSubscriptions.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(alertSubscriptionsArray => this.processAlertSubscriptions(alertSubscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "topic" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.alertSubscriptions.where('topicUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(alertSubscriptionsArray => this.processAlertSubscriptions(alertSubscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "datafeed" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.alertSubscriptions.where('datafeedUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(alertSubscriptionsArray => this.processAlertSubscriptions(alertSubscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.alertSubscriptions.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(alertSubscriptionsArray => this.processAlertSubscriptions(alertSubscriptionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.alertSubscriptions.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(alertSubscriptionsArray => this.processAlertSubscriptions(alertSubscriptionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.alertSubscriptions.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(alertSubscriptionsArray => this.processAlertSubscriptions(alertSubscriptionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.alertSubscriptions.toArray()).pipe(
                map((alertSubscriptionsArray) => {
                    // Convert the array of plain objects to an array of SORespAlertSubscription instances
                    const sorAlertSubscriptions = alertSubscriptionsArray.map(req => plainToClass(SORespAlertSubscription, req));
                    // Create and return a new instance of SOPluralAlertSubscription
                    const pluralAlertSubscription = new SOPluralAlertSubscription();
                    pluralAlertSubscription.objects = sorAlertSubscriptions;
                    pluralAlertSubscription.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralAlertSubscription;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processAlertSubscriptions(alertSubscriptionsArray) {
        const sorAlertSubscriptions = alertSubscriptionsArray.map(req => plainToClass(SORespAlertSubscription, req));
        const pluralAlertSubscription = new SOPluralAlertSubscription();
        pluralAlertSubscription.objects = sorAlertSubscriptions;
        pluralAlertSubscription.nextOffset = null;
        return pluralAlertSubscription;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostAlertSubscription): Observable<SORespAlertSubscription> {
  return this.httpClient.post<SORespAlertSubscription>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespAlertSubscription, resp)),

    tap(async (response: SORespAlertSubscription) => {
      this.log(`added AlertSubscription w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.alertSubscriptions.add(response.toAlertSubscription());
    }),
    catchError(this.handleError<SORespAlertSubscription>('AlertSubscription post'))
  );
}


  put(request: SOPutAlertSubscription): Observable<SORespAlertSubscription> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAlertSubscription>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespAlertSubscription, resp)),
      tap(async (response: SORespAlertSubscription) => {
        this.log(`edited AlertSubscription w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.alertSubscriptions.put(response.toAlertSubscription());
      }),
      catchError(this.handleError<SORespAlertSubscription>('AlertSubscription put'))
    );
  }


  delete(request: SODeleteAlertSubscription): Observable<SORespAlertSubscription> {
    return this.httpClient.delete<SORespAlertSubscription>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted AlertSubscription uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.alertSubscriptions.delete(request.uid);
      }),
      catchError(this.handleError<SORespAlertSubscription>('AlertSubscription delete'))
    );
  }


}
