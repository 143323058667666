import {SOPutProcessStepAssociation} from '../../core/process-step-association/so-put-process-step-association';
import {ProcessStepAssociationCore} from './process-step-association.core';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';
import {FormField, SelectionOption} from 'lionheartland';
import {putProcessStepAssociationFormFieldOptions} from './put-process-step-association-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ProcessCore} from '../process/process.core';
import {SOGetProcess} from '../../core/process/so-get-process';
import {StepCore} from '../step/step.core';
import {SOGetStep} from '../../core/step/so-get-step';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutProcessStepAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putProcessStepAssociationFormFieldOptions);

  RequestClass = SOPutProcessStepAssociation

  constructor(
    public entityCore: ProcessStepAssociationCore
  ) {
    super();
    if (putProcessStepAssociationFormFieldOptions.process) {
      putProcessStepAssociationFormFieldOptions.process.makeCore = true;
    }
    if (putProcessStepAssociationFormFieldOptions.step) {
      putProcessStepAssociationFormFieldOptions.step.makeCore = true;
    }
    if (putProcessStepAssociationFormFieldOptions.project) {
      putProcessStepAssociationFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('processStepType'),
      this.getFormValueFromIdentifier(
        'process',
        SOGetProcess,
      ),
      this.getFormValueFromIdentifier(
        'step',
        SOGetStep,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}