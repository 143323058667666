import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProcessService} from '../../core/process/eo-process';
import {SOGetProcess} from '../../core/process/so-get-process';
import {RAPutProcess} from './ra-put-process';
import {RAPostProcess} from './ra-post-process';
import {RADeleteProcess} from './ra-delete-process';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEProcess } from "./ce-process"


export class ProcessCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'process';
  title = 'Process';
  ceProtocol: CEConstructor = CEProcess

  entityService: EOProcessService;
  soGetClass = SOGetProcess;
  soGet: SOGetProcess;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProcessService,
      null,
    );
    this.adapters = [
      new RAPostProcess(this),
      new RAPutProcess(this),
      new RADeleteProcess(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProcess();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}