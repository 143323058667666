import { SOGetProcessStepAssociation } from '../process-step-association/so-get-process-step-association';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutStep {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public stepNumber: number = null,
    public processStepAssociations: SOGetProcessStepAssociation[] = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutStep(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'stepNumber' in params ? params.stepNumber : null,
      'processStepAssociations' in params ? params.processStepAssociations : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
