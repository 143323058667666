import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Topic} from "../../dexie/db";

export class SORespTopic implements ObjectiveProtocol {

  entityType: string = 'topic';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toTopic(): Topic {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            alertSubscriptionsUid: null,
            messagesUid: null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}