import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {SOMediaSpec} from 'lionheartland';
import {ObjectiveProtocol} from 'lionheartland';
import {Media} from "../../dexie/db";

export class SORespMedia implements ObjectiveProtocol {

  entityType: string = 'media';
  uid: string;
  name: string = "";
  description: string = "";
  size: number;
  @Type(() => SOMediaSpec)
  links: SOMediaSpec[];
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toMedia(): Media {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            size: this.size,
            mediaStatus: null,
            mediaTypePrimary: null,
            mediaTypeSecondary: null,
            links: this.links,
            dateCreated: this.dateCreated,
            attachmentsUid: null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}