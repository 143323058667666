

export class SOPutHello {

  constructor(
    public uid: string,
    public loginStyle: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutHello(
      'uid' in params ? params.uid : null,
      'loginStyle' in params ? params.loginStyle : null,
    );
  }
}
