import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAttributeSpecService} from '../../core/attribute-spec/eo-attribute-spec';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {RAPutAttributeSpec} from './ra-put-attribute-spec';
import {RAPostAttributeSpec} from './ra-post-attribute-spec';
import {RADeleteAttributeSpec} from './ra-delete-attribute-spec';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEAttributeSpec } from "./ce-attribute-spec"


export class AttributeSpecCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attributeSpec';
  title = 'Attribute spec';
  ceProtocol: CEConstructor = CEAttributeSpec

  entityService: EOAttributeSpecService;
  soGetClass = SOGetAttributeSpec;
  soGet: SOGetAttributeSpec;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAttributeSpecService,
      null,
    );
    this.adapters = [
      new RAPostAttributeSpec(this),
      new RAPutAttributeSpec(this),
      new RADeleteAttributeSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttributeSpec();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}