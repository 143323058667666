import { Type } from 'class-transformer';
import {SORespRelationship} from './so-resp-relationship';
import {PluralProtocol} from 'lionheartland';
import {Relationship} from "../../dexie/db";

export class SOPluralRelationship implements PluralProtocol {
  @Type(() => SORespRelationship)
  objects: SORespRelationship[] = []
  nextOffset: number = null

    toRelationships(): Relationship[] {
        return this.objects.map(relationship => relationship.toRelationship());
    }
}