import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENAttributePermissionType} from '../../enums';


export class SOPutSimpleAttributePermission {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public whiteList: boolean = null,
    public permissionType: ENAttributePermissionType = null,
    public simpleAttribute: SOGetSimpleAttribute = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSimpleAttributePermission(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'whiteList' in params ? params.whiteList : null,
      'permissionType' in params ? params.permissionType : null,
      'simpleAttribute' in params ? params.simpleAttribute : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
