import {SORespNotification} from '../../core/notification/so-resp-notification';
import {NotificationCore} from './notification.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutNotificationFormFields} from './put-notification-form-fields';
import { CENotificationAttributes } from './ce-notification-attributes'


export class CENotificationBase extends CEBase {

    public details: CENotificationAttributes;
    public fromFields: PutNotificationFormFields = new PutNotificationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: NotificationCore,
        public entity: SORespNotification,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CENotificationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.datafeed)
        this.attributes.push(this.details.message)

    }
}