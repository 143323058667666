import { Type } from 'class-transformer';
import {SORespAppliedConstraint} from './so-resp-applied-constraint';
import {PluralProtocol} from 'lionheartland';
import {AppliedConstraint} from "../../dexie/db";

export class SOPluralAppliedConstraint implements PluralProtocol {
  @Type(() => SORespAppliedConstraint)
  objects: SORespAppliedConstraint[] = []
  nextOffset: number = null

    toAppliedConstraints(): AppliedConstraint[] {
        return this.objects.map(appliedConstraint => appliedConstraint.toAppliedConstraint());
    }
}