import { Type } from 'class-transformer';
import {SORespWork} from './so-resp-work';
import {PluralProtocol} from 'lionheartland';
import {Work} from "../../dexie/db";

export class SOPluralWork implements PluralProtocol {
  @Type(() => SORespWork)
  objects: SORespWork[] = []
  nextOffset: number = null

    toWorks(): Work[] {
        return this.objects.map(work => work.toWork());
    }
}