import {SOPutOrganizationInvite} from '../../core/organization-invite/so-put-organization-invite';
import {OrganizationInviteCore} from './organization-invite.core';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {FormField, SelectionOption} from 'lionheartland';
import {putOrganizationInviteFormFieldOptions} from './put-organization-invite-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutOrganizationInvite extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putOrganizationInviteFormFieldOptions);

  RequestClass = SOPutOrganizationInvite

  constructor(
    public entityCore: OrganizationInviteCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}