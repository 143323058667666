import {SORespEntityPermission} from '../../core/entity-permission/so-resp-entity-permission';
import {EntityPermissionCore} from './entity-permission.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutEntityPermissionFormFields} from './put-entity-permission-form-fields';
import { CEEntityPermissionAttributes } from './ce-entity-permission-attributes'


export class CEEntityPermissionBase extends CEBase {

    public details: CEEntityPermissionAttributes;
    public fromFields: PutEntityPermissionFormFields = new PutEntityPermissionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: EntityPermissionCore,
        public entity: SORespEntityPermission,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEEntityPermissionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.whiteList)
        this.attributes.push(this.details.permissionType)
        this.attributes.push(this.details.entity)
        this.attributes.push(this.details.rolePermissions)
        this.attributes.push(this.details.project)

    }
}