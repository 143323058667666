import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENInterphaceType} from '../../enums';


export class SOPostInterphace {

  constructor(
    public name: string,
    public interphaceType: ENInterphaceType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public entity: SOGetEntity = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInterphace(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'interphaceType' in params ? params.interphaceType : null,
      'entity' in params ? params.entity : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
