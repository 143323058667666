import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOInterphaceService} from '../../core/interphace/eo-interphace';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {RAPutInterphace} from './ra-put-interphace';
import {RAPostInterphace} from './ra-post-interphace';
import {RADeleteInterphace} from './ra-delete-interphace';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEInterphace } from "./ce-interphace"


export class InterphaceCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'interphace';
  title = 'Interphace';
  ceProtocol: CEConstructor = CEInterphace

  entityService: EOInterphaceService;
  soGetClass = SOGetInterphace;
  soGet: SOGetInterphace;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOInterphaceService,
      null,
    );
    this.adapters = [
      new RAPostInterphace(this),
      new RAPutInterphace(this),
      new RADeleteInterphace(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInterphace();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}