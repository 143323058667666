import {SORespOrganization} from '../../core/organization/so-resp-organization';
import {OrganizationCore} from './organization.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutOrganizationFormFields} from './put-organization-form-fields';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {SOGetIngest} from '../../core/ingest/so-get-ingest';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetAlertSubscription} from '../../core/alert-subscription/so-get-alert-subscription';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetNotification} from '../../core/notification/so-get-notification';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {SOGetBlock} from '../../core/block/so-get-block';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {SOGetReport} from '../../core/report/so-get-report';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {SOGetWork} from '../../core/work/so-get-work';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetProcess} from '../../core/process/so-get-process';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SOGetBacklog} from '../../core/backlog/so-get-backlog';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {SOGetExportProject} from '../../core/export-project/so-get-export-project';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {SOGetRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {SOGetPerson} from '../../core/person/so-get-person';
import {SOGetAssignedProject} from '../../core/assigned-project/so-get-assigned-project';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {SOGetStep} from '../../core/step/so-get-step';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';


export class CEOrganizationAttributesBase {

    name: DetailEntry
    dateCreated: DetailEntry
    identifier: DetailEntry
    subscriptions: DetailEntry
    exRefPaymentsLink: DetailEntry
    roleGrants: DetailEntry
    medias: DetailEntry
    invites: DetailEntry
    ingests: DetailEntry
    attachments: DetailEntry
    alertSubscriptions: DetailEntry
    messages: DetailEntry
    notifications: DetailEntry
    topics: DetailEntry
    assessment: DetailEntry
    assessmentSummary: DetailEntry
    block: DetailEntry
    deficiency: DetailEntry
    period: DetailEntry
    report: DetailEntry
    requirement: DetailEntry
    customLogicRequirement: DetailEntry
    work: DetailEntry
    realization: DetailEntry
    entity: DetailEntry
    relationship: DetailEntry
    relationshipAttribute: DetailEntry
    simpleAttribute: DetailEntry
    process: DetailEntry
    project: DetailEntry
    interphace: DetailEntry
    backlog: DetailEntry
    attributeSpec: DetailEntry
    exportProject: DetailEntry
    simpleAttributePermission: DetailEntry
    simpleAttributePermissionRule: DetailEntry
    relationshipAttributePermission: DetailEntry
    relationshipAttributePermissionRule: DetailEntry
    entityPermission: DetailEntry
    plan: DetailEntry
    person: DetailEntry
    assignedProject: DetailEntry
    planWorkAssociation: DetailEntry
    rolePermission: DetailEntry
    role: DetailEntry
    processRole: DetailEntry
    step: DetailEntry
    processStepAssociation: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespOrganization,
        app: CommanderApp,
        formFields: PutOrganizationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.exRefPaymentsLink = new DetailEntry(
            'Manage billing and payments',
            entity.exRefPaymentsLink,
            'exRefPaymentsLink',
            BaseDetailType.link,
            null,
            EditableType.never,
        )


        const subscriptionsCore = app.makeCore( 'subscription');
        subscriptionsCore.soGet = SOGetSubscription.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionsCore
        )
        subscriptionsCore.title = heading ? heading : subscriptionsCore.title
        subscriptionsCore.listTitle = 'Subscriptions'

        subscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.subscriptions = new DetailEntry(
          heading ? heading : subscriptionsCore.listTitle,
          null,
          'subscriptions',
          BaseDetailType.embeddedList,
          subscriptionsCore,
          EditableType.never
        )

        const roleGrantsCore = app.makeCore( 'roleGrant');
        roleGrantsCore.soGet = SOGetRoleGrant.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleGrantsCore
        )
        roleGrantsCore.title = heading ? heading : roleGrantsCore.title
        roleGrantsCore.listTitle = 'Role grants'

        roleGrantsCore.configureForDisplay(parentEntityViewSpec)

        this.roleGrants = new DetailEntry(
          heading ? heading : roleGrantsCore.listTitle,
          null,
          'roleGrants',
          BaseDetailType.embeddedList,
          roleGrantsCore,
          EditableType.never
        )

        const mediasCore = app.makeCore( 'media');
        mediasCore.soGet = SOGetMedia.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        mediasCore.soGet.queryOptions.sortKey = 'dateCreated';
        mediasCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            mediasCore
        )
        mediasCore.title = heading ? heading : mediasCore.title
        mediasCore.listTitle = 'Medias'
        mediasCore.listSpecType = ListSpecType.thumbList

        mediasCore.configureForDisplay(parentEntityViewSpec)

        this.medias = new DetailEntry(
          heading ? heading : mediasCore.listTitle,
          null,
          'medias',
          BaseDetailType.embeddedList,
          mediasCore,
          EditableType.never
        )

        const invitesCore = app.makeCore( 'organizationInvite');
        invitesCore.soGet = SOGetOrganizationInvite.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invitesCore
        )
        invitesCore.title = heading ? heading : invitesCore.title
        invitesCore.listTitle = 'Invites'

        invitesCore.configureForDisplay(parentEntityViewSpec)

        this.invites = new DetailEntry(
          heading ? heading : invitesCore.listTitle,
          null,
          'invites',
          BaseDetailType.embeddedList,
          invitesCore,
          EditableType.never
        )

        const ingestsCore = app.makeCore( 'ingest');
        ingestsCore.soGet = SOGetIngest.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            ingestsCore
        )
        ingestsCore.title = heading ? heading : ingestsCore.title
        ingestsCore.listTitle = 'Ingests'

        ingestsCore.configureForDisplay(parentEntityViewSpec)

        this.ingests = new DetailEntry(
          heading ? heading : ingestsCore.listTitle,
          null,
          'ingests',
          BaseDetailType.embeddedList,
          ingestsCore,
          EditableType.never
        )

        const attachmentsCore = app.makeCore( 'attachment');
        attachmentsCore.soGet = SOGetAttachment.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attachmentsCore
        )
        attachmentsCore.title = heading ? heading : attachmentsCore.title
        attachmentsCore.listTitle = 'Attachments'
        attachmentsCore.listSpecType = ListSpecType.thumbList

        attachmentsCore.configureForDisplay(parentEntityViewSpec)

        this.attachments = new DetailEntry(
          heading ? heading : attachmentsCore.listTitle,
          null,
          'attachments',
          BaseDetailType.embeddedList,
          attachmentsCore,
          EditableType.never
        )

        const alertSubscriptionsCore = app.makeCore( 'alertSubscription');
        alertSubscriptionsCore.soGet = SOGetAlertSubscription.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            alertSubscriptionsCore
        )
        alertSubscriptionsCore.title = heading ? heading : alertSubscriptionsCore.title
        alertSubscriptionsCore.listTitle = 'Alert subscriptions'

        alertSubscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.alertSubscriptions = new DetailEntry(
          heading ? heading : alertSubscriptionsCore.listTitle,
          null,
          'alertSubscriptions',
          BaseDetailType.embeddedList,
          alertSubscriptionsCore,
          EditableType.never
        )

        const messagesCore = app.makeCore( 'message');
        messagesCore.soGet = SOGetMessage.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messagesCore
        )
        messagesCore.title = heading ? heading : messagesCore.title
        messagesCore.listTitle = 'Messages'

        messagesCore.configureForDisplay(parentEntityViewSpec)

        this.messages = new DetailEntry(
          heading ? heading : messagesCore.listTitle,
          null,
          'messages',
          BaseDetailType.embeddedList,
          messagesCore,
          EditableType.never
        )

        const notificationsCore = app.makeCore( 'notification');
        notificationsCore.soGet = SOGetNotification.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            notificationsCore
        )
        notificationsCore.title = heading ? heading : notificationsCore.title
        notificationsCore.listTitle = 'Notifications'

        notificationsCore.configureForDisplay(parentEntityViewSpec)

        this.notifications = new DetailEntry(
          heading ? heading : notificationsCore.listTitle,
          null,
          'notifications',
          BaseDetailType.embeddedList,
          notificationsCore,
          EditableType.never
        )

        const topicsCore = app.makeCore( 'topic');
        topicsCore.soGet = SOGetTopic.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            topicsCore
        )
        topicsCore.title = heading ? heading : topicsCore.title
        topicsCore.listTitle = 'Topics'

        topicsCore.configureForDisplay(parentEntityViewSpec)

        this.topics = new DetailEntry(
          heading ? heading : topicsCore.listTitle,
          null,
          'topics',
          BaseDetailType.embeddedList,
          topicsCore,
          EditableType.never
        )

        const assessmentCore = app.makeCore( 'assessment');
        assessmentCore.soGet = SOGetAssessment.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        assessmentCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentCore
        )
        assessmentCore.title = heading ? heading : assessmentCore.title
        assessmentCore.listTitle = 'Assessment'

        assessmentCore.configureForDisplay(parentEntityViewSpec)

        this.assessment = new DetailEntry(
          heading ? heading : assessmentCore.listTitle,
          null,
          'assessment',
          BaseDetailType.embeddedList,
          assessmentCore,
          EditableType.always,
          formFields.assessment
        )

        const assessmentSummaryCore = app.makeCore( 'assessmentSummary');
        assessmentSummaryCore.soGet = SOGetAssessmentSummary.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        assessmentSummaryCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentSummaryCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentSummaryCore
        )
        assessmentSummaryCore.title = heading ? heading : assessmentSummaryCore.title
        assessmentSummaryCore.listTitle = 'Assessment summary'

        assessmentSummaryCore.configureForDisplay(parentEntityViewSpec)

        this.assessmentSummary = new DetailEntry(
          heading ? heading : assessmentSummaryCore.listTitle,
          null,
          'assessmentSummary',
          BaseDetailType.embeddedList,
          assessmentSummaryCore,
          EditableType.always,
          formFields.assessmentSummary
        )

        const blockCore = app.makeCore( 'block');
        blockCore.soGet = SOGetBlock.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        blockCore.soGet.queryOptions.sortKey = 'dateCreated';
        blockCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            blockCore
        )
        blockCore.title = heading ? heading : blockCore.title
        blockCore.listTitle = 'Block'

        blockCore.configureForDisplay(parentEntityViewSpec)

        this.block = new DetailEntry(
          heading ? heading : blockCore.listTitle,
          null,
          'block',
          BaseDetailType.embeddedList,
          blockCore,
          EditableType.always,
          formFields.block
        )

        const deficiencyCore = app.makeCore( 'deficiency');
        deficiencyCore.soGet = SOGetDeficiency.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        deficiencyCore.soGet.queryOptions.sortKey = 'dateCreated';
        deficiencyCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deficiencyCore
        )
        deficiencyCore.title = heading ? heading : deficiencyCore.title
        deficiencyCore.listTitle = 'Deficiency'

        deficiencyCore.configureForDisplay(parentEntityViewSpec)

        this.deficiency = new DetailEntry(
          heading ? heading : deficiencyCore.listTitle,
          null,
          'deficiency',
          BaseDetailType.embeddedList,
          deficiencyCore,
          EditableType.always,
          formFields.deficiency
        )

        const periodCore = app.makeCore( 'period');
        periodCore.soGet = SOGetPeriod.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        periodCore.soGet.queryOptions.sortKey = 'dateCreated';
        periodCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            periodCore
        )
        periodCore.title = heading ? heading : periodCore.title
        periodCore.listTitle = 'Period'

        periodCore.configureForDisplay(parentEntityViewSpec)

        this.period = new DetailEntry(
          heading ? heading : periodCore.listTitle,
          null,
          'period',
          BaseDetailType.embeddedList,
          periodCore,
          EditableType.always,
          formFields.period
        )

        const reportCore = app.makeCore( 'report');
        reportCore.soGet = SOGetReport.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        reportCore.soGet.queryOptions.sortKey = 'dateCreated';
        reportCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reportCore
        )
        reportCore.title = heading ? heading : reportCore.title
        reportCore.listTitle = 'Report'

        reportCore.configureForDisplay(parentEntityViewSpec)

        this.report = new DetailEntry(
          heading ? heading : reportCore.listTitle,
          null,
          'report',
          BaseDetailType.embeddedList,
          reportCore,
          EditableType.always,
          formFields.report
        )

        const requirementCore = app.makeCore( 'requirement');
        requirementCore.soGet = SOGetRequirement.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        requirementCore.soGet.queryOptions.sortKey = 'dateCreated';
        requirementCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            requirementCore
        )
        requirementCore.title = heading ? heading : requirementCore.title
        requirementCore.listTitle = 'Requirement'

        requirementCore.configureForDisplay(parentEntityViewSpec)

        this.requirement = new DetailEntry(
          heading ? heading : requirementCore.listTitle,
          null,
          'requirement',
          BaseDetailType.embeddedList,
          requirementCore,
          EditableType.always,
          formFields.requirement
        )

        const customLogicRequirementCore = app.makeCore( 'customLogicRequirement');
        customLogicRequirementCore.soGet = SOGetCustomLogicRequirement.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        customLogicRequirementCore.soGet.queryOptions.sortKey = 'dateCreated';
        customLogicRequirementCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customLogicRequirementCore
        )
        customLogicRequirementCore.title = heading ? heading : customLogicRequirementCore.title
        customLogicRequirementCore.listTitle = 'Custom logic requirement'

        customLogicRequirementCore.configureForDisplay(parentEntityViewSpec)

        this.customLogicRequirement = new DetailEntry(
          heading ? heading : customLogicRequirementCore.listTitle,
          null,
          'customLogicRequirement',
          BaseDetailType.embeddedList,
          customLogicRequirementCore,
          EditableType.always,
          formFields.customLogicRequirement
        )

        const workCore = app.makeCore( 'work');
        workCore.soGet = SOGetWork.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        workCore.soGet.queryOptions.sortKey = 'dateCreated';
        workCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            workCore
        )
        workCore.title = heading ? heading : workCore.title
        workCore.listTitle = 'Work'

        workCore.configureForDisplay(parentEntityViewSpec)

        this.work = new DetailEntry(
          heading ? heading : workCore.listTitle,
          null,
          'work',
          BaseDetailType.embeddedList,
          workCore,
          EditableType.always,
          formFields.work
        )

        const realizationCore = app.makeCore( 'realization');
        realizationCore.soGet = SOGetRealization.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        realizationCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationCore
        )
        realizationCore.title = heading ? heading : realizationCore.title
        realizationCore.listTitle = 'Realization'

        realizationCore.configureForDisplay(parentEntityViewSpec)

        this.realization = new DetailEntry(
          heading ? heading : realizationCore.listTitle,
          null,
          'realization',
          BaseDetailType.embeddedList,
          realizationCore,
          EditableType.always,
          formFields.realization
        )

        const entityCore = app.makeCore( 'entity');
        entityCore.soGet = SOGetEntity.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        entityCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityCore
        )
        entityCore.title = heading ? heading : entityCore.title
        entityCore.listTitle = 'Entity'

        entityCore.configureForDisplay(parentEntityViewSpec)

        this.entity = new DetailEntry(
          heading ? heading : entityCore.listTitle,
          null,
          'entity',
          BaseDetailType.embeddedList,
          entityCore,
          EditableType.always,
          formFields.entity
        )

        const relationshipCore = app.makeCore( 'relationship');
        relationshipCore.soGet = SOGetRelationship.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        relationshipCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipCore
        )
        relationshipCore.title = heading ? heading : relationshipCore.title
        relationshipCore.listTitle = 'Relationship'

        relationshipCore.configureForDisplay(parentEntityViewSpec)

        this.relationship = new DetailEntry(
          heading ? heading : relationshipCore.listTitle,
          null,
          'relationship',
          BaseDetailType.embeddedList,
          relationshipCore,
          EditableType.always,
          formFields.relationship
        )

        const relationshipAttributeCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributeCore.soGet = SOGetRelationshipAttribute.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        relationshipAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributeCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributeCore
        )
        relationshipAttributeCore.title = heading ? heading : relationshipAttributeCore.title
        relationshipAttributeCore.listTitle = 'Relationship attribute'

        relationshipAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttribute = new DetailEntry(
          heading ? heading : relationshipAttributeCore.listTitle,
          null,
          'relationshipAttribute',
          BaseDetailType.embeddedList,
          relationshipAttributeCore,
          EditableType.always,
          formFields.relationshipAttribute
        )

        const simpleAttributeCore = app.makeCore( 'simpleAttribute');
        simpleAttributeCore.soGet = SOGetSimpleAttribute.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        simpleAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributeCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributeCore
        )
        simpleAttributeCore.title = heading ? heading : simpleAttributeCore.title
        simpleAttributeCore.listTitle = 'Simple attribute'

        simpleAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttribute = new DetailEntry(
          heading ? heading : simpleAttributeCore.listTitle,
          null,
          'simpleAttribute',
          BaseDetailType.embeddedList,
          simpleAttributeCore,
          EditableType.always,
          formFields.simpleAttribute
        )

        const processCore = app.makeCore( 'process');
        processCore.soGet = SOGetProcess.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        processCore.soGet.queryOptions.sortKey = 'dateCreated';
        processCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processCore
        )
        processCore.title = heading ? heading : processCore.title
        processCore.listTitle = 'Process'

        processCore.configureForDisplay(parentEntityViewSpec)

        this.process = new DetailEntry(
          heading ? heading : processCore.listTitle,
          null,
          'process',
          BaseDetailType.embeddedList,
          processCore,
          EditableType.always,
          formFields.process
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          null,
          'project',
          BaseDetailType.embeddedList,
          projectCore,
          EditableType.always,
          formFields.project
        )

        const interphaceCore = app.makeCore( 'interphace');
        interphaceCore.soGet = SOGetInterphace.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        interphaceCore.soGet.queryOptions.sortKey = 'dateCreated';
        interphaceCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            interphaceCore
        )
        interphaceCore.title = heading ? heading : interphaceCore.title
        interphaceCore.listTitle = 'Interphace'

        interphaceCore.configureForDisplay(parentEntityViewSpec)

        this.interphace = new DetailEntry(
          heading ? heading : interphaceCore.listTitle,
          null,
          'interphace',
          BaseDetailType.embeddedList,
          interphaceCore,
          EditableType.always,
          formFields.interphace
        )

        const backlogCore = app.makeCore( 'backlog');
        backlogCore.soGet = SOGetBacklog.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        backlogCore.soGet.queryOptions.sortKey = 'dateCreated';
        backlogCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            backlogCore
        )
        backlogCore.title = heading ? heading : backlogCore.title
        backlogCore.listTitle = 'Backlog'

        backlogCore.configureForDisplay(parentEntityViewSpec)

        this.backlog = new DetailEntry(
          heading ? heading : backlogCore.listTitle,
          null,
          'backlog',
          BaseDetailType.embeddedList,
          backlogCore,
          EditableType.always,
          formFields.backlog
        )

        const attributeSpecCore = app.makeCore( 'attributeSpec');
        attributeSpecCore.soGet = SOGetAttributeSpec.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        attributeSpecCore.soGet.queryOptions.sortKey = 'dateCreated';
        attributeSpecCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attributeSpecCore
        )
        attributeSpecCore.title = heading ? heading : attributeSpecCore.title
        attributeSpecCore.listTitle = 'Attribute spec'

        attributeSpecCore.configureForDisplay(parentEntityViewSpec)

        this.attributeSpec = new DetailEntry(
          heading ? heading : attributeSpecCore.listTitle,
          null,
          'attributeSpec',
          BaseDetailType.embeddedList,
          attributeSpecCore,
          EditableType.always,
          formFields.attributeSpec
        )

        const exportProjectCore = app.makeCore( 'exportProject');
        exportProjectCore.soGet = SOGetExportProject.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        exportProjectCore.soGet.queryOptions.sortKey = 'dateCreated';
        exportProjectCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            exportProjectCore
        )
        exportProjectCore.title = heading ? heading : exportProjectCore.title
        exportProjectCore.listTitle = 'Export project'

        exportProjectCore.configureForDisplay(parentEntityViewSpec)

        this.exportProject = new DetailEntry(
          heading ? heading : exportProjectCore.listTitle,
          null,
          'exportProject',
          BaseDetailType.embeddedList,
          exportProjectCore,
          EditableType.always,
          formFields.exportProject
        )

        const simpleAttributePermissionCore = app.makeCore( 'simpleAttributePermission');
        simpleAttributePermissionCore.soGet = SOGetSimpleAttributePermission.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        simpleAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionCore
        )
        simpleAttributePermissionCore.title = heading ? heading : simpleAttributePermissionCore.title
        simpleAttributePermissionCore.listTitle = 'Simple attribute permission'

        simpleAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermission = new DetailEntry(
          heading ? heading : simpleAttributePermissionCore.listTitle,
          null,
          'simpleAttributePermission',
          BaseDetailType.embeddedList,
          simpleAttributePermissionCore,
          EditableType.always,
          formFields.simpleAttributePermission
        )

        const simpleAttributePermissionRuleCore = app.makeCore( 'simpleAttributePermissionRule');
        simpleAttributePermissionRuleCore.soGet = SOGetSimpleAttributePermissionRule.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        simpleAttributePermissionRuleCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionRuleCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionRuleCore
        )
        simpleAttributePermissionRuleCore.title = heading ? heading : simpleAttributePermissionRuleCore.title
        simpleAttributePermissionRuleCore.listTitle = 'Simple attribute permission rule'

        simpleAttributePermissionRuleCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermissionRule = new DetailEntry(
          heading ? heading : simpleAttributePermissionRuleCore.listTitle,
          null,
          'simpleAttributePermissionRule',
          BaseDetailType.embeddedList,
          simpleAttributePermissionRuleCore,
          EditableType.always,
          formFields.simpleAttributePermissionRule
        )

        const relationshipAttributePermissionCore = app.makeCore( 'relationshipAttributePermission');
        relationshipAttributePermissionCore.soGet = SOGetRelationshipAttributePermission.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        relationshipAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionCore
        )
        relationshipAttributePermissionCore.title = heading ? heading : relationshipAttributePermissionCore.title
        relationshipAttributePermissionCore.listTitle = 'Relationship attribute permission'

        relationshipAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermission = new DetailEntry(
          heading ? heading : relationshipAttributePermissionCore.listTitle,
          null,
          'relationshipAttributePermission',
          BaseDetailType.embeddedList,
          relationshipAttributePermissionCore,
          EditableType.always,
          formFields.relationshipAttributePermission
        )

        const relationshipAttributePermissionRuleCore = app.makeCore( 'relationshipAttributePermissionRule');
        relationshipAttributePermissionRuleCore.soGet = SOGetRelationshipAttributePermissionRule.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        relationshipAttributePermissionRuleCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionRuleCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionRuleCore
        )
        relationshipAttributePermissionRuleCore.title = heading ? heading : relationshipAttributePermissionRuleCore.title
        relationshipAttributePermissionRuleCore.listTitle = 'Relationship attribute permission rule'

        relationshipAttributePermissionRuleCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermissionRule = new DetailEntry(
          heading ? heading : relationshipAttributePermissionRuleCore.listTitle,
          null,
          'relationshipAttributePermissionRule',
          BaseDetailType.embeddedList,
          relationshipAttributePermissionRuleCore,
          EditableType.always,
          formFields.relationshipAttributePermissionRule
        )

        const entityPermissionCore = app.makeCore( 'entityPermission');
        entityPermissionCore.soGet = SOGetEntityPermission.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        entityPermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityPermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityPermissionCore
        )
        entityPermissionCore.title = heading ? heading : entityPermissionCore.title
        entityPermissionCore.listTitle = 'Entity permission'

        entityPermissionCore.configureForDisplay(parentEntityViewSpec)

        this.entityPermission = new DetailEntry(
          heading ? heading : entityPermissionCore.listTitle,
          null,
          'entityPermission',
          BaseDetailType.embeddedList,
          entityPermissionCore,
          EditableType.always,
          formFields.entityPermission
        )

        const planCore = app.makeCore( 'plan');
        planCore.soGet = SOGetPlan.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        planCore.soGet.queryOptions.sortKey = 'dateCreated';
        planCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            planCore
        )
        planCore.title = heading ? heading : planCore.title
        planCore.listTitle = 'Plan'

        planCore.configureForDisplay(parentEntityViewSpec)

        this.plan = new DetailEntry(
          heading ? heading : planCore.listTitle,
          null,
          'plan',
          BaseDetailType.embeddedList,
          planCore,
          EditableType.always,
          formFields.plan
        )

        const personCore = app.makeCore( 'person');
        personCore.soGet = SOGetPerson.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        personCore.soGet.queryOptions.sortKey = 'dateCreated';
        personCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            personCore
        )
        personCore.title = heading ? heading : personCore.title
        personCore.listTitle = 'Person'

        personCore.configureForDisplay(parentEntityViewSpec)

        this.person = new DetailEntry(
          heading ? heading : personCore.listTitle,
          null,
          'person',
          BaseDetailType.embeddedList,
          personCore,
          EditableType.always,
          formFields.person
        )

        const assignedProjectCore = app.makeCore( 'assignedProject');
        assignedProjectCore.soGet = SOGetAssignedProject.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assignedProjectCore
        )
        assignedProjectCore.title = heading ? heading : assignedProjectCore.title
        assignedProjectCore.listTitle = 'Assigned project'

        assignedProjectCore.configureForDisplay(parentEntityViewSpec)

        this.assignedProject = new DetailEntry(
          heading ? heading : assignedProjectCore.listTitle,
          null,
          'assignedProject',
          BaseDetailType.embeddedList,
          assignedProjectCore,
          EditableType.always,
          formFields.assignedProject
        )

        const planWorkAssociationCore = app.makeCore( 'planWorkAssociation');
        planWorkAssociationCore.soGet = SOGetPlanWorkAssociation.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        planWorkAssociationCore.soGet.queryOptions.sortKey = 'dateCreated';
        planWorkAssociationCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            planWorkAssociationCore
        )
        planWorkAssociationCore.title = heading ? heading : planWorkAssociationCore.title
        planWorkAssociationCore.listTitle = 'Plan work association'

        planWorkAssociationCore.configureForDisplay(parentEntityViewSpec)

        this.planWorkAssociation = new DetailEntry(
          heading ? heading : planWorkAssociationCore.listTitle,
          null,
          'planWorkAssociation',
          BaseDetailType.embeddedList,
          planWorkAssociationCore,
          EditableType.always,
          formFields.planWorkAssociation
        )

        const rolePermissionCore = app.makeCore( 'rolePermission');
        rolePermissionCore.soGet = SOGetRolePermission.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        rolePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        rolePermissionCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolePermissionCore
        )
        rolePermissionCore.title = heading ? heading : rolePermissionCore.title
        rolePermissionCore.listTitle = 'Role permission'

        rolePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.rolePermission = new DetailEntry(
          heading ? heading : rolePermissionCore.listTitle,
          null,
          'rolePermission',
          BaseDetailType.embeddedList,
          rolePermissionCore,
          EditableType.always,
          formFields.rolePermission
        )

        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          null,
          'role',
          BaseDetailType.embeddedList,
          roleCore,
          EditableType.always,
          formFields.role
        )

        const processRoleCore = app.makeCore( 'processRole');
        processRoleCore.soGet = SOGetProcessRole.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        processRoleCore.soGet.queryOptions.sortKey = 'dateCreated';
        processRoleCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processRoleCore
        )
        processRoleCore.title = heading ? heading : processRoleCore.title
        processRoleCore.listTitle = 'Process role'

        processRoleCore.configureForDisplay(parentEntityViewSpec)

        this.processRole = new DetailEntry(
          heading ? heading : processRoleCore.listTitle,
          null,
          'processRole',
          BaseDetailType.embeddedList,
          processRoleCore,
          EditableType.always,
          formFields.processRole
        )

        const stepCore = app.makeCore( 'step');
        stepCore.soGet = SOGetStep.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        stepCore.soGet.queryOptions.sortKey = 'dateCreated';
        stepCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            stepCore
        )
        stepCore.title = heading ? heading : stepCore.title
        stepCore.listTitle = 'Step'

        stepCore.configureForDisplay(parentEntityViewSpec)

        this.step = new DetailEntry(
          heading ? heading : stepCore.listTitle,
          null,
          'step',
          BaseDetailType.embeddedList,
          stepCore,
          EditableType.always,
          formFields.step
        )

        const processStepAssociationCore = app.makeCore( 'processStepAssociation');
        processStepAssociationCore.soGet = SOGetProcessStepAssociation.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        processStepAssociationCore.soGet.queryOptions.sortKey = 'dateCreated';
        processStepAssociationCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processStepAssociationCore
        )
        processStepAssociationCore.title = heading ? heading : processStepAssociationCore.title
        processStepAssociationCore.listTitle = 'Process step association'

        processStepAssociationCore.configureForDisplay(parentEntityViewSpec)

        this.processStepAssociation = new DetailEntry(
          heading ? heading : processStepAssociationCore.listTitle,
          null,
          'processStepAssociation',
          BaseDetailType.embeddedList,
          processStepAssociationCore,
          EditableType.always,
          formFields.processStepAssociation
        )
    }
}