import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutHello } from './so-put-hello';
import { SOGetHello } from './so-get-hello';
import { SOPostHello } from './so-post-hello';
import { SODeleteHello } from './so-delete-hello';
import {SORespHello} from './so-resp-hello';
import {SOPluralHello} from './so-plural-hello';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOHelloService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('hello', entityCore);
  }

  /** GET Hello by id. Will 404 if id not found */
  get(request: SOGetHello): Observable<SOPluralHello> {
    return this.httpClient.get<SOPluralHello>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralHello, resp)),
      tap(async (response: SOPluralHello) => {
        this.log(`fetched Hello`);
        // After a successful server response, update IndexedDB
        const activities = response.toHellos(); // Convert your response to an array of Hello instances
        const promises = activities.map(hello => db.hellos.put(hello));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralHello>(`get Hello`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralHello> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.hellos.toArray()).pipe(
                map((hellosArray) => {
                    // Convert the array of plain objects to an array of SORespHello instances
                    const sorHellos = hellosArray.map(req => plainToClass(SORespHello, req));
                    // Create and return a new instance of SOPluralHello
                    const pluralHello = new SOPluralHello();
                    pluralHello.objects = sorHellos;
                    pluralHello.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralHello;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processHellos(hellosArray) {
        const sorHellos = hellosArray.map(req => plainToClass(SORespHello, req));
        const pluralHello = new SOPluralHello();
        pluralHello.objects = sorHellos;
        pluralHello.nextOffset = null;
        return pluralHello;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostHello): Observable<SORespHello> {
  return this.httpClient.post<SORespHello>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespHello, resp)),

    tap(async (response: SORespHello) => {
      this.log(`added Hello w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.hellos.add(response.toHello());
    }),
    catchError(this.handleError<SORespHello>('Hello post'))
  );
}


  put(request: SOPutHello): Observable<SORespHello> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHello>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespHello, resp)),
      tap(async (response: SORespHello) => {
        this.log(`edited Hello w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.hellos.put(response.toHello());
      }),
      catchError(this.handleError<SORespHello>('Hello put'))
    );
  }


  delete(request: SODeleteHello): Observable<SORespHello> {
    return this.httpClient.delete<SORespHello>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Hello uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.hellos.delete(request.uid);
      }),
      catchError(this.handleError<SORespHello>('Hello delete'))
    );
  }


}
