import {AlertSubscriptionCore} from './entities/alert-subscription/alert-subscription.core';
import {AppliedConstraintCore} from './entities/applied-constraint/applied-constraint.core';
import {AssessmentCore} from './entities/assessment/assessment.core';
import {AssessmentSummaryCore} from './entities/assessment-summary/assessment-summary.core';
import {AssignedProjectCore} from './entities/assigned-project/assigned-project.core';
import {AttachmentCore} from './entities/attachment/attachment.core';
import {AttributeSpecCore} from './entities/attribute-spec/attribute-spec.core';
import {BacklogCore} from './entities/backlog/backlog.core';
import {BlockCore} from './entities/block/block.core';
import {CertificateCore} from './entities/certificate/certificate.core';
import {ConstraintCore} from './entities/constraint/constraint.core';
import {CustomLogicRequirementCore} from './entities/custom-logic-requirement/custom-logic-requirement.core';
import {DatafeedCore} from './entities/datafeed/datafeed.core';
import {DeficiencyCore} from './entities/deficiency/deficiency.core';
import {EntityCore} from './entities/entity/entity.core';
import {EntityPermissionCore} from './entities/entity-permission/entity-permission.core';
import {ExportProjectCore} from './entities/export-project/export-project.core';
import {HelloCore} from './entities/hello/hello.core';
import {IdentityCore} from './entities/identity/identity.core';
import {IngestCore} from './entities/ingest/ingest.core';
import {InterphaceCore} from './entities/interphace/interphace.core';
import {InvoiceCore} from './entities/invoice/invoice.core';
import {InvoiceLineCore} from './entities/invoice-line/invoice-line.core';
import {MediaCore} from './entities/media/media.core';
import {MessageCore} from './entities/message/message.core';
import {NotificationCore} from './entities/notification/notification.core';
import {OrganizationCore} from './entities/organization/organization.core';
import {OrganizationInviteCore} from './entities/organization-invite/organization-invite.core';
import {PaymentIntentCore} from './entities/payment-intent/payment-intent.core';
import {PeriodCore} from './entities/period/period.core';
import {PersonCore} from './entities/person/person.core';
import {PlanCore} from './entities/plan/plan.core';
import {PlanWorkAssociationCore} from './entities/plan-work-association/plan-work-association.core';
import {PriceCore} from './entities/price/price.core';
import {ProcessCore} from './entities/process/process.core';
import {ProcessRoleCore} from './entities/process-role/process-role.core';
import {ProcessStepAssociationCore} from './entities/process-step-association/process-step-association.core';
import {ProductCore} from './entities/product/product.core';
import {ProjectCore} from './entities/project/project.core';
import {RealizationCore} from './entities/realization/realization.core';
import {RelationshipCore} from './entities/relationship/relationship.core';
import {RelationshipAttributeCore} from './entities/relationship-attribute/relationship-attribute.core';
import {RelationshipAttributePermissionCore} from './entities/relationship-attribute-permission/relationship-attribute-permission.core';
import {RelationshipAttributePermissionRuleCore} from './entities/relationship-attribute-permission-rule/relationship-attribute-permission-rule.core';
import {ReportCore} from './entities/report/report.core';
import {RequirementCore} from './entities/requirement/requirement.core';
import {RoleCore} from './entities/role/role.core';
import {RoleGrantCore} from './entities/role-grant/role-grant.core';
import {RolePermissionCore} from './entities/role-permission/role-permission.core';
import {SesMessageCore} from './entities/ses-message/ses-message.core';
import {SimpleAttributeCore} from './entities/simple-attribute/simple-attribute.core';
import {SimpleAttributePermissionCore} from './entities/simple-attribute-permission/simple-attribute-permission.core';
import {SimpleAttributePermissionRuleCore} from './entities/simple-attribute-permission-rule/simple-attribute-permission-rule.core';
import {StepCore} from './entities/step/step.core';
import {SubscriptionCore} from './entities/subscription/subscription.core';
import {TokenCore} from './entities/token/token.core';
import {TopicCore} from './entities/topic/topic.core';
import {UserCore} from './entities/user/user.core';
import {ViewCore} from './entities/view/view.core';
import {ViewGrantCore} from './entities/view-grant/view-grant.core';
import {WorkCore} from './entities/work/work.core';


export const entityCoreMap = {
  alertSubscription: AlertSubscriptionCore,
  appliedConstraint: AppliedConstraintCore,
  assessment: AssessmentCore,
  assessmentSummary: AssessmentSummaryCore,
  assignedProject: AssignedProjectCore,
  attachment: AttachmentCore,
  attributeSpec: AttributeSpecCore,
  backlog: BacklogCore,
  block: BlockCore,
  certificate: CertificateCore,
  constraint: ConstraintCore,
  customLogicRequirement: CustomLogicRequirementCore,
  datafeed: DatafeedCore,
  deficiency: DeficiencyCore,
  entity: EntityCore,
  entityPermission: EntityPermissionCore,
  exportProject: ExportProjectCore,
  hello: HelloCore,
  identity: IdentityCore,
  ingest: IngestCore,
  interphace: InterphaceCore,
  invoice: InvoiceCore,
  invoiceLine: InvoiceLineCore,
  media: MediaCore,
  message: MessageCore,
  notification: NotificationCore,
  organization: OrganizationCore,
  organizationInvite: OrganizationInviteCore,
  paymentIntent: PaymentIntentCore,
  period: PeriodCore,
  person: PersonCore,
  plan: PlanCore,
  planWorkAssociation: PlanWorkAssociationCore,
  price: PriceCore,
  process: ProcessCore,
  processRole: ProcessRoleCore,
  processStepAssociation: ProcessStepAssociationCore,
  product: ProductCore,
  project: ProjectCore,
  realization: RealizationCore,
  relationship: RelationshipCore,
  relationshipAttribute: RelationshipAttributeCore,
  relationshipAttributePermission: RelationshipAttributePermissionCore,
  relationshipAttributePermissionRule: RelationshipAttributePermissionRuleCore,
  report: ReportCore,
  requirement: RequirementCore,
  role: RoleCore,
  roleGrant: RoleGrantCore,
  rolePermission: RolePermissionCore,
  sesMessage: SesMessageCore,
  simpleAttribute: SimpleAttributeCore,
  simpleAttributePermission: SimpleAttributePermissionCore,
  simpleAttributePermissionRule: SimpleAttributePermissionRuleCore,
  step: StepCore,
  subscription: SubscriptionCore,
  token: TokenCore,
  topic: TopicCore,
  user: UserCore,
  view: ViewCore,
  viewGrant: ViewGrantCore,
  work: WorkCore,
};

export const entityCoreNameMap = {
  AlertSubscriptionCore: AlertSubscriptionCore,
  AppliedConstraintCore: AppliedConstraintCore,
  AssessmentCore: AssessmentCore,
  AssessmentSummaryCore: AssessmentSummaryCore,
  AssignedProjectCore: AssignedProjectCore,
  AttachmentCore: AttachmentCore,
  AttributeSpecCore: AttributeSpecCore,
  BacklogCore: BacklogCore,
  BlockCore: BlockCore,
  CertificateCore: CertificateCore,
  ConstraintCore: ConstraintCore,
  CustomLogicRequirementCore: CustomLogicRequirementCore,
  DatafeedCore: DatafeedCore,
  DeficiencyCore: DeficiencyCore,
  EntityCore: EntityCore,
  EntityPermissionCore: EntityPermissionCore,
  ExportProjectCore: ExportProjectCore,
  HelloCore: HelloCore,
  IdentityCore: IdentityCore,
  IngestCore: IngestCore,
  InterphaceCore: InterphaceCore,
  InvoiceCore: InvoiceCore,
  InvoiceLineCore: InvoiceLineCore,
  MediaCore: MediaCore,
  MessageCore: MessageCore,
  NotificationCore: NotificationCore,
  OrganizationCore: OrganizationCore,
  OrganizationInviteCore: OrganizationInviteCore,
  PaymentIntentCore: PaymentIntentCore,
  PeriodCore: PeriodCore,
  PersonCore: PersonCore,
  PlanCore: PlanCore,
  PlanWorkAssociationCore: PlanWorkAssociationCore,
  PriceCore: PriceCore,
  ProcessCore: ProcessCore,
  ProcessRoleCore: ProcessRoleCore,
  ProcessStepAssociationCore: ProcessStepAssociationCore,
  ProductCore: ProductCore,
  ProjectCore: ProjectCore,
  RealizationCore: RealizationCore,
  RelationshipCore: RelationshipCore,
  RelationshipAttributeCore: RelationshipAttributeCore,
  RelationshipAttributePermissionCore: RelationshipAttributePermissionCore,
  RelationshipAttributePermissionRuleCore: RelationshipAttributePermissionRuleCore,
  ReportCore: ReportCore,
  RequirementCore: RequirementCore,
  RoleCore: RoleCore,
  RoleGrantCore: RoleGrantCore,
  RolePermissionCore: RolePermissionCore,
  SesMessageCore: SesMessageCore,
  SimpleAttributeCore: SimpleAttributeCore,
  SimpleAttributePermissionCore: SimpleAttributePermissionCore,
  SimpleAttributePermissionRuleCore: SimpleAttributePermissionRuleCore,
  StepCore: StepCore,
  SubscriptionCore: SubscriptionCore,
  TokenCore: TokenCore,
  TopicCore: TopicCore,
  UserCore: UserCore,
  ViewCore: ViewCore,
  ViewGrantCore: ViewGrantCore,
  WorkCore: WorkCore,
};