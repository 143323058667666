import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRequirement } from './so-put-requirement';
import { SOGetRequirement } from './so-get-requirement';
import { SOPostRequirement } from './so-post-requirement';
import { SODeleteRequirement } from './so-delete-requirement';
import {SORespRequirement} from './so-resp-requirement';
import {SOPluralRequirement} from './so-plural-requirement';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORequirementService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('requirement', entityCore);
  }

  /** GET Requirement by id. Will 404 if id not found */
  get(request: SOGetRequirement): Observable<SOPluralRequirement> {
    return this.httpClient.get<SOPluralRequirement>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRequirement, resp)),
      tap(async (response: SOPluralRequirement) => {
        this.log(`fetched Requirement`);
        // After a successful server response, update IndexedDB
        const activities = response.toRequirements(); // Convert your response to an array of Requirement instances
        const promises = activities.map(requirement => db.requirements.put(requirement));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRequirement>(`get Requirement`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRequirement> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'requirement' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.requirements.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(requirementsArray => this.processRequirements(requirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "requirements" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.requirements.where('requirementsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(requirementsArray => this.processRequirements(requirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "parent" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.requirements.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(requirementsArray => this.processRequirements(requirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "realizations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.requirements.where('realizationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(requirementsArray => this.processRequirements(requirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.requirements.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(requirementsArray => this.processRequirements(requirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.requirements.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(requirementsArray => this.processRequirements(requirementsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.requirements.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(requirementsArray => this.processRequirements(requirementsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.requirements.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(requirementsArray => this.processRequirements(requirementsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.requirements.toArray()).pipe(
                map((requirementsArray) => {
                    // Convert the array of plain objects to an array of SORespRequirement instances
                    const sorRequirements = requirementsArray.map(req => plainToClass(SORespRequirement, req));
                    // Create and return a new instance of SOPluralRequirement
                    const pluralRequirement = new SOPluralRequirement();
                    pluralRequirement.objects = sorRequirements;
                    pluralRequirement.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRequirement;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRequirements(requirementsArray) {
        const sorRequirements = requirementsArray.map(req => plainToClass(SORespRequirement, req));
        const pluralRequirement = new SOPluralRequirement();
        pluralRequirement.objects = sorRequirements;
        pluralRequirement.nextOffset = null;
        return pluralRequirement;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRequirement): Observable<SORespRequirement> {
  return this.httpClient.post<SORespRequirement>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRequirement, resp)),

    tap(async (response: SORespRequirement) => {
      this.log(`added Requirement w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.requirements.add(response.toRequirement());
    }),
    catchError(this.handleError<SORespRequirement>('Requirement post'))
  );
}


  put(request: SOPutRequirement): Observable<SORespRequirement> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRequirement>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRequirement, resp)),
      tap(async (response: SORespRequirement) => {
        this.log(`edited Requirement w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.requirements.put(response.toRequirement());
      }),
      catchError(this.handleError<SORespRequirement>('Requirement put'))
    );
  }


  delete(request: SODeleteRequirement): Observable<SORespRequirement> {
    return this.httpClient.delete<SORespRequirement>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Requirement uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.requirements.delete(request.uid);
      }),
      catchError(this.handleError<SORespRequirement>('Requirement delete'))
    );
  }


}
