import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOMediaService} from '../../core/media/eo-media';
import {SOGetMedia} from '../../core/media/so-get-media';
import {RAPutMedia} from './ra-put-media';
import {RAPostMedia} from './ra-post-media';
import {RADeleteMedia} from './ra-delete-media';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEMedia } from "./ce-media"


export class MediaCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'media';
  title = 'Media';
  ceProtocol: CEConstructor = CEMedia

  entityService: EOMediaService;
  soGetClass = SOGetMedia;
  soGet: SOGetMedia;

  listSpecType = ListSpecType.thumbList;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOMediaService,
      null,
    );
    this.adapters = [
      new RAPostMedia(this),
      new RAPutMedia(this),
      new RADeleteMedia(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetMedia();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}