import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHelloFormFields {

    public loginStyle: FormField

    constructor() {

        this.loginStyle = new FormField(
          'loginStyle',
          'Login style',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostHelloFormFields()

export const postHelloFormFieldOptions: {[key: string]: FormField} = {
  loginStyle: fields.loginStyle,
};