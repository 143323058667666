import {SOPostPaymentIntent} from '../../core/payment-intent/so-post-payment-intent';
import {PaymentIntentCore} from './payment-intent.core';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {FormField, SelectionOption} from 'lionheartland';
import {postPaymentIntentFormFieldOptions} from './post-payment-intent-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {InvoiceCore} from '../invoice/invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';

export class RAPostPaymentIntent extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postPaymentIntentFormFieldOptions);

  RequestClass = SOPostPaymentIntent

  constructor(
    public entityCore: PaymentIntentCore
  ) {
    super();
    if (postPaymentIntentFormFieldOptions.invoice) {
      postPaymentIntentFormFieldOptions.invoice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier(
        'invoice',
        SOGetInvoice,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}