import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutProcessStepAssociation } from './so-put-process-step-association';
import { SOGetProcessStepAssociation } from './so-get-process-step-association';
import { SOPostProcessStepAssociation } from './so-post-process-step-association';
import { SODeleteProcessStepAssociation } from './so-delete-process-step-association';
import {SORespProcessStepAssociation} from './so-resp-process-step-association';
import {SOPluralProcessStepAssociation} from './so-plural-process-step-association';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOProcessStepAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('process_step_association', entityCore);
  }

  /** GET ProcessStepAssociation by id. Will 404 if id not found */
  get(request: SOGetProcessStepAssociation): Observable<SOPluralProcessStepAssociation> {
    return this.httpClient.get<SOPluralProcessStepAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralProcessStepAssociation, resp)),
      tap(async (response: SOPluralProcessStepAssociation) => {
        this.log(`fetched ProcessStepAssociation`);
        // After a successful server response, update IndexedDB
        const activities = response.toProcessStepAssociations(); // Convert your response to an array of ProcessStepAssociation instances
        const promises = activities.map(processStepAssociation => db.processStepAssociations.put(processStepAssociation));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralProcessStepAssociation>(`get ProcessStepAssociation`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralProcessStepAssociation> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'processStepAssociation' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.processStepAssociations.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "process" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processStepAssociations.where('processUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "step" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processStepAssociations.where('stepUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processStepAssociations.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.processStepAssociations.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.processStepAssociations.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.processStepAssociations.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(processStepAssociationsArray => this.processProcessStepAssociations(processStepAssociationsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.processStepAssociations.toArray()).pipe(
                map((processStepAssociationsArray) => {
                    // Convert the array of plain objects to an array of SORespProcessStepAssociation instances
                    const sorProcessStepAssociations = processStepAssociationsArray.map(req => plainToClass(SORespProcessStepAssociation, req));
                    // Create and return a new instance of SOPluralProcessStepAssociation
                    const pluralProcessStepAssociation = new SOPluralProcessStepAssociation();
                    pluralProcessStepAssociation.objects = sorProcessStepAssociations;
                    pluralProcessStepAssociation.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralProcessStepAssociation;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processProcessStepAssociations(processStepAssociationsArray) {
        const sorProcessStepAssociations = processStepAssociationsArray.map(req => plainToClass(SORespProcessStepAssociation, req));
        const pluralProcessStepAssociation = new SOPluralProcessStepAssociation();
        pluralProcessStepAssociation.objects = sorProcessStepAssociations;
        pluralProcessStepAssociation.nextOffset = null;
        return pluralProcessStepAssociation;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostProcessStepAssociation): Observable<SORespProcessStepAssociation> {
  return this.httpClient.post<SORespProcessStepAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespProcessStepAssociation, resp)),

    tap(async (response: SORespProcessStepAssociation) => {
      this.log(`added ProcessStepAssociation w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.processStepAssociations.add(response.toProcessStepAssociation());
    }),
    catchError(this.handleError<SORespProcessStepAssociation>('ProcessStepAssociation post'))
  );
}


  put(request: SOPutProcessStepAssociation): Observable<SORespProcessStepAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespProcessStepAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespProcessStepAssociation, resp)),
      tap(async (response: SORespProcessStepAssociation) => {
        this.log(`edited ProcessStepAssociation w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.processStepAssociations.put(response.toProcessStepAssociation());
      }),
      catchError(this.handleError<SORespProcessStepAssociation>('ProcessStepAssociation put'))
    );
  }


  delete(request: SODeleteProcessStepAssociation): Observable<SORespProcessStepAssociation> {
    return this.httpClient.delete<SORespProcessStepAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted ProcessStepAssociation uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.processStepAssociations.delete(request.uid);
      }),
      catchError(this.handleError<SORespProcessStepAssociation>('ProcessStepAssociation delete'))
    );
  }


}
