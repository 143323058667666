import {SORespProcess} from '../../core/process/so-resp-process';
import {ProcessCore} from './process.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProcessFormFields} from './put-process-form-fields';
import { CEProcessAttributes } from './ce-process-attributes'


export class CEProcessBase extends CEBase {

    public details: CEProcessAttributes;
    public fromFields: PutProcessFormFields = new PutProcessFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProcessCore,
        public entity: SORespProcess,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProcessAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.deficiencys)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.processRoles)
        this.attributes.push(this.details.project)
        this.attributes.push(this.details.processStepAssociations)

    }
}