import { SOGetRole } from '../role/so-get-role';
import { SOGetView } from '../view/so-get-view';


export class SOPostViewGrant {

  constructor(
    public role: SOGetRole,
    public view: SOGetView,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostViewGrant(
      'role' in params ? params.role : null,
      'view' in params ? params.view : null,
    );
  }
}
