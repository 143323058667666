import {SORespStep} from '../../core/step/so-resp-step';
import {StepCore} from './step.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutStepFormFields} from './put-step-form-fields';
import {SOGetStep} from '../../core/step/so-get-step';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEStepAttributesBase {

    name: DetailEntry
    description: DetailEntry
    stepNumber: DetailEntry
    processStepAssociations: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespStep,
        app: CommanderApp,
        formFields: PutStepFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.stepNumber = new DetailEntry(
            'Step number',
            entity.stepNumber,
            'stepNumber',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.stepNumber
        )


        const processStepAssociationsCore = app.makeCore( 'processStepAssociation');
        processStepAssociationsCore.soGet = SOGetProcessStepAssociation.construct({
          step: new SOGetStep(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processStepAssociationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        processStepAssociationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processStepAssociationsCore
        )
        processStepAssociationsCore.title = heading ? heading : processStepAssociationsCore.title
        processStepAssociationsCore.listTitle = 'Process step associations'

        processStepAssociationsCore.configureForDisplay(parentEntityViewSpec)

        this.processStepAssociations = new DetailEntry(
          heading ? heading : processStepAssociationsCore.listTitle,
          null,
          'processStepAssociations',
          BaseDetailType.embeddedList,
          processStepAssociationsCore,
          EditableType.always,
          formFields.processStepAssociations
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}