import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENEntityPermissionType} from '../../enums';

export class PutEntityPermissionFormFields {

    public name: FormField
    public description: FormField
    public whiteList: FormField
    public permissionType: FormField
    public entity: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.whiteList = new FormField(
          'whiteList',
          'White list',
          null,
          FormFieldType.boolean,
          false,
        )
        this.permissionType = new FormField(
          'permissionType',
          'Permission type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.permissionType.enumeration = ENEntityPermissionType
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutEntityPermissionFormFields()

export const putEntityPermissionFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  whiteList: fields.whiteList,
  permissionType: fields.permissionType,
  entity: fields.entity,
  project: fields.project,
};