import {SOPutAttachment} from '../../core/attachment/so-put-attachment';
import {AttachmentCore} from './attachment.core';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {FormField, SelectionOption} from 'lionheartland';
import {putAttachmentFormFieldOptions} from './put-attachment-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutAttachment extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAttachmentFormFieldOptions);

  RequestClass = SOPutAttachment

  constructor(
    public entityCore: AttachmentCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}