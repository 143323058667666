import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAlertSubscriptionService} from '../../core/alert-subscription/eo-alert-subscription';
import {SOGetAlertSubscription} from '../../core/alert-subscription/so-get-alert-subscription';
import {RAPutAlertSubscription} from './ra-put-alert-subscription';
import {RAPostAlertSubscription} from './ra-post-alert-subscription';
import {RADeleteAlertSubscription} from './ra-delete-alert-subscription';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEAlertSubscription } from "./ce-alert-subscription"


export class AlertSubscriptionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'alertSubscription';
  title = 'Alert subscription';
  ceProtocol: CEConstructor = CEAlertSubscription

  entityService: EOAlertSubscriptionService;
  soGetClass = SOGetAlertSubscription;
  soGet: SOGetAlertSubscription;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAlertSubscriptionService,
      null,
    );
    this.adapters = [
      new RAPostAlertSubscription(this),
      new RAPutAlertSubscription(this),
      new RADeleteAlertSubscription(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAlertSubscription();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}