import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOInvoiceLineService} from '../../core/invoice-line/eo-invoice-line';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';
import {RAPutInvoiceLine} from './ra-put-invoice-line';
import {RAPostInvoiceLine} from './ra-post-invoice-line';
import {RADeleteInvoiceLine} from './ra-delete-invoice-line';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEInvoiceLine } from "./ce-invoice-line"


export class InvoiceLineCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'invoiceLine';
  title = 'Invoice line';
  ceProtocol: CEConstructor = CEInvoiceLine

  entityService: EOInvoiceLineService;
  soGetClass = SOGetInvoiceLine;
  soGet: SOGetInvoiceLine;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOInvoiceLineService,
      null,
    );
    this.adapters = [
      new RAPostInvoiceLine(this),
      new RAPutInvoiceLine(this),
      new RADeleteInvoiceLine(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInvoiceLine();
    this.soGet.queryOptions.limit = this.limit;
  }


}