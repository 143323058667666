import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutInvoice } from './so-put-invoice';
import { SOGetInvoice } from './so-get-invoice';
import { SOPostInvoice } from './so-post-invoice';
import { SODeleteInvoice } from './so-delete-invoice';
import {SORespInvoice} from './so-resp-invoice';
import {SOPluralInvoice} from './so-plural-invoice';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOInvoiceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('invoice', entityCore);
  }

  /** GET Invoice by id. Will 404 if id not found */
  get(request: SOGetInvoice): Observable<SOPluralInvoice> {
    return this.httpClient.get<SOPluralInvoice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralInvoice, resp)),
      tap(async (response: SOPluralInvoice) => {
        this.log(`fetched Invoice`);
        // After a successful server response, update IndexedDB
        const activities = response.toInvoices(); // Convert your response to an array of Invoice instances
        const promises = activities.map(invoice => db.invoices.put(invoice));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralInvoice>(`get Invoice`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralInvoice> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.invoices.toArray()).pipe(
                map((invoicesArray) => {
                    // Convert the array of plain objects to an array of SORespInvoice instances
                    const sorInvoices = invoicesArray.map(req => plainToClass(SORespInvoice, req));
                    // Create and return a new instance of SOPluralInvoice
                    const pluralInvoice = new SOPluralInvoice();
                    pluralInvoice.objects = sorInvoices;
                    pluralInvoice.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralInvoice;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processInvoices(invoicesArray) {
        const sorInvoices = invoicesArray.map(req => plainToClass(SORespInvoice, req));
        const pluralInvoice = new SOPluralInvoice();
        pluralInvoice.objects = sorInvoices;
        pluralInvoice.nextOffset = null;
        return pluralInvoice;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostInvoice): Observable<SORespInvoice> {
  return this.httpClient.post<SORespInvoice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespInvoice, resp)),

    tap(async (response: SORespInvoice) => {
      this.log(`added Invoice w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.invoices.add(response.toInvoice());
    }),
    catchError(this.handleError<SORespInvoice>('Invoice post'))
  );
}


  put(request: SOPutInvoice): Observable<SORespInvoice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInvoice>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespInvoice, resp)),
      tap(async (response: SORespInvoice) => {
        this.log(`edited Invoice w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.invoices.put(response.toInvoice());
      }),
      catchError(this.handleError<SORespInvoice>('Invoice put'))
    );
  }


  delete(request: SODeleteInvoice): Observable<SORespInvoice> {
    return this.httpClient.delete<SORespInvoice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Invoice uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.invoices.delete(request.uid);
      }),
      catchError(this.handleError<SORespInvoice>('Invoice delete'))
    );
  }


}
