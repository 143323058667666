import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostOrganizationInviteFormFields {

    public email: FormField
    public role: FormField
    public organization: FormField

    constructor() {

        this.email = new FormField(
          'email',
          'Email',
          null,
          FormFieldType.text,
          true,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostOrganizationInviteFormFields()

export const postOrganizationInviteFormFieldOptions: {[key: string]: FormField} = {
  email: fields.email,
  role: fields.role,
  organization: fields.organization,
};