import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutExportProject } from './so-put-export-project';
import { SOGetExportProject } from './so-get-export-project';
import { SOPostExportProject } from './so-post-export-project';
import { SODeleteExportProject } from './so-delete-export-project';
import {SORespExportProject} from './so-resp-export-project';
import {SOPluralExportProject} from './so-plural-export-project';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOExportProjectService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('export_project', entityCore);
  }

  /** GET ExportProject by id. Will 404 if id not found */
  get(request: SOGetExportProject): Observable<SOPluralExportProject> {
    return this.httpClient.get<SOPluralExportProject>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralExportProject, resp)),
      tap(async (response: SOPluralExportProject) => {
        this.log(`fetched ExportProject`);
        // After a successful server response, update IndexedDB
        const activities = response.toExportProjects(); // Convert your response to an array of ExportProject instances
        const promises = activities.map(exportProject => db.exportProjects.put(exportProject));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralExportProject>(`get ExportProject`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralExportProject> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'exportProject' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.exportProjects.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(exportProjectsArray => this.processExportProjects(exportProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.exportProjects.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(exportProjectsArray => this.processExportProjects(exportProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.exportProjects.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(exportProjectsArray => this.processExportProjects(exportProjectsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.exportProjects.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(exportProjectsArray => this.processExportProjects(exportProjectsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.exportProjects.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(exportProjectsArray => this.processExportProjects(exportProjectsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.exportProjects.toArray()).pipe(
                map((exportProjectsArray) => {
                    // Convert the array of plain objects to an array of SORespExportProject instances
                    const sorExportProjects = exportProjectsArray.map(req => plainToClass(SORespExportProject, req));
                    // Create and return a new instance of SOPluralExportProject
                    const pluralExportProject = new SOPluralExportProject();
                    pluralExportProject.objects = sorExportProjects;
                    pluralExportProject.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralExportProject;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processExportProjects(exportProjectsArray) {
        const sorExportProjects = exportProjectsArray.map(req => plainToClass(SORespExportProject, req));
        const pluralExportProject = new SOPluralExportProject();
        pluralExportProject.objects = sorExportProjects;
        pluralExportProject.nextOffset = null;
        return pluralExportProject;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostExportProject): Observable<SORespExportProject> {
  return this.httpClient.post<SORespExportProject>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespExportProject, resp)),

    tap(async (response: SORespExportProject) => {
      this.log(`added ExportProject w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.exportProjects.add(response.toExportProject());
    }),
    catchError(this.handleError<SORespExportProject>('ExportProject post'))
  );
}


  put(request: SOPutExportProject): Observable<SORespExportProject> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespExportProject>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespExportProject, resp)),
      tap(async (response: SORespExportProject) => {
        this.log(`edited ExportProject w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.exportProjects.put(response.toExportProject());
      }),
      catchError(this.handleError<SORespExportProject>('ExportProject put'))
    );
  }


  delete(request: SODeleteExportProject): Observable<SORespExportProject> {
    return this.httpClient.delete<SORespExportProject>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted ExportProject uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.exportProjects.delete(request.uid);
      }),
      catchError(this.handleError<SORespExportProject>('ExportProject delete'))
    );
  }


}
