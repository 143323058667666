import { Type } from 'class-transformer';
import {ENViewType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {View} from "../../dexie/db";

export class SORespView implements ObjectiveProtocol {

  entityType: string = 'view';
  uid: string;
  name: string;
  identifier: string;
  type: ENViewType = null;

    toView(): View {
        return {
            uid: this.uid,
            name: this.name,
            identifier: this.identifier,
            type: null,
            viewGrantsUid: null,
}

        };
}