import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutInvoiceFormFields {

    public ref: FormField
    public exRef: FormField
    public amountDue: FormField
    public amountPaid: FormField
    public amountRemaining: FormField
    public dateDue: FormField
    public periodStart: FormField
    public periodEnd: FormField
    public paid: FormField
    public subscription: FormField

    constructor() {

        this.ref = new FormField(
          'ref',
          'Ref',
          null,
          FormFieldType.text,
          false,
        )
        this.exRef = new FormField(
          'exRef',
          'Ex ref',
          null,
          FormFieldType.text,
          false,
        )
        this.amountDue = new FormField(
          'amountDue',
          'Amount due',
          null,
          FormFieldType.text,
          false,
        )
        this.amountPaid = new FormField(
          'amountPaid',
          'Amount paid',
          null,
          FormFieldType.text,
          false,
        )
        this.amountRemaining = new FormField(
          'amountRemaining',
          'Amount remaining',
          null,
          FormFieldType.text,
          false,
        )
        this.dateDue = new FormField(
          'dateDue',
          'Date due',
          null,
          FormFieldType.datetime,
          false,
        )
        this.periodStart = new FormField(
          'periodStart',
          'Period start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.periodEnd = new FormField(
          'periodEnd',
          'Period end',
          null,
          FormFieldType.datetime,
          false,
        )
        this.paid = new FormField(
          'paid',
          'Paid',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.subscription = new FormField(
          'subscription',
          'Subscription',
          'subscription',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutInvoiceFormFields()

export const putInvoiceFormFieldOptions: {[key: string]: FormField} = {
  ref: fields.ref,
  exRef: fields.exRef,
  amountDue: fields.amountDue,
  amountPaid: fields.amountPaid,
  amountRemaining: fields.amountRemaining,
  dateDue: fields.dateDue,
  periodStart: fields.periodStart,
  periodEnd: fields.periodEnd,
  paid: fields.paid,
  subscription: fields.subscription,
};