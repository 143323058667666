import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { SORespRole } from '../role/so-resp-role';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENInviteStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {OrganizationInvite} from "../../dexie/db";

export class SORespOrganizationInvite implements ObjectiveProtocol {

  entityType: string = 'organizationInvite';
  uid: string;
  email: string;
  status: ENInviteStatus = ENInviteStatus.sent;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toOrganizationInvite(): OrganizationInvite {
        return {
            uid: this.uid,
            username: null,
            email: this.email,
            status: this.status,
            dateCreated: this.dateCreated,
            userUid: this.user ? this.user.uid : null,
            roleUid: this.role ? this.role.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}