import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutCertificate } from './so-put-certificate';
import { SOGetCertificate } from './so-get-certificate';
import { SOPostCertificate } from './so-post-certificate';
import { SODeleteCertificate } from './so-delete-certificate';
import {SORespCertificate} from './so-resp-certificate';
import {SOPluralCertificate} from './so-plural-certificate';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOCertificateService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('certificate', entityCore);
  }

  /** GET Certificate by id. Will 404 if id not found */
  get(request: SOGetCertificate): Observable<SOPluralCertificate> {
    return this.httpClient.get<SOPluralCertificate>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralCertificate, resp)),
      tap(async (response: SOPluralCertificate) => {
        this.log(`fetched Certificate`);
        // After a successful server response, update IndexedDB
        const activities = response.toCertificates(); // Convert your response to an array of Certificate instances
        const promises = activities.map(certificate => db.certificates.put(certificate));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralCertificate>(`get Certificate`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralCertificate> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.certificates.toArray()).pipe(
                map((certificatesArray) => {
                    // Convert the array of plain objects to an array of SORespCertificate instances
                    const sorCertificates = certificatesArray.map(req => plainToClass(SORespCertificate, req));
                    // Create and return a new instance of SOPluralCertificate
                    const pluralCertificate = new SOPluralCertificate();
                    pluralCertificate.objects = sorCertificates;
                    pluralCertificate.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralCertificate;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processCertificates(certificatesArray) {
        const sorCertificates = certificatesArray.map(req => plainToClass(SORespCertificate, req));
        const pluralCertificate = new SOPluralCertificate();
        pluralCertificate.objects = sorCertificates;
        pluralCertificate.nextOffset = null;
        return pluralCertificate;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostCertificate): Observable<SORespCertificate> {
  return this.httpClient.post<SORespCertificate>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespCertificate, resp)),

    tap(async (response: SORespCertificate) => {
      this.log(`added Certificate w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.certificates.add(response.toCertificate());
    }),
    catchError(this.handleError<SORespCertificate>('Certificate post'))
  );
}


  put(request: SOPutCertificate): Observable<SORespCertificate> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCertificate>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespCertificate, resp)),
      tap(async (response: SORespCertificate) => {
        this.log(`edited Certificate w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.certificates.put(response.toCertificate());
      }),
      catchError(this.handleError<SORespCertificate>('Certificate put'))
    );
  }


  delete(request: SODeleteCertificate): Observable<SORespCertificate> {
    return this.httpClient.delete<SORespCertificate>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Certificate uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.certificates.delete(request.uid);
      }),
      catchError(this.handleError<SORespCertificate>('Certificate delete'))
    );
  }


}
