import {SORespMessage} from '../../core/message/so-resp-message';
import {MessageCore} from './message.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutMessageFormFields} from './put-message-form-fields';
import { CEMessageAttributes } from './ce-message-attributes'


export class CEMessageBase extends CEBase {

    public details: CEMessageAttributes;
    public fromFields: PutMessageFormFields = new PutMessageFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: MessageCore,
        public entity: SORespMessage,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEMessageAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.notifications)
        this.attributes.push(this.details.topic)

    }
}