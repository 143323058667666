import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENEntityPermissionType} from '../../enums';


export class SOPostEntityPermission {

  constructor(
    public name: string,
    public whiteList: boolean,
    public permissionType: ENEntityPermissionType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public entity: SOGetEntity = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostEntityPermission(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'whiteList' in params ? params.whiteList : null,
      'permissionType' in params ? params.permissionType : null,
      'entity' in params ? params.entity : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
