import { SOGetUser } from '../user/so-get-user';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutPerson {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public relation: string = null,
    public user: SOGetUser = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPerson(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'relation' in params ? params.relation : null,
      'user' in params ? params.user : null,
      'organization' in params ? params.organization : null,
    );
  }
}
