import {SOPutIngest} from '../../core/ingest/so-put-ingest';
import {IngestCore} from './ingest.core';
import {SOGetIngest} from '../../core/ingest/so-get-ingest';
import {FormField, SelectionOption} from 'lionheartland';
import {putIngestFormFieldOptions} from './put-ingest-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutIngest extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putIngestFormFieldOptions);

  RequestClass = SOPutIngest

  constructor(
    public entityCore: IngestCore
  ) {
    super();
    if (putIngestFormFieldOptions.organization) {
      putIngestFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('entityName'),
      this.getFormValueFromIdentifier('data'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}