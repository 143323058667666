import { Type } from 'class-transformer';
import { SORespDeficiency } from '../deficiency/so-resp-deficiency';
import { SORespPeriod } from '../period/so-resp-period';
import { SORespBacklog } from '../backlog/so-resp-backlog';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Work} from "../../dexie/db";

export class SORespWork implements ObjectiveProtocol {

  entityType: string = 'work';
  uid: string;
  name: string;
  number: number;
  description: string = "";
  active: boolean;
  @Type(() => SORespDeficiency)
  deficiency: SORespDeficiency = null;
  @Type(() => SORespPeriod)
  period: SORespPeriod = null;
  @Type(() => SORespBacklog)
  backlog: SORespBacklog = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toWork(): Work {
        return {
            uid: this.uid,
            name: this.name,
            number: this.number,
            description: this.description,
            dateCreated: null,
            active: this.active,
            deficiencyUid: this.deficiency ? this.deficiency.uid : null,
            periodUid: this.period ? this.period.uid : null,
            planWorkAssociationsUid: null,
            backlogUid: this.backlog ? this.backlog.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}