import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAttachmentService} from '../../core/attachment/eo-attachment';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {RAPutAttachment} from './ra-put-attachment';
import {RAPostAttachment} from './ra-post-attachment';
import {RADeleteAttachment} from './ra-delete-attachment';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEAttachment } from "./ce-attachment"


export class AttachmentCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attachment';
  title = 'Attachment';
  ceProtocol: CEConstructor = CEAttachment

  entityService: EOAttachmentService;
  soGetClass = SOGetAttachment;
  soGet: SOGetAttachment;

  listSpecType = ListSpecType.thumbList;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAttachmentService,
      null,
    );
    this.adapters = [
      new RAPostAttachment(this),
      new RAPutAttachment(this),
      new RADeleteAttachment(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttachment();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}