import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAttachmentFormFields {

    public media: FormField

    constructor() {

        this.media = new FormField(
          'media',
          'Media',
          'media',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostAttachmentFormFields()

export const postAttachmentFormFieldOptions: {[key: string]: FormField} = {
  media: fields.media,
};