import { SOGetInvoice } from '../invoice/so-get-invoice';
import {ENPaymentIntentStatus} from '../../enums';


export class SOPutPaymentIntent {

  constructor(
    public uid: string,
    public amount: number = null,
    public status: ENPaymentIntentStatus = null,
    public invoice: SOGetInvoice = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPaymentIntent(
      'uid' in params ? params.uid : null,
      'amount' in params ? params.amount : null,
      'status' in params ? params.status : null,
      'invoice' in params ? params.invoice : null,
    );
  }
}
