import { Type } from 'class-transformer';
import {SORespSubscription} from './so-resp-subscription';
import {PluralProtocol} from 'lionheartland';
import {Subscription} from "../../dexie/db";

export class SOPluralSubscription implements PluralProtocol {
  @Type(() => SORespSubscription)
  objects: SORespSubscription[] = []
  nextOffset: number = null

    toSubscriptions(): Subscription[] {
        return this.objects.map(subscription => subscription.toSubscription());
    }
}