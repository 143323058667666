import {SORespConstraint} from '../../core/constraint/so-resp-constraint';
import {ConstraintCore} from './constraint.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutConstraintFormFields} from './put-constraint-form-fields';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';


export class CEConstraintAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    description: DetailEntry
    appliedConstraints: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespConstraint,
        app: CommanderApp,
        formFields: PutConstraintFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const appliedConstraintsCore = app.makeCore( 'appliedConstraint');
        appliedConstraintsCore.soGet = SOGetAppliedConstraint.construct({
          constraint: new SOGetConstraint(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            appliedConstraintsCore
        )
        appliedConstraintsCore.title = heading ? heading : appliedConstraintsCore.title
        appliedConstraintsCore.listTitle = 'Applied constraints'

        appliedConstraintsCore.configureForDisplay(parentEntityViewSpec)

        this.appliedConstraints = new DetailEntry(
          heading ? heading : appliedConstraintsCore.listTitle,
          null,
          'appliedConstraints',
          BaseDetailType.embeddedList,
          appliedConstraintsCore,
          EditableType.never
        )
    }
}