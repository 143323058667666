import {SORespTopic} from '../../core/topic/so-resp-topic';
import {TopicCore} from './topic.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutTopicFormFields} from './put-topic-form-fields';
import { CETopicAttributes } from './ce-topic-attributes'


export class CETopicBase extends CEBase {

    public details: CETopicAttributes;
    public fromFields: PutTopicFormFields = new PutTopicFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TopicCore,
        public entity: SORespTopic,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETopicAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.alertSubscriptions)
        this.attributes.push(this.details.messages)

    }
}