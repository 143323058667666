import {SORespAttributeSpec} from '../../core/attribute-spec/so-resp-attribute-spec';
import {AttributeSpecCore} from './attribute-spec.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAttributeSpecFormFields} from './put-attribute-spec-form-fields';
import { CEAttributeSpecAttributes } from './ce-attribute-spec-attributes'


export class CEAttributeSpecBase extends CEBase {

    public details: CEAttributeSpecAttributes;
    public fromFields: PutAttributeSpecFormFields = new PutAttributeSpecFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AttributeSpecCore,
        public entity: SORespAttributeSpec,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAttributeSpecAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.interphace)
        this.attributes.push(this.details.simpleAttribute)
        this.attributes.push(this.details.relationshipAttribute)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.project)

    }
}