import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOCustomLogicRequirementService} from '../../core/custom-logic-requirement/eo-custom-logic-requirement';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {RAPutCustomLogicRequirement} from './ra-put-custom-logic-requirement';
import {RAPostCustomLogicRequirement} from './ra-post-custom-logic-requirement';
import {RADeleteCustomLogicRequirement} from './ra-delete-custom-logic-requirement';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CECustomLogicRequirement } from "./ce-custom-logic-requirement"


export class CustomLogicRequirementCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'customLogicRequirement';
  title = 'Custom logic requirement';
  ceProtocol: CEConstructor = CECustomLogicRequirement

  entityService: EOCustomLogicRequirementService;
  soGetClass = SOGetCustomLogicRequirement;
  soGet: SOGetCustomLogicRequirement;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOCustomLogicRequirementService,
      null,
    );
    this.adapters = [
      new RAPostCustomLogicRequirement(this),
      new RAPutCustomLogicRequirement(this),
      new RADeleteCustomLogicRequirement(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCustomLogicRequirement();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}