import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutSimpleAttributePermission } from './so-put-simple-attribute-permission';
import { SOGetSimpleAttributePermission } from './so-get-simple-attribute-permission';
import { SOPostSimpleAttributePermission } from './so-post-simple-attribute-permission';
import { SODeleteSimpleAttributePermission } from './so-delete-simple-attribute-permission';
import {SORespSimpleAttributePermission} from './so-resp-simple-attribute-permission';
import {SOPluralSimpleAttributePermission} from './so-plural-simple-attribute-permission';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOSimpleAttributePermissionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('simple_attribute_permission', entityCore);
  }

  /** GET SimpleAttributePermission by id. Will 404 if id not found */
  get(request: SOGetSimpleAttributePermission): Observable<SOPluralSimpleAttributePermission> {
    return this.httpClient.get<SOPluralSimpleAttributePermission>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralSimpleAttributePermission, resp)),
      tap(async (response: SOPluralSimpleAttributePermission) => {
        this.log(`fetched SimpleAttributePermission`);
        // After a successful server response, update IndexedDB
        const activities = response.toSimpleAttributePermissions(); // Convert your response to an array of SimpleAttributePermission instances
        const promises = activities.map(simpleAttributePermission => db.simpleAttributePermissions.put(simpleAttributePermission));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralSimpleAttributePermission>(`get SimpleAttributePermission`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralSimpleAttributePermission> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'simpleAttributePermission' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.simpleAttributePermissions.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "simpleAttribute" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributePermissions.where('simpleAttributeUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "simpleAttributePermissionRules" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributePermissions.where('simpleAttributePermissionRulesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "rolePermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributePermissions.where('rolePermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributePermissions.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.simpleAttributePermissions.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.simpleAttributePermissions.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.simpleAttributePermissions.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(simpleAttributePermissionsArray => this.processSimpleAttributePermissions(simpleAttributePermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.simpleAttributePermissions.toArray()).pipe(
                map((simpleAttributePermissionsArray) => {
                    // Convert the array of plain objects to an array of SORespSimpleAttributePermission instances
                    const sorSimpleAttributePermissions = simpleAttributePermissionsArray.map(req => plainToClass(SORespSimpleAttributePermission, req));
                    // Create and return a new instance of SOPluralSimpleAttributePermission
                    const pluralSimpleAttributePermission = new SOPluralSimpleAttributePermission();
                    pluralSimpleAttributePermission.objects = sorSimpleAttributePermissions;
                    pluralSimpleAttributePermission.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralSimpleAttributePermission;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processSimpleAttributePermissions(simpleAttributePermissionsArray) {
        const sorSimpleAttributePermissions = simpleAttributePermissionsArray.map(req => plainToClass(SORespSimpleAttributePermission, req));
        const pluralSimpleAttributePermission = new SOPluralSimpleAttributePermission();
        pluralSimpleAttributePermission.objects = sorSimpleAttributePermissions;
        pluralSimpleAttributePermission.nextOffset = null;
        return pluralSimpleAttributePermission;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostSimpleAttributePermission): Observable<SORespSimpleAttributePermission> {
  return this.httpClient.post<SORespSimpleAttributePermission>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespSimpleAttributePermission, resp)),

    tap(async (response: SORespSimpleAttributePermission) => {
      this.log(`added SimpleAttributePermission w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.simpleAttributePermissions.add(response.toSimpleAttributePermission());
    }),
    catchError(this.handleError<SORespSimpleAttributePermission>('SimpleAttributePermission post'))
  );
}


  put(request: SOPutSimpleAttributePermission): Observable<SORespSimpleAttributePermission> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSimpleAttributePermission>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespSimpleAttributePermission, resp)),
      tap(async (response: SORespSimpleAttributePermission) => {
        this.log(`edited SimpleAttributePermission w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.simpleAttributePermissions.put(response.toSimpleAttributePermission());
      }),
      catchError(this.handleError<SORespSimpleAttributePermission>('SimpleAttributePermission put'))
    );
  }


  delete(request: SODeleteSimpleAttributePermission): Observable<SORespSimpleAttributePermission> {
    return this.httpClient.delete<SORespSimpleAttributePermission>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted SimpleAttributePermission uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.simpleAttributePermissions.delete(request.uid);
      }),
      catchError(this.handleError<SORespSimpleAttributePermission>('SimpleAttributePermission delete'))
    );
  }


}
