import { SOGetDatafeed } from '../datafeed/so-get-datafeed';
import { SOGetMessage } from '../message/so-get-message';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENMessageStatus} from '../../enums';


export class SOPostNotification {

  constructor(
    public name: string,
    public message: SOGetMessage,
    public organization: SOGetOrganization,
    public description: string = "",
    public status: ENMessageStatus = ENMessageStatus.unread,
    public datafeed: SOGetDatafeed = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostNotification(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'status' in params ? params.status : null,
      'datafeed' in params ? params.datafeed : null,
      'message' in params ? params.message : null,
      'organization' in params ? params.organization : null,
    );
  }
}
