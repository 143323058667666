import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENAttributePermissionType} from '../../enums';

export class PostRelationshipAttributePermissionRuleFormFields {

    public name: FormField
    public description: FormField
    public whiteList: FormField
    public permissionType: FormField
    public relationshipAttribute: FormField
    public relationshipAttributePermission: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.whiteList = new FormField(
          'whiteList',
          'White list',
          null,
          FormFieldType.boolean,
          true,
        )
        this.permissionType = new FormField(
          'permissionType',
          'Permission type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.permissionType.enumeration = ENAttributePermissionType
        this.relationshipAttribute = new FormField(
          'relationshipAttribute',
          'Relationship attribute',
          'relationshipAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttributePermission = new FormField(
          'relationshipAttributePermission',
          'Relationship attribute permission',
          'relationshipAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostRelationshipAttributePermissionRuleFormFields()

export const postRelationshipAttributePermissionRuleFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  whiteList: fields.whiteList,
  permissionType: fields.permissionType,
  relationshipAttribute: fields.relationshipAttribute,
  relationshipAttributePermission: fields.relationshipAttributePermission,
  project: fields.project,
};