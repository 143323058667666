import {SOPostRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-post-relationship-attribute-permission';
import {RelationshipAttributePermissionCore} from './relationship-attribute-permission.core';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {FormField, SelectionOption} from 'lionheartland';
import {postRelationshipAttributePermissionFormFieldOptions} from './post-relationship-attribute-permission-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {RelationshipAttributeCore} from '../relationship-attribute/relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostRelationshipAttributePermission extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRelationshipAttributePermissionFormFieldOptions);

  RequestClass = SOPostRelationshipAttributePermission

  constructor(
    public entityCore: RelationshipAttributePermissionCore
  ) {
    super();
    if (postRelationshipAttributePermissionFormFieldOptions.relationshipAttribute) {
      postRelationshipAttributePermissionFormFieldOptions.relationshipAttribute.makeCore = true;
    }
    if (postRelationshipAttributePermissionFormFieldOptions.project) {
      postRelationshipAttributePermissionFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('whiteList'),
      this.getFormValueFromIdentifier('permissionType'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'relationshipAttribute',
        SOGetRelationshipAttribute,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}