import {SORespExportProject} from '../../core/export-project/so-resp-export-project';
import {ExportProjectCore} from './export-project.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutExportProjectFormFields} from './put-export-project-form-fields';
import { CEExportProjectAttributes } from './ce-export-project-attributes'


export class CEExportProjectBase extends CEBase {

    public details: CEExportProjectAttributes;
    public fromFields: PutExportProjectFormFields = new PutExportProjectFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ExportProjectCore,
        public entity: SORespExportProject,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEExportProjectAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.reasonForExport)
        this.attributes.push(this.details.exportType)
        this.attributes.push(this.details.downloadLink)
        this.attributes.push(this.details.project)

    }
}