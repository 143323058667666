import {SORespCustomLogicRequirement} from '../../core/custom-logic-requirement/so-resp-custom-logic-requirement';
import {CustomLogicRequirementCore} from './custom-logic-requirement.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutCustomLogicRequirementFormFields} from './put-custom-logic-requirement-form-fields';
import { CECustomLogicRequirementAttributes } from './ce-custom-logic-requirement-attributes'


export class CECustomLogicRequirementBase extends CEBase {

    public details: CECustomLogicRequirementAttributes;
    public fromFields: PutCustomLogicRequirementFormFields = new PutCustomLogicRequirementFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: CustomLogicRequirementCore,
        public entity: SORespCustomLogicRequirement,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CECustomLogicRequirementAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.currentStatus)
        this.attributes.push(this.details.customLogicRequirements)
        this.attributes.push(this.details.parent)
        this.attributes.push(this.details.blocks)
        this.attributes.push(this.details.simpleAttributes)
        this.attributes.push(this.details.relationshipAttributes)
        this.attributes.push(this.details.project)

    }
}