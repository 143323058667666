import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Role} from "../../dexie/db";

export class SORespRole implements ObjectiveProtocol {

  entityType: string = 'role';
  uid: string;
  name: string;
  identifier: string;
  @Type(() => SORespOrganization)
  organization: SORespOrganization = null;

    toRole(): Role {
        return {
            uid: this.uid,
            name: this.name,
            identifier: this.identifier,
            roleGrantsUid: null,
            viewGrantsUid: null,
            invitesUid: null,
            rolePermissionsUid: null,
            processRolesUid: null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}