import { Type } from 'class-transformer';
import { SORespEntityPermission } from '../entity-permission/so-resp-entity-permission';
import { SORespRelationshipAttributePermission } from '../relationship-attribute-permission/so-resp-relationship-attribute-permission';
import { SORespSimpleAttributePermission } from '../simple-attribute-permission/so-resp-simple-attribute-permission';
import { SORespRole } from '../role/so-resp-role';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {RolePermission} from "../../dexie/db";

export class SORespRolePermission implements ObjectiveProtocol {

  entityType: string = 'rolePermission';
  uid: string;
  name: string;
  @Type(() => SORespEntityPermission)
  entityPermission: SORespEntityPermission = null;
  @Type(() => SORespRelationshipAttributePermission)
  relationshipAttributePermission: SORespRelationshipAttributePermission = null;
  @Type(() => SORespSimpleAttributePermission)
  simpleAttributePermission: SORespSimpleAttributePermission = null;
  @Type(() => SORespRole)
  role: SORespRole = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toRolePermission(): RolePermission {
        return {
            uid: this.uid,
            name: this.name,
            dateCreated: null,
            entityPermissionUid: this.entityPermission ? this.entityPermission.uid : null,
            relationshipAttributePermissionUid: this.relationshipAttributePermission ? this.relationshipAttributePermission.uid : null,
            simpleAttributePermissionUid: this.simpleAttributePermission ? this.simpleAttributePermission.uid : null,
            roleUid: this.role ? this.role.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}