import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRelationship {

  constructor(
    public name: string,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationship(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
