import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutCertificateFormFields {

    public user: FormField
    public tokens: FormField

    constructor() {

        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.tokens = new FormField(
          'tokens',
          'Tokens',
          'token',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutCertificateFormFields()

export const putCertificateFormFieldOptions: {[key: string]: FormField} = {
  user: fields.user,
  tokens: fields.tokens,
};