import {MMQueryOptions} from 'lionheartland';


export class SODeleteRelationshipAttributePermission {

  constructor(
    public uid: string,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
}
