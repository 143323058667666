import { BASE_ROUTES, AuthGuard, SvNavigatorComponent } from 'lionheartland';


import {AssessmentCore} from './entities/assessment/assessment.core';
import {AssessmentSummaryCore} from './entities/assessment-summary/assessment-summary.core';
import {AssignedProjectCore} from './entities/assigned-project/assigned-project.core';
import {AttributeSpecCore} from './entities/attribute-spec/attribute-spec.core';
import {BacklogCore} from './entities/backlog/backlog.core';
import {BlockCore} from './entities/block/block.core';
import {CustomLogicRequirementCore} from './entities/custom-logic-requirement/custom-logic-requirement.core';
import {DeficiencyCore} from './entities/deficiency/deficiency.core';
import {EntityCore} from './entities/entity/entity.core';
import {EntityPermissionCore} from './entities/entity-permission/entity-permission.core';
import {ExportProjectCore} from './entities/export-project/export-project.core';
import {InterphaceCore} from './entities/interphace/interphace.core';
import {PeriodCore} from './entities/period/period.core';
import {PersonCore} from './entities/person/person.core';
import {PlanCore} from './entities/plan/plan.core';
import {PlanWorkAssociationCore} from './entities/plan-work-association/plan-work-association.core';
import {ProcessCore} from './entities/process/process.core';
import {ProcessRoleCore} from './entities/process-role/process-role.core';
import {ProcessStepAssociationCore} from './entities/process-step-association/process-step-association.core';
import {ProjectCore} from './entities/project/project.core';
import {RealizationCore} from './entities/realization/realization.core';
import {RelationshipCore} from './entities/relationship/relationship.core';
import {RelationshipAttributeCore} from './entities/relationship-attribute/relationship-attribute.core';
import {RelationshipAttributePermissionCore} from './entities/relationship-attribute-permission/relationship-attribute-permission.core';
import {RelationshipAttributePermissionRuleCore} from './entities/relationship-attribute-permission-rule/relationship-attribute-permission-rule.core';
import {ReportCore} from './entities/report/report.core';
import {RequirementCore} from './entities/requirement/requirement.core';
import {RolePermissionCore} from './entities/role-permission/role-permission.core';
import {SimpleAttributeCore} from './entities/simple-attribute/simple-attribute.core';
import {SimpleAttributePermissionCore} from './entities/simple-attribute-permission/simple-attribute-permission.core';
import {SimpleAttributePermissionRuleCore} from './entities/simple-attribute-permission-rule/simple-attribute-permission-rule.core';
import {StepCore} from './entities/step/step.core';
import {WorkCore} from './entities/work/work.core';

export const DEFAULT_ROUTES = [];

for (const route of BASE_ROUTES) {
  DEFAULT_ROUTES.push(
      route
  )
}


DEFAULT_ROUTES.push(
  { path: 'assessment', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AssessmentCore, identifier: 'assessment'} },
  { path: 'assessment/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AssessmentCore, identifier: 'assessment'} },
  { path: 'assessment_summary', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AssessmentSummaryCore, identifier: 'assessmentSummary'} },
  { path: 'assessment_summary/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AssessmentSummaryCore, identifier: 'assessmentSummary'} },
  { path: 'assigned_project', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AssignedProjectCore, identifier: 'assignedProject'} },
  { path: 'assigned_project/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AssignedProjectCore, identifier: 'assignedProject'} },
  { path: 'attribute_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeSpecCore, identifier: 'attributeSpec'} },
  { path: 'attribute_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeSpecCore, identifier: 'attributeSpec'} },
  { path: 'backlog', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BacklogCore, identifier: 'backlog'} },
  { path: 'backlog/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BacklogCore, identifier: 'backlog'} },
  { path: 'block', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BlockCore, identifier: 'block'} },
  { path: 'block/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BlockCore, identifier: 'block'} },
  { path: 'custom_logic_requirement', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CustomLogicRequirementCore, identifier: 'customLogicRequirement'} },
  { path: 'custom_logic_requirement/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CustomLogicRequirementCore, identifier: 'customLogicRequirement'} },
  { path: 'deficiency', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DeficiencyCore, identifier: 'deficiency'} },
  { path: 'deficiency/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DeficiencyCore, identifier: 'deficiency'} },
  { path: 'entity', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityCore, identifier: 'entity'} },
  { path: 'entity/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityCore, identifier: 'entity'} },
  { path: 'entity_permission', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityPermissionCore, identifier: 'entityPermission'} },
  { path: 'entity_permission/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityPermissionCore, identifier: 'entityPermission'} },
  { path: 'export_project', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ExportProjectCore, identifier: 'exportProject'} },
  { path: 'export_project/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ExportProjectCore, identifier: 'exportProject'} },
  { path: 'interphace', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InterphaceCore, identifier: 'interphace'} },
  { path: 'interphace/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InterphaceCore, identifier: 'interphace'} },
  { path: 'period', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PeriodCore, identifier: 'period'} },
  { path: 'period/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PeriodCore, identifier: 'period'} },
  { path: 'person', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PersonCore, identifier: 'person'} },
  { path: 'person/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PersonCore, identifier: 'person'} },
  { path: 'plan', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PlanCore, identifier: 'plan'} },
  { path: 'plan/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PlanCore, identifier: 'plan'} },
  { path: 'plan_work_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PlanWorkAssociationCore, identifier: 'planWorkAssociation'} },
  { path: 'plan_work_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PlanWorkAssociationCore, identifier: 'planWorkAssociation'} },
  { path: 'process', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProcessCore, identifier: 'process'} },
  { path: 'process/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProcessCore, identifier: 'process'} },
  { path: 'process_role', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProcessRoleCore, identifier: 'processRole'} },
  { path: 'process_role/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProcessRoleCore, identifier: 'processRole'} },
  { path: 'process_step_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProcessStepAssociationCore, identifier: 'processStepAssociation'} },
  { path: 'process_step_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProcessStepAssociationCore, identifier: 'processStepAssociation'} },
  { path: 'project', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProjectCore, identifier: 'project'} },
  { path: 'project/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProjectCore, identifier: 'project'} },
  { path: 'realization', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RealizationCore, identifier: 'realization'} },
  { path: 'realization/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RealizationCore, identifier: 'realization'} },
  { path: 'relationship', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipCore, identifier: 'relationship'} },
  { path: 'relationship/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipCore, identifier: 'relationship'} },
  { path: 'relationship_attribute', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipAttributeCore, identifier: 'relationshipAttribute'} },
  { path: 'relationship_attribute/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipAttributeCore, identifier: 'relationshipAttribute'} },
  { path: 'relationship_attribute_permission', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipAttributePermissionCore, identifier: 'relationshipAttributePermission'} },
  { path: 'relationship_attribute_permission/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipAttributePermissionCore, identifier: 'relationshipAttributePermission'} },
  { path: 'relationship_attribute_permission_rule', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipAttributePermissionRuleCore, identifier: 'relationshipAttributePermissionRule'} },
  { path: 'relationship_attribute_permission_rule/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipAttributePermissionRuleCore, identifier: 'relationshipAttributePermissionRule'} },
  { path: 'report', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ReportCore, identifier: 'report'} },
  { path: 'report/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ReportCore, identifier: 'report'} },
  { path: 'requirement', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequirementCore, identifier: 'requirement'} },
  { path: 'requirement/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequirementCore, identifier: 'requirement'} },
  { path: 'role_permission', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RolePermissionCore, identifier: 'rolePermission'} },
  { path: 'role_permission/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RolePermissionCore, identifier: 'rolePermission'} },
  { path: 'simple_attribute', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SimpleAttributeCore, identifier: 'simpleAttribute'} },
  { path: 'simple_attribute/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SimpleAttributeCore, identifier: 'simpleAttribute'} },
  { path: 'simple_attribute_permission', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SimpleAttributePermissionCore, identifier: 'simpleAttributePermission'} },
  { path: 'simple_attribute_permission/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SimpleAttributePermissionCore, identifier: 'simpleAttributePermission'} },
  { path: 'simple_attribute_permission_rule', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SimpleAttributePermissionRuleCore, identifier: 'simpleAttributePermissionRule'} },
  { path: 'simple_attribute_permission_rule/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SimpleAttributePermissionRuleCore, identifier: 'simpleAttributePermissionRule'} },
  { path: 'step', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StepCore, identifier: 'step'} },
  { path: 'step/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StepCore, identifier: 'step'} },
  { path: 'work', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WorkCore, identifier: 'work'} },
  { path: 'work/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WorkCore, identifier: 'work'} },
)