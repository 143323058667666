import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTopicService} from '../../core/topic/eo-topic';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {RAPutTopic} from './ra-put-topic';
import {RAPostTopic} from './ra-post-topic';
import {RADeleteTopic} from './ra-delete-topic';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CETopic } from "./ce-topic"


export class TopicCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'topic';
  title = 'Topic';
  ceProtocol: CEConstructor = CETopic

  entityService: EOTopicService;
  soGetClass = SOGetTopic;
  soGet: SOGetTopic;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTopicService,
      null,
    );
    this.adapters = [
      new RAPostTopic(this),
      new RAPutTopic(this),
      new RADeleteTopic(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTopic();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}