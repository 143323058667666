import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENPeriodType} from '../../enums';

export class PutPeriodFormFields {

    public name: FormField
    public numeral: FormField
    public type: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.numeral = new FormField(
          'numeral',
          'Numeral',
          null,
          FormFieldType.text,
          false,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.type.enumeration = ENPeriodType
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutPeriodFormFields()

export const putPeriodFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  numeral: fields.numeral,
  type: fields.type,
  project: fields.project,
};