import {SORespProduct} from '../../core/product/so-resp-product';
import {ProductCore} from './product.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProductFormFields} from './put-product-form-fields';
import {SOGetProduct} from '../../core/product/so-get-product';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {SOGetPrice} from '../../core/price/so-get-price';


export class CEProductAttributesBase {

    name: DetailEntry
    sku: DetailEntry
    exRef: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    active: DetailEntry
    appliedConstraints: DetailEntry
    prices: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProduct,
        app: CommanderApp,
        formFields: PutProductFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.sku = new DetailEntry(
            'Sku',
            entity.sku,
            'sku',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.sku
        )

        this.exRef = new DetailEntry(
            'Ex ref',
            entity.exRef,
            'exRef',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.exRef
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )


        const appliedConstraintsCore = app.makeCore( 'appliedConstraint');
        appliedConstraintsCore.soGet = SOGetAppliedConstraint.construct({
          product: new SOGetProduct(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            appliedConstraintsCore
        )
        appliedConstraintsCore.title = heading ? heading : appliedConstraintsCore.title
        appliedConstraintsCore.listTitle = 'Applied constraints'

        appliedConstraintsCore.configureForDisplay(parentEntityViewSpec)

        this.appliedConstraints = new DetailEntry(
          heading ? heading : appliedConstraintsCore.listTitle,
          null,
          'appliedConstraints',
          BaseDetailType.embeddedList,
          appliedConstraintsCore,
          EditableType.never
        )

        const pricesCore = app.makeCore( 'price');
        pricesCore.soGet = SOGetPrice.construct({
          product: new SOGetProduct(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            pricesCore
        )
        pricesCore.title = heading ? heading : pricesCore.title
        pricesCore.listTitle = 'Prices'

        pricesCore.configureForDisplay(parentEntityViewSpec)

        this.prices = new DetailEntry(
          heading ? heading : pricesCore.listTitle,
          null,
          'prices',
          BaseDetailType.embeddedList,
          pricesCore,
          EditableType.never
        )
    }
}