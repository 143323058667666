import {SORespView} from '../../core/view/so-resp-view';
import {ViewCore} from './view.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutViewFormFields} from './put-view-form-fields';
import {SOGetView} from '../../core/view/so-get-view';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';


export class CEViewAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    type: DetailEntry
    viewGrants: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespView,
        app: CommanderApp,
        formFields: PutViewFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.type
        )


        const viewGrantsCore = app.makeCore( 'viewGrant');
        viewGrantsCore.soGet = SOGetViewGrant.construct({
          view: new SOGetView(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            viewGrantsCore
        )
        viewGrantsCore.title = heading ? heading : viewGrantsCore.title
        viewGrantsCore.listTitle = 'View grants'

        viewGrantsCore.configureForDisplay(parentEntityViewSpec)

        this.viewGrants = new DetailEntry(
          heading ? heading : viewGrantsCore.listTitle,
          null,
          'viewGrants',
          BaseDetailType.embeddedList,
          viewGrantsCore,
          EditableType.never
        )
    }
}