import { Type } from 'class-transformer';
import {SORespRealization} from './so-resp-realization';
import {PluralProtocol} from 'lionheartland';
import {Realization} from "../../dexie/db";

export class SOPluralRealization implements PluralProtocol {
  @Type(() => SORespRealization)
  objects: SORespRealization[] = []
  nextOffset: number = null

    toRealizations(): Realization[] {
        return this.objects.map(realization => realization.toRealization());
    }
}