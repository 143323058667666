import { Type } from 'class-transformer';
import {SORespProduct} from './so-resp-product';
import {PluralProtocol} from 'lionheartland';
import {Product} from "../../dexie/db";

export class SOPluralProduct implements PluralProtocol {
  @Type(() => SORespProduct)
  objects: SORespProduct[] = []
  nextOffset: number = null

    toProducts(): Product[] {
        return this.objects.map(product => product.toProduct());
    }
}