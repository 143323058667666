import {SORespPlan} from '../../core/plan/so-resp-plan';
import {PlanCore} from './plan.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPlanFormFields} from './put-plan-form-fields';
import { CEPlanAttributes } from './ce-plan-attributes'


export class CEPlanBase extends CEBase {

    public details: CEPlanAttributes;
    public fromFields: PutPlanFormFields = new PutPlanFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PlanCore,
        public entity: SORespPlan,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPlanAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.period)
        this.attributes.push(this.details.planWorkAssociations)
        this.attributes.push(this.details.project)

    }
}