import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSimpleAttributeService} from '../../core/simple-attribute/eo-simple-attribute';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {RAPutSimpleAttribute} from './ra-put-simple-attribute';
import {RAPostSimpleAttribute} from './ra-post-simple-attribute';
import {RADeleteSimpleAttribute} from './ra-delete-simple-attribute';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CESimpleAttribute } from "./ce-simple-attribute"


export class SimpleAttributeCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'simpleAttribute';
  title = 'Simple attribute';
  ceProtocol: CEConstructor = CESimpleAttribute

  entityService: EOSimpleAttributeService;
  soGetClass = SOGetSimpleAttribute;
  soGet: SOGetSimpleAttribute;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSimpleAttributeService,
      null,
    );
    this.adapters = [
      new RAPostSimpleAttribute(this),
      new RAPutSimpleAttribute(this),
      new RADeleteSimpleAttribute(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSimpleAttribute();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}