import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPlanWorkAssociationService} from '../../core/plan-work-association/eo-plan-work-association';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {RAPutPlanWorkAssociation} from './ra-put-plan-work-association';
import {RAPostPlanWorkAssociation} from './ra-post-plan-work-association';
import {RADeletePlanWorkAssociation} from './ra-delete-plan-work-association';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEPlanWorkAssociation } from "./ce-plan-work-association"


export class PlanWorkAssociationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'planWorkAssociation';
  title = 'Plan work association';
  ceProtocol: CEConstructor = CEPlanWorkAssociation

  entityService: EOPlanWorkAssociationService;
  soGetClass = SOGetPlanWorkAssociation;
  soGet: SOGetPlanWorkAssociation;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPlanWorkAssociationService,
      null,
    );
    this.adapters = [
      new RAPostPlanWorkAssociation(this),
      new RAPutPlanWorkAssociation(this),
      new RADeletePlanWorkAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPlanWorkAssociation();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}