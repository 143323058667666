import {ENRoleGrantStatus} from '../../enums';


export class SOPutRoleGrant {

  constructor(
    public uid: string,
    public isDefault: boolean = null,
    public status: ENRoleGrantStatus = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRoleGrant(
      'uid' in params ? params.uid : null,
      'isDefault' in params ? params.isDefault : null,
      'status' in params ? params.status : null,
    );
  }
}
