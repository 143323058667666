import { Type } from 'class-transformer';
import {SORespOrganizationInvite} from './so-resp-organization-invite';
import {PluralProtocol} from 'lionheartland';
import {OrganizationInvite} from "../../dexie/db";

export class SOPluralOrganizationInvite implements PluralProtocol {
  @Type(() => SORespOrganizationInvite)
  objects: SORespOrganizationInvite[] = []
  nextOffset: number = null

    toOrganizationInvites(): OrganizationInvite[] {
        return this.objects.map(organizationInvite => organizationInvite.toOrganizationInvite());
    }
}