import { Type } from 'class-transformer';
import {SORespPaymentIntent} from './so-resp-payment-intent';
import {PluralProtocol} from 'lionheartland';
import {PaymentIntent} from "../../dexie/db";

export class SOPluralPaymentIntent implements PluralProtocol {
  @Type(() => SORespPaymentIntent)
  objects: SORespPaymentIntent[] = []
  nextOffset: number = null

    toPaymentIntents(): PaymentIntent[] {
        return this.objects.map(paymentIntent => paymentIntent.toPaymentIntent());
    }
}