import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutDatafeed } from './so-put-datafeed';
import { SOGetDatafeed } from './so-get-datafeed';
import { SOPostDatafeed } from './so-post-datafeed';
import { SODeleteDatafeed } from './so-delete-datafeed';
import {SORespDatafeed} from './so-resp-datafeed';
import {SOPluralDatafeed} from './so-plural-datafeed';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EODatafeedService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('datafeed', entityCore);
  }

  /** GET Datafeed by id. Will 404 if id not found */
  get(request: SOGetDatafeed): Observable<SOPluralDatafeed> {
    return this.httpClient.get<SOPluralDatafeed>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralDatafeed, resp)),
      tap(async (response: SOPluralDatafeed) => {
        this.log(`fetched Datafeed`);
        // After a successful server response, update IndexedDB
        const activities = response.toDatafeeds(); // Convert your response to an array of Datafeed instances
        const promises = activities.map(datafeed => db.datafeeds.put(datafeed));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralDatafeed>(`get Datafeed`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralDatafeed> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.datafeeds.toArray()).pipe(
                map((datafeedsArray) => {
                    // Convert the array of plain objects to an array of SORespDatafeed instances
                    const sorDatafeeds = datafeedsArray.map(req => plainToClass(SORespDatafeed, req));
                    // Create and return a new instance of SOPluralDatafeed
                    const pluralDatafeed = new SOPluralDatafeed();
                    pluralDatafeed.objects = sorDatafeeds;
                    pluralDatafeed.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralDatafeed;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processDatafeeds(datafeedsArray) {
        const sorDatafeeds = datafeedsArray.map(req => plainToClass(SORespDatafeed, req));
        const pluralDatafeed = new SOPluralDatafeed();
        pluralDatafeed.objects = sorDatafeeds;
        pluralDatafeed.nextOffset = null;
        return pluralDatafeed;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostDatafeed): Observable<SORespDatafeed> {
  return this.httpClient.post<SORespDatafeed>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespDatafeed, resp)),

    tap(async (response: SORespDatafeed) => {
      this.log(`added Datafeed w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.datafeeds.add(response.toDatafeed());
    }),
    catchError(this.handleError<SORespDatafeed>('Datafeed post'))
  );
}


  put(request: SOPutDatafeed): Observable<SORespDatafeed> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDatafeed>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespDatafeed, resp)),
      tap(async (response: SORespDatafeed) => {
        this.log(`edited Datafeed w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.datafeeds.put(response.toDatafeed());
      }),
      catchError(this.handleError<SORespDatafeed>('Datafeed put'))
    );
  }


  delete(request: SODeleteDatafeed): Observable<SORespDatafeed> {
    return this.httpClient.delete<SORespDatafeed>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Datafeed uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.datafeeds.delete(request.uid);
      }),
      catchError(this.handleError<SORespDatafeed>('Datafeed delete'))
    );
  }


}
