import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAppliedConstraintService} from '../../core/applied-constraint/eo-applied-constraint';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {RAPutAppliedConstraint} from './ra-put-applied-constraint';
import {RAPostAppliedConstraint} from './ra-post-applied-constraint';
import {RADeleteAppliedConstraint} from './ra-delete-applied-constraint';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEAppliedConstraint } from "./ce-applied-constraint"


export class AppliedConstraintCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'appliedConstraint';
  title = 'Applied constraint';
  ceProtocol: CEConstructor = CEAppliedConstraint

  entityService: EOAppliedConstraintService;
  soGetClass = SOGetAppliedConstraint;
  soGet: SOGetAppliedConstraint;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAppliedConstraintService,
      null,
    );
    this.adapters = [
      new RAPostAppliedConstraint(this),
      new RAPutAppliedConstraint(this),
      new RADeleteAppliedConstraint(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAppliedConstraint();
    this.soGet.queryOptions.limit = this.limit;
  }


}