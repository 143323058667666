import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Process} from "../../dexie/db";

export class SORespProcess implements ObjectiveProtocol {

  entityType: string = 'process';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toProcess(): Process {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            deficiencysUid: null,
            realizationsUid: null,
            processRolesUid: null,
            projectUid: this.project ? this.project.uid : null,
            processStepAssociationsUid: null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}