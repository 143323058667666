import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Ingest} from "../../dexie/db";

export class SORespIngest implements ObjectiveProtocol {

  entityType: string = 'ingest';
  uid: string;
  entityName: string;
  data: any;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toIngest(): Ingest {
        return {
            uid: this.uid,
            entityName: this.entityName,
            data: this.data,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}