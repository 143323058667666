import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSubscriptionStatus} from '../../enums';

export class PostSubscriptionFormFields {

    public exRef: FormField
    public organization: FormField
    public price: FormField
    public status: FormField

    constructor() {

        this.exRef = new FormField(
          'exRef',
          'Ex ref',
          null,
          FormFieldType.text,
          false,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.price = new FormField(
          'price',
          'Price',
          'price',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.status.enumeration = ENSubscriptionStatus

    }
}

const fields = new PostSubscriptionFormFields()

export const postSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  exRef: fields.exRef,
  organization: fields.organization,
  price: fields.price,
  status: fields.status,
};