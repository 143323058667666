import {SORespDatafeed} from '../../core/datafeed/so-resp-datafeed';
import {DatafeedCore} from './datafeed.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutDatafeedFormFields} from './put-datafeed-form-fields';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {SOGetNotification} from '../../core/notification/so-get-notification';
import {SOGetAlertSubscription} from '../../core/alert-subscription/so-get-alert-subscription';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEDatafeedAttributesBase {

    name: DetailEntry
    description: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    notifications: DetailEntry
    alertSubscriptions: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDatafeed,
        app: CommanderApp,
        formFields: PutDatafeedFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const notificationsCore = app.makeCore( 'notification');
        notificationsCore.soGet = SOGetNotification.construct({
          datafeed: new SOGetDatafeed(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            notificationsCore
        )
        notificationsCore.title = heading ? heading : notificationsCore.title
        notificationsCore.listTitle = 'Notifications'

        notificationsCore.configureForDisplay(parentEntityViewSpec)

        this.notifications = new DetailEntry(
          heading ? heading : notificationsCore.listTitle,
          null,
          'notifications',
          BaseDetailType.embeddedList,
          notificationsCore,
          EditableType.never
        )

        const alertSubscriptionsCore = app.makeCore( 'alertSubscription');
        alertSubscriptionsCore.soGet = SOGetAlertSubscription.construct({
          datafeed: new SOGetDatafeed(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            alertSubscriptionsCore
        )
        alertSubscriptionsCore.title = heading ? heading : alertSubscriptionsCore.title
        alertSubscriptionsCore.listTitle = 'Alert subscriptions'

        alertSubscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.alertSubscriptions = new DetailEntry(
          heading ? heading : alertSubscriptionsCore.listTitle,
          null,
          'alertSubscriptions',
          BaseDetailType.embeddedList,
          alertSubscriptionsCore,
          EditableType.never
        )
    }
}