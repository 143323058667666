import { Type } from 'class-transformer';
import {SORespProcess} from './so-resp-process';
import {PluralProtocol} from 'lionheartland';
import {Process} from "../../dexie/db";

export class SOPluralProcess implements PluralProtocol {
  @Type(() => SORespProcess)
  objects: SORespProcess[] = []
  nextOffset: number = null

    toProcesss(): Process[] {
        return this.objects.map(process => process.toProcess());
    }
}