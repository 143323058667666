import {SORespRelationshipAttribute} from '../../core/relationship-attribute/so-resp-relationship-attribute';
import {RelationshipAttributeCore} from './relationship-attribute.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipAttributeFormFields} from './put-relationship-attribute-form-fields';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {SOGetRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-get-relationship-attribute-permission-rule';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERelationshipAttributeAttributesBase {

    name: DetailEntry
    description: DetailEntry
    multiplicity: DetailEntry
    editPermissions: DetailEntry
    relationshipAttributePermissions: DetailEntry
    relationshipAttributePermissionRules: DetailEntry
    realizations: DetailEntry
    entity: DetailEntry
    customLogicRequirement: DetailEntry
    relationship: DetailEntry
    attributeSpecs: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRelationshipAttribute,
        app: CommanderApp,
        formFields: PutRelationshipAttributeFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.multiplicity = new DetailEntry(
            'Multiplicity',
            entity.multiplicity,
            'multiplicity',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.multiplicity
        )

        this.editPermissions = new DetailEntry(
            'Edit permissions',
            entity.editPermissions,
            'editPermissions',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.editPermissions
        )


        const relationshipAttributePermissionsCore = app.makeCore( 'relationshipAttributePermission');
        relationshipAttributePermissionsCore.soGet = SOGetRelationshipAttributePermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributePermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionsCore
        )
        relationshipAttributePermissionsCore.title = heading ? heading : relationshipAttributePermissionsCore.title
        relationshipAttributePermissionsCore.listTitle = 'Relationship attribute permissions'

        relationshipAttributePermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermissions = new DetailEntry(
          heading ? heading : relationshipAttributePermissionsCore.listTitle,
          null,
          'relationshipAttributePermissions',
          BaseDetailType.embeddedList,
          relationshipAttributePermissionsCore,
          EditableType.always,
          formFields.relationshipAttributePermissions
        )

        const relationshipAttributePermissionRulesCore = app.makeCore( 'relationshipAttributePermissionRule');
        relationshipAttributePermissionRulesCore.soGet = SOGetRelationshipAttributePermissionRule.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributePermissionRulesCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionRulesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionRulesCore
        )
        relationshipAttributePermissionRulesCore.title = heading ? heading : relationshipAttributePermissionRulesCore.title
        relationshipAttributePermissionRulesCore.listTitle = 'Relationship attribute permission rules'

        relationshipAttributePermissionRulesCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermissionRules = new DetailEntry(
          heading ? heading : relationshipAttributePermissionRulesCore.listTitle,
          null,
          'relationshipAttributePermissionRules',
          BaseDetailType.embeddedList,
          relationshipAttributePermissionRulesCore,
          EditableType.always,
          formFields.relationshipAttributePermissionRules
        )

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const entityCore = app.makeCore( 'entity');
        entityCore.soGet = SOGetEntity.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityCore.soGet.queryOptions.descending = true;
        let entityCeProtocol;
        if (entity.entity) {
            entityCeProtocol = createCEProtocol(
                entityCore.ceProtocol,
                parentEntityViewSpec,
                entityCore,
                entity.entity,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityCore
        )
        entityCore.title = heading ? heading : entityCore.title
        entityCore.listTitle = 'Entity'

        if (entity.entity) {
            displayStringFactory.reconfigure(
                entity.entity,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        entityCore.configureForDisplay(parentEntityViewSpec)

        this.entity = new DetailEntry(
          heading ? heading : entityCore.listTitle,
          entityCeProtocol ? entityCeProtocol.entityListView.fullHeading : null,
          'entity',
          BaseDetailType.pushedDetail,
          entityCore,
          EditableType.always,
          formFields.entity
        )
        if (entity.entity) {
          this.entity.ceProtocol = entityCeProtocol;
          this.entity.cachedListEntry = entityCore.makeListEntry(parentEntityViewSpec, entity.entity, 0)
          entityCore.selectedEntity = entity.entity;
        }
        this.entity.singleFieldSelect = true

        const customLogicRequirementCore = app.makeCore( 'customLogicRequirement');
        customLogicRequirementCore.soGet = SOGetCustomLogicRequirement.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        customLogicRequirementCore.soGet.queryOptions.sortKey = 'dateCreated';
        customLogicRequirementCore.soGet.queryOptions.descending = true;
        let customLogicRequirementCeProtocol;
        if (entity.customLogicRequirement) {
            customLogicRequirementCeProtocol = createCEProtocol(
                customLogicRequirementCore.ceProtocol,
                parentEntityViewSpec,
                customLogicRequirementCore,
                entity.customLogicRequirement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customLogicRequirementCore
        )
        customLogicRequirementCore.title = heading ? heading : customLogicRequirementCore.title
        customLogicRequirementCore.listTitle = 'Custom logic requirement'

        if (entity.customLogicRequirement) {
            displayStringFactory.reconfigure(
                entity.customLogicRequirement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        customLogicRequirementCore.configureForDisplay(parentEntityViewSpec)

        this.customLogicRequirement = new DetailEntry(
          heading ? heading : customLogicRequirementCore.listTitle,
          customLogicRequirementCeProtocol ? customLogicRequirementCeProtocol.entityListView.fullHeading : null,
          'customLogicRequirement',
          BaseDetailType.pushedDetail,
          customLogicRequirementCore,
          EditableType.always,
          formFields.customLogicRequirement
        )
        if (entity.customLogicRequirement) {
          this.customLogicRequirement.ceProtocol = customLogicRequirementCeProtocol;
          this.customLogicRequirement.cachedListEntry = customLogicRequirementCore.makeListEntry(parentEntityViewSpec, entity.customLogicRequirement, 0)
          customLogicRequirementCore.selectedEntity = entity.customLogicRequirement;
        }
        this.customLogicRequirement.singleFieldSelect = true

        const relationshipCore = app.makeCore( 'relationship');
        relationshipCore.soGet = SOGetRelationship.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipCore.soGet.queryOptions.descending = true;
        let relationshipCeProtocol;
        if (entity.relationship) {
            relationshipCeProtocol = createCEProtocol(
                relationshipCore.ceProtocol,
                parentEntityViewSpec,
                relationshipCore,
                entity.relationship,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipCore
        )
        relationshipCore.title = heading ? heading : relationshipCore.title
        relationshipCore.listTitle = 'Relationship'

        if (entity.relationship) {
            displayStringFactory.reconfigure(
                entity.relationship,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        relationshipCore.configureForDisplay(parentEntityViewSpec)

        this.relationship = new DetailEntry(
          heading ? heading : relationshipCore.listTitle,
          relationshipCeProtocol ? relationshipCeProtocol.entityListView.fullHeading : null,
          'relationship',
          BaseDetailType.pushedDetail,
          relationshipCore,
          EditableType.always,
          formFields.relationship
        )
        if (entity.relationship) {
          this.relationship.ceProtocol = relationshipCeProtocol;
          this.relationship.cachedListEntry = relationshipCore.makeListEntry(parentEntityViewSpec, entity.relationship, 0)
          relationshipCore.selectedEntity = entity.relationship;
        }
        this.relationship.singleFieldSelect = true

        const attributeSpecsCore = app.makeCore( 'attributeSpec');
        attributeSpecsCore.soGet = SOGetAttributeSpec.construct({
          relationshipAttribute: new SOGetRelationshipAttribute(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        attributeSpecsCore.soGet.queryOptions.sortKey = 'dateCreated';
        attributeSpecsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attributeSpecsCore
        )
        attributeSpecsCore.title = heading ? heading : attributeSpecsCore.title
        attributeSpecsCore.listTitle = 'Attribute specs'

        attributeSpecsCore.configureForDisplay(parentEntityViewSpec)

        this.attributeSpecs = new DetailEntry(
          heading ? heading : attributeSpecsCore.listTitle,
          null,
          'attributeSpecs',
          BaseDetailType.embeddedList,
          attributeSpecsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}