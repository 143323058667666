import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetRelationshipAttributePermission } from '../relationship-attribute-permission/so-get-relationship-attribute-permission';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENAttributePermissionType} from '../../enums';


export class SOPutRelationshipAttributePermissionRule {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public whiteList: boolean = null,
    public permissionType: ENAttributePermissionType = null,
    public relationshipAttribute: SOGetRelationshipAttribute = null,
    public relationshipAttributePermission: SOGetRelationshipAttributePermission = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationshipAttributePermissionRule(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'whiteList' in params ? params.whiteList : null,
      'permissionType' in params ? params.permissionType : null,
      'relationshipAttribute' in params ? params.relationshipAttribute : null,
      'relationshipAttributePermission' in params ? params.relationshipAttributePermission : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
