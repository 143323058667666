import {SORespTopic} from '../../core/topic/so-resp-topic';
import {TopicCore} from './topic.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutTopicFormFields} from './put-topic-form-fields';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {SOGetAlertSubscription} from '../../core/alert-subscription/so-get-alert-subscription';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CETopicAttributesBase {

    name: DetailEntry
    description: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    alertSubscriptions: DetailEntry
    messages: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTopic,
        app: CommanderApp,
        formFields: PutTopicFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const alertSubscriptionsCore = app.makeCore( 'alertSubscription');
        alertSubscriptionsCore.soGet = SOGetAlertSubscription.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            alertSubscriptionsCore
        )
        alertSubscriptionsCore.title = heading ? heading : alertSubscriptionsCore.title
        alertSubscriptionsCore.listTitle = 'Alert subscriptions'

        alertSubscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.alertSubscriptions = new DetailEntry(
          heading ? heading : alertSubscriptionsCore.listTitle,
          null,
          'alertSubscriptions',
          BaseDetailType.embeddedList,
          alertSubscriptionsCore,
          EditableType.never
        )

        const messagesCore = app.makeCore( 'message');
        messagesCore.soGet = SOGetMessage.construct({
          topic: new SOGetTopic(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messagesCore
        )
        messagesCore.title = heading ? heading : messagesCore.title
        messagesCore.listTitle = 'Messages'

        messagesCore.configureForDisplay(parentEntityViewSpec)

        this.messages = new DetailEntry(
          heading ? heading : messagesCore.listTitle,
          null,
          'messages',
          BaseDetailType.embeddedList,
          messagesCore,
          EditableType.never
        )
    }
}