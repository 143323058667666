import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutDeficiency } from './so-put-deficiency';
import { SOGetDeficiency } from './so-get-deficiency';
import { SOPostDeficiency } from './so-post-deficiency';
import { SODeleteDeficiency } from './so-delete-deficiency';
import {SORespDeficiency} from './so-resp-deficiency';
import {SOPluralDeficiency} from './so-plural-deficiency';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EODeficiencyService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('deficiency', entityCore);
  }

  /** GET Deficiency by id. Will 404 if id not found */
  get(request: SOGetDeficiency): Observable<SOPluralDeficiency> {
    return this.httpClient.get<SOPluralDeficiency>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralDeficiency, resp)),
      tap(async (response: SOPluralDeficiency) => {
        this.log(`fetched Deficiency`);
        // After a successful server response, update IndexedDB
        const activities = response.toDeficiencys(); // Convert your response to an array of Deficiency instances
        const promises = activities.map(deficiency => db.deficiencys.put(deficiency));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralDeficiency>(`get Deficiency`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralDeficiency> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'deficiency' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.deficiencys.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "works" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.deficiencys.where('worksUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "assessment" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.deficiencys.where('assessmentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "block" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.deficiencys.where('blockUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "process" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.deficiencys.where('processUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.deficiencys.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.deficiencys.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.deficiencys.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.deficiencys.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(deficiencysArray => this.processDeficiencys(deficiencysArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.deficiencys.toArray()).pipe(
                map((deficiencysArray) => {
                    // Convert the array of plain objects to an array of SORespDeficiency instances
                    const sorDeficiencys = deficiencysArray.map(req => plainToClass(SORespDeficiency, req));
                    // Create and return a new instance of SOPluralDeficiency
                    const pluralDeficiency = new SOPluralDeficiency();
                    pluralDeficiency.objects = sorDeficiencys;
                    pluralDeficiency.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralDeficiency;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processDeficiencys(deficiencysArray) {
        const sorDeficiencys = deficiencysArray.map(req => plainToClass(SORespDeficiency, req));
        const pluralDeficiency = new SOPluralDeficiency();
        pluralDeficiency.objects = sorDeficiencys;
        pluralDeficiency.nextOffset = null;
        return pluralDeficiency;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostDeficiency): Observable<SORespDeficiency> {
  return this.httpClient.post<SORespDeficiency>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespDeficiency, resp)),

    tap(async (response: SORespDeficiency) => {
      this.log(`added Deficiency w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.deficiencys.add(response.toDeficiency());
    }),
    catchError(this.handleError<SORespDeficiency>('Deficiency post'))
  );
}


  put(request: SOPutDeficiency): Observable<SORespDeficiency> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDeficiency>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespDeficiency, resp)),
      tap(async (response: SORespDeficiency) => {
        this.log(`edited Deficiency w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.deficiencys.put(response.toDeficiency());
      }),
      catchError(this.handleError<SORespDeficiency>('Deficiency put'))
    );
  }


  delete(request: SODeleteDeficiency): Observable<SORespDeficiency> {
    return this.httpClient.delete<SORespDeficiency>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Deficiency uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.deficiencys.delete(request.uid);
      }),
      catchError(this.handleError<SORespDeficiency>('Deficiency delete'))
    );
  }


}
