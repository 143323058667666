import { Type } from 'class-transformer';
import {SORespRole} from './so-resp-role';
import {PluralProtocol} from 'lionheartland';
import {Role} from "../../dexie/db";

export class SOPluralRole implements PluralProtocol {
  @Type(() => SORespRole)
  objects: SORespRole[] = []
  nextOffset: number = null

    toRoles(): Role[] {
        return this.objects.map(role => role.toRole());
    }
}