import {ENSubscriptionStatus} from '../../enums';


export class SOPutSubscription {

  constructor(
    public uid: string,
    public dateExpires: Date = null,
    public status: ENSubscriptionStatus = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSubscription(
      'uid' in params ? params.uid : null,
      'dateExpires' in params ? params.dateExpires : null,
      'status' in params ? params.status : null,
    );
  }
}
