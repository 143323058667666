import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRelationshipAttributePermission } from './so-put-relationship-attribute-permission';
import { SOGetRelationshipAttributePermission } from './so-get-relationship-attribute-permission';
import { SOPostRelationshipAttributePermission } from './so-post-relationship-attribute-permission';
import { SODeleteRelationshipAttributePermission } from './so-delete-relationship-attribute-permission';
import {SORespRelationshipAttributePermission} from './so-resp-relationship-attribute-permission';
import {SOPluralRelationshipAttributePermission} from './so-plural-relationship-attribute-permission';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORelationshipAttributePermissionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('relationship_attribute_permission', entityCore);
  }

  /** GET RelationshipAttributePermission by id. Will 404 if id not found */
  get(request: SOGetRelationshipAttributePermission): Observable<SOPluralRelationshipAttributePermission> {
    return this.httpClient.get<SOPluralRelationshipAttributePermission>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRelationshipAttributePermission, resp)),
      tap(async (response: SOPluralRelationshipAttributePermission) => {
        this.log(`fetched RelationshipAttributePermission`);
        // After a successful server response, update IndexedDB
        const activities = response.toRelationshipAttributePermissions(); // Convert your response to an array of RelationshipAttributePermission instances
        const promises = activities.map(relationshipAttributePermission => db.relationshipAttributePermissions.put(relationshipAttributePermission));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRelationshipAttributePermission>(`get RelationshipAttributePermission`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRelationshipAttributePermission> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'relationshipAttributePermission' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.relationshipAttributePermissions.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "relationshipAttribute" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributePermissions.where('relationshipAttributeUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationshipAttributePermissionRules" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributePermissions.where('relationshipAttributePermissionRulesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "rolePermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributePermissions.where('rolePermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributePermissions.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributePermissions.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.relationshipAttributePermissions.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.relationshipAttributePermissions.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(relationshipAttributePermissionsArray => this.processRelationshipAttributePermissions(relationshipAttributePermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.relationshipAttributePermissions.toArray()).pipe(
                map((relationshipAttributePermissionsArray) => {
                    // Convert the array of plain objects to an array of SORespRelationshipAttributePermission instances
                    const sorRelationshipAttributePermissions = relationshipAttributePermissionsArray.map(req => plainToClass(SORespRelationshipAttributePermission, req));
                    // Create and return a new instance of SOPluralRelationshipAttributePermission
                    const pluralRelationshipAttributePermission = new SOPluralRelationshipAttributePermission();
                    pluralRelationshipAttributePermission.objects = sorRelationshipAttributePermissions;
                    pluralRelationshipAttributePermission.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRelationshipAttributePermission;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRelationshipAttributePermissions(relationshipAttributePermissionsArray) {
        const sorRelationshipAttributePermissions = relationshipAttributePermissionsArray.map(req => plainToClass(SORespRelationshipAttributePermission, req));
        const pluralRelationshipAttributePermission = new SOPluralRelationshipAttributePermission();
        pluralRelationshipAttributePermission.objects = sorRelationshipAttributePermissions;
        pluralRelationshipAttributePermission.nextOffset = null;
        return pluralRelationshipAttributePermission;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRelationshipAttributePermission): Observable<SORespRelationshipAttributePermission> {
  return this.httpClient.post<SORespRelationshipAttributePermission>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRelationshipAttributePermission, resp)),

    tap(async (response: SORespRelationshipAttributePermission) => {
      this.log(`added RelationshipAttributePermission w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.relationshipAttributePermissions.add(response.toRelationshipAttributePermission());
    }),
    catchError(this.handleError<SORespRelationshipAttributePermission>('RelationshipAttributePermission post'))
  );
}


  put(request: SOPutRelationshipAttributePermission): Observable<SORespRelationshipAttributePermission> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationshipAttributePermission>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRelationshipAttributePermission, resp)),
      tap(async (response: SORespRelationshipAttributePermission) => {
        this.log(`edited RelationshipAttributePermission w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.relationshipAttributePermissions.put(response.toRelationshipAttributePermission());
      }),
      catchError(this.handleError<SORespRelationshipAttributePermission>('RelationshipAttributePermission put'))
    );
  }


  delete(request: SODeleteRelationshipAttributePermission): Observable<SORespRelationshipAttributePermission> {
    return this.httpClient.delete<SORespRelationshipAttributePermission>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted RelationshipAttributePermission uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.relationshipAttributePermissions.delete(request.uid);
      }),
      catchError(this.handleError<SORespRelationshipAttributePermission>('RelationshipAttributePermission delete'))
    );
  }


}
