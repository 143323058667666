import {SORespCertificate} from '../../core/certificate/so-resp-certificate';
import {CertificateCore} from './certificate.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutCertificateFormFields} from './put-certificate-form-fields';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetToken} from '../../core/token/so-get-token';


export class CECertificateAttributesBase {

    user: DetailEntry
    dateCreated: DetailEntry
    tokens: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespCertificate,
        app: CommanderApp,
        formFields: PutCertificateFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.always,
          formFields.user
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true

        const tokensCore = app.makeCore( 'token');
        tokensCore.soGet = SOGetToken.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tokensCore
        )
        tokensCore.title = heading ? heading : tokensCore.title
        tokensCore.listTitle = 'Tokens'

        tokensCore.configureForDisplay(parentEntityViewSpec)

        this.tokens = new DetailEntry(
          heading ? heading : tokensCore.listTitle,
          null,
          'tokens',
          BaseDetailType.embeddedList,
          tokensCore,
          EditableType.always,
          formFields.tokens
        )
    }
}