import { Type } from 'class-transformer';
import {SORespRoleGrant} from './so-resp-role-grant';
import {PluralProtocol} from 'lionheartland';
import {RoleGrant} from "../../dexie/db";

export class SOPluralRoleGrant implements PluralProtocol {
  @Type(() => SORespRoleGrant)
  objects: SORespRoleGrant[] = []
  nextOffset: number = null

    toRoleGrants(): RoleGrant[] {
        return this.objects.map(roleGrant => roleGrant.toRoleGrant());
    }
}