import {SORespReport} from '../../core/report/so-resp-report';
import {ReportCore} from './report.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutReportFormFields} from './put-report-form-fields';
import {SOGetReport} from '../../core/report/so-get-report';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEReportAttributesBase {

    name: DetailEntry
    description: DetailEntry
    dateCreated: DetailEntry
    active: DetailEntry
    period: DetailEntry
    assessments: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespReport,
        app: CommanderApp,
        formFields: PutReportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )


        const periodCore = app.makeCore( 'period');
        periodCore.soGet = SOGetPeriod.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        periodCore.soGet.queryOptions.sortKey = 'dateCreated';
        periodCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            periodCore
        )
        periodCore.title = heading ? heading : periodCore.title
        periodCore.listTitle = 'Period'

        periodCore.configureForDisplay(parentEntityViewSpec)

        this.period = new DetailEntry(
          heading ? heading : periodCore.listTitle,
          null,
          'period',
          BaseDetailType.pushedDetail,
          periodCore,
          EditableType.never
        )
        this.period.singleFieldSelect = true

        const assessmentsCore = app.makeCore( 'assessment');
        assessmentsCore.soGet = SOGetAssessment.construct({
          report: new SOGetReport(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        assessmentsCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentsCore
        )
        assessmentsCore.title = heading ? heading : assessmentsCore.title
        assessmentsCore.listTitle = 'Assessments'

        assessmentsCore.configureForDisplay(parentEntityViewSpec)

        this.assessments = new DetailEntry(
          heading ? heading : assessmentsCore.listTitle,
          null,
          'assessments',
          BaseDetailType.embeddedList,
          assessmentsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}