import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutInterphace } from './so-put-interphace';
import { SOGetInterphace } from './so-get-interphace';
import { SOPostInterphace } from './so-post-interphace';
import { SODeleteInterphace } from './so-delete-interphace';
import {SORespInterphace} from './so-resp-interphace';
import {SOPluralInterphace} from './so-plural-interphace';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOInterphaceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('interphace', entityCore);
  }

  /** GET Interphace by id. Will 404 if id not found */
  get(request: SOGetInterphace): Observable<SOPluralInterphace> {
    return this.httpClient.get<SOPluralInterphace>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralInterphace, resp)),
      tap(async (response: SOPluralInterphace) => {
        this.log(`fetched Interphace`);
        // After a successful server response, update IndexedDB
        const activities = response.toInterphaces(); // Convert your response to an array of Interphace instances
        const promises = activities.map(interphace => db.interphaces.put(interphace));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralInterphace>(`get Interphace`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralInterphace> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'interphace' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.interphaces.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(interphacesArray => this.processInterphaces(interphacesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "blocks" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.interphaces.where('blocksUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(interphacesArray => this.processInterphaces(interphacesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "entity" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.interphaces.where('entityUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(interphacesArray => this.processInterphaces(interphacesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "attributeSpecs" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.interphaces.where('attributeSpecsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(interphacesArray => this.processInterphaces(interphacesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.interphaces.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(interphacesArray => this.processInterphaces(interphacesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.interphaces.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(interphacesArray => this.processInterphaces(interphacesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.interphaces.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(interphacesArray => this.processInterphaces(interphacesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.interphaces.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(interphacesArray => this.processInterphaces(interphacesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.interphaces.toArray()).pipe(
                map((interphacesArray) => {
                    // Convert the array of plain objects to an array of SORespInterphace instances
                    const sorInterphaces = interphacesArray.map(req => plainToClass(SORespInterphace, req));
                    // Create and return a new instance of SOPluralInterphace
                    const pluralInterphace = new SOPluralInterphace();
                    pluralInterphace.objects = sorInterphaces;
                    pluralInterphace.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralInterphace;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processInterphaces(interphacesArray) {
        const sorInterphaces = interphacesArray.map(req => plainToClass(SORespInterphace, req));
        const pluralInterphace = new SOPluralInterphace();
        pluralInterphace.objects = sorInterphaces;
        pluralInterphace.nextOffset = null;
        return pluralInterphace;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostInterphace): Observable<SORespInterphace> {
  return this.httpClient.post<SORespInterphace>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespInterphace, resp)),

    tap(async (response: SORespInterphace) => {
      this.log(`added Interphace w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.interphaces.add(response.toInterphace());
    }),
    catchError(this.handleError<SORespInterphace>('Interphace post'))
  );
}


  put(request: SOPutInterphace): Observable<SORespInterphace> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInterphace>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespInterphace, resp)),
      tap(async (response: SORespInterphace) => {
        this.log(`edited Interphace w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.interphaces.put(response.toInterphace());
      }),
      catchError(this.handleError<SORespInterphace>('Interphace put'))
    );
  }


  delete(request: SODeleteInterphace): Observable<SORespInterphace> {
    return this.httpClient.delete<SORespInterphace>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Interphace uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.interphaces.delete(request.uid);
      }),
      catchError(this.handleError<SORespInterphace>('Interphace delete'))
    );
  }


}
