import { Type } from 'class-transformer';
import { SORespPeriod } from '../period/so-resp-period';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Plan} from "../../dexie/db";

export class SORespPlan implements ObjectiveProtocol {

  entityType: string = 'plan';
  uid: string;
  name: string;
  @Type(() => SORespPeriod)
  period: SORespPeriod;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toPlan(): Plan {
        return {
            uid: this.uid,
            name: this.name,
            dateCreated: null,
            periodUid: this.period ? this.period.uid : null,
            planWorkAssociationsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}