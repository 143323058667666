import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENMultiplicity} from '../../enums';

export class PostRelationshipAttributeFormFields {

    public name: FormField
    public description: FormField
    public multiplicity: FormField
    public editPermissions: FormField
    public entity: FormField
    public customLogicRequirement: FormField
    public relationship: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.multiplicity = new FormField(
          'multiplicity',
          'Multiplicity',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.multiplicity.enumeration = ENMultiplicity
        this.editPermissions = new FormField(
          'editPermissions',
          'Edit permissions',
          null,
          FormFieldType.boolean,
          true,
        )
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.customLogicRequirement = new FormField(
          'customLogicRequirement',
          'Custom logic requirement',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationship = new FormField(
          'relationship',
          'Relationship',
          'relationship',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostRelationshipAttributeFormFields()

export const postRelationshipAttributeFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  multiplicity: fields.multiplicity,
  editPermissions: fields.editPermissions,
  entity: fields.entity,
  customLogicRequirement: fields.customLogicRequirement,
  relationship: fields.relationship,
  project: fields.project,
};