import { Type } from 'class-transformer';
import {SORespRolePermission} from './so-resp-role-permission';
import {PluralProtocol} from 'lionheartland';
import {RolePermission} from "../../dexie/db";

export class SOPluralRolePermission implements PluralProtocol {
  @Type(() => SORespRolePermission)
  objects: SORespRolePermission[] = []
  nextOffset: number = null

    toRolePermissions(): RolePermission[] {
        return this.objects.map(rolePermission => rolePermission.toRolePermission());
    }
}