import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutIdentity } from './so-put-identity';
import { SOGetIdentity } from './so-get-identity';
import { SOPostIdentity } from './so-post-identity';
import { SODeleteIdentity } from './so-delete-identity';
import {SORespIdentity} from './so-resp-identity';
import {SOPluralIdentity} from './so-plural-identity';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOIdentityService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('identity', entityCore);
  }

  /** GET Identity by id. Will 404 if id not found */
  get(request: SOGetIdentity): Observable<SOPluralIdentity> {
    return this.httpClient.get<SOPluralIdentity>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralIdentity, resp)),
      tap(async (response: SOPluralIdentity) => {
        this.log(`fetched Identity`);
        // After a successful server response, update IndexedDB
        const activities = response.toIdentitys(); // Convert your response to an array of Identity instances
        const promises = activities.map(identity => db.identitys.put(identity));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralIdentity>(`get Identity`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralIdentity> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.identitys.toArray()).pipe(
                map((identitysArray) => {
                    // Convert the array of plain objects to an array of SORespIdentity instances
                    const sorIdentitys = identitysArray.map(req => plainToClass(SORespIdentity, req));
                    // Create and return a new instance of SOPluralIdentity
                    const pluralIdentity = new SOPluralIdentity();
                    pluralIdentity.objects = sorIdentitys;
                    pluralIdentity.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralIdentity;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processIdentitys(identitysArray) {
        const sorIdentitys = identitysArray.map(req => plainToClass(SORespIdentity, req));
        const pluralIdentity = new SOPluralIdentity();
        pluralIdentity.objects = sorIdentitys;
        pluralIdentity.nextOffset = null;
        return pluralIdentity;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostIdentity): Observable<SORespIdentity> {
  return this.httpClient.post<SORespIdentity>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespIdentity, resp)),

    tap(async (response: SORespIdentity) => {
      this.log(`added Identity w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.identitys.add(response.toIdentity());
    }),
    catchError(this.handleError<SORespIdentity>('Identity post'))
  );
}


  put(request: SOPutIdentity): Observable<SORespIdentity> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespIdentity>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespIdentity, resp)),
      tap(async (response: SORespIdentity) => {
        this.log(`edited Identity w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.identitys.put(response.toIdentity());
      }),
      catchError(this.handleError<SORespIdentity>('Identity put'))
    );
  }


  delete(request: SODeleteIdentity): Observable<SORespIdentity> {
    return this.httpClient.delete<SORespIdentity>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Identity uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.identitys.delete(request.uid);
      }),
      catchError(this.handleError<SORespIdentity>('Identity delete'))
    );
  }


}
