import { Type } from 'class-transformer';
import {SORespEntity} from './so-resp-entity';
import {PluralProtocol} from 'lionheartland';
import {Entity} from "../../dexie/db";

export class SOPluralEntity implements PluralProtocol {
  @Type(() => SORespEntity)
  objects: SORespEntity[] = []
  nextOffset: number = null

    toEntitys(): Entity[] {
        return this.objects.map(entity => entity.toEntity());
    }
}