import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENPeriodType} from '../../enums';


export class SOPutPeriod {

  constructor(
    public uid: string,
    public name: string = null,
    public numeral: number = null,
    public type: ENPeriodType = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPeriod(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'numeral' in params ? params.numeral : null,
      'type' in params ? params.type : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
