import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProjectService} from '../../core/project/eo-project';
import {SOGetProject} from '../../core/project/so-get-project';
import {RAPutProject} from './ra-put-project';
import {RAPostProject} from './ra-post-project';
import {RADeleteProject} from './ra-delete-project';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEProject } from "./ce-project"


export class ProjectCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'project';
  title = 'Project';
  ceProtocol: CEConstructor = CEProject

  entityService: EOProjectService;
  soGetClass = SOGetProject;
  soGet: SOGetProject;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProjectService,
      null,
    );
    this.adapters = [
      new RAPostProject(this),
      new RAPutProject(this),
      new RADeleteProject(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProject();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}