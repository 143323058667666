import { Type } from 'class-transformer';
import {SORespInvoice} from './so-resp-invoice';
import {PluralProtocol} from 'lionheartland';
import {Invoice} from "../../dexie/db";

export class SOPluralInvoice implements PluralProtocol {
  @Type(() => SORespInvoice)
  objects: SORespInvoice[] = []
  nextOffset: number = null

    toInvoices(): Invoice[] {
        return this.objects.map(invoice => invoice.toInvoice());
    }
}