import { Type } from 'class-transformer';
import { SORespProcess } from '../process/so-resp-process';
import { SORespRole } from '../role/so-resp-role';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {ProcessRole} from "../../dexie/db";

export class SORespProcessRole implements ObjectiveProtocol {

  entityType: string = 'processRole';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespProcess)
  process: SORespProcess = null;
  @Type(() => SORespRole)
  role: SORespRole = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toProcessRole(): ProcessRole {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            processUid: this.process ? this.process.uid : null,
            roleUid: this.role ? this.role.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}