import {SORespProject} from '../../core/project/so-resp-project';
import {ProjectCore} from './project.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProjectFormFields} from './put-project-form-fields';
import { CEProjectAttributes } from './ce-project-attributes'


export class CEProjectBase extends CEBase {

    public details: CEProjectAttributes;
    public fromFields: PutProjectFormFields = new PutProjectFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProjectCore,
        public entity: SORespProject,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProjectAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.assessments)
        this.attributes.push(this.details.assessmentSummarys)
        this.attributes.push(this.details.blocks)
        this.attributes.push(this.details.deficiencys)
        this.attributes.push(this.details.entitys)
        this.attributes.push(this.details.periods)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.relationships)
        this.attributes.push(this.details.relationshipAttributes)
        this.attributes.push(this.details.reports)
        this.attributes.push(this.details.requirements)
        this.attributes.push(this.details.customLogicRequirements)
        this.attributes.push(this.details.simpleAttributes)
        this.attributes.push(this.details.works)
        this.attributes.push(this.details.processes)
        this.attributes.push(this.details.interphaces)
        this.attributes.push(this.details.backlogs)
        this.attributes.push(this.details.attributeSpec)
        this.attributes.push(this.details.exportProject)
        this.attributes.push(this.details.simpleAttributePermission)
        this.attributes.push(this.details.simpleAttributePermissionRule)
        this.attributes.push(this.details.relationshipAttributePermission)
        this.attributes.push(this.details.relationshipAttributePermissionRule)
        this.attributes.push(this.details.entityPermission)
        this.attributes.push(this.details.plans)
        this.attributes.push(this.details.assignedProjects)
        this.attributes.push(this.details.rolePermissions)
        this.attributes.push(this.details.processRoles)
        this.attributes.push(this.details.steps)
        this.attributes.push(this.details.processStepAssociations)

    }
}