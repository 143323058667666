import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOWorkService} from '../../core/work/eo-work';
import {SOGetWork} from '../../core/work/so-get-work';
import {RAPutWork} from './ra-put-work';
import {RAPostWork} from './ra-post-work';
import {RADeleteWork} from './ra-delete-work';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEWork } from "./ce-work"


export class WorkCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'work';
  title = 'Work';
  ceProtocol: CEConstructor = CEWork

  entityService: EOWorkService;
  soGetClass = SOGetWork;
  soGet: SOGetWork;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOWorkService,
      null,
    );
    this.adapters = [
      new RAPostWork(this),
      new RAPutWork(this),
      new RADeleteWork(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWork();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}