import { SOGetRequirement } from '../requirement/so-get-requirement';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENRequirementStatus} from '../../enums';


export class SOPostRequirement {

  constructor(
    public name: string,
    public active: boolean,
    public currentStatus: ENRequirementStatus,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public parent: SOGetRequirement = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRequirement(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'currentStatus' in params ? params.currentStatus : null,
      'parent' in params ? params.parent : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
