import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';
import {Hello} from "../../dexie/db";

export class SORespHello implements ObjectiveProtocol {

  entityType: string = 'hello';
  uid: string;
  loginStyle: number;

    toHello(): Hello {
        return {
            uid: this.uid,
            loginStyle: this.loginStyle,
}

        };
}