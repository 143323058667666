import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRelationship } from './so-put-relationship';
import { SOGetRelationship } from './so-get-relationship';
import { SOPostRelationship } from './so-post-relationship';
import { SODeleteRelationship } from './so-delete-relationship';
import {SORespRelationship} from './so-resp-relationship';
import {SOPluralRelationship} from './so-plural-relationship';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORelationshipService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('relationship', entityCore);
  }

  /** GET Relationship by id. Will 404 if id not found */
  get(request: SOGetRelationship): Observable<SOPluralRelationship> {
    return this.httpClient.get<SOPluralRelationship>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRelationship, resp)),
      tap(async (response: SOPluralRelationship) => {
        this.log(`fetched Relationship`);
        // After a successful server response, update IndexedDB
        const activities = response.toRelationships(); // Convert your response to an array of Relationship instances
        const promises = activities.map(relationship => db.relationships.put(relationship));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRelationship>(`get Relationship`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRelationship> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'relationship' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.relationships.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipsArray => this.processRelationships(relationshipsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "relationshipAttributes" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationships.where('relationshipAttributesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipsArray => this.processRelationships(relationshipsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationships.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipsArray => this.processRelationships(relationshipsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationships.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipsArray => this.processRelationships(relationshipsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.relationships.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(relationshipsArray => this.processRelationships(relationshipsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.relationships.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(relationshipsArray => this.processRelationships(relationshipsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.relationships.toArray()).pipe(
                map((relationshipsArray) => {
                    // Convert the array of plain objects to an array of SORespRelationship instances
                    const sorRelationships = relationshipsArray.map(req => plainToClass(SORespRelationship, req));
                    // Create and return a new instance of SOPluralRelationship
                    const pluralRelationship = new SOPluralRelationship();
                    pluralRelationship.objects = sorRelationships;
                    pluralRelationship.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRelationship;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRelationships(relationshipsArray) {
        const sorRelationships = relationshipsArray.map(req => plainToClass(SORespRelationship, req));
        const pluralRelationship = new SOPluralRelationship();
        pluralRelationship.objects = sorRelationships;
        pluralRelationship.nextOffset = null;
        return pluralRelationship;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRelationship): Observable<SORespRelationship> {
  return this.httpClient.post<SORespRelationship>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRelationship, resp)),

    tap(async (response: SORespRelationship) => {
      this.log(`added Relationship w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.relationships.add(response.toRelationship());
    }),
    catchError(this.handleError<SORespRelationship>('Relationship post'))
  );
}


  put(request: SOPutRelationship): Observable<SORespRelationship> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationship>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRelationship, resp)),
      tap(async (response: SORespRelationship) => {
        this.log(`edited Relationship w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.relationships.put(response.toRelationship());
      }),
      catchError(this.handleError<SORespRelationship>('Relationship put'))
    );
  }


  delete(request: SODeleteRelationship): Observable<SORespRelationship> {
    return this.httpClient.delete<SORespRelationship>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Relationship uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.relationships.delete(request.uid);
      }),
      catchError(this.handleError<SORespRelationship>('Relationship delete'))
    );
  }


}
