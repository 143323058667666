import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENExportType} from '../../enums';


export class SOPostExportProject {

  constructor(
    public reasonForExport: string,
    public exportType: ENExportType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostExportProject(
      'reasonForExport' in params ? params.reasonForExport : null,
      'exportType' in params ? params.exportType : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
