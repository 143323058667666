import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutPaymentIntent } from './so-put-payment-intent';
import { SOGetPaymentIntent } from './so-get-payment-intent';
import { SOPostPaymentIntent } from './so-post-payment-intent';
import { SODeletePaymentIntent } from './so-delete-payment-intent';
import {SORespPaymentIntent} from './so-resp-payment-intent';
import {SOPluralPaymentIntent} from './so-plural-payment-intent';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOPaymentIntentService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('payment_intent', entityCore);
  }

  /** GET PaymentIntent by id. Will 404 if id not found */
  get(request: SOGetPaymentIntent): Observable<SOPluralPaymentIntent> {
    return this.httpClient.get<SOPluralPaymentIntent>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralPaymentIntent, resp)),
      tap(async (response: SOPluralPaymentIntent) => {
        this.log(`fetched PaymentIntent`);
        // After a successful server response, update IndexedDB
        const activities = response.toPaymentIntents(); // Convert your response to an array of PaymentIntent instances
        const promises = activities.map(paymentIntent => db.paymentIntents.put(paymentIntent));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralPaymentIntent>(`get PaymentIntent`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralPaymentIntent> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.paymentIntents.toArray()).pipe(
                map((paymentIntentsArray) => {
                    // Convert the array of plain objects to an array of SORespPaymentIntent instances
                    const sorPaymentIntents = paymentIntentsArray.map(req => plainToClass(SORespPaymentIntent, req));
                    // Create and return a new instance of SOPluralPaymentIntent
                    const pluralPaymentIntent = new SOPluralPaymentIntent();
                    pluralPaymentIntent.objects = sorPaymentIntents;
                    pluralPaymentIntent.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralPaymentIntent;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processPaymentIntents(paymentIntentsArray) {
        const sorPaymentIntents = paymentIntentsArray.map(req => plainToClass(SORespPaymentIntent, req));
        const pluralPaymentIntent = new SOPluralPaymentIntent();
        pluralPaymentIntent.objects = sorPaymentIntents;
        pluralPaymentIntent.nextOffset = null;
        return pluralPaymentIntent;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostPaymentIntent): Observable<SORespPaymentIntent> {
  return this.httpClient.post<SORespPaymentIntent>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespPaymentIntent, resp)),

    tap(async (response: SORespPaymentIntent) => {
      this.log(`added PaymentIntent w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.paymentIntents.add(response.toPaymentIntent());
    }),
    catchError(this.handleError<SORespPaymentIntent>('PaymentIntent post'))
  );
}


  put(request: SOPutPaymentIntent): Observable<SORespPaymentIntent> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPaymentIntent>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespPaymentIntent, resp)),
      tap(async (response: SORespPaymentIntent) => {
        this.log(`edited PaymentIntent w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.paymentIntents.put(response.toPaymentIntent());
      }),
      catchError(this.handleError<SORespPaymentIntent>('PaymentIntent put'))
    );
  }


  delete(request: SODeletePaymentIntent): Observable<SORespPaymentIntent> {
    return this.httpClient.delete<SORespPaymentIntent>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted PaymentIntent uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.paymentIntents.delete(request.uid);
      }),
      catchError(this.handleError<SORespPaymentIntent>('PaymentIntent delete'))
    );
  }


}
