import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespPrice } from '../price/so-resp-price';
import {ENSubscriptionStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Subscription} from "../../dexie/db";

export class SORespSubscription implements ObjectiveProtocol {

  entityType: string = 'subscription';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateExpires: Date = null;
  exRef: string = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespPrice)
  price: SORespPrice = null;
  status: ENSubscriptionStatus;

    toSubscription(): Subscription {
        return {
            uid: this.uid,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            dateExpires: null,
            exRef: null,
            organizationUid: this.organization ? this.organization.uid : null,
            priceUid: this.price ? this.price.uid : null,
            status: this.status,
            invoicesUid: null,
}

        };
}