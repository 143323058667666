import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORealizationService} from '../../core/realization/eo-realization';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {RAPutRealization} from './ra-put-realization';
import {RAPostRealization} from './ra-post-realization';
import {RADeleteRealization} from './ra-delete-realization';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERealization } from "./ce-realization"


export class RealizationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'realization';
  title = 'Realization';
  ceProtocol: CEConstructor = CERealization

  entityService: EORealizationService;
  soGetClass = SOGetRealization;
  soGet: SOGetRealization;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORealizationService,
      null,
    );
    this.adapters = [
      new RAPostRealization(this),
      new RAPutRealization(this),
      new RADeleteRealization(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRealization();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}