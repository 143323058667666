import { Type } from 'class-transformer';
import { SORespConstraint } from '../constraint/so-resp-constraint';
import { SORespProduct } from '../product/so-resp-product';
import {ObjectiveProtocol} from 'lionheartland';
import {AppliedConstraint} from "../../dexie/db";

export class SORespAppliedConstraint implements ObjectiveProtocol {

  entityType: string = 'appliedConstraint';
  uid: string;
  @Type(() => SORespConstraint)
  constraint: SORespConstraint;
  @Type(() => SORespProduct)
  product: SORespProduct;
  quantity: number;

    toAppliedConstraint(): AppliedConstraint {
        return {
            uid: this.uid,
            constraintUid: this.constraint ? this.constraint.uid : null,
            productUid: this.product ? this.product.uid : null,
            quantity: this.quantity,
}

        };
}