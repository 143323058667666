import {SOPostWork} from '../../core/work/so-post-work';
import {WorkCore} from './work.core';
import {SOGetWork} from '../../core/work/so-get-work';
import {FormField, SelectionOption} from 'lionheartland';
import {postWorkFormFieldOptions} from './post-work-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DeficiencyCore} from '../deficiency/deficiency.core';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {PeriodCore} from '../period/period.core';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {BacklogCore} from '../backlog/backlog.core';
import {SOGetBacklog} from '../../core/backlog/so-get-backlog';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostWork extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postWorkFormFieldOptions);

  RequestClass = SOPostWork

  constructor(
    public entityCore: WorkCore
  ) {
    super();
    if (postWorkFormFieldOptions.deficiency) {
      postWorkFormFieldOptions.deficiency.makeCore = true;
    }
    if (postWorkFormFieldOptions.period) {
      postWorkFormFieldOptions.period.makeCore = true;
    }
    if (postWorkFormFieldOptions.backlog) {
      postWorkFormFieldOptions.backlog.makeCore = true;
    }
    if (postWorkFormFieldOptions.project) {
      postWorkFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'deficiency',
        SOGetDeficiency,
      ),
      this.getFormValueFromIdentifier(
        'period',
        SOGetPeriod,
      ),
      this.getFormValueFromIdentifier(
        'backlog',
        SOGetBacklog,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}