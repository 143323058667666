import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Person} from "../../dexie/db";

export class SORespPerson implements ObjectiveProtocol {

  entityType: string = 'person';
  uid: string;
  name: string;
  description: string = "";
  relation: string = "";
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toPerson(): Person {
        return {
            uid: this.uid,
            name: this.name,
            description: null,
            relation: null,
            dateCreated: null,
            assignedProjectsUid: null,
            userUid: this.user ? this.user.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}