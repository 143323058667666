import {SOPutProcessRole} from '../../core/process-role/so-put-process-role';
import {ProcessRoleCore} from './process-role.core';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {FormField, SelectionOption} from 'lionheartland';
import {putProcessRoleFormFieldOptions} from './put-process-role-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ProcessCore} from '../process/process.core';
import {SOGetProcess} from '../../core/process/so-get-process';
import {RoleCore} from '../role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutProcessRole extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putProcessRoleFormFieldOptions);

  RequestClass = SOPutProcessRole

  constructor(
    public entityCore: ProcessRoleCore
  ) {
    super();
    if (putProcessRoleFormFieldOptions.process) {
      putProcessRoleFormFieldOptions.process.makeCore = true;
    }
    if (putProcessRoleFormFieldOptions.role) {
      putProcessRoleFormFieldOptions.role.makeCore = true;
    }
    if (putProcessRoleFormFieldOptions.project) {
      putProcessRoleFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'process',
        SOGetProcess,
      ),
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}