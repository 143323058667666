import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostStepFormFields {

    public name: FormField
    public description: FormField
    public stepNumber: FormField
    public processStepAssociations: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.stepNumber = new FormField(
          'stepNumber',
          'Step number',
          null,
          FormFieldType.text,
          true,
        )
        this.processStepAssociations = new FormField(
          'processStepAssociations',
          'Process step associations',
          'processStepAssociation',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostStepFormFields()

export const postStepFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  stepNumber: fields.stepNumber,
  processStepAssociations: fields.processStepAssociations,
  project: fields.project,
};