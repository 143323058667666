import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORelationshipService} from '../../core/relationship/eo-relationship';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {RAPutRelationship} from './ra-put-relationship';
import {RAPostRelationship} from './ra-post-relationship';
import {RADeleteRelationship} from './ra-delete-relationship';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERelationship } from "./ce-relationship"


export class RelationshipCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationship';
  title = 'Relationship';
  ceProtocol: CEConstructor = CERelationship

  entityService: EORelationshipService;
  soGetClass = SOGetRelationship;
  soGet: SOGetRelationship;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORelationshipService,
      null,
    );
    this.adapters = [
      new RAPostRelationship(this),
      new RAPutRelationship(this),
      new RADeleteRelationship(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationship();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}