import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRelationshipAttribute } from './so-put-relationship-attribute';
import { SOGetRelationshipAttribute } from './so-get-relationship-attribute';
import { SOPostRelationshipAttribute } from './so-post-relationship-attribute';
import { SODeleteRelationshipAttribute } from './so-delete-relationship-attribute';
import {SORespRelationshipAttribute} from './so-resp-relationship-attribute';
import {SOPluralRelationshipAttribute} from './so-plural-relationship-attribute';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORelationshipAttributeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('relationship_attribute', entityCore);
  }

  /** GET RelationshipAttribute by id. Will 404 if id not found */
  get(request: SOGetRelationshipAttribute): Observable<SOPluralRelationshipAttribute> {
    return this.httpClient.get<SOPluralRelationshipAttribute>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRelationshipAttribute, resp)),
      tap(async (response: SOPluralRelationshipAttribute) => {
        this.log(`fetched RelationshipAttribute`);
        // After a successful server response, update IndexedDB
        const activities = response.toRelationshipAttributes(); // Convert your response to an array of RelationshipAttribute instances
        const promises = activities.map(relationshipAttribute => db.relationshipAttributes.put(relationshipAttribute));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRelationshipAttribute>(`get RelationshipAttribute`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRelationshipAttribute> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'relationshipAttribute' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.relationshipAttributes.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "relationshipAttributePermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('relationshipAttributePermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationshipAttributePermissionRules" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('relationshipAttributePermissionRulesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "realizations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('realizationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "entity" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('entityUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "customLogicRequirement" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('customLogicRequirementUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationship" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('relationshipUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "attributeSpecs" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('attributeSpecsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.relationshipAttributes.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.relationshipAttributes.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.relationshipAttributes.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(relationshipAttributesArray => this.processRelationshipAttributes(relationshipAttributesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.relationshipAttributes.toArray()).pipe(
                map((relationshipAttributesArray) => {
                    // Convert the array of plain objects to an array of SORespRelationshipAttribute instances
                    const sorRelationshipAttributes = relationshipAttributesArray.map(req => plainToClass(SORespRelationshipAttribute, req));
                    // Create and return a new instance of SOPluralRelationshipAttribute
                    const pluralRelationshipAttribute = new SOPluralRelationshipAttribute();
                    pluralRelationshipAttribute.objects = sorRelationshipAttributes;
                    pluralRelationshipAttribute.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRelationshipAttribute;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRelationshipAttributes(relationshipAttributesArray) {
        const sorRelationshipAttributes = relationshipAttributesArray.map(req => plainToClass(SORespRelationshipAttribute, req));
        const pluralRelationshipAttribute = new SOPluralRelationshipAttribute();
        pluralRelationshipAttribute.objects = sorRelationshipAttributes;
        pluralRelationshipAttribute.nextOffset = null;
        return pluralRelationshipAttribute;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRelationshipAttribute): Observable<SORespRelationshipAttribute> {
  return this.httpClient.post<SORespRelationshipAttribute>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRelationshipAttribute, resp)),

    tap(async (response: SORespRelationshipAttribute) => {
      this.log(`added RelationshipAttribute w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.relationshipAttributes.add(response.toRelationshipAttribute());
    }),
    catchError(this.handleError<SORespRelationshipAttribute>('RelationshipAttribute post'))
  );
}


  put(request: SOPutRelationshipAttribute): Observable<SORespRelationshipAttribute> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationshipAttribute>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRelationshipAttribute, resp)),
      tap(async (response: SORespRelationshipAttribute) => {
        this.log(`edited RelationshipAttribute w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.relationshipAttributes.put(response.toRelationshipAttribute());
      }),
      catchError(this.handleError<SORespRelationshipAttribute>('RelationshipAttribute put'))
    );
  }


  delete(request: SODeleteRelationshipAttribute): Observable<SORespRelationshipAttribute> {
    return this.httpClient.delete<SORespRelationshipAttribute>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted RelationshipAttribute uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.relationshipAttributes.delete(request.uid);
      }),
      catchError(this.handleError<SORespRelationshipAttribute>('RelationshipAttribute delete'))
    );
  }


}
