import {SORespAssignedProject} from '../../core/assigned-project/so-resp-assigned-project';
import {AssignedProjectCore} from './assigned-project.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAssignedProjectFormFields} from './put-assigned-project-form-fields';
import { CEAssignedProjectAttributes } from './ce-assigned-project-attributes'


export class CEAssignedProjectBase extends CEBase {

    public details: CEAssignedProjectAttributes;
    public fromFields: PutAssignedProjectFormFields = new PutAssignedProjectFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AssignedProjectCore,
        public entity: SORespAssignedProject,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAssignedProjectAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.person)
        this.attributes.push(this.details.project)

    }
}