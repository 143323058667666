import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOEntityService} from '../../core/entity/eo-entity';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {RAPutEntity} from './ra-put-entity';
import {RAPostEntity} from './ra-post-entity';
import {RADeleteEntity} from './ra-delete-entity';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEEntity } from "./ce-entity"


export class EntityCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entity';
  title = 'Entity';
  ceProtocol: CEConstructor = CEEntity

  entityService: EOEntityService;
  soGetClass = SOGetEntity;
  soGet: SOGetEntity;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOEntityService,
      null,
    );
    this.adapters = [
      new RAPostEntity(this),
      new RAPutEntity(this),
      new RADeleteEntity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntity();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}