import {SORespRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-resp-relationship-attribute-permission';
import {RelationshipAttributePermissionCore} from './relationship-attribute-permission.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipAttributePermissionFormFields} from './put-relationship-attribute-permission-form-fields';
import { CERelationshipAttributePermissionAttributes } from './ce-relationship-attribute-permission-attributes'


export class CERelationshipAttributePermissionBase extends CEBase {

    public details: CERelationshipAttributePermissionAttributes;
    public fromFields: PutRelationshipAttributePermissionFormFields = new PutRelationshipAttributePermissionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RelationshipAttributePermissionCore,
        public entity: SORespRelationshipAttributePermission,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERelationshipAttributePermissionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.whiteList)
        this.attributes.push(this.details.permissionType)
        this.attributes.push(this.details.relationshipAttribute)
        this.attributes.push(this.details.relationshipAttributePermissionRules)
        this.attributes.push(this.details.rolePermissions)
        this.attributes.push(this.details.project)

    }
}