import {SORespEntity} from '../../core/entity/so-resp-entity';
import {EntityCore} from './entity.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutEntityFormFields} from './put-entity-form-fields';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEEntityAttributesBase {

    name: DetailEntry
    description: DetailEntry
    editPermissions: DetailEntry
    entityPermissions: DetailEntry
    realizations: DetailEntry
    simpleAttributes: DetailEntry
    relationshipAttributes: DetailEntry
    interphaces: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespEntity,
        app: CommanderApp,
        formFields: PutEntityFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.editPermissions = new DetailEntry(
            'Edit permissions',
            entity.editPermissions,
            'editPermissions',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.editPermissions
        )


        const entityPermissionsCore = app.makeCore( 'entityPermission');
        entityPermissionsCore.soGet = SOGetEntityPermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityPermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityPermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityPermissionsCore
        )
        entityPermissionsCore.title = heading ? heading : entityPermissionsCore.title
        entityPermissionsCore.listTitle = 'Entity permissions'

        entityPermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.entityPermissions = new DetailEntry(
          heading ? heading : entityPermissionsCore.listTitle,
          null,
          'entityPermissions',
          BaseDetailType.embeddedList,
          entityPermissionsCore,
          EditableType.never
        )

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          entity: new SOGetEntity(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const simpleAttributesCore = app.makeCore( 'simpleAttribute');
        simpleAttributesCore.soGet = SOGetSimpleAttribute.construct({
          entity: new SOGetEntity(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributesCore
        )
        simpleAttributesCore.title = heading ? heading : simpleAttributesCore.title
        simpleAttributesCore.listTitle = 'Simple attributes'

        simpleAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributes = new DetailEntry(
          heading ? heading : simpleAttributesCore.listTitle,
          null,
          'simpleAttributes',
          BaseDetailType.embeddedList,
          simpleAttributesCore,
          EditableType.never
        )

        const relationshipAttributesCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributesCore.soGet = SOGetRelationshipAttribute.construct({
          entity: new SOGetEntity(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributesCore
        )
        relationshipAttributesCore.title = heading ? heading : relationshipAttributesCore.title
        relationshipAttributesCore.listTitle = 'Relationship attributes'

        relationshipAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributes = new DetailEntry(
          heading ? heading : relationshipAttributesCore.listTitle,
          null,
          'relationshipAttributes',
          BaseDetailType.embeddedList,
          relationshipAttributesCore,
          EditableType.never
        )

        const interphacesCore = app.makeCore( 'interphace');
        interphacesCore.soGet = SOGetInterphace.construct({
          entity: new SOGetEntity(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        interphacesCore.soGet.queryOptions.sortKey = 'dateCreated';
        interphacesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            interphacesCore
        )
        interphacesCore.title = heading ? heading : interphacesCore.title
        interphacesCore.listTitle = 'Interphaces'

        interphacesCore.configureForDisplay(parentEntityViewSpec)

        this.interphaces = new DetailEntry(
          heading ? heading : interphacesCore.listTitle,
          null,
          'interphaces',
          BaseDetailType.embeddedList,
          interphacesCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}