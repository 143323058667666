import {SORespRelationshipAttributePermissionRule} from '../../core/relationship-attribute-permission-rule/so-resp-relationship-attribute-permission-rule';
import {RelationshipAttributePermissionRuleCore} from './relationship-attribute-permission-rule.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipAttributePermissionRuleFormFields} from './put-relationship-attribute-permission-rule-form-fields';
import { CERelationshipAttributePermissionRuleAttributes } from './ce-relationship-attribute-permission-rule-attributes'


export class CERelationshipAttributePermissionRuleBase extends CEBase {

    public details: CERelationshipAttributePermissionRuleAttributes;
    public fromFields: PutRelationshipAttributePermissionRuleFormFields = new PutRelationshipAttributePermissionRuleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RelationshipAttributePermissionRuleCore,
        public entity: SORespRelationshipAttributePermissionRule,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERelationshipAttributePermissionRuleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.whiteList)
        this.attributes.push(this.details.permissionType)
        this.attributes.push(this.details.relationshipAttribute)
        this.attributes.push(this.details.relationshipAttributePermission)
        this.attributes.push(this.details.project)

    }
}