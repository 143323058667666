import { Type } from 'class-transformer';
import { SORespAssessment } from '../assessment/so-resp-assessment';
import { SORespBlock } from '../block/so-resp-block';
import { SORespProcess } from '../process/so-resp-process';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENSeverity} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Deficiency} from "../../dexie/db";

export class SORespDeficiency implements ObjectiveProtocol {

  entityType: string = 'deficiency';
  uid: string;
  name: string;
  description: string = "";
  active: boolean;
  severity: ENSeverity;
  @Type(() => SORespAssessment)
  assessment: SORespAssessment = null;
  @Type(() => SORespBlock)
  block: SORespBlock = null;
  @Type(() => SORespProcess)
  process: SORespProcess = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toDeficiency(): Deficiency {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            active: this.active,
            severity: this.severity,
            worksUid: null,
            assessmentUid: this.assessment ? this.assessment.uid : null,
            blockUid: this.block ? this.block.uid : null,
            processUid: this.process ? this.process.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}