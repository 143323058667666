import { SOGetProcess } from '../process/so-get-process';
import { SOGetStep } from '../step/so-get-step';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENProcessStepType} from '../../enums';


export class SOPostProcessStepAssociation {

  constructor(
    public name: string,
    public processStepType: ENProcessStepType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public process: SOGetProcess = null,
    public step: SOGetStep = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostProcessStepAssociation(
      'name' in params ? params.name : null,
      'processStepType' in params ? params.processStepType : null,
      'process' in params ? params.process : null,
      'step' in params ? params.step : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
