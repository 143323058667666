import { Type } from 'class-transformer';
import { SORespProcessStepAssociation } from '../process-step-association/so-resp-process-step-association';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Step} from "../../dexie/db";

export class SORespStep implements ObjectiveProtocol {

  entityType: string = 'step';
  uid: string;
  name: string;
  description: string = "";
  stepNumber: number;
  @Type(() => SORespProcessStepAssociation)
  processStepAssociations: SORespProcessStepAssociation[];
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toStep(): Step {
        return {
            uid: this.uid,
            name: this.name,
            description: null,
            stepNumber: this.stepNumber,
            dateCreated: null,
            processStepAssociationsUid: this.processStepAssociations ? this.processStepAssociations.map(processStepAssociations => processStepAssociations.uid) : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}