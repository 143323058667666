import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutPersonFormFields {

    public name: FormField
    public description: FormField
    public relation: FormField
    public user: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.relation = new FormField(
          'relation',
          'Relation',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutPersonFormFields()

export const putPersonFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  relation: fields.relation,
  user: fields.user,
};