import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAssessmentService} from '../../core/assessment/eo-assessment';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {RAPutAssessment} from './ra-put-assessment';
import {RAPostAssessment} from './ra-post-assessment';
import {RADeleteAssessment} from './ra-delete-assessment';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEAssessment } from "./ce-assessment"


export class AssessmentCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'assessment';
  title = 'Assessment';
  ceProtocol: CEConstructor = CEAssessment

  entityService: EOAssessmentService;
  soGetClass = SOGetAssessment;
  soGet: SOGetAssessment;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAssessmentService,
      null,
    );
    this.adapters = [
      new RAPostAssessment(this),
      new RAPutAssessment(this),
      new RADeleteAssessment(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAssessment();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}