import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSimpleAttributePermissionRuleService} from '../../core/simple-attribute-permission-rule/eo-simple-attribute-permission-rule';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {RAPutSimpleAttributePermissionRule} from './ra-put-simple-attribute-permission-rule';
import {RAPostSimpleAttributePermissionRule} from './ra-post-simple-attribute-permission-rule';
import {RADeleteSimpleAttributePermissionRule} from './ra-delete-simple-attribute-permission-rule';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CESimpleAttributePermissionRule } from "./ce-simple-attribute-permission-rule"


export class SimpleAttributePermissionRuleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'simpleAttributePermissionRule';
  title = 'Simple attribute permission rule';
  ceProtocol: CEConstructor = CESimpleAttributePermissionRule

  entityService: EOSimpleAttributePermissionRuleService;
  soGetClass = SOGetSimpleAttributePermissionRule;
  soGet: SOGetSimpleAttributePermissionRule;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSimpleAttributePermissionRuleService,
      null,
    );
    this.adapters = [
      new RAPostSimpleAttributePermissionRule(this),
      new RAPutSimpleAttributePermissionRule(this),
      new RADeleteSimpleAttributePermissionRule(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSimpleAttributePermissionRule();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}