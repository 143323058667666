import { SOGetDeficiency } from '../deficiency/so-get-deficiency';
import { SOGetPeriod } from '../period/so-get-period';
import { SOGetBacklog } from '../backlog/so-get-backlog';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostWork {

  constructor(
    public name: string,
    public active: boolean,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public deficiency: SOGetDeficiency = null,
    public period: SOGetPeriod = null,
    public backlog: SOGetBacklog = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWork(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'deficiency' in params ? params.deficiency : null,
      'period' in params ? params.period : null,
      'backlog' in params ? params.backlog : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
