import { SOGetReport } from '../report/so-get-report';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAssessment {

  constructor(
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public report: SOGetReport = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAssessment(
      'report' in params ? params.report : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
