import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';
import {Organization} from "../../dexie/db";

export class SORespOrganization implements ObjectiveProtocol {

  entityType: string = 'organization';
  uid: string;
  name: string;
  @Type(() => Date)
  dateCreated: Date;
  identifier: string;
  exRefPaymentsLink: string = null;

    toOrganization(): Organization {
        return {
            uid: this.uid,
            name: this.name,
            dateCreated: this.dateCreated,
            identifier: this.identifier,
            subscriptionsUid: null,
            exRefPayment: null,
            exRefPaymentsLink: null,
            roleGrantsUid: null,
            mediasUid: null,
            invitesUid: null,
            ingestsUid: null,
            attachmentsUid: null,
            alertSubscriptionsUid: null,
            messagesUid: null,
            notificationsUid: null,
            topicsUid: null,
            assessmentUid: null,
            assessmentSummaryUid: null,
            blockUid: null,
            deficiencyUid: null,
            periodUid: null,
            reportUid: null,
            requirementUid: null,
            customLogicRequirementUid: null,
            workUid: null,
            realizationUid: null,
            entityUid: null,
            relationshipUid: null,
            relationshipAttributeUid: null,
            simpleAttributeUid: null,
            processUid: null,
            projectUid: null,
            interphaceUid: null,
            backlogUid: null,
            attributeSpecUid: null,
            exportProjectUid: null,
            simpleAttributePermissionUid: null,
            simpleAttributePermissionRuleUid: null,
            relationshipAttributePermissionUid: null,
            relationshipAttributePermissionRuleUid: null,
            entityPermissionUid: null,
            planUid: null,
            personUid: null,
            assignedProjectUid: null,
            planWorkAssociationUid: null,
            rolePermissionUid: null,
            roleUid: null,
            processRoleUid: null,
            stepUid: null,
            processStepAssociationUid: null,
}

        };
}