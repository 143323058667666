import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutWorkFormFields {

    public name: FormField
    public description: FormField
    public active: FormField
    public deficiency: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          false,
        )
        this.deficiency = new FormField(
          'deficiency',
          'Deficiency',
          'deficiency',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutWorkFormFields()

export const putWorkFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  active: fields.active,
  deficiency: fields.deficiency,
  project: fields.project,
};