import {SOPostRelationshipAttribute} from '../../core/relationship-attribute/so-post-relationship-attribute';
import {RelationshipAttributeCore} from './relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {FormField, SelectionOption} from 'lionheartland';
import {postRelationshipAttributeFormFieldOptions} from './post-relationship-attribute-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {EntityCore} from '../entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {CustomLogicRequirementCore} from '../custom-logic-requirement/custom-logic-requirement.core';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {RelationshipCore} from '../relationship/relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostRelationshipAttribute extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRelationshipAttributeFormFieldOptions);

  RequestClass = SOPostRelationshipAttribute

  constructor(
    public entityCore: RelationshipAttributeCore
  ) {
    super();
    if (postRelationshipAttributeFormFieldOptions.entity) {
      postRelationshipAttributeFormFieldOptions.entity.makeCore = true;
    }
    if (postRelationshipAttributeFormFieldOptions.customLogicRequirement) {
      postRelationshipAttributeFormFieldOptions.customLogicRequirement.makeCore = true;
    }
    if (postRelationshipAttributeFormFieldOptions.relationship) {
      postRelationshipAttributeFormFieldOptions.relationship.makeCore = true;
    }
    if (postRelationshipAttributeFormFieldOptions.project) {
      postRelationshipAttributeFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('multiplicity'),
      this.getFormValueFromIdentifier('editPermissions'),
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'customLogicRequirement',
        SOGetCustomLogicRequirement,
      ),
      this.getFormValueFromIdentifier(
        'relationship',
        SOGetRelationship,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}