import { SOGetProcessStepAssociation } from '../process-step-association/so-get-process-step-association';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostStep {

  constructor(
    public name: string,
    public stepNumber: number,
    public processStepAssociations: SOGetProcessStepAssociation[],
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostStep(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'stepNumber' in params ? params.stepNumber : null,
      'processStepAssociations' in params ? params.processStepAssociations : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
