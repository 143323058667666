import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENPeriodType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Period} from "../../dexie/db";

export class SORespPeriod implements ObjectiveProtocol {

  entityType: string = 'period';
  uid: string;
  name: string;
  numeral: number;
  type: ENPeriodType;
  @Type(() => SORespPeriod)
  parent: SORespPeriod = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toPeriod(): Period {
        return {
            uid: this.uid,
            name: this.name,
            numeral: this.numeral,
            startDate: null,
            endDate: null,
            dateCreated: null,
            type: this.type,
            plansUid: null,
            worksUid: null,
            reportsUid: null,
            periodsUid: null,
            parentUid: this.parent ? this.parent.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}