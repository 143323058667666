import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostRoleGrantFormFields {

    public isDefault: FormField
    public user: FormField
    public role: FormField
    public organization: FormField

    constructor() {

        this.isDefault = new FormField(
          'isDefault',
          'Is default',
          null,
          FormFieldType.boolean,
          true,
          true,
          false
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostRoleGrantFormFields()

export const postRoleGrantFormFieldOptions: {[key: string]: FormField} = {
  isDefault: fields.isDefault,
  user: fields.user,
  role: fields.role,
  organization: fields.organization,
};