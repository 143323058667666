import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENRequirementStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Requirement} from "../../dexie/db";

export class SORespRequirement implements ObjectiveProtocol {

  entityType: string = 'requirement';
  uid: string;
  name: string;
  description: string = "";
  active: boolean;
  currentStatus: ENRequirementStatus;
  @Type(() => SORespRequirement)
  parent: SORespRequirement = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toRequirement(): Requirement {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            active: this.active,
            currentStatus: this.currentStatus,
            requirementsUid: null,
            parentUid: this.parent ? this.parent.uid : null,
            realizationsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}