import {SOPutConstraint} from '../../core/constraint/so-put-constraint';
import {ConstraintCore} from './constraint.core';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {FormField, SelectionOption} from 'lionheartland';
import {putConstraintFormFieldOptions} from './put-constraint-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutConstraint extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putConstraintFormFieldOptions);

  RequestClass = SOPutConstraint

  constructor(
    public entityCore: ConstraintCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}