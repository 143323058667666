import { Type } from 'class-transformer';
import {SORespRelationshipAttribute} from './so-resp-relationship-attribute';
import {PluralProtocol} from 'lionheartland';
import {RelationshipAttribute} from "../../dexie/db";

export class SOPluralRelationshipAttribute implements PluralProtocol {
  @Type(() => SORespRelationshipAttribute)
  objects: SORespRelationshipAttribute[] = []
  nextOffset: number = null

    toRelationshipAttributes(): RelationshipAttribute[] {
        return this.objects.map(relationshipAttribute => relationshipAttribute.toRelationshipAttribute());
    }
}