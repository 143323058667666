import {SORespPaymentIntent} from '../../core/payment-intent/so-resp-payment-intent';
import {PaymentIntentCore} from './payment-intent.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPaymentIntentFormFields} from './put-payment-intent-form-fields';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';


export class CEPaymentIntentAttributesBase {

    amount: DetailEntry
    dateCreated: DetailEntry
    status: DetailEntry
    invoice: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPaymentIntent,
        app: CommanderApp,
        formFields: PutPaymentIntentFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amount
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.status
        )


        const invoiceCore = app.makeCore( 'invoice');
        invoiceCore.soGet = SOGetInvoice.construct({
        });
        let invoiceCeProtocol;
        if (entity.invoice) {
            invoiceCeProtocol = createCEProtocol(
                invoiceCore.ceProtocol,
                parentEntityViewSpec,
                invoiceCore,
                entity.invoice,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceCore
        )
        invoiceCore.title = heading ? heading : invoiceCore.title
        invoiceCore.listTitle = 'Invoice'

        if (entity.invoice) {
            displayStringFactory.reconfigure(
                entity.invoice,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        invoiceCore.configureForDisplay(parentEntityViewSpec)

        this.invoice = new DetailEntry(
          heading ? heading : invoiceCore.listTitle,
          invoiceCeProtocol ? invoiceCeProtocol.entityListView.fullHeading : null,
          'invoice',
          BaseDetailType.pushedDetail,
          invoiceCore,
          EditableType.always,
          formFields.invoice
        )
        if (entity.invoice) {
          this.invoice.ceProtocol = invoiceCeProtocol;
          this.invoice.cachedListEntry = invoiceCore.makeListEntry(parentEntityViewSpec, entity.invoice, 0)
          invoiceCore.selectedEntity = entity.invoice;
        }
        this.invoice.singleFieldSelect = true
    }
}