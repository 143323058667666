import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPeriodService} from '../../core/period/eo-period';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {RAPutPeriod} from './ra-put-period';
import {RAPostPeriod} from './ra-post-period';
import {RADeletePeriod} from './ra-delete-period';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEPeriod } from "./ce-period"


export class PeriodCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'period';
  title = 'Period';
  ceProtocol: CEConstructor = CEPeriod

  entityService: EOPeriodService;
  soGetClass = SOGetPeriod;
  soGet: SOGetPeriod;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPeriodService,
      null,
    );
    this.adapters = [
      new RAPostPeriod(this),
      new RAPutPeriod(this),
      new RADeletePeriod(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPeriod();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}