import { Type } from 'class-transformer';
import {SORespDatafeed} from './so-resp-datafeed';
import {PluralProtocol} from 'lionheartland';
import {Datafeed} from "../../dexie/db";

export class SOPluralDatafeed implements PluralProtocol {
  @Type(() => SORespDatafeed)
  objects: SORespDatafeed[] = []
  nextOffset: number = null

    toDatafeeds(): Datafeed[] {
        return this.objects.map(datafeed => datafeed.toDatafeed());
    }
}