import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORoleGrantService} from '../../core/role-grant/eo-role-grant';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {RAPutRoleGrant} from './ra-put-role-grant';
import {RAPostRoleGrant} from './ra-post-role-grant';
import {RADeleteRoleGrant} from './ra-delete-role-grant';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CERoleGrant } from "./ce-role-grant"


export class RoleGrantCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'roleGrant';
  title = 'Role grant';
  ceProtocol: CEConstructor = CERoleGrant

  entityService: EORoleGrantService;
  soGetClass = SOGetRoleGrant;
  soGet: SOGetRoleGrant;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORoleGrantService,
      null,
    );
    this.adapters = [
      new RAPostRoleGrant(this),
      new RAPutRoleGrant(this),
      new RADeleteRoleGrant(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRoleGrant();
    this.soGet.queryOptions.limit = this.limit;
  }


}