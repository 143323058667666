import {SORespRelationship} from '../../core/relationship/so-resp-relationship';
import {RelationshipCore} from './relationship.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipFormFields} from './put-relationship-form-fields';
import { CERelationshipAttributes } from './ce-relationship-attributes'


export class CERelationshipBase extends CEBase {

    public details: CERelationshipAttributes;
    public fromFields: PutRelationshipFormFields = new PutRelationshipFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RelationshipCore,
        public entity: SORespRelationship,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERelationshipAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.relationshipAttributes)
        this.attributes.push(this.details.project)

    }
}