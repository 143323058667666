import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENBlockType} from '../../enums';

export class PostBlockFormFields {

    public name: FormField
    public description: FormField
    public active: FormField
    public type: FormField
    public parent: FormField
    public customLogicRequirement: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.type.enumeration = ENBlockType
        this.parent = new FormField(
          'parent',
          'Parent',
          'block',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.customLogicRequirement = new FormField(
          'customLogicRequirement',
          'Custom logic requirement',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostBlockFormFields()

export const postBlockFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  active: fields.active,
  type: fields.type,
  parent: fields.parent,
  customLogicRequirement: fields.customLogicRequirement,
  project: fields.project,
};