import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutProduct } from './so-put-product';
import { SOGetProduct } from './so-get-product';
import { SOPostProduct } from './so-post-product';
import { SODeleteProduct } from './so-delete-product';
import {SORespProduct} from './so-resp-product';
import {SOPluralProduct} from './so-plural-product';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOProductService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('product', entityCore);
  }

  /** GET Product by id. Will 404 if id not found */
  get(request: SOGetProduct): Observable<SOPluralProduct> {
    return this.httpClient.get<SOPluralProduct>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralProduct, resp)),
      tap(async (response: SOPluralProduct) => {
        this.log(`fetched Product`);
        // After a successful server response, update IndexedDB
        const activities = response.toProducts(); // Convert your response to an array of Product instances
        const promises = activities.map(product => db.products.put(product));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralProduct>(`get Product`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralProduct> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.products.toArray()).pipe(
                map((productsArray) => {
                    // Convert the array of plain objects to an array of SORespProduct instances
                    const sorProducts = productsArray.map(req => plainToClass(SORespProduct, req));
                    // Create and return a new instance of SOPluralProduct
                    const pluralProduct = new SOPluralProduct();
                    pluralProduct.objects = sorProducts;
                    pluralProduct.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralProduct;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processProducts(productsArray) {
        const sorProducts = productsArray.map(req => plainToClass(SORespProduct, req));
        const pluralProduct = new SOPluralProduct();
        pluralProduct.objects = sorProducts;
        pluralProduct.nextOffset = null;
        return pluralProduct;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostProduct): Observable<SORespProduct> {
  return this.httpClient.post<SORespProduct>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespProduct, resp)),

    tap(async (response: SORespProduct) => {
      this.log(`added Product w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.products.add(response.toProduct());
    }),
    catchError(this.handleError<SORespProduct>('Product post'))
  );
}


  put(request: SOPutProduct): Observable<SORespProduct> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespProduct>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespProduct, resp)),
      tap(async (response: SORespProduct) => {
        this.log(`edited Product w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.products.put(response.toProduct());
      }),
      catchError(this.handleError<SORespProduct>('Product put'))
    );
  }


  delete(request: SODeleteProduct): Observable<SORespProduct> {
    return this.httpClient.delete<SORespProduct>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Product uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.products.delete(request.uid);
      }),
      catchError(this.handleError<SORespProduct>('Product delete'))
    );
  }


}
