import {SORespInvoiceLine} from '../../core/invoice-line/so-resp-invoice-line';
import {InvoiceLineCore} from './invoice-line.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutInvoiceLineFormFields} from './put-invoice-line-form-fields';
import { CEInvoiceLineAttributes } from './ce-invoice-line-attributes'


export class CEInvoiceLineBase extends CEBase {

    public details: CEInvoiceLineAttributes;
    public fromFields: PutInvoiceLineFormFields = new PutInvoiceLineFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: InvoiceLineCore,
        public entity: SORespInvoiceLine,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEInvoiceLineAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.price)
        this.attributes.push(this.details.invoice)

    }
}