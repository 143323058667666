import {SORespWork} from '../../core/work/so-resp-work';
import {WorkCore} from './work.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutWorkFormFields} from './put-work-form-fields';
import { CEWorkAttributes } from './ce-work-attributes'


export class CEWorkBase extends CEBase {

    public details: CEWorkAttributes;
    public fromFields: PutWorkFormFields = new PutWorkFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: WorkCore,
        public entity: SORespWork,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEWorkAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.number)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.deficiency)
        this.attributes.push(this.details.period)
        this.attributes.push(this.details.planWorkAssociations)
        this.attributes.push(this.details.backlog)
        this.attributes.push(this.details.project)

    }
}