import { Type } from 'class-transformer';
import {SORespAssessment} from './so-resp-assessment';
import {PluralProtocol} from 'lionheartland';
import {Assessment} from "../../dexie/db";

export class SOPluralAssessment implements PluralProtocol {
  @Type(() => SORespAssessment)
  objects: SORespAssessment[] = []
  nextOffset: number = null

    toAssessments(): Assessment[] {
        return this.objects.map(assessment => assessment.toAssessment());
    }
}