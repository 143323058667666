import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENMessageStatus} from '../../enums';

export class PostNotificationFormFields {

    public name: FormField
    public description: FormField
    public status: FormField
    public datafeed: FormField
    public message: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          true,
          true,
          ENMessageStatus.unread
        )
        this.status.enumeration = ENMessageStatus
        this.datafeed = new FormField(
          'datafeed',
          'Datafeed',
          'datafeed',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.message = new FormField(
          'message',
          'Message',
          'message',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostNotificationFormFields()

export const postNotificationFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  status: fields.status,
  datafeed: fields.datafeed,
  message: fields.message,
};