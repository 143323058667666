import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAssignedProjectFormFields {

    public name: FormField
    public person: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.person = new FormField(
          'person',
          'Person',
          'person',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutAssignedProjectFormFields()

export const putAssignedProjectFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  person: fields.person,
  project: fields.project,
};