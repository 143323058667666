import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutProductFormFields {

    public name: FormField
    public sku: FormField
    public exRef: FormField
    public active: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.sku = new FormField(
          'sku',
          'Sku',
          null,
          FormFieldType.text,
          false,
          true,
          ''
        )
        this.exRef = new FormField(
          'exRef',
          'Ex ref',
          null,
          FormFieldType.text,
          false,
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )

    }
}

const fields = new PutProductFormFields()

export const putProductFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  sku: fields.sku,
  exRef: fields.exRef,
  active: fields.active,
};