import { Type } from 'class-transformer';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespCustomLogicRequirement } from '../custom-logic-requirement/so-resp-custom-logic-requirement';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENSimpleAttributeType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {SimpleAttribute} from "../../dexie/db";

export class SORespSimpleAttribute implements ObjectiveProtocol {

  entityType: string = 'simpleAttribute';
  uid: string;
  name: string;
  description: string = "";
  attributeType: ENSimpleAttributeType = null;
  editPermissions: boolean;
  @Type(() => SORespEntity)
  entity: SORespEntity;
  @Type(() => SORespCustomLogicRequirement)
  customLogicRequirement: SORespCustomLogicRequirement = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toSimpleAttribute(): SimpleAttribute {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            attributeType: null,
            editPermissions: this.editPermissions,
            simpleAttributePermissionsUid: null,
            simpleAttributePermissionRulesUid: null,
            realizationsUid: null,
            entityUid: this.entity ? this.entity.uid : null,
            customLogicRequirementUid: this.customLogicRequirement ? this.customLogicRequirement.uid : null,
            attributeSpecsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}