import { Type } from 'class-transformer';
import { SORespPlan } from '../plan/so-resp-plan';
import { SORespWork } from '../work/so-resp-work';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENWeeklyReportSection} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {PlanWorkAssociation} from "../../dexie/db";

export class SORespPlanWorkAssociation implements ObjectiveProtocol {

  entityType: string = 'planWorkAssociation';
  uid: string;
  name: string;
  reportSection: ENWeeklyReportSection;
  @Type(() => SORespPlan)
  plan: SORespPlan;
  @Type(() => SORespWork)
  work: SORespWork;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toPlanWorkAssociation(): PlanWorkAssociation {
        return {
            uid: this.uid,
            name: this.name,
            dateCreated: null,
            reportSection: this.reportSection,
            planUid: this.plan ? this.plan.uid : null,
            workUid: this.work ? this.work.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}