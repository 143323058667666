import { Type } from 'class-transformer';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENInterphaceType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Interphace} from "../../dexie/db";

export class SORespInterphace implements ObjectiveProtocol {

  entityType: string = 'interphace';
  uid: string;
  name: string;
  description: string = "";
  interphaceType: ENInterphaceType;
  @Type(() => SORespEntity)
  entity: SORespEntity = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toInterphace(): Interphace {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            interphaceType: this.interphaceType,
            blocksUid: null,
            entityUid: this.entity ? this.entity.uid : null,
            attributeSpecsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}