import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostRoleFormFields {

    public name: FormField
    public identifier: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostRoleFormFields()

export const postRoleFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
};