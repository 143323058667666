import { SOGetRoleGrant } from '../role-grant/so-get-role-grant';
import {ENTokenStatus} from '../../enums';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetToken implements QueryProtocol {

  constructor(
    public uid: string = null,
    public status: ENTokenStatus[] = null,
    public roleGrant: SOGetRoleGrant = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetToken(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'roleGrant' in params ? params.roleGrant : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
