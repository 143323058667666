import {SORespAttributeSpec} from '../../core/attribute-spec/so-resp-attribute-spec';
import {AttributeSpecCore} from './attribute-spec.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAttributeSpecFormFields} from './put-attribute-spec-form-fields';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEAttributeSpecAttributesBase {

    name: DetailEntry
    description: DetailEntry
    interphace: DetailEntry
    simpleAttribute: DetailEntry
    relationshipAttribute: DetailEntry
    realizations: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAttributeSpec,
        app: CommanderApp,
        formFields: PutAttributeSpecFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const interphaceCore = app.makeCore( 'interphace');
        interphaceCore.soGet = SOGetInterphace.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        interphaceCore.soGet.queryOptions.sortKey = 'dateCreated';
        interphaceCore.soGet.queryOptions.descending = true;
        let interphaceCeProtocol;
        if (entity.interphace) {
            interphaceCeProtocol = createCEProtocol(
                interphaceCore.ceProtocol,
                parentEntityViewSpec,
                interphaceCore,
                entity.interphace,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            interphaceCore
        )
        interphaceCore.title = heading ? heading : interphaceCore.title
        interphaceCore.listTitle = 'Interphace'

        if (entity.interphace) {
            displayStringFactory.reconfigure(
                entity.interphace,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        interphaceCore.configureForDisplay(parentEntityViewSpec)

        this.interphace = new DetailEntry(
          heading ? heading : interphaceCore.listTitle,
          interphaceCeProtocol ? interphaceCeProtocol.entityListView.fullHeading : null,
          'interphace',
          BaseDetailType.pushedDetail,
          interphaceCore,
          EditableType.always,
          formFields.interphace
        )
        if (entity.interphace) {
          this.interphace.ceProtocol = interphaceCeProtocol;
          this.interphace.cachedListEntry = interphaceCore.makeListEntry(parentEntityViewSpec, entity.interphace, 0)
          interphaceCore.selectedEntity = entity.interphace;
        }
        this.interphace.singleFieldSelect = true

        const simpleAttributeCore = app.makeCore( 'simpleAttribute');
        simpleAttributeCore.soGet = SOGetSimpleAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributeCore.soGet.queryOptions.descending = true;
        let simpleAttributeCeProtocol;
        if (entity.simpleAttribute) {
            simpleAttributeCeProtocol = createCEProtocol(
                simpleAttributeCore.ceProtocol,
                parentEntityViewSpec,
                simpleAttributeCore,
                entity.simpleAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributeCore
        )
        simpleAttributeCore.title = heading ? heading : simpleAttributeCore.title
        simpleAttributeCore.listTitle = 'Simple attribute'

        if (entity.simpleAttribute) {
            displayStringFactory.reconfigure(
                entity.simpleAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        simpleAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttribute = new DetailEntry(
          heading ? heading : simpleAttributeCore.listTitle,
          simpleAttributeCeProtocol ? simpleAttributeCeProtocol.entityListView.fullHeading : null,
          'simpleAttribute',
          BaseDetailType.pushedDetail,
          simpleAttributeCore,
          EditableType.always,
          formFields.simpleAttribute
        )
        if (entity.simpleAttribute) {
          this.simpleAttribute.ceProtocol = simpleAttributeCeProtocol;
          this.simpleAttribute.cachedListEntry = simpleAttributeCore.makeListEntry(parentEntityViewSpec, entity.simpleAttribute, 0)
          simpleAttributeCore.selectedEntity = entity.simpleAttribute;
        }
        this.simpleAttribute.singleFieldSelect = true

        const relationshipAttributeCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributeCore.soGet = SOGetRelationshipAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributeCore.soGet.queryOptions.descending = true;
        let relationshipAttributeCeProtocol;
        if (entity.relationshipAttribute) {
            relationshipAttributeCeProtocol = createCEProtocol(
                relationshipAttributeCore.ceProtocol,
                parentEntityViewSpec,
                relationshipAttributeCore,
                entity.relationshipAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributeCore
        )
        relationshipAttributeCore.title = heading ? heading : relationshipAttributeCore.title
        relationshipAttributeCore.listTitle = 'Relationship attribute'

        if (entity.relationshipAttribute) {
            displayStringFactory.reconfigure(
                entity.relationshipAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        relationshipAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttribute = new DetailEntry(
          heading ? heading : relationshipAttributeCore.listTitle,
          relationshipAttributeCeProtocol ? relationshipAttributeCeProtocol.entityListView.fullHeading : null,
          'relationshipAttribute',
          BaseDetailType.pushedDetail,
          relationshipAttributeCore,
          EditableType.always,
          formFields.relationshipAttribute
        )
        if (entity.relationshipAttribute) {
          this.relationshipAttribute.ceProtocol = relationshipAttributeCeProtocol;
          this.relationshipAttribute.cachedListEntry = relationshipAttributeCore.makeListEntry(parentEntityViewSpec, entity.relationshipAttribute, 0)
          relationshipAttributeCore.selectedEntity = entity.relationshipAttribute;
        }
        this.relationshipAttribute.singleFieldSelect = true

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          attributeSpec: new SOGetAttributeSpec(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}