import {SOPostToken} from '../../core/token/so-post-token';
import {TokenCore} from './token.core';
import {SOGetToken} from '../../core/token/so-get-token';
import {FormField, SelectionOption} from 'lionheartland';
import {postTokenFormFieldOptions} from './post-token-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {CertificateCore} from '../certificate/certificate.core';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {RoleGrantCore} from '../role-grant/role-grant.core';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';

export class RAPostToken extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTokenFormFieldOptions);

  RequestClass = SOPostToken

  constructor(
    public entityCore: TokenCore
  ) {
    super();
    if (postTokenFormFieldOptions.certificate) {
      postTokenFormFieldOptions.certificate.makeCore = true;
    }
    if (postTokenFormFieldOptions.roleGrant) {
      postTokenFormFieldOptions.roleGrant.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'certificate',
        SOGetCertificate,
      ),
      this.getFormValueFromIdentifier(
        'roleGrant',
        SOGetRoleGrant,
      ),
      this.getFormValueFromIdentifier('accessToken'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}