import {SORespRolePermission} from '../../core/role-permission/so-resp-role-permission';
import {RolePermissionCore} from './role-permission.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRolePermissionFormFields} from './put-role-permission-form-fields';
import { CERolePermissionAttributes } from './ce-role-permission-attributes'


export class CERolePermissionBase extends CEBase {

    public details: CERolePermissionAttributes;
    public fromFields: PutRolePermissionFormFields = new PutRolePermissionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RolePermissionCore,
        public entity: SORespRolePermission,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERolePermissionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.entityPermission)
        this.attributes.push(this.details.relationshipAttributePermission)
        this.attributes.push(this.details.simpleAttributePermission)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.project)

    }
}