import { SOGetBlock } from '../block/so-get-block';
import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENBlockType} from '../../enums';


export class SOPostBlock {

  constructor(
    public name: string,
    public active: boolean,
    public type: ENBlockType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public parent: SOGetBlock = null,
    public customLogicRequirement: SOGetCustomLogicRequirement = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostBlock(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'type' in params ? params.type : null,
      'parent' in params ? params.parent : null,
      'customLogicRequirement' in params ? params.customLogicRequirement : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
