import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENRequirementStatus} from '../../enums';


export class SOPutCustomLogicRequirement {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public active: boolean = null,
    public currentStatus: ENRequirementStatus = null,
    public parent: SOGetCustomLogicRequirement = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCustomLogicRequirement(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'currentStatus' in params ? params.currentStatus : null,
      'parent' in params ? params.parent : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
