import {ENSesMessageType} from '../../enums';


export class SOPutSesMessage {

  constructor(
    public uid: string,
    public data: string = null,
    public messageType: ENSesMessageType = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSesMessage(
      'uid' in params ? params.uid : null,
      'data' in params ? params.data : null,
      'messageType' in params ? params.messageType : null,
    );
  }
}
