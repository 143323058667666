import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutInvoiceLine } from './so-put-invoice-line';
import { SOGetInvoiceLine } from './so-get-invoice-line';
import { SOPostInvoiceLine } from './so-post-invoice-line';
import { SODeleteInvoiceLine } from './so-delete-invoice-line';
import {SORespInvoiceLine} from './so-resp-invoice-line';
import {SOPluralInvoiceLine} from './so-plural-invoice-line';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOInvoiceLineService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('invoice_line', entityCore);
  }

  /** GET InvoiceLine by id. Will 404 if id not found */
  get(request: SOGetInvoiceLine): Observable<SOPluralInvoiceLine> {
    return this.httpClient.get<SOPluralInvoiceLine>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralInvoiceLine, resp)),
      tap(async (response: SOPluralInvoiceLine) => {
        this.log(`fetched InvoiceLine`);
        // After a successful server response, update IndexedDB
        const activities = response.toInvoiceLines(); // Convert your response to an array of InvoiceLine instances
        const promises = activities.map(invoiceLine => db.invoiceLines.put(invoiceLine));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralInvoiceLine>(`get InvoiceLine`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralInvoiceLine> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.invoiceLines.toArray()).pipe(
                map((invoiceLinesArray) => {
                    // Convert the array of plain objects to an array of SORespInvoiceLine instances
                    const sorInvoiceLines = invoiceLinesArray.map(req => plainToClass(SORespInvoiceLine, req));
                    // Create and return a new instance of SOPluralInvoiceLine
                    const pluralInvoiceLine = new SOPluralInvoiceLine();
                    pluralInvoiceLine.objects = sorInvoiceLines;
                    pluralInvoiceLine.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralInvoiceLine;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processInvoiceLines(invoiceLinesArray) {
        const sorInvoiceLines = invoiceLinesArray.map(req => plainToClass(SORespInvoiceLine, req));
        const pluralInvoiceLine = new SOPluralInvoiceLine();
        pluralInvoiceLine.objects = sorInvoiceLines;
        pluralInvoiceLine.nextOffset = null;
        return pluralInvoiceLine;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostInvoiceLine): Observable<SORespInvoiceLine> {
  return this.httpClient.post<SORespInvoiceLine>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespInvoiceLine, resp)),

    tap(async (response: SORespInvoiceLine) => {
      this.log(`added InvoiceLine w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.invoiceLines.add(response.toInvoiceLine());
    }),
    catchError(this.handleError<SORespInvoiceLine>('InvoiceLine post'))
  );
}


  put(request: SOPutInvoiceLine): Observable<SORespInvoiceLine> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInvoiceLine>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespInvoiceLine, resp)),
      tap(async (response: SORespInvoiceLine) => {
        this.log(`edited InvoiceLine w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.invoiceLines.put(response.toInvoiceLine());
      }),
      catchError(this.handleError<SORespInvoiceLine>('InvoiceLine put'))
    );
  }


  delete(request: SODeleteInvoiceLine): Observable<SORespInvoiceLine> {
    return this.httpClient.delete<SORespInvoiceLine>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted InvoiceLine uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.invoiceLines.delete(request.uid);
      }),
      catchError(this.handleError<SORespInvoiceLine>('InvoiceLine delete'))
    );
  }


}
