import { Type } from 'class-transformer';
import {SORespInterphace} from './so-resp-interphace';
import {PluralProtocol} from 'lionheartland';
import {Interphace} from "../../dexie/db";

export class SOPluralInterphace implements PluralProtocol {
  @Type(() => SORespInterphace)
  objects: SORespInterphace[] = []
  nextOffset: number = null

    toInterphaces(): Interphace[] {
        return this.objects.map(interphace => interphace.toInterphace());
    }
}