import { Type } from 'class-transformer';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ENPaymentIntentStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {PaymentIntent} from "../../dexie/db";

export class SORespPaymentIntent implements ObjectiveProtocol {

  entityType: string = 'paymentIntent';
  uid: string;
  amount: number;
  @Type(() => Date)
  dateCreated: Date;
  status: ENPaymentIntentStatus;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;

    toPaymentIntent(): PaymentIntent {
        return {
            uid: this.uid,
            amount: this.amount,
            dateCreated: this.dateCreated,
            status: this.status,
            invoiceUid: this.invoice ? this.invoice.uid : null,
}

        };
}