import {SORespUser} from '../../core/user/so-resp-user';
import {UserCore} from './user.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutUserFormFields} from './put-user-form-fields';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {SOGetPerson} from '../../core/person/so-get-person';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEUserAttributesBase {

    username: DetailEntry
    givenName: DetailEntry
    surname: DetailEntry
    email: DetailEntry
    emailPending: DetailEntry
    emailVerified: DetailEntry
    dateCreated: DetailEntry
    roleGrants: DetailEntry
    invites: DetailEntry
    datafeeds: DetailEntry
    person: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespUser,
        app: CommanderApp,
        formFields: PutUserFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.username = new DetailEntry(
            'Username',
            entity.username,
            'username',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.givenName = new DetailEntry(
            'Given name',
            entity.givenName,
            'givenName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.givenName
        )

        this.surname = new DetailEntry(
            'Surname',
            entity.surname,
            'surname',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.surname
        )

        this.email = new DetailEntry(
            'Email',
            entity.email,
            'email',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.email
        )

        this.emailPending = new DetailEntry(
            'Email pending',
            entity.emailPending,
            'emailPending',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.emailVerified = new DetailEntry(
            'Email verified',
            entity.emailVerified,
            'emailVerified',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const roleGrantsCore = app.makeCore( 'roleGrant');
        roleGrantsCore.soGet = SOGetRoleGrant.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleGrantsCore
        )
        roleGrantsCore.title = heading ? heading : roleGrantsCore.title
        roleGrantsCore.listTitle = 'Role grants'

        roleGrantsCore.configureForDisplay(parentEntityViewSpec)

        this.roleGrants = new DetailEntry(
          heading ? heading : roleGrantsCore.listTitle,
          null,
          'roleGrants',
          BaseDetailType.embeddedList,
          roleGrantsCore,
          EditableType.never
        )

        const invitesCore = app.makeCore( 'organizationInvite');
        invitesCore.soGet = SOGetOrganizationInvite.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invitesCore
        )
        invitesCore.title = heading ? heading : invitesCore.title
        invitesCore.listTitle = 'Invites'

        invitesCore.configureForDisplay(parentEntityViewSpec)

        this.invites = new DetailEntry(
          heading ? heading : invitesCore.listTitle,
          null,
          'invites',
          BaseDetailType.embeddedList,
          invitesCore,
          EditableType.never
        )

        const datafeedsCore = app.makeCore( 'datafeed');
        datafeedsCore.soGet = SOGetDatafeed.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            datafeedsCore
        )
        datafeedsCore.title = heading ? heading : datafeedsCore.title
        datafeedsCore.listTitle = 'Datafeeds'

        datafeedsCore.configureForDisplay(parentEntityViewSpec)

        this.datafeeds = new DetailEntry(
          heading ? heading : datafeedsCore.listTitle,
          null,
          'datafeeds',
          BaseDetailType.embeddedList,
          datafeedsCore,
          EditableType.never
        )

        const personCore = app.makeCore( 'person');
        personCore.soGet = SOGetPerson.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        personCore.soGet.queryOptions.sortKey = 'dateCreated';
        personCore.soGet.queryOptions.descending = true;
        let personCeProtocol;
        if (entity.person) {
            personCeProtocol = createCEProtocol(
                personCore.ceProtocol,
                parentEntityViewSpec,
                personCore,
                entity.person,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            personCore
        )
        personCore.title = heading ? heading : personCore.title
        personCore.listTitle = 'Person'

        if (entity.person) {
            displayStringFactory.reconfigure(
                entity.person,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        personCore.configureForDisplay(parentEntityViewSpec)

        this.person = new DetailEntry(
          heading ? heading : personCore.listTitle,
          personCeProtocol ? personCeProtocol.entityListView.fullHeading : null,
          'person',
          BaseDetailType.pushedDetail,
          personCore,
          EditableType.always,
          formFields.person
        )
        if (entity.person) {
          this.person.ceProtocol = personCeProtocol;
          this.person.cachedListEntry = personCore.makeListEntry(parentEntityViewSpec, entity.person, 0)
          personCore.selectedEntity = entity.person;
        }
        this.person.singleFieldSelect = true
    }
}