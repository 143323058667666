

export class SOPostIdentity {

  constructor(
    public idToken: string,
    public accessToken: string,
    public provider: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostIdentity(
      'idToken' in params ? params.idToken : null,
      'accessToken' in params ? params.accessToken : null,
      'provider' in params ? params.provider : null,
    );
  }
}
