import {SORespNotification} from '../../core/notification/so-resp-notification';
import {NotificationCore} from './notification.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutNotificationFormFields} from './put-notification-form-fields';
import {SOGetNotification} from '../../core/notification/so-get-notification';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CENotificationAttributesBase {

    name: DetailEntry
    description: DetailEntry
    status: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    datafeed: DetailEntry
    message: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespNotification,
        app: CommanderApp,
        formFields: PutNotificationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.status
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const datafeedCore = app.makeCore( 'datafeed');
        datafeedCore.soGet = SOGetDatafeed.construct({
          user: app.getScope('user') ? new SOGetUser(app.getScope('user').uid) : null,
        });
        let datafeedCeProtocol;
        if (entity.datafeed) {
            datafeedCeProtocol = createCEProtocol(
                datafeedCore.ceProtocol,
                parentEntityViewSpec,
                datafeedCore,
                entity.datafeed,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            datafeedCore
        )
        datafeedCore.title = heading ? heading : datafeedCore.title
        datafeedCore.listTitle = 'Datafeed'

        if (entity.datafeed) {
            displayStringFactory.reconfigure(
                entity.datafeed,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        datafeedCore.configureForDisplay(parentEntityViewSpec)

        this.datafeed = new DetailEntry(
          heading ? heading : datafeedCore.listTitle,
          datafeedCeProtocol ? datafeedCeProtocol.entityListView.fullHeading : null,
          'datafeed',
          BaseDetailType.pushedDetail,
          datafeedCore,
          EditableType.never
        )
        if (entity.datafeed) {
          this.datafeed.ceProtocol = datafeedCeProtocol;
          this.datafeed.cachedListEntry = datafeedCore.makeListEntry(parentEntityViewSpec, entity.datafeed, 0)
          datafeedCore.selectedEntity = entity.datafeed;
        }
        this.datafeed.singleFieldSelect = true

        const messageCore = app.makeCore( 'message');
        messageCore.soGet = SOGetMessage.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        let messageCeProtocol;
        if (entity.message) {
            messageCeProtocol = createCEProtocol(
                messageCore.ceProtocol,
                parentEntityViewSpec,
                messageCore,
                entity.message,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messageCore
        )
        messageCore.title = heading ? heading : messageCore.title
        messageCore.listTitle = 'Message'

        if (entity.message) {
            displayStringFactory.reconfigure(
                entity.message,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        messageCore.configureForDisplay(parentEntityViewSpec)

        this.message = new DetailEntry(
          heading ? heading : messageCore.listTitle,
          messageCeProtocol ? messageCeProtocol.entityListView.fullHeading : null,
          'message',
          BaseDetailType.pushedDetail,
          messageCore,
          EditableType.always,
          formFields.message
        )
        if (entity.message) {
          this.message.ceProtocol = messageCeProtocol;
          this.message.cachedListEntry = messageCore.makeListEntry(parentEntityViewSpec, entity.message, 0)
          messageCore.selectedEntity = entity.message;
        }
        this.message.singleFieldSelect = true
    }
}