import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOMessageService} from '../../core/message/eo-message';
import {SOGetMessage} from '../../core/message/so-get-message';
import {RAPutMessage} from './ra-put-message';
import {RAPostMessage} from './ra-post-message';
import {RADeleteMessage} from './ra-delete-message';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEMessage } from "./ce-message"


export class MessageCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'message';
  title = 'Message';
  ceProtocol: CEConstructor = CEMessage

  entityService: EOMessageService;
  soGetClass = SOGetMessage;
  soGet: SOGetMessage;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOMessageService,
      null,
    );
    this.adapters = [
      new RAPostMessage(this),
      new RAPutMessage(this),
      new RADeleteMessage(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetMessage();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}