import { Type } from 'class-transformer';
import {SORespView} from './so-resp-view';
import {PluralProtocol} from 'lionheartland';
import {View} from "../../dexie/db";

export class SOPluralView implements PluralProtocol {
  @Type(() => SORespView)
  objects: SORespView[] = []
  nextOffset: number = null

    toViews(): View[] {
        return this.objects.map(view => view.toView());
    }
}