import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from 'lionheartland';
import {Certificate} from "../../dexie/db";

export class SORespCertificate implements ObjectiveProtocol {

  entityType: string = 'certificate';
  uid: string;
  @Type(() => SORespUser)
  user: SORespUser;
  @Type(() => Date)
  dateCreated: Date;

    toCertificate(): Certificate {
        return {
            uid: this.uid,
            userUid: this.user ? this.user.uid : null,
            dateCreated: this.dateCreated,
            credential: null,
            tokensUid: null,
}

        };
}