import {SORespRealization} from '../../core/realization/so-resp-realization';
import {RealizationCore} from './realization.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRealizationFormFields} from './put-realization-form-fields';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {SOGetProcess} from '../../core/process/so-get-process';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERealizationAttributesBase {

    name: DetailEntry
    description: DetailEntry
    entity: DetailEntry
    simpleAttribute: DetailEntry
    relationshipAttribute: DetailEntry
    requirement: DetailEntry
    process: DetailEntry
    attributeSpec: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRealization,
        app: CommanderApp,
        formFields: PutRealizationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const entityCore = app.makeCore( 'entity');
        entityCore.soGet = SOGetEntity.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityCore.soGet.queryOptions.descending = true;
        let entityCeProtocol;
        if (entity.entity) {
            entityCeProtocol = createCEProtocol(
                entityCore.ceProtocol,
                parentEntityViewSpec,
                entityCore,
                entity.entity,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityCore
        )
        entityCore.title = heading ? heading : entityCore.title
        entityCore.listTitle = 'Entity'

        if (entity.entity) {
            displayStringFactory.reconfigure(
                entity.entity,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        entityCore.configureForDisplay(parentEntityViewSpec)

        this.entity = new DetailEntry(
          heading ? heading : entityCore.listTitle,
          entityCeProtocol ? entityCeProtocol.entityListView.fullHeading : null,
          'entity',
          BaseDetailType.pushedDetail,
          entityCore,
          EditableType.always,
          formFields.entity
        )
        if (entity.entity) {
          this.entity.ceProtocol = entityCeProtocol;
          this.entity.cachedListEntry = entityCore.makeListEntry(parentEntityViewSpec, entity.entity, 0)
          entityCore.selectedEntity = entity.entity;
        }
        this.entity.singleFieldSelect = true

        const simpleAttributeCore = app.makeCore( 'simpleAttribute');
        simpleAttributeCore.soGet = SOGetSimpleAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributeCore.soGet.queryOptions.descending = true;
        let simpleAttributeCeProtocol;
        if (entity.simpleAttribute) {
            simpleAttributeCeProtocol = createCEProtocol(
                simpleAttributeCore.ceProtocol,
                parentEntityViewSpec,
                simpleAttributeCore,
                entity.simpleAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributeCore
        )
        simpleAttributeCore.title = heading ? heading : simpleAttributeCore.title
        simpleAttributeCore.listTitle = 'Simple attribute'

        if (entity.simpleAttribute) {
            displayStringFactory.reconfigure(
                entity.simpleAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        simpleAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttribute = new DetailEntry(
          heading ? heading : simpleAttributeCore.listTitle,
          simpleAttributeCeProtocol ? simpleAttributeCeProtocol.entityListView.fullHeading : null,
          'simpleAttribute',
          BaseDetailType.pushedDetail,
          simpleAttributeCore,
          EditableType.always,
          formFields.simpleAttribute
        )
        if (entity.simpleAttribute) {
          this.simpleAttribute.ceProtocol = simpleAttributeCeProtocol;
          this.simpleAttribute.cachedListEntry = simpleAttributeCore.makeListEntry(parentEntityViewSpec, entity.simpleAttribute, 0)
          simpleAttributeCore.selectedEntity = entity.simpleAttribute;
        }
        this.simpleAttribute.singleFieldSelect = true

        const relationshipAttributeCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributeCore.soGet = SOGetRelationshipAttribute.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributeCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributeCore.soGet.queryOptions.descending = true;
        let relationshipAttributeCeProtocol;
        if (entity.relationshipAttribute) {
            relationshipAttributeCeProtocol = createCEProtocol(
                relationshipAttributeCore.ceProtocol,
                parentEntityViewSpec,
                relationshipAttributeCore,
                entity.relationshipAttribute,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributeCore
        )
        relationshipAttributeCore.title = heading ? heading : relationshipAttributeCore.title
        relationshipAttributeCore.listTitle = 'Relationship attribute'

        if (entity.relationshipAttribute) {
            displayStringFactory.reconfigure(
                entity.relationshipAttribute,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        relationshipAttributeCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttribute = new DetailEntry(
          heading ? heading : relationshipAttributeCore.listTitle,
          relationshipAttributeCeProtocol ? relationshipAttributeCeProtocol.entityListView.fullHeading : null,
          'relationshipAttribute',
          BaseDetailType.pushedDetail,
          relationshipAttributeCore,
          EditableType.always,
          formFields.relationshipAttribute
        )
        if (entity.relationshipAttribute) {
          this.relationshipAttribute.ceProtocol = relationshipAttributeCeProtocol;
          this.relationshipAttribute.cachedListEntry = relationshipAttributeCore.makeListEntry(parentEntityViewSpec, entity.relationshipAttribute, 0)
          relationshipAttributeCore.selectedEntity = entity.relationshipAttribute;
        }
        this.relationshipAttribute.singleFieldSelect = true

        const requirementCore = app.makeCore( 'requirement');
        requirementCore.soGet = SOGetRequirement.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        requirementCore.soGet.queryOptions.sortKey = 'dateCreated';
        requirementCore.soGet.queryOptions.descending = true;
        let requirementCeProtocol;
        if (entity.requirement) {
            requirementCeProtocol = createCEProtocol(
                requirementCore.ceProtocol,
                parentEntityViewSpec,
                requirementCore,
                entity.requirement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            requirementCore
        )
        requirementCore.title = heading ? heading : requirementCore.title
        requirementCore.listTitle = 'Requirement'

        if (entity.requirement) {
            displayStringFactory.reconfigure(
                entity.requirement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        requirementCore.configureForDisplay(parentEntityViewSpec)

        this.requirement = new DetailEntry(
          heading ? heading : requirementCore.listTitle,
          requirementCeProtocol ? requirementCeProtocol.entityListView.fullHeading : null,
          'requirement',
          BaseDetailType.pushedDetail,
          requirementCore,
          EditableType.always,
          formFields.requirement
        )
        if (entity.requirement) {
          this.requirement.ceProtocol = requirementCeProtocol;
          this.requirement.cachedListEntry = requirementCore.makeListEntry(parentEntityViewSpec, entity.requirement, 0)
          requirementCore.selectedEntity = entity.requirement;
        }
        this.requirement.singleFieldSelect = true

        const processCore = app.makeCore( 'process');
        processCore.soGet = SOGetProcess.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processCore.soGet.queryOptions.sortKey = 'dateCreated';
        processCore.soGet.queryOptions.descending = true;
        let processCeProtocol;
        if (entity.process) {
            processCeProtocol = createCEProtocol(
                processCore.ceProtocol,
                parentEntityViewSpec,
                processCore,
                entity.process,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processCore
        )
        processCore.title = heading ? heading : processCore.title
        processCore.listTitle = 'Process'

        if (entity.process) {
            displayStringFactory.reconfigure(
                entity.process,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        processCore.configureForDisplay(parentEntityViewSpec)

        this.process = new DetailEntry(
          heading ? heading : processCore.listTitle,
          processCeProtocol ? processCeProtocol.entityListView.fullHeading : null,
          'process',
          BaseDetailType.pushedDetail,
          processCore,
          EditableType.always,
          formFields.process
        )
        if (entity.process) {
          this.process.ceProtocol = processCeProtocol;
          this.process.cachedListEntry = processCore.makeListEntry(parentEntityViewSpec, entity.process, 0)
          processCore.selectedEntity = entity.process;
        }
        this.process.singleFieldSelect = true

        const attributeSpecCore = app.makeCore( 'attributeSpec');
        attributeSpecCore.soGet = SOGetAttributeSpec.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        attributeSpecCore.soGet.queryOptions.sortKey = 'dateCreated';
        attributeSpecCore.soGet.queryOptions.descending = true;
        let attributeSpecCeProtocol;
        if (entity.attributeSpec) {
            attributeSpecCeProtocol = createCEProtocol(
                attributeSpecCore.ceProtocol,
                parentEntityViewSpec,
                attributeSpecCore,
                entity.attributeSpec,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attributeSpecCore
        )
        attributeSpecCore.title = heading ? heading : attributeSpecCore.title
        attributeSpecCore.listTitle = 'Attribute spec'

        if (entity.attributeSpec) {
            displayStringFactory.reconfigure(
                entity.attributeSpec,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        attributeSpecCore.configureForDisplay(parentEntityViewSpec)

        this.attributeSpec = new DetailEntry(
          heading ? heading : attributeSpecCore.listTitle,
          attributeSpecCeProtocol ? attributeSpecCeProtocol.entityListView.fullHeading : null,
          'attributeSpec',
          BaseDetailType.pushedDetail,
          attributeSpecCore,
          EditableType.always,
          formFields.attributeSpec
        )
        if (entity.attributeSpec) {
          this.attributeSpec.ceProtocol = attributeSpecCeProtocol;
          this.attributeSpec.cachedListEntry = attributeSpecCore.makeListEntry(parentEntityViewSpec, entity.attributeSpec, 0)
          attributeSpecCore.selectedEntity = entity.attributeSpec;
        }
        this.attributeSpec.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}