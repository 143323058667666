import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutOrganization } from './so-put-organization';
import { SOGetOrganization } from './so-get-organization';
import { SOPostOrganization } from './so-post-organization';
import { SODeleteOrganization } from './so-delete-organization';
import {SORespOrganization} from './so-resp-organization';
import {SOPluralOrganization} from './so-plural-organization';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOOrganizationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('organization', entityCore);
  }

  /** GET Organization by id. Will 404 if id not found */
  get(request: SOGetOrganization): Observable<SOPluralOrganization> {
    return this.httpClient.get<SOPluralOrganization>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralOrganization, resp)),
      tap(async (response: SOPluralOrganization) => {
        this.log(`fetched Organization`);
        // After a successful server response, update IndexedDB
        const activities = response.toOrganizations(); // Convert your response to an array of Organization instances
        const promises = activities.map(organization => db.organizations.put(organization));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralOrganization>(`get Organization`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralOrganization> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.organizations.toArray()).pipe(
                map((organizationsArray) => {
                    // Convert the array of plain objects to an array of SORespOrganization instances
                    const sorOrganizations = organizationsArray.map(req => plainToClass(SORespOrganization, req));
                    // Create and return a new instance of SOPluralOrganization
                    const pluralOrganization = new SOPluralOrganization();
                    pluralOrganization.objects = sorOrganizations;
                    pluralOrganization.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralOrganization;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processOrganizations(organizationsArray) {
        const sorOrganizations = organizationsArray.map(req => plainToClass(SORespOrganization, req));
        const pluralOrganization = new SOPluralOrganization();
        pluralOrganization.objects = sorOrganizations;
        pluralOrganization.nextOffset = null;
        return pluralOrganization;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostOrganization): Observable<SORespOrganization> {
  return this.httpClient.post<SORespOrganization>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespOrganization, resp)),

    tap(async (response: SORespOrganization) => {
      this.log(`added Organization w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.organizations.add(response.toOrganization());
    }),
    catchError(this.handleError<SORespOrganization>('Organization post'))
  );
}


  put(request: SOPutOrganization): Observable<SORespOrganization> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespOrganization>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespOrganization, resp)),
      tap(async (response: SORespOrganization) => {
        this.log(`edited Organization w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.organizations.put(response.toOrganization());
      }),
      catchError(this.handleError<SORespOrganization>('Organization put'))
    );
  }


  delete(request: SODeleteOrganization): Observable<SORespOrganization> {
    return this.httpClient.delete<SORespOrganization>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Organization uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.organizations.delete(request.uid);
      }),
      catchError(this.handleError<SORespOrganization>('Organization delete'))
    );
  }


}
