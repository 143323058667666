import { Type } from 'class-transformer';
import {SORespViewGrant} from './so-resp-view-grant';
import {PluralProtocol} from 'lionheartland';
import {ViewGrant} from "../../dexie/db";

export class SOPluralViewGrant implements PluralProtocol {
  @Type(() => SORespViewGrant)
  objects: SORespViewGrant[] = []
  nextOffset: number = null

    toViewGrants(): ViewGrant[] {
        return this.objects.map(viewGrant => viewGrant.toViewGrant());
    }
}