import {SORespInvoice} from '../../core/invoice/so-resp-invoice';
import {InvoiceCore} from './invoice.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutInvoiceFormFields} from './put-invoice-form-fields';
import { CEInvoiceAttributes } from './ce-invoice-attributes'


export class CEInvoiceBase extends CEBase {

    public details: CEInvoiceAttributes;
    public fromFields: PutInvoiceFormFields = new PutInvoiceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: InvoiceCore,
        public entity: SORespInvoice,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEInvoiceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.ref)
        this.attributes.push(this.details.exRef)
        this.attributes.push(this.details.amountDue)
        this.attributes.push(this.details.amountPaid)
        this.attributes.push(this.details.amountRemaining)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.dateDue)
        this.attributes.push(this.details.periodStart)
        this.attributes.push(this.details.periodEnd)
        this.attributes.push(this.details.paid)
        this.attributes.push(this.details.subscription)
        this.attributes.push(this.details.paymentIntents)
        this.attributes.push(this.details.invoiceLines)

    }
}