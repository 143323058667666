import {SOPostAlertSubscription} from '../../core/alert-subscription/so-post-alert-subscription';
import {AlertSubscriptionCore} from './alert-subscription.core';
import {SOGetAlertSubscription} from '../../core/alert-subscription/so-get-alert-subscription';
import {FormField, SelectionOption} from 'lionheartland';
import {postAlertSubscriptionFormFieldOptions} from './post-alert-subscription-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TopicCore} from '../topic/topic.core';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {DatafeedCore} from '../datafeed/datafeed.core';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAlertSubscription extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAlertSubscriptionFormFieldOptions);

  RequestClass = SOPostAlertSubscription

  constructor(
    public entityCore: AlertSubscriptionCore
  ) {
    super();
    if (postAlertSubscriptionFormFieldOptions.topic) {
      postAlertSubscriptionFormFieldOptions.topic.makeCore = true;
    }
    if (postAlertSubscriptionFormFieldOptions.datafeed) {
      postAlertSubscriptionFormFieldOptions.datafeed.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'topic',
        SOGetTopic,
      ),
      this.getFormValueFromIdentifier(
        'datafeed',
        SOGetDatafeed,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}