import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSesMessageType} from '../../enums';

export class PostSesMessageFormFields {

    public data: FormField
    public messageType: FormField

    constructor() {

        this.data = new FormField(
          'data',
          'Data',
          null,
          FormFieldType.text,
          true,
        )
        this.messageType = new FormField(
          'messageType',
          'Message type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.messageType.enumeration = ENSesMessageType

    }
}

const fields = new PostSesMessageFormFields()

export const postSesMessageFormFieldOptions: {[key: string]: FormField} = {
  data: fields.data,
  messageType: fields.messageType,
};