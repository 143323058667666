import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetRelationshipAttributePermission } from '../relationship-attribute-permission/so-get-relationship-attribute-permission';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENAttributePermissionType} from '../../enums';


export class SOPostRelationshipAttributePermissionRule {

  constructor(
    public name: string,
    public whiteList: boolean,
    public permissionType: ENAttributePermissionType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public relationshipAttribute: SOGetRelationshipAttribute = null,
    public relationshipAttributePermission: SOGetRelationshipAttributePermission = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipAttributePermissionRule(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'whiteList' in params ? params.whiteList : null,
      'permissionType' in params ? params.permissionType : null,
      'relationshipAttribute' in params ? params.relationshipAttribute : null,
      'relationshipAttributePermission' in params ? params.relationshipAttributePermission : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
