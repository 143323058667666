import {SOPostPlan} from '../../core/plan/so-post-plan';
import {PlanCore} from './plan.core';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {FormField, SelectionOption} from 'lionheartland';
import {postPlanFormFieldOptions} from './post-plan-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {PeriodCore} from '../period/period.core';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostPlan extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postPlanFormFieldOptions);

  RequestClass = SOPostPlan

  constructor(
    public entityCore: PlanCore
  ) {
    super();
    if (postPlanFormFieldOptions.period) {
      postPlanFormFieldOptions.period.makeCore = true;
    }
    if (postPlanFormFieldOptions.project) {
      postPlanFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'period',
        SOGetPeriod,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}