import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProcessStepAssociationService} from '../../core/process-step-association/eo-process-step-association';
import {SOGetProcessStepAssociation} from '../../core/process-step-association/so-get-process-step-association';
import {RAPutProcessStepAssociation} from './ra-put-process-step-association';
import {RAPostProcessStepAssociation} from './ra-post-process-step-association';
import {RADeleteProcessStepAssociation} from './ra-delete-process-step-association';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEProcessStepAssociation } from "./ce-process-step-association"


export class ProcessStepAssociationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'processStepAssociation';
  title = 'Process step association';
  ceProtocol: CEConstructor = CEProcessStepAssociation

  entityService: EOProcessStepAssociationService;
  soGetClass = SOGetProcessStepAssociation;
  soGet: SOGetProcessStepAssociation;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProcessStepAssociationService,
      null,
    );
    this.adapters = [
      new RAPostProcessStepAssociation(this),
      new RAPutProcessStepAssociation(this),
      new RADeleteProcessStepAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProcessStepAssociation();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}