import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostBacklogFormFields {

    public name: FormField
    public description: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostBacklogFormFields()

export const postBacklogFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  project: fields.project,
};