import {SORespSimpleAttribute} from '../../core/simple-attribute/so-resp-simple-attribute';
import {SimpleAttributeCore} from './simple-attribute.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSimpleAttributeFormFields} from './put-simple-attribute-form-fields';
import { CESimpleAttributeAttributes } from './ce-simple-attribute-attributes'


export class CESimpleAttributeBase extends CEBase {

    public details: CESimpleAttributeAttributes;
    public fromFields: PutSimpleAttributeFormFields = new PutSimpleAttributeFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SimpleAttributeCore,
        public entity: SORespSimpleAttribute,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESimpleAttributeAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.attributeType)
        this.attributes.push(this.details.editPermissions)
        this.attributes.push(this.details.simpleAttributePermissions)
        this.attributes.push(this.details.simpleAttributePermissionRules)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.entity)
        this.attributes.push(this.details.customLogicRequirement)
        this.attributes.push(this.details.attributeSpecs)
        this.attributes.push(this.details.project)

    }
}