import { Type } from 'class-transformer';
import {SORespTopic} from './so-resp-topic';
import {PluralProtocol} from 'lionheartland';
import {Topic} from "../../dexie/db";

export class SOPluralTopic implements PluralProtocol {
  @Type(() => SORespTopic)
  objects: SORespTopic[] = []
  nextOffset: number = null

    toTopics(): Topic[] {
        return this.objects.map(topic => topic.toTopic());
    }
}