import { Type } from 'class-transformer';
import { SORespProduct } from '../product/so-resp-product';
import {ENInterval} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Price} from "../../dexie/db";

export class SORespPrice implements ObjectiveProtocol {

  entityType: string = 'price';
  uid: string;
  name: string;
  sku: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  exRef: string = null;
  amount: number;
  trailDays: number = null;
  interval: ENInterval;
  active: boolean = true;
  @Type(() => SORespProduct)
  product: SORespProduct;

    toPrice(): Price {
        return {
            uid: this.uid,
            name: this.name,
            sku: this.sku,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            exRef: null,
            amount: this.amount,
            trailDays: null,
            interval: this.interval,
            active: this.active,
            productUid: this.product ? this.product.uid : null,
            subscriptionsUid: null,
            invoiceLinesUid: null,
}

        };
}