import { Type } from 'class-transformer';
import { SORespRole } from '../role/so-resp-role';
import { SORespView } from '../view/so-resp-view';
import {ObjectiveProtocol} from 'lionheartland';
import {ViewGrant} from "../../dexie/db";

export class SORespViewGrant implements ObjectiveProtocol {

  entityType: string = 'viewGrant';
  uid: string;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespView)
  view: SORespView;

    toViewGrant(): ViewGrant {
        return {
            uid: this.uid,
            roleUid: this.role ? this.role.uid : null,
            viewUid: this.view ? this.view.uid : null,
}

        };
}