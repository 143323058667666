import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostMessageFormFields {

    public name: FormField
    public description: FormField
    public topic: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.topic = new FormField(
          'topic',
          'Topic',
          'topic',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostMessageFormFields()

export const postMessageFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  topic: fields.topic,
};