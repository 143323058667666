import { Type } from 'class-transformer';
import { SORespProcess } from '../process/so-resp-process';
import { SORespStep } from '../step/so-resp-step';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENProcessStepType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {ProcessStepAssociation} from "../../dexie/db";

export class SORespProcessStepAssociation implements ObjectiveProtocol {

  entityType: string = 'processStepAssociation';
  uid: string;
  name: string;
  processStepType: ENProcessStepType;
  @Type(() => SORespProcess)
  process: SORespProcess = null;
  @Type(() => SORespStep)
  step: SORespStep = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toProcessStepAssociation(): ProcessStepAssociation {
        return {
            uid: this.uid,
            name: this.name,
            processStepType: this.processStepType,
            dateCreated: null,
            processUid: this.process ? this.process.uid : null,
            stepUid: this.step ? this.step.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}