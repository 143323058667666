import { Type } from 'class-transformer';
import {SORespBacklog} from './so-resp-backlog';
import {PluralProtocol} from 'lionheartland';
import {Backlog} from "../../dexie/db";

export class SOPluralBacklog implements PluralProtocol {
  @Type(() => SORespBacklog)
  objects: SORespBacklog[] = []
  nextOffset: number = null

    toBacklogs(): Backlog[] {
        return this.objects.map(backlog => backlog.toBacklog());
    }
}