import {SORespSimpleAttribute} from '../../core/simple-attribute/so-resp-simple-attribute';
import {SimpleAttributeCore} from './simple-attribute.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSimpleAttributeFormFields} from './put-simple-attribute-form-fields';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CESimpleAttributeAttributesBase {

    name: DetailEntry
    description: DetailEntry
    attributeType: DetailEntry
    editPermissions: DetailEntry
    simpleAttributePermissions: DetailEntry
    simpleAttributePermissionRules: DetailEntry
    realizations: DetailEntry
    entity: DetailEntry
    customLogicRequirement: DetailEntry
    attributeSpecs: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSimpleAttribute,
        app: CommanderApp,
        formFields: PutSimpleAttributeFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.attributeType = new DetailEntry(
            'Attribute type',
            entity.attributeType,
            'attributeType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.attributeType
        )

        this.editPermissions = new DetailEntry(
            'Edit permissions',
            entity.editPermissions,
            'editPermissions',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.editPermissions
        )


        const simpleAttributePermissionsCore = app.makeCore( 'simpleAttributePermission');
        simpleAttributePermissionsCore.soGet = SOGetSimpleAttributePermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionsCore
        )
        simpleAttributePermissionsCore.title = heading ? heading : simpleAttributePermissionsCore.title
        simpleAttributePermissionsCore.listTitle = 'Simple attribute permissions'

        simpleAttributePermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermissions = new DetailEntry(
          heading ? heading : simpleAttributePermissionsCore.listTitle,
          null,
          'simpleAttributePermissions',
          BaseDetailType.embeddedList,
          simpleAttributePermissionsCore,
          EditableType.never
        )

        const simpleAttributePermissionRulesCore = app.makeCore( 'simpleAttributePermissionRule');
        simpleAttributePermissionRulesCore.soGet = SOGetSimpleAttributePermissionRule.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionRulesCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionRulesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionRulesCore
        )
        simpleAttributePermissionRulesCore.title = heading ? heading : simpleAttributePermissionRulesCore.title
        simpleAttributePermissionRulesCore.listTitle = 'Simple attribute permission rules'

        simpleAttributePermissionRulesCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermissionRules = new DetailEntry(
          heading ? heading : simpleAttributePermissionRulesCore.listTitle,
          null,
          'simpleAttributePermissionRules',
          BaseDetailType.embeddedList,
          simpleAttributePermissionRulesCore,
          EditableType.always,
          formFields.simpleAttributePermissionRules
        )

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const entityCore = app.makeCore( 'entity');
        entityCore.soGet = SOGetEntity.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityCore.soGet.queryOptions.descending = true;
        let entityCeProtocol;
        if (entity.entity) {
            entityCeProtocol = createCEProtocol(
                entityCore.ceProtocol,
                parentEntityViewSpec,
                entityCore,
                entity.entity,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityCore
        )
        entityCore.title = heading ? heading : entityCore.title
        entityCore.listTitle = 'Entity'

        if (entity.entity) {
            displayStringFactory.reconfigure(
                entity.entity,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        entityCore.configureForDisplay(parentEntityViewSpec)

        this.entity = new DetailEntry(
          heading ? heading : entityCore.listTitle,
          entityCeProtocol ? entityCeProtocol.entityListView.fullHeading : null,
          'entity',
          BaseDetailType.pushedDetail,
          entityCore,
          EditableType.always,
          formFields.entity
        )
        if (entity.entity) {
          this.entity.ceProtocol = entityCeProtocol;
          this.entity.cachedListEntry = entityCore.makeListEntry(parentEntityViewSpec, entity.entity, 0)
          entityCore.selectedEntity = entity.entity;
        }
        this.entity.singleFieldSelect = true

        const customLogicRequirementCore = app.makeCore( 'customLogicRequirement');
        customLogicRequirementCore.soGet = SOGetCustomLogicRequirement.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        customLogicRequirementCore.soGet.queryOptions.sortKey = 'dateCreated';
        customLogicRequirementCore.soGet.queryOptions.descending = true;
        let customLogicRequirementCeProtocol;
        if (entity.customLogicRequirement) {
            customLogicRequirementCeProtocol = createCEProtocol(
                customLogicRequirementCore.ceProtocol,
                parentEntityViewSpec,
                customLogicRequirementCore,
                entity.customLogicRequirement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customLogicRequirementCore
        )
        customLogicRequirementCore.title = heading ? heading : customLogicRequirementCore.title
        customLogicRequirementCore.listTitle = 'Custom logic requirement'

        if (entity.customLogicRequirement) {
            displayStringFactory.reconfigure(
                entity.customLogicRequirement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        customLogicRequirementCore.configureForDisplay(parentEntityViewSpec)

        this.customLogicRequirement = new DetailEntry(
          heading ? heading : customLogicRequirementCore.listTitle,
          customLogicRequirementCeProtocol ? customLogicRequirementCeProtocol.entityListView.fullHeading : null,
          'customLogicRequirement',
          BaseDetailType.pushedDetail,
          customLogicRequirementCore,
          EditableType.always,
          formFields.customLogicRequirement
        )
        if (entity.customLogicRequirement) {
          this.customLogicRequirement.ceProtocol = customLogicRequirementCeProtocol;
          this.customLogicRequirement.cachedListEntry = customLogicRequirementCore.makeListEntry(parentEntityViewSpec, entity.customLogicRequirement, 0)
          customLogicRequirementCore.selectedEntity = entity.customLogicRequirement;
        }
        this.customLogicRequirement.singleFieldSelect = true

        const attributeSpecsCore = app.makeCore( 'attributeSpec');
        attributeSpecsCore.soGet = SOGetAttributeSpec.construct({
          simpleAttribute: new SOGetSimpleAttribute(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        attributeSpecsCore.soGet.queryOptions.sortKey = 'dateCreated';
        attributeSpecsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attributeSpecsCore
        )
        attributeSpecsCore.title = heading ? heading : attributeSpecsCore.title
        attributeSpecsCore.listTitle = 'Attribute specs'

        attributeSpecsCore.configureForDisplay(parentEntityViewSpec)

        this.attributeSpecs = new DetailEntry(
          heading ? heading : attributeSpecsCore.listTitle,
          null,
          'attributeSpecs',
          BaseDetailType.embeddedList,
          attributeSpecsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}