import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetRequirement } from '../requirement/so-get-requirement';
import { SOGetProcess } from '../process/so-get-process';
import { SOGetAttributeSpec } from '../attribute-spec/so-get-attribute-spec';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetRealization implements QueryProtocol {

  constructor(
    public uid: string = null,
    public name: string = null,
    public entity: SOGetEntity = null,
    public requirement: SOGetRequirement = null,
    public process: SOGetProcess = null,
    public attributeSpec: SOGetAttributeSpec = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetRealization(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'entity' in params ? params.entity : null,
      'requirement' in params ? params.requirement : null,
      'process' in params ? params.process : null,
      'attributeSpec' in params ? params.attributeSpec : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
