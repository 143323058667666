import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRolePermission } from './so-put-role-permission';
import { SOGetRolePermission } from './so-get-role-permission';
import { SOPostRolePermission } from './so-post-role-permission';
import { SODeleteRolePermission } from './so-delete-role-permission';
import {SORespRolePermission} from './so-resp-role-permission';
import {SOPluralRolePermission} from './so-plural-role-permission';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORolePermissionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('role_permission', entityCore);
  }

  /** GET RolePermission by id. Will 404 if id not found */
  get(request: SOGetRolePermission): Observable<SOPluralRolePermission> {
    return this.httpClient.get<SOPluralRolePermission>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRolePermission, resp)),
      tap(async (response: SOPluralRolePermission) => {
        this.log(`fetched RolePermission`);
        // After a successful server response, update IndexedDB
        const activities = response.toRolePermissions(); // Convert your response to an array of RolePermission instances
        const promises = activities.map(rolePermission => db.rolePermissions.put(rolePermission));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRolePermission>(`get RolePermission`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRolePermission> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'rolePermission' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.rolePermissions.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "entityPermission" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.rolePermissions.where('entityPermissionUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationshipAttributePermission" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.rolePermissions.where('relationshipAttributePermissionUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "simpleAttributePermission" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.rolePermissions.where('simpleAttributePermissionUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "role" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.rolePermissions.where('roleUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.rolePermissions.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.rolePermissions.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.rolePermissions.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.rolePermissions.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(rolePermissionsArray => this.processRolePermissions(rolePermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.rolePermissions.toArray()).pipe(
                map((rolePermissionsArray) => {
                    // Convert the array of plain objects to an array of SORespRolePermission instances
                    const sorRolePermissions = rolePermissionsArray.map(req => plainToClass(SORespRolePermission, req));
                    // Create and return a new instance of SOPluralRolePermission
                    const pluralRolePermission = new SOPluralRolePermission();
                    pluralRolePermission.objects = sorRolePermissions;
                    pluralRolePermission.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRolePermission;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRolePermissions(rolePermissionsArray) {
        const sorRolePermissions = rolePermissionsArray.map(req => plainToClass(SORespRolePermission, req));
        const pluralRolePermission = new SOPluralRolePermission();
        pluralRolePermission.objects = sorRolePermissions;
        pluralRolePermission.nextOffset = null;
        return pluralRolePermission;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRolePermission): Observable<SORespRolePermission> {
  return this.httpClient.post<SORespRolePermission>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRolePermission, resp)),

    tap(async (response: SORespRolePermission) => {
      this.log(`added RolePermission w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.rolePermissions.add(response.toRolePermission());
    }),
    catchError(this.handleError<SORespRolePermission>('RolePermission post'))
  );
}


  put(request: SOPutRolePermission): Observable<SORespRolePermission> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRolePermission>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRolePermission, resp)),
      tap(async (response: SORespRolePermission) => {
        this.log(`edited RolePermission w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.rolePermissions.put(response.toRolePermission());
      }),
      catchError(this.handleError<SORespRolePermission>('RolePermission put'))
    );
  }


  delete(request: SODeleteRolePermission): Observable<SORespRolePermission> {
    return this.httpClient.delete<SORespRolePermission>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted RolePermission uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.rolePermissions.delete(request.uid);
      }),
      catchError(this.handleError<SORespRolePermission>('RolePermission delete'))
    );
  }


}
