import {SORespViewGrant} from '../../core/view-grant/so-resp-view-grant';
import {ViewGrantCore} from './view-grant.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutViewGrantFormFields} from './put-view-grant-form-fields';
import { CEViewGrantAttributes } from './ce-view-grant-attributes'


export class CEViewGrantBase extends CEBase {

    public details: CEViewGrantAttributes;
    public fromFields: PutViewGrantFormFields = new PutViewGrantFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ViewGrantCore,
        public entity: SORespViewGrant,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEViewGrantAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.view)

    }
}