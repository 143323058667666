import {SORespAppliedConstraint} from '../../core/applied-constraint/so-resp-applied-constraint';
import {AppliedConstraintCore} from './applied-constraint.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAppliedConstraintFormFields} from './put-applied-constraint-form-fields';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {SOGetProduct} from '../../core/product/so-get-product';


export class CEAppliedConstraintAttributesBase {

    constraint: DetailEntry
    product: DetailEntry
    quantity: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAppliedConstraint,
        app: CommanderApp,
        formFields: PutAppliedConstraintFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )


        const constraintCore = app.makeCore( 'constraint');
        constraintCore.soGet = SOGetConstraint.construct({
        });
        let constraintCeProtocol;
        if (entity.constraint) {
            constraintCeProtocol = createCEProtocol(
                constraintCore.ceProtocol,
                parentEntityViewSpec,
                constraintCore,
                entity.constraint,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            constraintCore
        )
        constraintCore.title = heading ? heading : constraintCore.title
        constraintCore.listTitle = 'Constraint'

        if (entity.constraint) {
            displayStringFactory.reconfigure(
                entity.constraint,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        constraintCore.configureForDisplay(parentEntityViewSpec)

        this.constraint = new DetailEntry(
          heading ? heading : constraintCore.listTitle,
          constraintCeProtocol ? constraintCeProtocol.entityListView.fullHeading : null,
          'constraint',
          BaseDetailType.pushedDetail,
          constraintCore,
          EditableType.never
        )
        if (entity.constraint) {
          this.constraint.ceProtocol = constraintCeProtocol;
          this.constraint.cachedListEntry = constraintCore.makeListEntry(parentEntityViewSpec, entity.constraint, 0)
          constraintCore.selectedEntity = entity.constraint;
        }
        this.constraint.singleFieldSelect = true

        const productCore = app.makeCore( 'product');
        productCore.soGet = SOGetProduct.construct({
        });
        let productCeProtocol;
        if (entity.product) {
            productCeProtocol = createCEProtocol(
                productCore.ceProtocol,
                parentEntityViewSpec,
                productCore,
                entity.product,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productCore
        )
        productCore.title = heading ? heading : productCore.title
        productCore.listTitle = 'Product'

        if (entity.product) {
            displayStringFactory.reconfigure(
                entity.product,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        productCore.configureForDisplay(parentEntityViewSpec)

        this.product = new DetailEntry(
          heading ? heading : productCore.listTitle,
          productCeProtocol ? productCeProtocol.entityListView.fullHeading : null,
          'product',
          BaseDetailType.pushedDetail,
          productCore,
          EditableType.never
        )
        if (entity.product) {
          this.product.ceProtocol = productCeProtocol;
          this.product.cachedListEntry = productCore.makeListEntry(parentEntityViewSpec, entity.product, 0)
          productCore.selectedEntity = entity.product;
        }
        this.product.singleFieldSelect = true
    }
}