import {SORespPeriod} from '../../core/period/so-resp-period';
import {PeriodCore} from './period.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPeriodFormFields} from './put-period-form-fields';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {SOGetWork} from '../../core/work/so-get-work';
import {SOGetReport} from '../../core/report/so-get-report';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEPeriodAttributesBase {

    name: DetailEntry
    numeral: DetailEntry
    type: DetailEntry
    plans: DetailEntry
    works: DetailEntry
    reports: DetailEntry
    periods: DetailEntry
    parent: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPeriod,
        app: CommanderApp,
        formFields: PutPeriodFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.numeral = new DetailEntry(
            'Numeral',
            entity.numeral,
            'numeral',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.numeral
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.type
        )


        const plansCore = app.makeCore( 'plan');
        plansCore.soGet = SOGetPlan.construct({
          period: new SOGetPeriod(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        plansCore.soGet.queryOptions.sortKey = 'dateCreated';
        plansCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            plansCore
        )
        plansCore.title = heading ? heading : plansCore.title
        plansCore.listTitle = 'Plans'

        plansCore.configureForDisplay(parentEntityViewSpec)

        this.plans = new DetailEntry(
          heading ? heading : plansCore.listTitle,
          null,
          'plans',
          BaseDetailType.embeddedList,
          plansCore,
          EditableType.never
        )

        const worksCore = app.makeCore( 'work');
        worksCore.soGet = SOGetWork.construct({
          period: new SOGetPeriod(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        worksCore.soGet.queryOptions.sortKey = 'dateCreated';
        worksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            worksCore
        )
        worksCore.title = heading ? heading : worksCore.title
        worksCore.listTitle = 'Works'

        worksCore.configureForDisplay(parentEntityViewSpec)

        this.works = new DetailEntry(
          heading ? heading : worksCore.listTitle,
          null,
          'works',
          BaseDetailType.embeddedList,
          worksCore,
          EditableType.never
        )

        const reportsCore = app.makeCore( 'report');
        reportsCore.soGet = SOGetReport.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        reportsCore.soGet.queryOptions.sortKey = 'dateCreated';
        reportsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reportsCore
        )
        reportsCore.title = heading ? heading : reportsCore.title
        reportsCore.listTitle = 'Reports'

        reportsCore.configureForDisplay(parentEntityViewSpec)

        this.reports = new DetailEntry(
          heading ? heading : reportsCore.listTitle,
          null,
          'reports',
          BaseDetailType.embeddedList,
          reportsCore,
          EditableType.never
        )

        const periodsCore = app.makeCore( 'period');
        periodsCore.soGet = SOGetPeriod.construct({
          parent: new SOGetPeriod(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        periodsCore.soGet.queryOptions.sortKey = 'dateCreated';
        periodsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            periodsCore
        )
        periodsCore.title = heading ? heading : periodsCore.title
        periodsCore.listTitle = 'Periods'

        periodsCore.configureForDisplay(parentEntityViewSpec)

        this.periods = new DetailEntry(
          heading ? heading : periodsCore.listTitle,
          null,
          'periods',
          BaseDetailType.embeddedList,
          periodsCore,
          EditableType.never
        )

        const parentCore = app.makeCore( 'period');
        parentCore.soGet = SOGetPeriod.construct({
          parent: new SOGetPeriod(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        parentCore.soGet.queryOptions.sortKey = 'dateCreated';
        parentCore.soGet.queryOptions.descending = true;
        let parentCeProtocol;
        if (entity.parent) {
            parentCeProtocol = createCEProtocol(
                parentCore.ceProtocol,
                parentEntityViewSpec,
                parentCore,
                entity.parent,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            parentCore
        )
        parentCore.title = heading ? heading : parentCore.title
        parentCore.listTitle = 'Parent'

        if (entity.parent) {
            displayStringFactory.reconfigure(
                entity.parent,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        parentCore.configureForDisplay(parentEntityViewSpec)

        this.parent = new DetailEntry(
          heading ? heading : parentCore.listTitle,
          parentCeProtocol ? parentCeProtocol.entityListView.fullHeading : null,
          'parent',
          BaseDetailType.pushedDetail,
          parentCore,
          EditableType.never
        )
        if (entity.parent) {
          this.parent.ceProtocol = parentCeProtocol;
          this.parent.cachedListEntry = parentCore.makeListEntry(parentEntityViewSpec, entity.parent, 0)
          parentCore.selectedEntity = entity.parent;
        }
        this.parent.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}