import {SOPutDeficiency} from '../../core/deficiency/so-put-deficiency';
import {DeficiencyCore} from './deficiency.core';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {FormField, SelectionOption} from 'lionheartland';
import {putDeficiencyFormFieldOptions} from './put-deficiency-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AssessmentCore} from '../assessment/assessment.core';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {BlockCore} from '../block/block.core';
import {SOGetBlock} from '../../core/block/so-get-block';
import {ProcessCore} from '../process/process.core';
import {SOGetProcess} from '../../core/process/so-get-process';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutDeficiency extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDeficiencyFormFieldOptions);

  RequestClass = SOPutDeficiency

  constructor(
    public entityCore: DeficiencyCore
  ) {
    super();
    if (putDeficiencyFormFieldOptions.assessment) {
      putDeficiencyFormFieldOptions.assessment.makeCore = true;
    }
    if (putDeficiencyFormFieldOptions.block) {
      putDeficiencyFormFieldOptions.block.makeCore = true;
    }
    if (putDeficiencyFormFieldOptions.process) {
      putDeficiencyFormFieldOptions.process.makeCore = true;
    }
    if (putDeficiencyFormFieldOptions.project) {
      putDeficiencyFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier('severity'),
      this.getFormValueFromIdentifier(
        'assessment',
        SOGetAssessment,
      ),
      this.getFormValueFromIdentifier(
        'block',
        SOGetBlock,
      ),
      this.getFormValueFromIdentifier(
        'process',
        SOGetProcess,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}