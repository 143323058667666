import { Type } from 'class-transformer';
import {SORespCertificate} from './so-resp-certificate';
import {PluralProtocol} from 'lionheartland';
import {Certificate} from "../../dexie/db";

export class SOPluralCertificate implements PluralProtocol {
  @Type(() => SORespCertificate)
  objects: SORespCertificate[] = []
  nextOffset: number = null

    toCertificates(): Certificate[] {
        return this.objects.map(certificate => certificate.toCertificate());
    }
}