import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutOrganizationInvite } from './so-put-organization-invite';
import { SOGetOrganizationInvite } from './so-get-organization-invite';
import { SOPostOrganizationInvite } from './so-post-organization-invite';
import { SODeleteOrganizationInvite } from './so-delete-organization-invite';
import {SORespOrganizationInvite} from './so-resp-organization-invite';
import {SOPluralOrganizationInvite} from './so-plural-organization-invite';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOOrganizationInviteService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('organization_invite', entityCore);
  }

  /** GET OrganizationInvite by id. Will 404 if id not found */
  get(request: SOGetOrganizationInvite): Observable<SOPluralOrganizationInvite> {
    return this.httpClient.get<SOPluralOrganizationInvite>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralOrganizationInvite, resp)),
      tap(async (response: SOPluralOrganizationInvite) => {
        this.log(`fetched OrganizationInvite`);
        // After a successful server response, update IndexedDB
        const activities = response.toOrganizationInvites(); // Convert your response to an array of OrganizationInvite instances
        const promises = activities.map(organizationInvite => db.organizationInvites.put(organizationInvite));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralOrganizationInvite>(`get OrganizationInvite`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralOrganizationInvite> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'organizationInvite' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.organizationInvites.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(organizationInvitesArray => this.processOrganizationInvites(organizationInvitesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "user" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.organizationInvites.where('userUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(organizationInvitesArray => this.processOrganizationInvites(organizationInvitesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "role" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.organizationInvites.where('roleUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(organizationInvitesArray => this.processOrganizationInvites(organizationInvitesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.organizationInvites.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(organizationInvitesArray => this.processOrganizationInvites(organizationInvitesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.organizationInvites.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(organizationInvitesArray => this.processOrganizationInvites(organizationInvitesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.organizationInvites.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(organizationInvitesArray => this.processOrganizationInvites(organizationInvitesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.organizationInvites.toArray()).pipe(
                map((organizationInvitesArray) => {
                    // Convert the array of plain objects to an array of SORespOrganizationInvite instances
                    const sorOrganizationInvites = organizationInvitesArray.map(req => plainToClass(SORespOrganizationInvite, req));
                    // Create and return a new instance of SOPluralOrganizationInvite
                    const pluralOrganizationInvite = new SOPluralOrganizationInvite();
                    pluralOrganizationInvite.objects = sorOrganizationInvites;
                    pluralOrganizationInvite.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralOrganizationInvite;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processOrganizationInvites(organizationInvitesArray) {
        const sorOrganizationInvites = organizationInvitesArray.map(req => plainToClass(SORespOrganizationInvite, req));
        const pluralOrganizationInvite = new SOPluralOrganizationInvite();
        pluralOrganizationInvite.objects = sorOrganizationInvites;
        pluralOrganizationInvite.nextOffset = null;
        return pluralOrganizationInvite;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostOrganizationInvite): Observable<SORespOrganizationInvite> {
  return this.httpClient.post<SORespOrganizationInvite>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespOrganizationInvite, resp)),

    tap(async (response: SORespOrganizationInvite) => {
      this.log(`added OrganizationInvite w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.organizationInvites.add(response.toOrganizationInvite());
    }),
    catchError(this.handleError<SORespOrganizationInvite>('OrganizationInvite post'))
  );
}


  put(request: SOPutOrganizationInvite): Observable<SORespOrganizationInvite> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespOrganizationInvite>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespOrganizationInvite, resp)),
      tap(async (response: SORespOrganizationInvite) => {
        this.log(`edited OrganizationInvite w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.organizationInvites.put(response.toOrganizationInvite());
      }),
      catchError(this.handleError<SORespOrganizationInvite>('OrganizationInvite put'))
    );
  }


  delete(request: SODeleteOrganizationInvite): Observable<SORespOrganizationInvite> {
    return this.httpClient.delete<SORespOrganizationInvite>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted OrganizationInvite uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.organizationInvites.delete(request.uid);
      }),
      catchError(this.handleError<SORespOrganizationInvite>('OrganizationInvite delete'))
    );
  }


}
