import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetInterphace } from '../interphace/so-get-interphace';
import { SOGetDeficiency } from '../deficiency/so-get-deficiency';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetBlock implements QueryProtocol {

  constructor(
    public uid: string = null,
    public name: string = null,
    public active: boolean = null,
    public parent: SOGetBlock = null,
    public customLogicRequirement: SOGetCustomLogicRequirement = null,
    public interphace: SOGetInterphace = null,
    public deficiencys: SOGetDeficiency[] = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetBlock(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'active' in params ? params.active : null,
      'parent' in params ? params.parent : null,
      'customLogicRequirement' in params ? params.customLogicRequirement : null,
      'interphace' in params ? params.interphace : null,
      'deficiencys' in params ? params.deficiencys : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
