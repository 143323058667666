import {ENSesMessageType} from '../../enums';


export class SOPostSesMessage {

  constructor(
    public data: string,
    public messageType: ENSesMessageType,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSesMessage(
      'data' in params ? params.data : null,
      'messageType' in params ? params.messageType : null,
    );
  }
}
