import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORequirementService} from '../../core/requirement/eo-requirement';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {RAPutRequirement} from './ra-put-requirement';
import {RAPostRequirement} from './ra-post-requirement';
import {RADeleteRequirement} from './ra-delete-requirement';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERequirement } from "./ce-requirement"


export class RequirementCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'requirement';
  title = 'Requirement';
  ceProtocol: CEConstructor = CERequirement

  entityService: EORequirementService;
  soGetClass = SOGetRequirement;
  soGet: SOGetRequirement;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORequirementService,
      null,
    );
    this.adapters = [
      new RAPostRequirement(this),
      new RAPutRequirement(this),
      new RADeleteRequirement(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRequirement();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}