import {SORespDatafeed} from '../../core/datafeed/so-resp-datafeed';
import {DatafeedCore} from './datafeed.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutDatafeedFormFields} from './put-datafeed-form-fields';
import { CEDatafeedAttributes } from './ce-datafeed-attributes'


export class CEDatafeedBase extends CEBase {

    public details: CEDatafeedAttributes;
    public fromFields: PutDatafeedFormFields = new PutDatafeedFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DatafeedCore,
        public entity: SORespDatafeed,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDatafeedAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.notifications)
        this.attributes.push(this.details.alertSubscriptions)

    }
}