import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutIdentityFormFields {

    public provider: FormField
    public user: FormField

    constructor() {

        this.provider = new FormField(
          'provider',
          'Provider',
          null,
          FormFieldType.text,
          false,
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutIdentityFormFields()

export const putIdentityFormFieldOptions: {[key: string]: FormField} = {
  provider: fields.provider,
  user: fields.user,
};