import {SORespAttachment} from '../../core/attachment/so-resp-attachment';
import {AttachmentCore} from './attachment.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAttachmentFormFields} from './put-attachment-form-fields';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEAttachmentAttributesBase {

    media: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAttachment,
        app: CommanderApp,
        formFields: PutAttachmentFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const mediaCore = app.makeCore( 'media');
        mediaCore.soGet = SOGetMedia.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        mediaCore.soGet.queryOptions.sortKey = 'dateCreated';
        mediaCore.soGet.queryOptions.descending = true;
        let mediaCeProtocol;
        if (entity.media) {
            mediaCeProtocol = createCEProtocol(
                mediaCore.ceProtocol,
                parentEntityViewSpec,
                mediaCore,
                entity.media,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            mediaCore
        )
        mediaCore.title = heading ? heading : mediaCore.title
        mediaCore.listTitle = 'Media'
        mediaCore.listSpecType = ListSpecType.thumbList

        if (entity.media) {
            displayStringFactory.reconfigure(
                entity.media,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        mediaCore.configureForDisplay(parentEntityViewSpec)

        this.media = new DetailEntry(
          heading ? heading : mediaCore.listTitle,
          mediaCeProtocol ? mediaCeProtocol.entityListView.fullHeading : null,
          'media',
          BaseDetailType.pushedDetail,
          mediaCore,
          EditableType.never
        )
        if (entity.media) {
          this.media.ceProtocol = mediaCeProtocol;
          this.media.cachedListEntry = mediaCore.makeListEntry(parentEntityViewSpec, entity.media, 0)
          mediaCore.selectedEntity = entity.media;
        }
        this.media.singleFieldSelect = true
    }
}