import {ENTokenStatus} from '../../enums';


export class SOPutToken {

  constructor(
    public uid: string,
    public status: ENTokenStatus = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutToken(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
    );
  }
}
