import { Type } from 'class-transformer';
import { SORespPerson } from '../person/so-resp-person';
import {ObjectiveProtocol} from 'lionheartland';
import {User} from "../../dexie/db";

export class SORespUser implements ObjectiveProtocol {

  entityType: string = 'user';
  uid: string;
  username: string;
  givenName: string;
  surname: string;
  email: string = null;
  emailPending: string = null;
  emailVerified: boolean = false;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespPerson)
  person: SORespPerson = null;

    toUser(): User {
        return {
            uid: this.uid,
            username: this.username,
            password: null,
            givenName: this.givenName,
            surname: this.surname,
            email: null,
            emailPending: null,
            emailVerified: this.emailVerified,
            emailVerificationToken: null,
            dateCreated: this.dateCreated,
            identityUid: null,
            certificatesUid: null,
            roleGrantsUid: null,
            invitesUid: null,
            datafeedsUid: null,
            personUid: this.person ? this.person.uid : null,
}

        };
}