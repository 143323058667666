import {SOPutProcess} from '../../core/process/so-put-process';
import {ProcessCore} from './process.core';
import {SOGetProcess} from '../../core/process/so-get-process';
import {FormField, SelectionOption} from 'lionheartland';
import {putProcessFormFieldOptions} from './put-process-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutProcess extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putProcessFormFieldOptions);

  RequestClass = SOPutProcess

  constructor(
    public entityCore: ProcessCore
  ) {
    super();
    if (putProcessFormFieldOptions.project) {
      putProcessFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}