import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENRequirementStatus} from '../../enums';


export class SOPostCustomLogicRequirement {

  constructor(
    public name: string,
    public active: boolean,
    public currentStatus: ENRequirementStatus,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public parent: SOGetCustomLogicRequirement = null,
    public simpleAttributes: SOGetSimpleAttribute[] = null,
    public relationshipAttributes: SOGetRelationshipAttribute[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCustomLogicRequirement(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'currentStatus' in params ? params.currentStatus : null,
      'parent' in params ? params.parent : null,
      'simpleAttributes' in params ? params.simpleAttributes : null,
      'relationshipAttributes' in params ? params.relationshipAttributes : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
