import {SORespAssessment} from '../../core/assessment/so-resp-assessment';
import {AssessmentCore} from './assessment.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAssessmentFormFields} from './put-assessment-form-fields';
import { CEAssessmentAttributes } from './ce-assessment-attributes'


export class CEAssessmentBase extends CEBase {

    public details: CEAssessmentAttributes;
    public fromFields: PutAssessmentFormFields = new PutAssessmentFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AssessmentCore,
        public entity: SORespAssessment,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAssessmentAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.totalRequirements)
        this.attributes.push(this.details.activeRequirements)
        this.attributes.push(this.details.inactiveRequirements)
        this.attributes.push(this.details.completedRequirements)
        this.attributes.push(this.details.incompleteRequirements)
        this.attributes.push(this.details.totalEntities)
        this.attributes.push(this.details.totalAttributes)
        this.attributes.push(this.details.simpleAttributes)
        this.attributes.push(this.details.relationshipAttributes)
        this.attributes.push(this.details.attributesWithRequirements)
        this.attributes.push(this.details.totalProcesses)
        this.attributes.push(this.details.processesWithRequirements)
        this.attributes.push(this.details.totalCustomLogicRequirements)
        this.attributes.push(this.details.completedCustomLogicRequirements)
        this.attributes.push(this.details.incompleteCustomLogicRequirements)
        this.attributes.push(this.details.totalBlocks)
        this.attributes.push(this.details.customBlocks)
        this.attributes.push(this.details.libraryBlocks)
        this.attributes.push(this.details.networkBlocks)
        this.attributes.push(this.details.blocksWithCustomLogicRequirements)
        this.attributes.push(this.details.totalDeficiencies)
        this.attributes.push(this.details.assessmentSummary)
        this.attributes.push(this.details.deficiency)
        this.attributes.push(this.details.report)
        this.attributes.push(this.details.project)

    }
}