import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutPerson } from './so-put-person';
import { SOGetPerson } from './so-get-person';
import { SOPostPerson } from './so-post-person';
import { SODeletePerson } from './so-delete-person';
import {SORespPerson} from './so-resp-person';
import {SOPluralPerson} from './so-plural-person';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOPersonService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('person', entityCore);
  }

  /** GET Person by id. Will 404 if id not found */
  get(request: SOGetPerson): Observable<SOPluralPerson> {
    return this.httpClient.get<SOPluralPerson>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralPerson, resp)),
      tap(async (response: SOPluralPerson) => {
        this.log(`fetched Person`);
        // After a successful server response, update IndexedDB
        const activities = response.toPersons(); // Convert your response to an array of Person instances
        const promises = activities.map(person => db.persons.put(person));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralPerson>(`get Person`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralPerson> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'person' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.persons.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(personsArray => this.processPersons(personsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "assignedProjects" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.persons.where('assignedProjectsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(personsArray => this.processPersons(personsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "user" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.persons.where('userUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(personsArray => this.processPersons(personsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.persons.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(personsArray => this.processPersons(personsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.persons.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(personsArray => this.processPersons(personsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.persons.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(personsArray => this.processPersons(personsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.persons.toArray()).pipe(
                map((personsArray) => {
                    // Convert the array of plain objects to an array of SORespPerson instances
                    const sorPersons = personsArray.map(req => plainToClass(SORespPerson, req));
                    // Create and return a new instance of SOPluralPerson
                    const pluralPerson = new SOPluralPerson();
                    pluralPerson.objects = sorPersons;
                    pluralPerson.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralPerson;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processPersons(personsArray) {
        const sorPersons = personsArray.map(req => plainToClass(SORespPerson, req));
        const pluralPerson = new SOPluralPerson();
        pluralPerson.objects = sorPersons;
        pluralPerson.nextOffset = null;
        return pluralPerson;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostPerson): Observable<SORespPerson> {
  return this.httpClient.post<SORespPerson>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespPerson, resp)),

    tap(async (response: SORespPerson) => {
      this.log(`added Person w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.persons.add(response.toPerson());
    }),
    catchError(this.handleError<SORespPerson>('Person post'))
  );
}


  put(request: SOPutPerson): Observable<SORespPerson> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPerson>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespPerson, resp)),
      tap(async (response: SORespPerson) => {
        this.log(`edited Person w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.persons.put(response.toPerson());
      }),
      catchError(this.handleError<SORespPerson>('Person put'))
    );
  }


  delete(request: SODeletePerson): Observable<SORespPerson> {
    return this.httpClient.delete<SORespPerson>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Person uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.persons.delete(request.uid);
      }),
      catchError(this.handleError<SORespPerson>('Person delete'))
    );
  }


}
