import {SORespEntity} from '../../core/entity/so-resp-entity';
import {EntityCore} from './entity.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutEntityFormFields} from './put-entity-form-fields';
import { CEEntityAttributes } from './ce-entity-attributes'


export class CEEntityBase extends CEBase {

    public details: CEEntityAttributes;
    public fromFields: PutEntityFormFields = new PutEntityFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: EntityCore,
        public entity: SORespEntity,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEEntityAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.editPermissions)
        this.attributes.push(this.details.entityPermissions)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.simpleAttributes)
        this.attributes.push(this.details.relationshipAttributes)
        this.attributes.push(this.details.interphaces)
        this.attributes.push(this.details.project)

    }
}