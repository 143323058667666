import { Type } from 'class-transformer';
import {SORespAssessmentSummary} from './so-resp-assessment-summary';
import {PluralProtocol} from 'lionheartland';
import {AssessmentSummary} from "../../dexie/db";

export class SOPluralAssessmentSummary implements PluralProtocol {
  @Type(() => SORespAssessmentSummary)
  objects: SORespAssessmentSummary[] = []
  nextOffset: number = null

    toAssessmentSummarys(): AssessmentSummary[] {
        return this.objects.map(assessmentSummary => assessmentSummary.toAssessmentSummary());
    }
}