import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutIngestFormFields {

    public entityName: FormField
    public data: FormField
    public organization: FormField

    constructor() {

        this.entityName = new FormField(
          'entityName',
          'Entity name',
          null,
          FormFieldType.text,
          false,
        )
        this.data = new FormField(
          'data',
          'Data',
          null,
          FormFieldType.text,
          false,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutIngestFormFields()

export const putIngestFormFieldOptions: {[key: string]: FormField} = {
  entityName: fields.entityName,
  data: fields.data,
  organization: fields.organization,
};