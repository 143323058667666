import { Type } from 'class-transformer';
import {SORespAttributeSpec} from './so-resp-attribute-spec';
import {PluralProtocol} from 'lionheartland';
import {AttributeSpec} from "../../dexie/db";

export class SOPluralAttributeSpec implements PluralProtocol {
  @Type(() => SORespAttributeSpec)
  objects: SORespAttributeSpec[] = []
  nextOffset: number = null

    toAttributeSpecs(): AttributeSpec[] {
        return this.objects.map(attributeSpec => attributeSpec.toAttributeSpec());
    }
}