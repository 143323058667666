import { Type } from 'class-transformer';
import {SORespProcessRole} from './so-resp-process-role';
import {PluralProtocol} from 'lionheartland';
import {ProcessRole} from "../../dexie/db";

export class SOPluralProcessRole implements PluralProtocol {
  @Type(() => SORespProcessRole)
  objects: SORespProcessRole[] = []
  nextOffset: number = null

    toProcessRoles(): ProcessRole[] {
        return this.objects.map(processRole => processRole.toProcessRole());
    }
}