import { Type } from 'class-transformer';
import {SORespAttachment} from './so-resp-attachment';
import {PluralProtocol} from 'lionheartland';
import {Attachment} from "../../dexie/db";

export class SOPluralAttachment implements PluralProtocol {
  @Type(() => SORespAttachment)
  objects: SORespAttachment[] = []
  nextOffset: number = null

    toAttachments(): Attachment[] {
        return this.objects.map(attachment => attachment.toAttachment());
    }
}