import {SOPostProduct} from '../../core/product/so-post-product';
import {ProductCore} from './product.core';
import {SOGetProduct} from '../../core/product/so-get-product';
import {FormField, SelectionOption} from 'lionheartland';
import {postProductFormFieldOptions} from './post-product-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostProduct extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postProductFormFieldOptions);

  RequestClass = SOPostProduct

  constructor(
    public entityCore: ProductCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('sku'),
      this.getFormValueFromIdentifier('exRef'),
      this.getFormValueFromIdentifier('active'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}