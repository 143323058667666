import { Type } from 'class-transformer';
import {SORespStep} from './so-resp-step';
import {PluralProtocol} from 'lionheartland';
import {Step} from "../../dexie/db";

export class SOPluralStep implements PluralProtocol {
  @Type(() => SORespStep)
  objects: SORespStep[] = []
  nextOffset: number = null

    toSteps(): Step[] {
        return this.objects.map(step => step.toStep());
    }
}