import {SOPutSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-put-simple-attribute-permission-rule';
import {SimpleAttributePermissionRuleCore} from './simple-attribute-permission-rule.core';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {FormField, SelectionOption} from 'lionheartland';
import {putSimpleAttributePermissionRuleFormFieldOptions} from './put-simple-attribute-permission-rule-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SimpleAttributePermissionCore} from '../simple-attribute-permission/simple-attribute-permission.core';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutSimpleAttributePermissionRule extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSimpleAttributePermissionRuleFormFieldOptions);

  RequestClass = SOPutSimpleAttributePermissionRule

  constructor(
    public entityCore: SimpleAttributePermissionRuleCore
  ) {
    super();
    if (putSimpleAttributePermissionRuleFormFieldOptions.simpleAttribute) {
      putSimpleAttributePermissionRuleFormFieldOptions.simpleAttribute.makeCore = true;
    }
    if (putSimpleAttributePermissionRuleFormFieldOptions.simpleAttributePermission) {
      putSimpleAttributePermissionRuleFormFieldOptions.simpleAttributePermission.makeCore = true;
    }
    if (putSimpleAttributePermissionRuleFormFieldOptions.project) {
      putSimpleAttributePermissionRuleFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'simpleAttribute',
        SOGetSimpleAttribute,
      ),
      this.getFormValueFromIdentifier(
        'simpleAttributePermission',
        SOGetSimpleAttributePermission,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}