import {SORespRelationshipAttribute} from '../../core/relationship-attribute/so-resp-relationship-attribute';
import {RelationshipAttributeCore} from './relationship-attribute.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRelationshipAttributeFormFields} from './put-relationship-attribute-form-fields';
import { CERelationshipAttributeAttributes } from './ce-relationship-attribute-attributes'


export class CERelationshipAttributeBase extends CEBase {

    public details: CERelationshipAttributeAttributes;
    public fromFields: PutRelationshipAttributeFormFields = new PutRelationshipAttributeFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RelationshipAttributeCore,
        public entity: SORespRelationshipAttribute,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERelationshipAttributeAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.multiplicity)
        this.attributes.push(this.details.editPermissions)
        this.attributes.push(this.details.relationshipAttributePermissions)
        this.attributes.push(this.details.relationshipAttributePermissionRules)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.entity)
        this.attributes.push(this.details.customLogicRequirement)
        this.attributes.push(this.details.relationship)
        this.attributes.push(this.details.attributeSpecs)
        this.attributes.push(this.details.project)

    }
}