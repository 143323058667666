import { Type } from 'class-transformer';
import {SORespRequirement} from './so-resp-requirement';
import {PluralProtocol} from 'lionheartland';
import {Requirement} from "../../dexie/db";

export class SOPluralRequirement implements PluralProtocol {
  @Type(() => SORespRequirement)
  objects: SORespRequirement[] = []
  nextOffset: number = null

    toRequirements(): Requirement[] {
        return this.objects.map(requirement => requirement.toRequirement());
    }
}