import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOStepService} from '../../core/step/eo-step';
import {SOGetStep} from '../../core/step/so-get-step';
import {RAPutStep} from './ra-put-step';
import {RAPostStep} from './ra-post-step';
import {RADeleteStep} from './ra-delete-step';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEStep } from "./ce-step"


export class StepCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'step';
  title = 'Step';
  ceProtocol: CEConstructor = CEStep

  entityService: EOStepService;
  soGetClass = SOGetStep;
  soGet: SOGetStep;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOStepService,
      null,
    );
    this.adapters = [
      new RAPostStep(this),
      new RAPutStep(this),
      new RADeleteStep(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetStep();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}