import {SORespToken} from '../../core/token/so-resp-token';
import {TokenCore} from './token.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutTokenFormFields} from './put-token-form-fields';
import {SOGetToken} from '../../core/token/so-get-token';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';


export class CETokenAttributesBase {

    certificate: DetailEntry
    dateCreated: DetailEntry
    status: DetailEntry
    roleGrant: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespToken,
        app: CommanderApp,
        formFields: PutTokenFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.status
        )


        const certificateCore = app.makeCore( 'certificate');
        certificateCore.soGet = SOGetCertificate.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            certificateCore
        )
        certificateCore.title = heading ? heading : certificateCore.title
        certificateCore.listTitle = 'Certificate'

        certificateCore.configureForDisplay(parentEntityViewSpec)

        this.certificate = new DetailEntry(
          heading ? heading : certificateCore.listTitle,
          null,
          'certificate',
          BaseDetailType.pushedDetail,
          certificateCore,
          EditableType.never
        )
        this.certificate.singleFieldSelect = true

        const roleGrantCore = app.makeCore( 'roleGrant');
        roleGrantCore.soGet = SOGetRoleGrant.construct({
        });
        let roleGrantCeProtocol;
        if (entity.roleGrant) {
            roleGrantCeProtocol = createCEProtocol(
                roleGrantCore.ceProtocol,
                parentEntityViewSpec,
                roleGrantCore,
                entity.roleGrant,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleGrantCore
        )
        roleGrantCore.title = heading ? heading : roleGrantCore.title
        roleGrantCore.listTitle = 'Role grant'

        if (entity.roleGrant) {
            displayStringFactory.reconfigure(
                entity.roleGrant,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleGrantCore.configureForDisplay(parentEntityViewSpec)

        this.roleGrant = new DetailEntry(
          heading ? heading : roleGrantCore.listTitle,
          roleGrantCeProtocol ? roleGrantCeProtocol.entityListView.fullHeading : null,
          'roleGrant',
          BaseDetailType.pushedDetail,
          roleGrantCore,
          EditableType.never
        )
        if (entity.roleGrant) {
          this.roleGrant.ceProtocol = roleGrantCeProtocol;
          this.roleGrant.cachedListEntry = roleGrantCore.makeListEntry(parentEntityViewSpec, entity.roleGrant, 0)
          roleGrantCore.selectedEntity = entity.roleGrant;
        }
        this.roleGrant.singleFieldSelect = true
    }
}