import {SORespProcessRole} from '../../core/process-role/so-resp-process-role';
import {ProcessRoleCore} from './process-role.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProcessRoleFormFields} from './put-process-role-form-fields';
import { CEProcessRoleAttributes } from './ce-process-role-attributes'


export class CEProcessRoleBase extends CEBase {

    public details: CEProcessRoleAttributes;
    public fromFields: PutProcessRoleFormFields = new PutProcessRoleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProcessRoleCore,
        public entity: SORespProcessRole,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProcessRoleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.process)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.project)

    }
}