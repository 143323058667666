import {SORespRolePermission} from '../../core/role-permission/so-resp-role-permission';
import {RolePermissionCore} from './role-permission.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRolePermissionFormFields} from './put-role-permission-form-fields';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERolePermissionAttributesBase {

    name: DetailEntry
    entityPermission: DetailEntry
    relationshipAttributePermission: DetailEntry
    simpleAttributePermission: DetailEntry
    role: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRolePermission,
        app: CommanderApp,
        formFields: PutRolePermissionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )


        const entityPermissionCore = app.makeCore( 'entityPermission');
        entityPermissionCore.soGet = SOGetEntityPermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityPermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityPermissionCore.soGet.queryOptions.descending = true;
        let entityPermissionCeProtocol;
        if (entity.entityPermission) {
            entityPermissionCeProtocol = createCEProtocol(
                entityPermissionCore.ceProtocol,
                parentEntityViewSpec,
                entityPermissionCore,
                entity.entityPermission,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityPermissionCore
        )
        entityPermissionCore.title = heading ? heading : entityPermissionCore.title
        entityPermissionCore.listTitle = 'Entity permission'

        if (entity.entityPermission) {
            displayStringFactory.reconfigure(
                entity.entityPermission,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        entityPermissionCore.configureForDisplay(parentEntityViewSpec)

        this.entityPermission = new DetailEntry(
          heading ? heading : entityPermissionCore.listTitle,
          entityPermissionCeProtocol ? entityPermissionCeProtocol.entityListView.fullHeading : null,
          'entityPermission',
          BaseDetailType.pushedDetail,
          entityPermissionCore,
          EditableType.always,
          formFields.entityPermission
        )
        if (entity.entityPermission) {
          this.entityPermission.ceProtocol = entityPermissionCeProtocol;
          this.entityPermission.cachedListEntry = entityPermissionCore.makeListEntry(parentEntityViewSpec, entity.entityPermission, 0)
          entityPermissionCore.selectedEntity = entity.entityPermission;
        }
        this.entityPermission.singleFieldSelect = true

        const relationshipAttributePermissionCore = app.makeCore( 'relationshipAttributePermission');
        relationshipAttributePermissionCore.soGet = SOGetRelationshipAttributePermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributePermissionCore.soGet.queryOptions.descending = true;
        let relationshipAttributePermissionCeProtocol;
        if (entity.relationshipAttributePermission) {
            relationshipAttributePermissionCeProtocol = createCEProtocol(
                relationshipAttributePermissionCore.ceProtocol,
                parentEntityViewSpec,
                relationshipAttributePermissionCore,
                entity.relationshipAttributePermission,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributePermissionCore
        )
        relationshipAttributePermissionCore.title = heading ? heading : relationshipAttributePermissionCore.title
        relationshipAttributePermissionCore.listTitle = 'Relationship attribute permission'

        if (entity.relationshipAttributePermission) {
            displayStringFactory.reconfigure(
                entity.relationshipAttributePermission,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        relationshipAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributePermission = new DetailEntry(
          heading ? heading : relationshipAttributePermissionCore.listTitle,
          relationshipAttributePermissionCeProtocol ? relationshipAttributePermissionCeProtocol.entityListView.fullHeading : null,
          'relationshipAttributePermission',
          BaseDetailType.pushedDetail,
          relationshipAttributePermissionCore,
          EditableType.always,
          formFields.relationshipAttributePermission
        )
        if (entity.relationshipAttributePermission) {
          this.relationshipAttributePermission.ceProtocol = relationshipAttributePermissionCeProtocol;
          this.relationshipAttributePermission.cachedListEntry = relationshipAttributePermissionCore.makeListEntry(parentEntityViewSpec, entity.relationshipAttributePermission, 0)
          relationshipAttributePermissionCore.selectedEntity = entity.relationshipAttributePermission;
        }
        this.relationshipAttributePermission.singleFieldSelect = true

        const simpleAttributePermissionCore = app.makeCore( 'simpleAttributePermission');
        simpleAttributePermissionCore.soGet = SOGetSimpleAttributePermission.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributePermissionCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributePermissionCore.soGet.queryOptions.descending = true;
        let simpleAttributePermissionCeProtocol;
        if (entity.simpleAttributePermission) {
            simpleAttributePermissionCeProtocol = createCEProtocol(
                simpleAttributePermissionCore.ceProtocol,
                parentEntityViewSpec,
                simpleAttributePermissionCore,
                entity.simpleAttributePermission,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributePermissionCore
        )
        simpleAttributePermissionCore.title = heading ? heading : simpleAttributePermissionCore.title
        simpleAttributePermissionCore.listTitle = 'Simple attribute permission'

        if (entity.simpleAttributePermission) {
            displayStringFactory.reconfigure(
                entity.simpleAttributePermission,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        simpleAttributePermissionCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributePermission = new DetailEntry(
          heading ? heading : simpleAttributePermissionCore.listTitle,
          simpleAttributePermissionCeProtocol ? simpleAttributePermissionCeProtocol.entityListView.fullHeading : null,
          'simpleAttributePermission',
          BaseDetailType.pushedDetail,
          simpleAttributePermissionCore,
          EditableType.always,
          formFields.simpleAttributePermission
        )
        if (entity.simpleAttributePermission) {
          this.simpleAttributePermission.ceProtocol = simpleAttributePermissionCeProtocol;
          this.simpleAttributePermission.cachedListEntry = simpleAttributePermissionCore.makeListEntry(parentEntityViewSpec, entity.simpleAttributePermission, 0)
          simpleAttributePermissionCore.selectedEntity = entity.simpleAttributePermission;
        }
        this.simpleAttributePermission.singleFieldSelect = true

        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        let roleCeProtocol;
        if (entity.role) {
            roleCeProtocol = createCEProtocol(
                roleCore.ceProtocol,
                parentEntityViewSpec,
                roleCore,
                entity.role,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        if (entity.role) {
            displayStringFactory.reconfigure(
                entity.role,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          roleCeProtocol ? roleCeProtocol.entityListView.fullHeading : null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.always,
          formFields.role
        )
        if (entity.role) {
          this.role.ceProtocol = roleCeProtocol;
          this.role.cachedListEntry = roleCore.makeListEntry(parentEntityViewSpec, entity.role, 0)
          roleCore.selectedEntity = entity.role;
        }
        this.role.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}