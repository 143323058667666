import { Type } from 'class-transformer';
import {SORespPlanWorkAssociation} from './so-resp-plan-work-association';
import {PluralProtocol} from 'lionheartland';
import {PlanWorkAssociation} from "../../dexie/db";

export class SOPluralPlanWorkAssociation implements PluralProtocol {
  @Type(() => SORespPlanWorkAssociation)
  objects: SORespPlanWorkAssociation[] = []
  nextOffset: number = null

    toPlanWorkAssociations(): PlanWorkAssociation[] {
        return this.objects.map(planWorkAssociation => planWorkAssociation.toPlanWorkAssociation());
    }
}