import { Type } from 'class-transformer';
import {SORespOrganization} from './so-resp-organization';
import {PluralProtocol} from 'lionheartland';
import {Organization} from "../../dexie/db";

export class SOPluralOrganization implements PluralProtocol {
  @Type(() => SORespOrganization)
  objects: SORespOrganization[] = []
  nextOffset: number = null

    toOrganizations(): Organization[] {
        return this.objects.map(organization => organization.toOrganization());
    }
}