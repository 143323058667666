import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutRoleFormFields {


    constructor() {


    }
}

const fields = new PutRoleFormFields()

export const putRoleFormFieldOptions: {[key: string]: FormField} = {
};