import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENInterphaceType} from '../../enums';

export class PutInterphaceFormFields {

    public name: FormField
    public description: FormField
    public interphaceType: FormField
    public entity: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.interphaceType = new FormField(
          'interphaceType',
          'Interphace type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.interphaceType.enumeration = ENInterphaceType
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutInterphaceFormFields()

export const putInterphaceFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  interphaceType: fields.interphaceType,
  entity: fields.entity,
  project: fields.project,
};