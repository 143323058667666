import { SOGetAssessment } from '../assessment/so-get-assessment';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutAssessmentSummary {

  constructor(
    public uid: string,
    public assessment: SOGetAssessment = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAssessmentSummary(
      'uid' in params ? params.uid : null,
      'assessment' in params ? params.assessment : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
