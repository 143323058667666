import { SOGetConstraint } from '../constraint/so-get-constraint';
import { SOGetProduct } from '../product/so-get-product';


export class SOPostAppliedConstraint {

  constructor(
    public constraint: SOGetConstraint,
    public product: SOGetProduct,
    public quantity: number,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAppliedConstraint(
      'constraint' in params ? params.constraint : null,
      'product' in params ? params.product : null,
      'quantity' in params ? params.quantity : null,
    );
  }
}
