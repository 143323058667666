import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutOrganizationFormFields {

    public name: FormField
    public identifier: FormField
    public assessment: FormField
    public assessmentSummary: FormField
    public block: FormField
    public deficiency: FormField
    public period: FormField
    public report: FormField
    public requirement: FormField
    public customLogicRequirement: FormField
    public work: FormField
    public realization: FormField
    public entity: FormField
    public relationship: FormField
    public relationshipAttribute: FormField
    public simpleAttribute: FormField
    public process: FormField
    public project: FormField
    public interphace: FormField
    public backlog: FormField
    public attributeSpec: FormField
    public exportProject: FormField
    public simpleAttributePermission: FormField
    public simpleAttributePermissionRule: FormField
    public relationshipAttributePermission: FormField
    public relationshipAttributePermissionRule: FormField
    public entityPermission: FormField
    public plan: FormField
    public person: FormField
    public assignedProject: FormField
    public planWorkAssociation: FormField
    public rolePermission: FormField
    public role: FormField
    public processRole: FormField
    public step: FormField
    public processStepAssociation: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          false,
        )
        this.assessment = new FormField(
          'assessment',
          'Assessment',
          'assessment',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.assessmentSummary = new FormField(
          'assessmentSummary',
          'Assessment summary',
          'assessmentSummary',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.block = new FormField(
          'block',
          'Block',
          'block',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.deficiency = new FormField(
          'deficiency',
          'Deficiency',
          'deficiency',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.period = new FormField(
          'period',
          'Period',
          'period',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.report = new FormField(
          'report',
          'Report',
          'report',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.requirement = new FormField(
          'requirement',
          'Requirement',
          'requirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.customLogicRequirement = new FormField(
          'customLogicRequirement',
          'Custom logic requirement',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.work = new FormField(
          'work',
          'Work',
          'work',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.realization = new FormField(
          'realization',
          'Realization',
          'realization',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationship = new FormField(
          'relationship',
          'Relationship',
          'relationship',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttribute = new FormField(
          'relationshipAttribute',
          'Relationship attribute',
          'relationshipAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttribute = new FormField(
          'simpleAttribute',
          'Simple attribute',
          'simpleAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.process = new FormField(
          'process',
          'Process',
          'process',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.interphace = new FormField(
          'interphace',
          'Interphace',
          'interphace',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.backlog = new FormField(
          'backlog',
          'Backlog',
          'backlog',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.attributeSpec = new FormField(
          'attributeSpec',
          'Attribute spec',
          'attributeSpec',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.exportProject = new FormField(
          'exportProject',
          'Export project',
          'exportProject',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttributePermission = new FormField(
          'simpleAttributePermission',
          'Simple attribute permission',
          'simpleAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttributePermissionRule = new FormField(
          'simpleAttributePermissionRule',
          'Simple attribute permission rule',
          'simpleAttributePermissionRule',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttributePermission = new FormField(
          'relationshipAttributePermission',
          'Relationship attribute permission',
          'relationshipAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttributePermissionRule = new FormField(
          'relationshipAttributePermissionRule',
          'Relationship attribute permission rule',
          'relationshipAttributePermissionRule',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.entityPermission = new FormField(
          'entityPermission',
          'Entity permission',
          'entityPermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.plan = new FormField(
          'plan',
          'Plan',
          'plan',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.person = new FormField(
          'person',
          'Person',
          'person',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.assignedProject = new FormField(
          'assignedProject',
          'Assigned project',
          'assignedProject',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.planWorkAssociation = new FormField(
          'planWorkAssociation',
          'Plan work association',
          'planWorkAssociation',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.rolePermission = new FormField(
          'rolePermission',
          'Role permission',
          'rolePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.processRole = new FormField(
          'processRole',
          'Process role',
          'processRole',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.step = new FormField(
          'step',
          'Step',
          'step',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.processStepAssociation = new FormField(
          'processStepAssociation',
          'Process step association',
          'processStepAssociation',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutOrganizationFormFields()

export const putOrganizationFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
  assessment: fields.assessment,
  assessmentSummary: fields.assessmentSummary,
  block: fields.block,
  deficiency: fields.deficiency,
  period: fields.period,
  report: fields.report,
  requirement: fields.requirement,
  customLogicRequirement: fields.customLogicRequirement,
  work: fields.work,
  realization: fields.realization,
  entity: fields.entity,
  relationship: fields.relationship,
  relationshipAttribute: fields.relationshipAttribute,
  simpleAttribute: fields.simpleAttribute,
  process: fields.process,
  project: fields.project,
  interphace: fields.interphace,
  backlog: fields.backlog,
  attributeSpec: fields.attributeSpec,
  exportProject: fields.exportProject,
  simpleAttributePermission: fields.simpleAttributePermission,
  simpleAttributePermissionRule: fields.simpleAttributePermissionRule,
  relationshipAttributePermission: fields.relationshipAttributePermission,
  relationshipAttributePermissionRule: fields.relationshipAttributePermissionRule,
  entityPermission: fields.entityPermission,
  plan: fields.plan,
  person: fields.person,
  assignedProject: fields.assignedProject,
  planWorkAssociation: fields.planWorkAssociation,
  rolePermission: fields.rolePermission,
  role: fields.role,
  processRole: fields.processRole,
  step: fields.step,
  processStepAssociation: fields.processStepAssociation,
};