import {SORespDeficiency} from '../../core/deficiency/so-resp-deficiency';
import {DeficiencyCore} from './deficiency.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutDeficiencyFormFields} from './put-deficiency-form-fields';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetWork} from '../../core/work/so-get-work';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {SOGetBlock} from '../../core/block/so-get-block';
import {SOGetProcess} from '../../core/process/so-get-process';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEDeficiencyAttributesBase {

    name: DetailEntry
    description: DetailEntry
    active: DetailEntry
    severity: DetailEntry
    works: DetailEntry
    assessment: DetailEntry
    block: DetailEntry
    process: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDeficiency,
        app: CommanderApp,
        formFields: PutDeficiencyFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )

        this.severity = new DetailEntry(
            'Severity',
            entity.severity,
            'severity',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.severity
        )


        const worksCore = app.makeCore( 'work');
        worksCore.soGet = SOGetWork.construct({
          deficiency: new SOGetDeficiency(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        worksCore.soGet.queryOptions.sortKey = 'dateCreated';
        worksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            worksCore
        )
        worksCore.title = heading ? heading : worksCore.title
        worksCore.listTitle = 'Works'

        worksCore.configureForDisplay(parentEntityViewSpec)

        this.works = new DetailEntry(
          heading ? heading : worksCore.listTitle,
          null,
          'works',
          BaseDetailType.embeddedList,
          worksCore,
          EditableType.never
        )

        const assessmentCore = app.makeCore( 'assessment');
        assessmentCore.soGet = SOGetAssessment.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        assessmentCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentCore.soGet.queryOptions.descending = true;
        let assessmentCeProtocol;
        if (entity.assessment) {
            assessmentCeProtocol = createCEProtocol(
                assessmentCore.ceProtocol,
                parentEntityViewSpec,
                assessmentCore,
                entity.assessment,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentCore
        )
        assessmentCore.title = heading ? heading : assessmentCore.title
        assessmentCore.listTitle = 'Assessment'

        if (entity.assessment) {
            displayStringFactory.reconfigure(
                entity.assessment,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        assessmentCore.configureForDisplay(parentEntityViewSpec)

        this.assessment = new DetailEntry(
          heading ? heading : assessmentCore.listTitle,
          assessmentCeProtocol ? assessmentCeProtocol.entityListView.fullHeading : null,
          'assessment',
          BaseDetailType.pushedDetail,
          assessmentCore,
          EditableType.always,
          formFields.assessment
        )
        if (entity.assessment) {
          this.assessment.ceProtocol = assessmentCeProtocol;
          this.assessment.cachedListEntry = assessmentCore.makeListEntry(parentEntityViewSpec, entity.assessment, 0)
          assessmentCore.selectedEntity = entity.assessment;
        }
        this.assessment.singleFieldSelect = true

        const blockCore = app.makeCore( 'block');
        blockCore.soGet = SOGetBlock.construct({
          deficiencys: new SOGetDeficiency(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        blockCore.soGet.queryOptions.sortKey = 'dateCreated';
        blockCore.soGet.queryOptions.descending = true;
        let blockCeProtocol;
        if (entity.block) {
            blockCeProtocol = createCEProtocol(
                blockCore.ceProtocol,
                parentEntityViewSpec,
                blockCore,
                entity.block,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            blockCore
        )
        blockCore.title = heading ? heading : blockCore.title
        blockCore.listTitle = 'Block'

        if (entity.block) {
            displayStringFactory.reconfigure(
                entity.block,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        blockCore.configureForDisplay(parentEntityViewSpec)

        this.block = new DetailEntry(
          heading ? heading : blockCore.listTitle,
          blockCeProtocol ? blockCeProtocol.entityListView.fullHeading : null,
          'block',
          BaseDetailType.pushedDetail,
          blockCore,
          EditableType.always,
          formFields.block
        )
        if (entity.block) {
          this.block.ceProtocol = blockCeProtocol;
          this.block.cachedListEntry = blockCore.makeListEntry(parentEntityViewSpec, entity.block, 0)
          blockCore.selectedEntity = entity.block;
        }
        this.block.singleFieldSelect = true

        const processCore = app.makeCore( 'process');
        processCore.soGet = SOGetProcess.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processCore.soGet.queryOptions.sortKey = 'dateCreated';
        processCore.soGet.queryOptions.descending = true;
        let processCeProtocol;
        if (entity.process) {
            processCeProtocol = createCEProtocol(
                processCore.ceProtocol,
                parentEntityViewSpec,
                processCore,
                entity.process,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processCore
        )
        processCore.title = heading ? heading : processCore.title
        processCore.listTitle = 'Process'

        if (entity.process) {
            displayStringFactory.reconfigure(
                entity.process,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        processCore.configureForDisplay(parentEntityViewSpec)

        this.process = new DetailEntry(
          heading ? heading : processCore.listTitle,
          processCeProtocol ? processCeProtocol.entityListView.fullHeading : null,
          'process',
          BaseDetailType.pushedDetail,
          processCore,
          EditableType.always,
          formFields.process
        )
        if (entity.process) {
          this.process.ceProtocol = processCeProtocol;
          this.process.cachedListEntry = processCore.makeListEntry(parentEntityViewSpec, entity.process, 0)
          processCore.selectedEntity = entity.process;
        }
        this.process.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}