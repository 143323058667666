import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutEntity } from './so-put-entity';
import { SOGetEntity } from './so-get-entity';
import { SOPostEntity } from './so-post-entity';
import { SODeleteEntity } from './so-delete-entity';
import {SORespEntity} from './so-resp-entity';
import {SOPluralEntity} from './so-plural-entity';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOEntityService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('entity', entityCore);
  }

  /** GET Entity by id. Will 404 if id not found */
  get(request: SOGetEntity): Observable<SOPluralEntity> {
    return this.httpClient.get<SOPluralEntity>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralEntity, resp)),
      tap(async (response: SOPluralEntity) => {
        this.log(`fetched Entity`);
        // After a successful server response, update IndexedDB
        const activities = response.toEntitys(); // Convert your response to an array of Entity instances
        const promises = activities.map(entity => db.entitys.put(entity));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralEntity>(`get Entity`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralEntity> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'entity' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.entitys.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "entityPermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('entityPermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "realizations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('realizationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "simpleAttributes" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('simpleAttributesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationshipAttributes" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('relationshipAttributesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "interphaces" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('interphacesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entitys.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entitysArray => this.processEntitys(entitysArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.entitys.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(entitysArray => this.processEntitys(entitysArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.entitys.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(entitysArray => this.processEntitys(entitysArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.entitys.toArray()).pipe(
                map((entitysArray) => {
                    // Convert the array of plain objects to an array of SORespEntity instances
                    const sorEntitys = entitysArray.map(req => plainToClass(SORespEntity, req));
                    // Create and return a new instance of SOPluralEntity
                    const pluralEntity = new SOPluralEntity();
                    pluralEntity.objects = sorEntitys;
                    pluralEntity.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralEntity;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processEntitys(entitysArray) {
        const sorEntitys = entitysArray.map(req => plainToClass(SORespEntity, req));
        const pluralEntity = new SOPluralEntity();
        pluralEntity.objects = sorEntitys;
        pluralEntity.nextOffset = null;
        return pluralEntity;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostEntity): Observable<SORespEntity> {
  return this.httpClient.post<SORespEntity>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespEntity, resp)),

    tap(async (response: SORespEntity) => {
      this.log(`added Entity w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.entitys.add(response.toEntity());
    }),
    catchError(this.handleError<SORespEntity>('Entity post'))
  );
}


  put(request: SOPutEntity): Observable<SORespEntity> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntity>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespEntity, resp)),
      tap(async (response: SORespEntity) => {
        this.log(`edited Entity w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.entitys.put(response.toEntity());
      }),
      catchError(this.handleError<SORespEntity>('Entity put'))
    );
  }


  delete(request: SODeleteEntity): Observable<SORespEntity> {
    return this.httpClient.delete<SORespEntity>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Entity uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.entitys.delete(request.uid);
      }),
      catchError(this.handleError<SORespEntity>('Entity delete'))
    );
  }


}
