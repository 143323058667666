import {SORespRole} from '../../core/role/so-resp-role';
import {RoleCore} from './role.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRoleFormFields} from './put-role-form-fields';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {SOGetProcessRole} from '../../core/process-role/so-get-process-role';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERoleAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    roleGrants: DetailEntry
    viewGrants: DetailEntry
    invites: DetailEntry
    rolePermissions: DetailEntry
    processRoles: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRole,
        app: CommanderApp,
        formFields: PutRoleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const roleGrantsCore = app.makeCore( 'roleGrant');
        roleGrantsCore.soGet = SOGetRoleGrant.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleGrantsCore
        )
        roleGrantsCore.title = heading ? heading : roleGrantsCore.title
        roleGrantsCore.listTitle = 'Role grants'

        roleGrantsCore.configureForDisplay(parentEntityViewSpec)

        this.roleGrants = new DetailEntry(
          heading ? heading : roleGrantsCore.listTitle,
          null,
          'roleGrants',
          BaseDetailType.embeddedList,
          roleGrantsCore,
          EditableType.never
        )

        const viewGrantsCore = app.makeCore( 'viewGrant');
        viewGrantsCore.soGet = SOGetViewGrant.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            viewGrantsCore
        )
        viewGrantsCore.title = heading ? heading : viewGrantsCore.title
        viewGrantsCore.listTitle = 'View grants'

        viewGrantsCore.configureForDisplay(parentEntityViewSpec)

        this.viewGrants = new DetailEntry(
          heading ? heading : viewGrantsCore.listTitle,
          null,
          'viewGrants',
          BaseDetailType.embeddedList,
          viewGrantsCore,
          EditableType.never
        )

        const invitesCore = app.makeCore( 'organizationInvite');
        invitesCore.soGet = SOGetOrganizationInvite.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invitesCore
        )
        invitesCore.title = heading ? heading : invitesCore.title
        invitesCore.listTitle = 'Invites'

        invitesCore.configureForDisplay(parentEntityViewSpec)

        this.invites = new DetailEntry(
          heading ? heading : invitesCore.listTitle,
          null,
          'invites',
          BaseDetailType.embeddedList,
          invitesCore,
          EditableType.never
        )

        const rolePermissionsCore = app.makeCore( 'rolePermission');
        rolePermissionsCore.soGet = SOGetRolePermission.construct({
          role: new SOGetRole(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        rolePermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        rolePermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolePermissionsCore
        )
        rolePermissionsCore.title = heading ? heading : rolePermissionsCore.title
        rolePermissionsCore.listTitle = 'Role permissions'

        rolePermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.rolePermissions = new DetailEntry(
          heading ? heading : rolePermissionsCore.listTitle,
          null,
          'rolePermissions',
          BaseDetailType.embeddedList,
          rolePermissionsCore,
          EditableType.never
        )

        const processRolesCore = app.makeCore( 'processRole');
        processRolesCore.soGet = SOGetProcessRole.construct({
          role: new SOGetRole(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        processRolesCore.soGet.queryOptions.sortKey = 'dateCreated';
        processRolesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            processRolesCore
        )
        processRolesCore.title = heading ? heading : processRolesCore.title
        processRolesCore.listTitle = 'Process roles'

        processRolesCore.configureForDisplay(parentEntityViewSpec)

        this.processRoles = new DetailEntry(
          heading ? heading : processRolesCore.listTitle,
          null,
          'processRoles',
          BaseDetailType.embeddedList,
          processRolesCore,
          EditableType.never
        )
    }
}