import {
  DisplayVector,
  DisplayVectorBundle,
  DisplayVectorComponent,
  DisplayVectorEntity,
  DisplayVectorKey,
  DisplaySeparator,
  DISPLAY_OPTIONS
} from 'lionheartland';


export const GENERATED_AND_BASE_DISPLAY_OPTIONS = []

for (const option of DISPLAY_OPTIONS) {
  GENERATED_AND_BASE_DISPLAY_OPTIONS.push(option)
}

GENERATED_AND_BASE_DISPLAY_OPTIONS.push(
    new DisplayVectorBundle(
        'appliedConstraint',
        [
        new DisplayVector(
            'constraint',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('price'),
                    ]
                ),
            ),
        new DisplayVector(
            'price',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('constraint'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'roleGrant',
        [
        new DisplayVector(
            'role',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('user'),
                    ]
                ),
            ),
        new DisplayVector(
            'user',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('role'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'subscription',
        [
        new DisplayVector(
            'product',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('organization'),
                    ]
                ),
            ),
        new DisplayVector(
            'organization',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('product'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'viewGrant',
        [
        new DisplayVector(
            'view',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('role'),
                    ]
                ),
            ),
        new DisplayVector(
            'role',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('view'),
                    ]
                ),
            ),
        ]
    ),
)