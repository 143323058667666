import {SORespCustomLogicRequirement} from '../../core/custom-logic-requirement/so-resp-custom-logic-requirement';
import {CustomLogicRequirementCore} from './custom-logic-requirement.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutCustomLogicRequirementFormFields} from './put-custom-logic-requirement-form-fields';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {SOGetBlock} from '../../core/block/so-get-block';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CECustomLogicRequirementAttributesBase {

    name: DetailEntry
    description: DetailEntry
    active: DetailEntry
    currentStatus: DetailEntry
    customLogicRequirements: DetailEntry
    parent: DetailEntry
    blocks: DetailEntry
    simpleAttributes: DetailEntry
    relationshipAttributes: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespCustomLogicRequirement,
        app: CommanderApp,
        formFields: PutCustomLogicRequirementFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )

        this.currentStatus = new DetailEntry(
            'Current status',
            entity.currentStatus,
            'currentStatus',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.currentStatus
        )


        const customLogicRequirementsCore = app.makeCore( 'customLogicRequirement');
        customLogicRequirementsCore.soGet = SOGetCustomLogicRequirement.construct({
          parent: new SOGetCustomLogicRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        customLogicRequirementsCore.soGet.queryOptions.sortKey = 'dateCreated';
        customLogicRequirementsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customLogicRequirementsCore
        )
        customLogicRequirementsCore.title = heading ? heading : customLogicRequirementsCore.title
        customLogicRequirementsCore.listTitle = 'Custom logic requirements'

        customLogicRequirementsCore.configureForDisplay(parentEntityViewSpec)

        this.customLogicRequirements = new DetailEntry(
          heading ? heading : customLogicRequirementsCore.listTitle,
          null,
          'customLogicRequirements',
          BaseDetailType.embeddedList,
          customLogicRequirementsCore,
          EditableType.never
        )

        const parentCore = app.makeCore( 'customLogicRequirement');
        parentCore.soGet = SOGetCustomLogicRequirement.construct({
          parent: new SOGetCustomLogicRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        parentCore.soGet.queryOptions.sortKey = 'dateCreated';
        parentCore.soGet.queryOptions.descending = true;
        let parentCeProtocol;
        if (entity.parent) {
            parentCeProtocol = createCEProtocol(
                parentCore.ceProtocol,
                parentEntityViewSpec,
                parentCore,
                entity.parent,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            parentCore
        )
        parentCore.title = heading ? heading : parentCore.title
        parentCore.listTitle = 'Parent'

        if (entity.parent) {
            displayStringFactory.reconfigure(
                entity.parent,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        parentCore.configureForDisplay(parentEntityViewSpec)

        this.parent = new DetailEntry(
          heading ? heading : parentCore.listTitle,
          parentCeProtocol ? parentCeProtocol.entityListView.fullHeading : null,
          'parent',
          BaseDetailType.pushedDetail,
          parentCore,
          EditableType.always,
          formFields.parent
        )
        if (entity.parent) {
          this.parent.ceProtocol = parentCeProtocol;
          this.parent.cachedListEntry = parentCore.makeListEntry(parentEntityViewSpec, entity.parent, 0)
          parentCore.selectedEntity = entity.parent;
        }
        this.parent.singleFieldSelect = true

        const blocksCore = app.makeCore( 'block');
        blocksCore.soGet = SOGetBlock.construct({
          customLogicRequirement: new SOGetCustomLogicRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        blocksCore.soGet.queryOptions.sortKey = 'dateCreated';
        blocksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            blocksCore
        )
        blocksCore.title = heading ? heading : blocksCore.title
        blocksCore.listTitle = 'Blocks'

        blocksCore.configureForDisplay(parentEntityViewSpec)

        this.blocks = new DetailEntry(
          heading ? heading : blocksCore.listTitle,
          null,
          'blocks',
          BaseDetailType.embeddedList,
          blocksCore,
          EditableType.never
        )

        const simpleAttributesCore = app.makeCore( 'simpleAttribute');
        simpleAttributesCore.soGet = SOGetSimpleAttribute.construct({
          customLogicRequirement: new SOGetCustomLogicRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        simpleAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        simpleAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            simpleAttributesCore
        )
        simpleAttributesCore.title = heading ? heading : simpleAttributesCore.title
        simpleAttributesCore.listTitle = 'Simple attributes'

        simpleAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.simpleAttributes = new DetailEntry(
          heading ? heading : simpleAttributesCore.listTitle,
          null,
          'simpleAttributes',
          BaseDetailType.embeddedList,
          simpleAttributesCore,
          EditableType.never
        )

        const relationshipAttributesCore = app.makeCore( 'relationshipAttribute');
        relationshipAttributesCore.soGet = SOGetRelationshipAttribute.construct({
          customLogicRequirement: new SOGetCustomLogicRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        relationshipAttributesCore.soGet.queryOptions.sortKey = 'dateCreated';
        relationshipAttributesCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            relationshipAttributesCore
        )
        relationshipAttributesCore.title = heading ? heading : relationshipAttributesCore.title
        relationshipAttributesCore.listTitle = 'Relationship attributes'

        relationshipAttributesCore.configureForDisplay(parentEntityViewSpec)

        this.relationshipAttributes = new DetailEntry(
          heading ? heading : relationshipAttributesCore.listTitle,
          null,
          'relationshipAttributes',
          BaseDetailType.embeddedList,
          relationshipAttributesCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}