import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENMessageStatus} from '../../enums';

export class PutNotificationFormFields {

    public name: FormField
    public status: FormField
    public message: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          false,
          true,
          ENMessageStatus.unread
        )
        this.status.enumeration = ENMessageStatus
        this.message = new FormField(
          'message',
          'Message',
          'message',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutNotificationFormFields()

export const putNotificationFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  status: fields.status,
  message: fields.message,
};