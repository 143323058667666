import {SOPostBlock} from '../../core/block/so-post-block';
import {BlockCore} from './block.core';
import {SOGetBlock} from '../../core/block/so-get-block';
import {FormField, SelectionOption} from 'lionheartland';
import {postBlockFormFieldOptions} from './post-block-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {CustomLogicRequirementCore} from '../custom-logic-requirement/custom-logic-requirement.core';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostBlock extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postBlockFormFieldOptions);

  RequestClass = SOPostBlock

  constructor(
    public entityCore: BlockCore
  ) {
    super();
    if (postBlockFormFieldOptions.parent) {
      postBlockFormFieldOptions.parent.makeCore = true;
    }
    if (postBlockFormFieldOptions.customLogicRequirement) {
      postBlockFormFieldOptions.customLogicRequirement.makeCore = true;
    }
    if (postBlockFormFieldOptions.project) {
      postBlockFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'parent',
        SOGetBlock,
      ),
      this.getFormValueFromIdentifier(
        'customLogicRequirement',
        SOGetCustomLogicRequirement,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}