import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBlockService} from '../../core/block/eo-block';
import {SOGetBlock} from '../../core/block/so-get-block';
import {RAPutBlock} from './ra-put-block';
import {RAPostBlock} from './ra-post-block';
import {RADeleteBlock} from './ra-delete-block';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEBlock } from "./ce-block"


export class BlockCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'block';
  title = 'Block';
  ceProtocol: CEConstructor = CEBlock

  entityService: EOBlockService;
  soGetClass = SOGetBlock;
  soGet: SOGetBlock;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBlockService,
      null,
    );
    this.adapters = [
      new RAPostBlock(this),
      new RAPutBlock(this),
      new RADeleteBlock(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBlock();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}