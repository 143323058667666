import { SOGetPeriod } from '../period/so-get-period';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutPlan {

  constructor(
    public uid: string,
    public name: string = null,
    public period: SOGetPeriod = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPlan(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'period' in params ? params.period : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
