import {SORespPrice} from '../../core/price/so-resp-price';
import {PriceCore} from './price.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPriceFormFields} from './put-price-form-fields';
import { CEPriceAttributes } from './ce-price-attributes'


export class CEPriceBase extends CEBase {

    public details: CEPriceAttributes;
    public fromFields: PutPriceFormFields = new PutPriceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PriceCore,
        public entity: SORespPrice,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPriceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.sku)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.exRef)
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.trailDays)
        this.attributes.push(this.details.interval)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.product)
        this.attributes.push(this.details.subscriptions)
        this.attributes.push(this.details.invoiceLines)

    }
}