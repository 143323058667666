import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENInterval} from '../../enums';

export class PutPriceFormFields {

    public name: FormField
    public sku: FormField
    public exRef: FormField
    public amount: FormField
    public trailDays: FormField
    public interval: FormField
    public active: FormField
    public product: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.sku = new FormField(
          'sku',
          'Sku',
          null,
          FormFieldType.text,
          false,
        )
        this.exRef = new FormField(
          'exRef',
          'Ex ref',
          null,
          FormFieldType.text,
          false,
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          false,
        )
        this.trailDays = new FormField(
          'trailDays',
          'Trail days',
          null,
          FormFieldType.text,
          false,
        )
        this.interval = new FormField(
          'interval',
          'Interval',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.interval.enumeration = ENInterval
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )
        this.product = new FormField(
          'product',
          'Product',
          'product',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutPriceFormFields()

export const putPriceFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  sku: fields.sku,
  exRef: fields.exRef,
  amount: fields.amount,
  trailDays: fields.trailDays,
  interval: fields.interval,
  active: fields.active,
  product: fields.product,
};