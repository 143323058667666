import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAssignedProjectService} from '../../core/assigned-project/eo-assigned-project';
import {SOGetAssignedProject} from '../../core/assigned-project/so-get-assigned-project';
import {RAPutAssignedProject} from './ra-put-assigned-project';
import {RAPostAssignedProject} from './ra-post-assigned-project';
import {RADeleteAssignedProject} from './ra-delete-assigned-project';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEAssignedProject } from "./ce-assigned-project"


export class AssignedProjectCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'assignedProject';
  title = 'Assigned project';
  ceProtocol: CEConstructor = CEAssignedProject

  entityService: EOAssignedProjectService;
  soGetClass = SOGetAssignedProject;
  soGet: SOGetAssignedProject;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAssignedProjectService,
      null,
    );
    this.adapters = [
      new RAPostAssignedProject(this),
      new RAPutAssignedProject(this),
      new RADeleteAssignedProject(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAssignedProject();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}