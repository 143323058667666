import {SORespBlock} from '../../core/block/so-resp-block';
import {BlockCore} from './block.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutBlockFormFields} from './put-block-form-fields';
import { CEBlockAttributes } from './ce-block-attributes'


export class CEBlockBase extends CEBase {

    public details: CEBlockAttributes;
    public fromFields: PutBlockFormFields = new PutBlockFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: BlockCore,
        public entity: SORespBlock,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEBlockAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.blocks)
        this.attributes.push(this.details.parent)
        this.attributes.push(this.details.customLogicRequirement)
        this.attributes.push(this.details.interphace)
        this.attributes.push(this.details.deficiencys)
        this.attributes.push(this.details.project)

    }
}