import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENAttributePermissionType} from '../../enums';

export class PutRelationshipAttributePermissionFormFields {

    public name: FormField
    public description: FormField
    public whiteList: FormField
    public permissionType: FormField
    public relationshipAttribute: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.whiteList = new FormField(
          'whiteList',
          'White list',
          null,
          FormFieldType.boolean,
          false,
        )
        this.permissionType = new FormField(
          'permissionType',
          'Permission type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.permissionType.enumeration = ENAttributePermissionType
        this.relationshipAttribute = new FormField(
          'relationshipAttribute',
          'Relationship attribute',
          'relationshipAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutRelationshipAttributePermissionFormFields()

export const putRelationshipAttributePermissionFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  whiteList: fields.whiteList,
  permissionType: fields.permissionType,
  relationshipAttribute: fields.relationshipAttribute,
  project: fields.project,
};