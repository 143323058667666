import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAssessmentFormFields {

    public report: FormField
    public project: FormField

    constructor() {

        this.report = new FormField(
          'report',
          'Report',
          'report',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostAssessmentFormFields()

export const postAssessmentFormFieldOptions: {[key: string]: FormField} = {
  report: fields.report,
  project: fields.project,
};