import {SORespReport} from '../../core/report/so-resp-report';
import {ReportCore} from './report.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutReportFormFields} from './put-report-form-fields';
import { CEReportAttributes } from './ce-report-attributes'


export class CEReportBase extends CEBase {

    public details: CEReportAttributes;
    public fromFields: PutReportFormFields = new PutReportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ReportCore,
        public entity: SORespReport,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEReportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.period)
        this.attributes.push(this.details.assessments)
        this.attributes.push(this.details.project)

    }
}