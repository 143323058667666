import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutViewGrant } from './so-put-view-grant';
import { SOGetViewGrant } from './so-get-view-grant';
import { SOPostViewGrant } from './so-post-view-grant';
import { SODeleteViewGrant } from './so-delete-view-grant';
import {SORespViewGrant} from './so-resp-view-grant';
import {SOPluralViewGrant} from './so-plural-view-grant';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOViewGrantService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('view_grant', entityCore);
  }

  /** GET ViewGrant by id. Will 404 if id not found */
  get(request: SOGetViewGrant): Observable<SOPluralViewGrant> {
    return this.httpClient.get<SOPluralViewGrant>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralViewGrant, resp)),
      tap(async (response: SOPluralViewGrant) => {
        this.log(`fetched ViewGrant`);
        // After a successful server response, update IndexedDB
        const activities = response.toViewGrants(); // Convert your response to an array of ViewGrant instances
        const promises = activities.map(viewGrant => db.viewGrants.put(viewGrant));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralViewGrant>(`get ViewGrant`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralViewGrant> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.viewGrants.toArray()).pipe(
                map((viewGrantsArray) => {
                    // Convert the array of plain objects to an array of SORespViewGrant instances
                    const sorViewGrants = viewGrantsArray.map(req => plainToClass(SORespViewGrant, req));
                    // Create and return a new instance of SOPluralViewGrant
                    const pluralViewGrant = new SOPluralViewGrant();
                    pluralViewGrant.objects = sorViewGrants;
                    pluralViewGrant.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralViewGrant;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processViewGrants(viewGrantsArray) {
        const sorViewGrants = viewGrantsArray.map(req => plainToClass(SORespViewGrant, req));
        const pluralViewGrant = new SOPluralViewGrant();
        pluralViewGrant.objects = sorViewGrants;
        pluralViewGrant.nextOffset = null;
        return pluralViewGrant;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostViewGrant): Observable<SORespViewGrant> {
  return this.httpClient.post<SORespViewGrant>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespViewGrant, resp)),

    tap(async (response: SORespViewGrant) => {
      this.log(`added ViewGrant w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.viewGrants.add(response.toViewGrant());
    }),
    catchError(this.handleError<SORespViewGrant>('ViewGrant post'))
  );
}


  put(request: SOPutViewGrant): Observable<SORespViewGrant> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespViewGrant>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespViewGrant, resp)),
      tap(async (response: SORespViewGrant) => {
        this.log(`edited ViewGrant w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.viewGrants.put(response.toViewGrant());
      }),
      catchError(this.handleError<SORespViewGrant>('ViewGrant put'))
    );
  }


  delete(request: SODeleteViewGrant): Observable<SORespViewGrant> {
    return this.httpClient.delete<SORespViewGrant>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted ViewGrant uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.viewGrants.delete(request.uid);
      }),
      catchError(this.handleError<SORespViewGrant>('ViewGrant delete'))
    );
  }


}
