import { Type } from 'class-transformer';
import {SORespEntityPermission} from './so-resp-entity-permission';
import {PluralProtocol} from 'lionheartland';
import {EntityPermission} from "../../dexie/db";

export class SOPluralEntityPermission implements PluralProtocol {
  @Type(() => SORespEntityPermission)
  objects: SORespEntityPermission[] = []
  nextOffset: number = null

    toEntityPermissions(): EntityPermission[] {
        return this.objects.map(entityPermission => entityPermission.toEntityPermission());
    }
}