import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {AssessmentSummary} from "../../dexie/db";

export class SORespAssessmentSummary implements ObjectiveProtocol {

  entityType: string = 'assessmentSummary';
  uid: string;
  name: string;
  description: string;
  totalRequirements: number;
  completedRequirements: number;
  draftRequirements: number;
  proposedRequirements: number;
  inReviewRequirements: number;
  acceptedRequirements: number;
  rejectedRequirements: number;
  totalCustomLogicRequirements: number;
  completedCustomLogicRequirements: number;
  draftCustomLogicRequirements: number;
  proposedCustomLogicRequirements: number;
  inReviewCustomLogicRequirements: number;
  acceptedCustomLogicRequirements: number;
  rejectedCustomLogicRequirements: number;
  totalAttributes: number;
  attributesWithRequirements: number;
  attributesWithoutRequirements: number;
  totalProcesses: number;
  processesWithRequirements: number;
  processesWithoutRequirements: number;
  totalBlocks: number;
  blocksWithCustomLogicRequirements: number;
  blocksWithoutCustomLogicRequirements: number;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toAssessmentSummary(): AssessmentSummary {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            totalRequirements: this.totalRequirements,
            completedRequirements: this.completedRequirements,
            draftRequirements: this.draftRequirements,
            proposedRequirements: this.proposedRequirements,
            inReviewRequirements: this.inReviewRequirements,
            acceptedRequirements: this.acceptedRequirements,
            rejectedRequirements: this.rejectedRequirements,
            totalCustomLogicRequirements: this.totalCustomLogicRequirements,
            completedCustomLogicRequirements: this.completedCustomLogicRequirements,
            draftCustomLogicRequirements: this.draftCustomLogicRequirements,
            proposedCustomLogicRequirements: this.proposedCustomLogicRequirements,
            inReviewCustomLogicRequirements: this.inReviewCustomLogicRequirements,
            acceptedCustomLogicRequirements: this.acceptedCustomLogicRequirements,
            rejectedCustomLogicRequirements: this.rejectedCustomLogicRequirements,
            totalAttributes: this.totalAttributes,
            attributesWithRequirements: this.attributesWithRequirements,
            attributesWithoutRequirements: this.attributesWithoutRequirements,
            totalProcesses: this.totalProcesses,
            processesWithRequirements: this.processesWithRequirements,
            processesWithoutRequirements: this.processesWithoutRequirements,
            totalBlocks: this.totalBlocks,
            blocksWithCustomLogicRequirements: this.blocksWithCustomLogicRequirements,
            blocksWithoutCustomLogicRequirements: this.blocksWithoutCustomLogicRequirements,
            assessmentUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}