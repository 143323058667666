import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPersonService} from '../../core/person/eo-person';
import {SOGetPerson} from '../../core/person/so-get-person';
import {RAPutPerson} from './ra-put-person';
import {RAPostPerson} from './ra-post-person';
import {RADeletePerson} from './ra-delete-person';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEPerson } from "./ce-person"


export class PersonCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'person';
  title = 'Person';
  ceProtocol: CEConstructor = CEPerson

  entityService: EOPersonService;
  soGetClass = SOGetPerson;
  soGet: SOGetPerson;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPersonService,
      null,
    );
    this.adapters = [
      new RAPostPerson(this),
      new RAPutPerson(this),
      new RADeletePerson(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPerson();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}