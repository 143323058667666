import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTokenFormFields {

    public certificate: FormField
    public roleGrant: FormField
    public accessToken: FormField

    constructor() {

        this.certificate = new FormField(
          'certificate',
          'Certificate',
          'certificate',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.roleGrant = new FormField(
          'roleGrant',
          'Role grant',
          'roleGrant',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.accessToken = new FormField(
          'accessToken',
          'Access token',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostTokenFormFields()

export const postTokenFormFieldOptions: {[key: string]: FormField} = {
  certificate: fields.certificate,
  roleGrant: fields.roleGrant,
  accessToken: fields.accessToken,
};