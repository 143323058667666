import {SOPutRealization} from '../../core/realization/so-put-realization';
import {RealizationCore} from './realization.core';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {FormField, SelectionOption} from 'lionheartland';
import {putRealizationFormFieldOptions} from './put-realization-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {EntityCore} from '../entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {RelationshipAttributeCore} from '../relationship-attribute/relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {RequirementCore} from '../requirement/requirement.core';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {ProcessCore} from '../process/process.core';
import {SOGetProcess} from '../../core/process/so-get-process';
import {AttributeSpecCore} from '../attribute-spec/attribute-spec.core';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutRealization extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRealizationFormFieldOptions);

  RequestClass = SOPutRealization

  constructor(
    public entityCore: RealizationCore
  ) {
    super();
    if (putRealizationFormFieldOptions.entity) {
      putRealizationFormFieldOptions.entity.makeCore = true;
    }
    if (putRealizationFormFieldOptions.simpleAttribute) {
      putRealizationFormFieldOptions.simpleAttribute.makeCore = true;
    }
    if (putRealizationFormFieldOptions.relationshipAttribute) {
      putRealizationFormFieldOptions.relationshipAttribute.makeCore = true;
    }
    if (putRealizationFormFieldOptions.requirement) {
      putRealizationFormFieldOptions.requirement.makeCore = true;
    }
    if (putRealizationFormFieldOptions.process) {
      putRealizationFormFieldOptions.process.makeCore = true;
    }
    if (putRealizationFormFieldOptions.attributeSpec) {
      putRealizationFormFieldOptions.attributeSpec.makeCore = true;
    }
    if (putRealizationFormFieldOptions.project) {
      putRealizationFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'simpleAttribute',
        SOGetSimpleAttribute,
      ),
      this.getFormValueFromIdentifier(
        'relationshipAttribute',
        SOGetRelationshipAttribute,
      ),
      this.getFormValueFromIdentifier(
        'requirement',
        SOGetRequirement,
      ),
      this.getFormValueFromIdentifier(
        'process',
        SOGetProcess,
      ),
      this.getFormValueFromIdentifier(
        'attributeSpec',
        SOGetAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}