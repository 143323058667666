import {SORespProduct} from '../../core/product/so-resp-product';
import {ProductCore} from './product.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutProductFormFields} from './put-product-form-fields';
import { CEProductAttributes } from './ce-product-attributes'


export class CEProductBase extends CEBase {

    public details: CEProductAttributes;
    public fromFields: PutProductFormFields = new PutProductFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProductCore,
        public entity: SORespProduct,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProductAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.sku)
        this.attributes.push(this.details.exRef)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.appliedConstraints)
        this.attributes.push(this.details.prices)

    }
}