import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutStep } from './so-put-step';
import { SOGetStep } from './so-get-step';
import { SOPostStep } from './so-post-step';
import { SODeleteStep } from './so-delete-step';
import {SORespStep} from './so-resp-step';
import {SOPluralStep} from './so-plural-step';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOStepService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('step', entityCore);
  }

  /** GET Step by id. Will 404 if id not found */
  get(request: SOGetStep): Observable<SOPluralStep> {
    return this.httpClient.get<SOPluralStep>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralStep, resp)),
      tap(async (response: SOPluralStep) => {
        this.log(`fetched Step`);
        // After a successful server response, update IndexedDB
        const activities = response.toSteps(); // Convert your response to an array of Step instances
        const promises = activities.map(step => db.steps.put(step));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralStep>(`get Step`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralStep> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'step' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.steps.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(stepsArray => this.processSteps(stepsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "processStepAssociations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.steps.where('processStepAssociationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(stepsArray => this.processSteps(stepsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.steps.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(stepsArray => this.processSteps(stepsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.steps.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(stepsArray => this.processSteps(stepsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.steps.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(stepsArray => this.processSteps(stepsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.steps.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(stepsArray => this.processSteps(stepsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.steps.toArray()).pipe(
                map((stepsArray) => {
                    // Convert the array of plain objects to an array of SORespStep instances
                    const sorSteps = stepsArray.map(req => plainToClass(SORespStep, req));
                    // Create and return a new instance of SOPluralStep
                    const pluralStep = new SOPluralStep();
                    pluralStep.objects = sorSteps;
                    pluralStep.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralStep;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processSteps(stepsArray) {
        const sorSteps = stepsArray.map(req => plainToClass(SORespStep, req));
        const pluralStep = new SOPluralStep();
        pluralStep.objects = sorSteps;
        pluralStep.nextOffset = null;
        return pluralStep;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostStep): Observable<SORespStep> {
  return this.httpClient.post<SORespStep>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespStep, resp)),

    tap(async (response: SORespStep) => {
      this.log(`added Step w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.steps.add(response.toStep());
    }),
    catchError(this.handleError<SORespStep>('Step post'))
  );
}


  put(request: SOPutStep): Observable<SORespStep> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespStep>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespStep, resp)),
      tap(async (response: SORespStep) => {
        this.log(`edited Step w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.steps.put(response.toStep());
      }),
      catchError(this.handleError<SORespStep>('Step put'))
    );
  }


  delete(request: SODeleteStep): Observable<SORespStep> {
    return this.httpClient.delete<SORespStep>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Step uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.steps.delete(request.uid);
      }),
      catchError(this.handleError<SORespStep>('Step delete'))
    );
  }


}
