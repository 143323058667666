

export class SOPutAppliedConstraint {

  constructor(
    public uid: string,
    public quantity: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAppliedConstraint(
      'uid' in params ? params.uid : null,
      'quantity' in params ? params.quantity : null,
    );
  }
}
