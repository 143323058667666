import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutSesMessage } from './so-put-ses-message';
import { SOGetSesMessage } from './so-get-ses-message';
import { SOPostSesMessage } from './so-post-ses-message';
import { SODeleteSesMessage } from './so-delete-ses-message';
import {SORespSesMessage} from './so-resp-ses-message';
import {SOPluralSesMessage} from './so-plural-ses-message';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOSesMessageService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('ses_message', entityCore);
  }

  /** GET SesMessage by id. Will 404 if id not found */
  get(request: SOGetSesMessage): Observable<SOPluralSesMessage> {
    return this.httpClient.get<SOPluralSesMessage>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralSesMessage, resp)),
      tap(async (response: SOPluralSesMessage) => {
        this.log(`fetched SesMessage`);
        // After a successful server response, update IndexedDB
        const activities = response.toSesMessages(); // Convert your response to an array of SesMessage instances
        const promises = activities.map(sesMessage => db.sesMessages.put(sesMessage));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralSesMessage>(`get SesMessage`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralSesMessage> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.sesMessages.toArray()).pipe(
                map((sesMessagesArray) => {
                    // Convert the array of plain objects to an array of SORespSesMessage instances
                    const sorSesMessages = sesMessagesArray.map(req => plainToClass(SORespSesMessage, req));
                    // Create and return a new instance of SOPluralSesMessage
                    const pluralSesMessage = new SOPluralSesMessage();
                    pluralSesMessage.objects = sorSesMessages;
                    pluralSesMessage.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralSesMessage;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processSesMessages(sesMessagesArray) {
        const sorSesMessages = sesMessagesArray.map(req => plainToClass(SORespSesMessage, req));
        const pluralSesMessage = new SOPluralSesMessage();
        pluralSesMessage.objects = sorSesMessages;
        pluralSesMessage.nextOffset = null;
        return pluralSesMessage;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostSesMessage): Observable<SORespSesMessage> {
  return this.httpClient.post<SORespSesMessage>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespSesMessage, resp)),

    tap(async (response: SORespSesMessage) => {
      this.log(`added SesMessage w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.sesMessages.add(response.toSesMessage());
    }),
    catchError(this.handleError<SORespSesMessage>('SesMessage post'))
  );
}


  put(request: SOPutSesMessage): Observable<SORespSesMessage> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSesMessage>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespSesMessage, resp)),
      tap(async (response: SORespSesMessage) => {
        this.log(`edited SesMessage w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.sesMessages.put(response.toSesMessage());
      }),
      catchError(this.handleError<SORespSesMessage>('SesMessage put'))
    );
  }


  delete(request: SODeleteSesMessage): Observable<SORespSesMessage> {
    return this.httpClient.delete<SORespSesMessage>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted SesMessage uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.sesMessages.delete(request.uid);
      }),
      catchError(this.handleError<SORespSesMessage>('SesMessage delete'))
    );
  }


}
