import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutProcessRoleFormFields {

    public name: FormField
    public description: FormField
    public process: FormField
    public role: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.process = new FormField(
          'process',
          'Process',
          'process',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutProcessRoleFormFields()

export const putProcessRoleFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  process: fields.process,
  role: fields.role,
  project: fields.project,
};