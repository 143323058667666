import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAssessmentSummaryFormFields {

    public assessment: FormField
    public project: FormField

    constructor() {

        this.assessment = new FormField(
          'assessment',
          'Assessment',
          'assessment',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutAssessmentSummaryFormFields()

export const putAssessmentSummaryFormFieldOptions: {[key: string]: FormField} = {
  assessment: fields.assessment,
  project: fields.project,
};