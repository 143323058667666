import { Type } from 'class-transformer';
import {SORespSimpleAttributePermission} from './so-resp-simple-attribute-permission';
import {PluralProtocol} from 'lionheartland';
import {SimpleAttributePermission} from "../../dexie/db";

export class SOPluralSimpleAttributePermission implements PluralProtocol {
  @Type(() => SORespSimpleAttributePermission)
  objects: SORespSimpleAttributePermission[] = []
  nextOffset: number = null

    toSimpleAttributePermissions(): SimpleAttributePermission[] {
        return this.objects.map(simpleAttributePermission => simpleAttributePermission.toSimpleAttributePermission());
    }
}