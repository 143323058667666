import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostPlanFormFields {

    public name: FormField
    public period: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.period = new FormField(
          'period',
          'Period',
          'period',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostPlanFormFields()

export const postPlanFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  period: fields.period,
  project: fields.project,
};