import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetSimpleAttributePermission } from '../simple-attribute-permission/so-get-simple-attribute-permission';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutSimpleAttributePermissionRule {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public simpleAttribute: SOGetSimpleAttribute = null,
    public simpleAttributePermission: SOGetSimpleAttributePermission = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSimpleAttributePermissionRule(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'simpleAttribute' in params ? params.simpleAttribute : null,
      'simpleAttributePermission' in params ? params.simpleAttributePermission : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
