import Dexie, { Table } from 'dexie';

import {ENBlockType} from "../enums";
import {MMCredential} from "lionheartland";
import {ENRequirementStatus} from "../enums";
import {ENSeverity} from "../enums";
import {ENEntityPermissionType} from "../enums";
import {ENExportType} from "../enums";
import {ENInterphaceType} from "../enums";
import {ENMediaStatus} from "../enums";
import {ENMediaTypePrimary} from "../enums";
import {ENMediaTypeSecondary} from "../enums";
import {SOMediaSpec} from "lionheartland";
import {ENMessageStatus} from "../enums";
import {ENInviteStatus} from "../enums";
import {ENPaymentIntentStatus} from "../enums";
import {ENPeriodType} from "../enums";
import {ENWeeklyReportSection} from "../enums";
import {ENInterval} from "../enums";
import {ENProcessStepType} from "../enums";
import {ENMultiplicity} from "../enums";
import {ENAttributePermissionType} from "../enums";
import {ENRoleGrantStatus} from "../enums";
import {ENSesMessageType} from "../enums";
import {ENSimpleAttributeType} from "../enums";
import {ENSubscriptionStatus} from "../enums";
import {ENTokenStatus} from "../enums";
import {ENViewType} from "../enums";

export interface AlertSubscription {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    topicUid: string | null;
    datafeedUid: string | null;
    organizationUid: string | null;
}
export interface AppliedConstraint {
    uid?: string;
    constraintUid: string | null;
    productUid: string | null;
    quantity: number | null;
}
export interface Assessment {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    totalRequirements: number | null;
    activeRequirements: number | null;
    inactiveRequirements: number | null;
    completedRequirements: number | null;
    incompleteRequirements: number | null;
    totalEntities: number | null;
    totalAttributes: number | null;
    simpleAttributes: number | null;
    relationshipAttributes: number | null;
    attributesWithRequirements: number | null;
    totalProcesses: number | null;
    processesWithRequirements: number | null;
    totalCustomLogicRequirements: number | null;
    completedCustomLogicRequirements: number | null;
    incompleteCustomLogicRequirements: number | null;
    totalBlocks: number | null;
    customBlocks: number | null;
    libraryBlocks: number | null;
    networkBlocks: number | null;
    blocksWithCustomLogicRequirements: number | null;
    totalDeficiencies: number | null;
    assessmentSummaryUid: string | null;
    deficiencyUid: string[] | null;
    reportUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface AssessmentSummary {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    totalRequirements: number | null;
    completedRequirements: number | null;
    draftRequirements: number | null;
    proposedRequirements: number | null;
    inReviewRequirements: number | null;
    acceptedRequirements: number | null;
    rejectedRequirements: number | null;
    totalCustomLogicRequirements: number | null;
    completedCustomLogicRequirements: number | null;
    draftCustomLogicRequirements: number | null;
    proposedCustomLogicRequirements: number | null;
    inReviewCustomLogicRequirements: number | null;
    acceptedCustomLogicRequirements: number | null;
    rejectedCustomLogicRequirements: number | null;
    totalAttributes: number | null;
    attributesWithRequirements: number | null;
    attributesWithoutRequirements: number | null;
    totalProcesses: number | null;
    processesWithRequirements: number | null;
    processesWithoutRequirements: number | null;
    totalBlocks: number | null;
    blocksWithCustomLogicRequirements: number | null;
    blocksWithoutCustomLogicRequirements: number | null;
    assessmentUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface AssignedProject {
    uid?: string;
    name: string | null;
    personUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Attachment {
    uid?: string;
    mediaUid: string | null;
    organizationUid: string | null;
}
export interface AttributeSpec {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    interphaceUid: string | null;
    simpleAttributeUid: string | null;
    relationshipAttributeUid: string | null;
    realizationsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Backlog {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    worksUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Block {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    active: boolean | null;
    type: ENBlockType | null;
    blocksUid: string[] | null;
    parentUid: string | null;
    customLogicRequirementUid: string | null;
    interphaceUid: string | null;
    deficiencysUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Certificate {
    uid?: string;
    userUid: string | null;
    dateCreated: Date | null;
    credential: MMCredential | null;
    tokensUid: string[] | null;
}
export interface Constraint {
    uid?: string;
    name: string | null;
    identifier: string | null;
    description: string | null;
    appliedConstraintsUid: string[] | null;
}
export interface CustomLogicRequirement {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    active: boolean | null;
    currentStatus: ENRequirementStatus | null;
    customLogicRequirementsUid: string[] | null;
    parentUid: string | null;
    blocksUid: string[] | null;
    simpleAttributesUid: string[] | null;
    relationshipAttributesUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Datafeed {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    notificationsUid: string[] | null;
    alertSubscriptionsUid: string[] | null;
    userUid: string | null;
}
export interface Deficiency {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    active: boolean | null;
    severity: ENSeverity | null;
    worksUid: string[] | null;
    assessmentUid: string | null;
    blockUid: string | null;
    processUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Entity {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    editPermissions: boolean | null;
    entityPermissionsUid: string[] | null;
    realizationsUid: string[] | null;
    simpleAttributesUid: string[] | null;
    relationshipAttributesUid: string[] | null;
    interphacesUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface EntityPermission {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    whiteList: boolean | null;
    permissionType: ENEntityPermissionType | null;
    entityUid: string | null;
    rolePermissionsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface ExportProject {
    uid?: string;
    name: string | null;
    reasonForExport: string | null;
    exportType: ENExportType | null;
    dateCreated: Date | null;
    downloadLink: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Hello {
    uid?: string;
    loginStyle: number | null;
}
export interface Identity {
    uid?: string;
    idToken: string | null;
    accessToken: string | null;
    provider: string | null;
    userUid: string | null;
}
export interface Ingest {
    uid?: string;
    entityName: string | null;
    data: object | null;
    organizationUid: string | null;
}
export interface Interphace {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    interphaceType: ENInterphaceType | null;
    blocksUid: string[] | null;
    entityUid: string | null;
    attributeSpecsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Invoice {
    uid?: string;
    ref: string | null;
    exRef: string | null;
    amountDue: number | null;
    amountPaid: number | null;
    amountRemaining: number | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    dateDue: Date | null;
    periodStart: Date | null;
    periodEnd: Date | null;
    paid: boolean | null;
    subscriptionUid: string | null;
    paymentIntentsUid: string[] | null;
    invoiceLinesUid: string[] | null;
}
export interface InvoiceLine {
    uid?: string;
    amount: number | null;
    quantity: number | null;
    dateCreated: Date | null;
    description: string | null;
    priceUid: string | null;
    invoiceUid: string | null;
}
export interface Media {
    uid?: string;
    name: string | null;
    description: string | null;
    size: number | null;
    mediaStatus: ENMediaStatus | null;
    mediaTypePrimary: ENMediaTypePrimary | null;
    mediaTypeSecondary: ENMediaTypeSecondary | null;
    links: SOMediaSpec[] | null;
    dateCreated: Date | null;
    attachmentsUid: string[] | null;
    organizationUid: string | null;
}
export interface Message {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    notificationsUid: string[] | null;
    topicUid: string | null;
    organizationUid: string | null;
}
export interface Notification {
    uid?: string;
    name: string | null;
    description: string | null;
    status: ENMessageStatus | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    datafeedUid: string | null;
    messageUid: string | null;
    organizationUid: string | null;
}
export interface Organization {
    uid?: string;
    name: string | null;
    dateCreated: Date | null;
    identifier: string | null;
    subscriptionsUid: string[] | null;
    exRefPayment: string | null;
    exRefPaymentsLink: string | null;
    roleGrantsUid: string[] | null;
    mediasUid: string[] | null;
    invitesUid: string[] | null;
    ingestsUid: string[] | null;
    attachmentsUid: string[] | null;
    alertSubscriptionsUid: string[] | null;
    messagesUid: string[] | null;
    notificationsUid: string[] | null;
    topicsUid: string[] | null;
    assessmentUid: string[] | null;
    assessmentSummaryUid: string[] | null;
    blockUid: string[] | null;
    deficiencyUid: string[] | null;
    periodUid: string[] | null;
    reportUid: string[] | null;
    requirementUid: string[] | null;
    customLogicRequirementUid: string[] | null;
    workUid: string[] | null;
    realizationUid: string[] | null;
    entityUid: string[] | null;
    relationshipUid: string[] | null;
    relationshipAttributeUid: string[] | null;
    simpleAttributeUid: string[] | null;
    processUid: string[] | null;
    projectUid: string[] | null;
    interphaceUid: string[] | null;
    backlogUid: string[] | null;
    attributeSpecUid: string[] | null;
    exportProjectUid: string[] | null;
    simpleAttributePermissionUid: string[] | null;
    simpleAttributePermissionRuleUid: string[] | null;
    relationshipAttributePermissionUid: string[] | null;
    relationshipAttributePermissionRuleUid: string[] | null;
    entityPermissionUid: string[] | null;
    planUid: string[] | null;
    personUid: string[] | null;
    assignedProjectUid: string[] | null;
    planWorkAssociationUid: string[] | null;
    rolePermissionUid: string[] | null;
    roleUid: string[] | null;
    processRoleUid: string[] | null;
    stepUid: string[] | null;
    processStepAssociationUid: string[] | null;
}
export interface OrganizationInvite {
    uid?: string;
    username: string | null;
    email: string | null;
    status: ENInviteStatus | null;
    dateCreated: Date | null;
    userUid: string | null;
    roleUid: string | null;
    organizationUid: string | null;
}
export interface PaymentIntent {
    uid?: string;
    amount: number | null;
    dateCreated: Date | null;
    status: ENPaymentIntentStatus | null;
    invoiceUid: string | null;
}
export interface Period {
    uid?: string;
    name: string | null;
    numeral: number | null;
    startDate: Date | null;
    endDate: Date | null;
    dateCreated: Date | null;
    type: ENPeriodType | null;
    plansUid: string[] | null;
    worksUid: string[] | null;
    reportsUid: string[] | null;
    periodsUid: string[] | null;
    parentUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Person {
    uid?: string;
    name: string | null;
    description: string | null;
    relation: string | null;
    dateCreated: Date | null;
    assignedProjectsUid: string[] | null;
    userUid: string | null;
    organizationUid: string | null;
}
export interface Plan {
    uid?: string;
    name: string | null;
    dateCreated: Date | null;
    periodUid: string | null;
    planWorkAssociationsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface PlanWorkAssociation {
    uid?: string;
    name: string | null;
    dateCreated: Date | null;
    reportSection: ENWeeklyReportSection | null;
    planUid: string | null;
    workUid: string | null;
    organizationUid: string | null;
}
export interface Price {
    uid?: string;
    name: string | null;
    sku: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    exRef: string | null;
    amount: number | null;
    trailDays: number | null;
    interval: ENInterval | null;
    active: boolean | null;
    productUid: string | null;
    subscriptionsUid: string[] | null;
    invoiceLinesUid: string[] | null;
}
export interface Process {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    deficiencysUid: string[] | null;
    realizationsUid: string[] | null;
    processRolesUid: string[] | null;
    projectUid: string | null;
    processStepAssociationsUid: string[] | null;
    organizationUid: string | null;
}
export interface ProcessRole {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    processUid: string | null;
    roleUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface ProcessStepAssociation {
    uid?: string;
    name: string | null;
    processStepType: ENProcessStepType | null;
    dateCreated: Date | null;
    processUid: string | null;
    stepUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Product {
    uid?: string;
    name: string | null;
    sku: string | null;
    exRef: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    active: boolean | null;
    appliedConstraintsUid: string[] | null;
    pricesUid: string[] | null;
}
export interface Project {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    assessmentsUid: string[] | null;
    assessmentSummarysUid: string[] | null;
    blocksUid: string[] | null;
    deficiencysUid: string[] | null;
    entitysUid: string[] | null;
    periodsUid: string[] | null;
    realizationsUid: string[] | null;
    relationshipsUid: string[] | null;
    relationshipAttributesUid: string[] | null;
    reportsUid: string[] | null;
    requirementsUid: string[] | null;
    customLogicRequirementsUid: string[] | null;
    simpleAttributesUid: string[] | null;
    worksUid: string[] | null;
    processesUid: string[] | null;
    interphacesUid: string[] | null;
    backlogsUid: string[] | null;
    attributeSpecUid: string[] | null;
    exportProjectUid: string[] | null;
    simpleAttributePermissionUid: string[] | null;
    simpleAttributePermissionRuleUid: string[] | null;
    relationshipAttributePermissionUid: string[] | null;
    relationshipAttributePermissionRuleUid: string[] | null;
    entityPermissionUid: string[] | null;
    plansUid: string[] | null;
    assignedProjectsUid: string[] | null;
    rolePermissionsUid: string[] | null;
    processRolesUid: string[] | null;
    stepsUid: string[] | null;
    processStepAssociationsUid: string[] | null;
    organizationUid: string | null;
}
export interface Realization {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    entityUid: string | null;
    simpleAttributeUid: string | null;
    relationshipAttributeUid: string | null;
    requirementUid: string | null;
    processUid: string | null;
    attributeSpecUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Relationship {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    relationshipAttributesUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface RelationshipAttribute {
    uid?: string;
    name: string | null;
    description: string | null;
    multiplicity: ENMultiplicity | null;
    dateCreated: Date | null;
    editPermissions: boolean | null;
    relationshipAttributePermissionsUid: string[] | null;
    relationshipAttributePermissionRulesUid: string[] | null;
    realizationsUid: string[] | null;
    entityUid: string | null;
    customLogicRequirementUid: string | null;
    relationshipUid: string | null;
    attributeSpecsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface RelationshipAttributePermission {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    whiteList: boolean | null;
    permissionType: ENAttributePermissionType | null;
    relationshipAttributeUid: string | null;
    relationshipAttributePermissionRulesUid: string[] | null;
    rolePermissionsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface RelationshipAttributePermissionRule {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    whiteList: boolean | null;
    permissionType: ENAttributePermissionType | null;
    relationshipAttributeUid: string | null;
    relationshipAttributePermissionUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Report {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    active: boolean | null;
    periodUid: string | null;
    assessmentsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Requirement {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    active: boolean | null;
    currentStatus: ENRequirementStatus | null;
    requirementsUid: string[] | null;
    parentUid: string | null;
    realizationsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Role {
    uid?: string;
    name: string | null;
    identifier: string | null;
    roleGrantsUid: string[] | null;
    viewGrantsUid: string[] | null;
    invitesUid: string[] | null;
    rolePermissionsUid: string[] | null;
    processRolesUid: string[] | null;
    organizationUid: string | null;
}
export interface RoleGrant {
    uid?: string;
    dateCreated: Date | null;
    isDefault: boolean | null;
    status: ENRoleGrantStatus | null;
    userUid: string | null;
    roleUid: string | null;
    organizationUid: string | null;
    tokensUid: string[] | null;
}
export interface RolePermission {
    uid?: string;
    name: string | null;
    dateCreated: Date | null;
    entityPermissionUid: string | null;
    relationshipAttributePermissionUid: string | null;
    simpleAttributePermissionUid: string | null;
    roleUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface SesMessage {
    uid?: string;
    data: string | null;
    dateCreated: Date | null;
    messageType: ENSesMessageType | null;
}
export interface SimpleAttribute {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    attributeType: ENSimpleAttributeType | null;
    editPermissions: boolean | null;
    simpleAttributePermissionsUid: string[] | null;
    simpleAttributePermissionRulesUid: string[] | null;
    realizationsUid: string[] | null;
    entityUid: string | null;
    customLogicRequirementUid: string | null;
    attributeSpecsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface SimpleAttributePermission {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    whiteList: boolean | null;
    permissionType: ENAttributePermissionType | null;
    simpleAttributeUid: string | null;
    simpleAttributePermissionRulesUid: string[] | null;
    rolePermissionsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface SimpleAttributePermissionRule {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    simpleAttributeUid: string | null;
    simpleAttributePermissionUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Step {
    uid?: string;
    name: string | null;
    description: string | null;
    stepNumber: number | null;
    dateCreated: Date | null;
    processStepAssociationsUid: string[] | null;
    projectUid: string | null;
    organizationUid: string | null;
}
export interface Subscription {
    uid?: string;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    dateExpires: Date | null;
    exRef: string | null;
    organizationUid: string | null;
    priceUid: string | null;
    status: ENSubscriptionStatus | null;
    invoicesUid: string[] | null;
}
export interface Token {
    uid?: string;
    certificateUid: string | null;
    dateCreated: Date | null;
    status: ENTokenStatus | null;
    roleGrantUid: string | null;
    identityToken: string | null;
    priorIdentityToken: string | null;
    refreshToken: string | null;
    accessToken: string | null;
    codeVerifier: string | null;
    code: string | null;
    provider: string | null;
}
export interface Topic {
    uid?: string;
    name: string | null;
    description: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    alertSubscriptionsUid: string[] | null;
    messagesUid: string[] | null;
    organizationUid: string | null;
}
export interface User {
    uid?: string;
    username: string | null;
    password: string | null;
    givenName: string | null;
    surname: string | null;
    email: string | null;
    emailPending: string | null;
    emailVerified: boolean | null;
    emailVerificationToken: string | null;
    dateCreated: Date | null;
    identityUid: string | null;
    certificatesUid: string[] | null;
    roleGrantsUid: string[] | null;
    invitesUid: string[] | null;
    datafeedsUid: string[] | null;
    personUid: string | null;
}
export interface View {
    uid?: string;
    name: string | null;
    identifier: string | null;
    type: ENViewType | null;
    viewGrantsUid: string[] | null;
}
export interface ViewGrant {
    uid?: string;
    roleUid: string | null;
    viewUid: string | null;
}
export interface Work {
    uid?: string;
    name: string | null;
    number: number | null;
    description: string | null;
    dateCreated: Date | null;
    active: boolean | null;
    deficiencyUid: string | null;
    periodUid: string | null;
    planWorkAssociationsUid: string[] | null;
    backlogUid: string | null;
    projectUid: string | null;
    organizationUid: string | null;
}

export class AppDB extends Dexie {
    alertSubscriptions!: Table<AlertSubscription, string>;
    appliedConstraints!: Table<AppliedConstraint, string>;
    assessments!: Table<Assessment, string>;
    assessmentSummarys!: Table<AssessmentSummary, string>;
    assignedProjects!: Table<AssignedProject, string>;
    attachments!: Table<Attachment, string>;
    attributeSpecs!: Table<AttributeSpec, string>;
    backlogs!: Table<Backlog, string>;
    blocks!: Table<Block, string>;
    certificates!: Table<Certificate, string>;
    constraints!: Table<Constraint, string>;
    customLogicRequirements!: Table<CustomLogicRequirement, string>;
    datafeeds!: Table<Datafeed, string>;
    deficiencys!: Table<Deficiency, string>;
    entitys!: Table<Entity, string>;
    entityPermissions!: Table<EntityPermission, string>;
    exportProjects!: Table<ExportProject, string>;
    hellos!: Table<Hello, string>;
    identitys!: Table<Identity, string>;
    ingests!: Table<Ingest, string>;
    interphaces!: Table<Interphace, string>;
    invoices!: Table<Invoice, string>;
    invoiceLines!: Table<InvoiceLine, string>;
    medias!: Table<Media, string>;
    messages!: Table<Message, string>;
    notifications!: Table<Notification, string>;
    organizations!: Table<Organization, string>;
    organizationInvites!: Table<OrganizationInvite, string>;
    paymentIntents!: Table<PaymentIntent, string>;
    periods!: Table<Period, string>;
    persons!: Table<Person, string>;
    plans!: Table<Plan, string>;
    planWorkAssociations!: Table<PlanWorkAssociation, string>;
    prices!: Table<Price, string>;
    processs!: Table<Process, string>;
    processRoles!: Table<ProcessRole, string>;
    processStepAssociations!: Table<ProcessStepAssociation, string>;
    products!: Table<Product, string>;
    projects!: Table<Project, string>;
    realizations!: Table<Realization, string>;
    relationships!: Table<Relationship, string>;
    relationshipAttributes!: Table<RelationshipAttribute, string>;
    relationshipAttributePermissions!: Table<RelationshipAttributePermission, string>;
    relationshipAttributePermissionRules!: Table<RelationshipAttributePermissionRule, string>;
    reports!: Table<Report, string>;
    requirements!: Table<Requirement, string>;
    roles!: Table<Role, string>;
    roleGrants!: Table<RoleGrant, string>;
    rolePermissions!: Table<RolePermission, string>;
    sesMessages!: Table<SesMessage, string>;
    simpleAttributes!: Table<SimpleAttribute, string>;
    simpleAttributePermissions!: Table<SimpleAttributePermission, string>;
    simpleAttributePermissionRules!: Table<SimpleAttributePermissionRule, string>;
    steps!: Table<Step, string>;
    subscriptions!: Table<Subscription, string>;
    tokens!: Table<Token, string>;
    topics!: Table<Topic, string>;
    users!: Table<User, string>;
    views!: Table<View, string>;
    viewGrants!: Table<ViewGrant, string>;
    works!: Table<Work, string>;

    constructor() {
        super('ngdexieliveQuery');
            this.version(1).stores({
                alertSubscriptions: 'uid, nameUid, descriptionUid, dateCreatedUid, dateUpdatedUid, topicUid, datafeedUid, organizationUid',
                appliedConstraints: 'uid, constraintUid, productUid, quantityUid',
                assessments: 'uid, nameUid, descriptionUid, dateCreatedUid, totalRequirementsUid, activeRequirementsUid, inactiveRequirementsUid, completedRequirementsUid, incompleteRequirementsUid, totalEntitiesUid, totalAttributesUid, simpleAttributesUid, relationshipAttributesUid, attributesWithRequirementsUid, totalProcessesUid, processesWithRequirementsUid, totalCustomLogicRequirementsUid, completedCustomLogicRequirementsUid, incompleteCustomLogicRequirementsUid, totalBlocksUid, customBlocksUid, libraryBlocksUid, networkBlocksUid, blocksWithCustomLogicRequirementsUid, totalDeficienciesUid, assessmentSummaryUid, deficiencyUid, reportUid, projectUid, organizationUid',
                assessmentSummarys: 'uid, nameUid, descriptionUid, dateCreatedUid, totalRequirementsUid, completedRequirementsUid, draftRequirementsUid, proposedRequirementsUid, inReviewRequirementsUid, acceptedRequirementsUid, rejectedRequirementsUid, totalCustomLogicRequirementsUid, completedCustomLogicRequirementsUid, draftCustomLogicRequirementsUid, proposedCustomLogicRequirementsUid, inReviewCustomLogicRequirementsUid, acceptedCustomLogicRequirementsUid, rejectedCustomLogicRequirementsUid, totalAttributesUid, attributesWithRequirementsUid, attributesWithoutRequirementsUid, totalProcessesUid, processesWithRequirementsUid, processesWithoutRequirementsUid, totalBlocksUid, blocksWithCustomLogicRequirementsUid, blocksWithoutCustomLogicRequirementsUid, assessmentUid, projectUid, organizationUid',
                assignedProjects: 'uid, nameUid, personUid, projectUid, organizationUid',
                attachments: 'uid, mediaUid, organizationUid',
                attributeSpecs: 'uid, nameUid, descriptionUid, dateCreatedUid, interphaceUid, simpleAttributeUid, relationshipAttributeUid, realizationsUid, projectUid, organizationUid',
                backlogs: 'uid, nameUid, descriptionUid, dateCreatedUid, worksUid, projectUid, organizationUid',
                blocks: 'uid, nameUid, descriptionUid, dateCreatedUid, activeUid, typeUid, blocksUid, parentUid, customLogicRequirementUid, interphaceUid, deficiencysUid, projectUid, organizationUid',
                certificates: 'uid, userUid, dateCreatedUid, credentialUid, tokensUid',
                constraints: 'uid, nameUid, identifierUid, descriptionUid, appliedConstraintsUid',
                customLogicRequirements: 'uid, nameUid, descriptionUid, dateCreatedUid, activeUid, currentStatusUid, customLogicRequirementsUid, parentUid, blocksUid, simpleAttributesUid, relationshipAttributesUid, projectUid, organizationUid',
                datafeeds: 'uid, nameUid, descriptionUid, dateCreatedUid, dateUpdatedUid, notificationsUid, alertSubscriptionsUid, userUid',
                deficiencys: 'uid, nameUid, descriptionUid, dateCreatedUid, activeUid, severityUid, worksUid, assessmentUid, blockUid, processUid, projectUid, organizationUid',
                entitys: 'uid, nameUid, descriptionUid, dateCreatedUid, editPermissionsUid, entityPermissionsUid, realizationsUid, simpleAttributesUid, relationshipAttributesUid, interphacesUid, projectUid, organizationUid',
                entityPermissions: 'uid, nameUid, descriptionUid, dateCreatedUid, whiteListUid, permissionTypeUid, entityUid, rolePermissionsUid, projectUid, organizationUid',
                exportProjects: 'uid, nameUid, reasonForExportUid, exportTypeUid, dateCreatedUid, downloadLinkUid, projectUid, organizationUid',
                hellos: 'uid, loginStyleUid',
                identitys: 'uid, idTokenUid, accessTokenUid, providerUid, userUid',
                ingests: 'uid, entityNameUid, dataUid, organizationUid',
                interphaces: 'uid, nameUid, descriptionUid, dateCreatedUid, interphaceTypeUid, blocksUid, entityUid, attributeSpecsUid, projectUid, organizationUid',
                invoices: 'uid, refUid, exRefUid, amountDueUid, amountPaidUid, amountRemainingUid, dateCreatedUid, dateUpdatedUid, dateDueUid, periodStartUid, periodEndUid, paidUid, subscriptionUid, paymentIntentsUid, invoiceLinesUid',
                invoiceLines: 'uid, amountUid, quantityUid, dateCreatedUid, descriptionUid, priceUid, invoiceUid',
                medias: 'uid, nameUid, descriptionUid, sizeUid, mediaStatusUid, mediaTypePrimaryUid, mediaTypeSecondaryUid, linksUid, dateCreatedUid, attachmentsUid, organizationUid',
                messages: 'uid, nameUid, descriptionUid, dateCreatedUid, dateUpdatedUid, notificationsUid, topicUid, organizationUid',
                notifications: 'uid, nameUid, descriptionUid, statusUid, dateCreatedUid, dateUpdatedUid, datafeedUid, messageUid, organizationUid',
                organizations: 'uid, nameUid, dateCreatedUid, identifierUid, subscriptionsUid, exRefPaymentUid, exRefPaymentsLinkUid, roleGrantsUid, mediasUid, invitesUid, ingestsUid, attachmentsUid, alertSubscriptionsUid, messagesUid, notificationsUid, topicsUid, assessmentUid, assessmentSummaryUid, blockUid, deficiencyUid, periodUid, reportUid, requirementUid, customLogicRequirementUid, workUid, realizationUid, entityUid, relationshipUid, relationshipAttributeUid, simpleAttributeUid, processUid, projectUid, interphaceUid, backlogUid, attributeSpecUid, exportProjectUid, simpleAttributePermissionUid, simpleAttributePermissionRuleUid, relationshipAttributePermissionUid, relationshipAttributePermissionRuleUid, entityPermissionUid, planUid, personUid, assignedProjectUid, planWorkAssociationUid, rolePermissionUid, roleUid, processRoleUid, stepUid, processStepAssociationUid',
                organizationInvites: 'uid, usernameUid, emailUid, statusUid, dateCreatedUid, userUid, roleUid, organizationUid',
                paymentIntents: 'uid, amountUid, dateCreatedUid, statusUid, invoiceUid',
                periods: 'uid, nameUid, numeralUid, startDateUid, endDateUid, dateCreatedUid, typeUid, plansUid, worksUid, reportsUid, periodsUid, parentUid, projectUid, organizationUid',
                persons: 'uid, nameUid, descriptionUid, relationUid, dateCreatedUid, assignedProjectsUid, userUid, organizationUid',
                plans: 'uid, nameUid, dateCreatedUid, periodUid, planWorkAssociationsUid, projectUid, organizationUid',
                planWorkAssociations: 'uid, nameUid, dateCreatedUid, reportSectionUid, planUid, workUid, organizationUid',
                prices: 'uid, nameUid, skuUid, dateCreatedUid, dateUpdatedUid, exRefUid, amountUid, trailDaysUid, intervalUid, activeUid, productUid, subscriptionsUid, invoiceLinesUid',
                processs: 'uid, nameUid, descriptionUid, dateCreatedUid, deficiencysUid, realizationsUid, processRolesUid, projectUid, processStepAssociationsUid, organizationUid',
                processRoles: 'uid, nameUid, descriptionUid, dateCreatedUid, processUid, roleUid, projectUid, organizationUid',
                processStepAssociations: 'uid, nameUid, processStepTypeUid, dateCreatedUid, processUid, stepUid, projectUid, organizationUid',
                products: 'uid, nameUid, skuUid, exRefUid, dateCreatedUid, dateUpdatedUid, activeUid, appliedConstraintsUid, pricesUid',
                projects: 'uid, nameUid, descriptionUid, dateCreatedUid, assessmentsUid, assessmentSummarysUid, blocksUid, deficiencysUid, entitysUid, periodsUid, realizationsUid, relationshipsUid, relationshipAttributesUid, reportsUid, requirementsUid, customLogicRequirementsUid, simpleAttributesUid, worksUid, processesUid, interphacesUid, backlogsUid, attributeSpecUid, exportProjectUid, simpleAttributePermissionUid, simpleAttributePermissionRuleUid, relationshipAttributePermissionUid, relationshipAttributePermissionRuleUid, entityPermissionUid, plansUid, assignedProjectsUid, rolePermissionsUid, processRolesUid, stepsUid, processStepAssociationsUid, organizationUid',
                realizations: 'uid, nameUid, descriptionUid, dateCreatedUid, entityUid, simpleAttributeUid, relationshipAttributeUid, requirementUid, processUid, attributeSpecUid, projectUid, organizationUid',
                relationships: 'uid, nameUid, descriptionUid, dateCreatedUid, relationshipAttributesUid, projectUid, organizationUid',
                relationshipAttributes: 'uid, nameUid, descriptionUid, multiplicityUid, dateCreatedUid, editPermissionsUid, relationshipAttributePermissionsUid, relationshipAttributePermissionRulesUid, realizationsUid, entityUid, customLogicRequirementUid, relationshipUid, attributeSpecsUid, projectUid, organizationUid',
                relationshipAttributePermissions: 'uid, nameUid, descriptionUid, dateCreatedUid, whiteListUid, permissionTypeUid, relationshipAttributeUid, relationshipAttributePermissionRulesUid, rolePermissionsUid, projectUid, organizationUid',
                relationshipAttributePermissionRules: 'uid, nameUid, descriptionUid, dateCreatedUid, whiteListUid, permissionTypeUid, relationshipAttributeUid, relationshipAttributePermissionUid, projectUid, organizationUid',
                reports: 'uid, nameUid, descriptionUid, dateCreatedUid, activeUid, periodUid, assessmentsUid, projectUid, organizationUid',
                requirements: 'uid, nameUid, descriptionUid, dateCreatedUid, activeUid, currentStatusUid, requirementsUid, parentUid, realizationsUid, projectUid, organizationUid',
                roles: 'uid, nameUid, identifierUid, roleGrantsUid, viewGrantsUid, invitesUid, rolePermissionsUid, processRolesUid, organizationUid',
                roleGrants: 'uid, dateCreatedUid, isDefaultUid, statusUid, userUid, roleUid, organizationUid, tokensUid',
                rolePermissions: 'uid, nameUid, dateCreatedUid, entityPermissionUid, relationshipAttributePermissionUid, simpleAttributePermissionUid, roleUid, projectUid, organizationUid',
                sesMessages: 'uid, dataUid, dateCreatedUid, messageTypeUid',
                simpleAttributes: 'uid, nameUid, descriptionUid, dateCreatedUid, attributeTypeUid, editPermissionsUid, simpleAttributePermissionsUid, simpleAttributePermissionRulesUid, realizationsUid, entityUid, customLogicRequirementUid, attributeSpecsUid, projectUid, organizationUid',
                simpleAttributePermissions: 'uid, nameUid, descriptionUid, dateCreatedUid, whiteListUid, permissionTypeUid, simpleAttributeUid, simpleAttributePermissionRulesUid, rolePermissionsUid, projectUid, organizationUid',
                simpleAttributePermissionRules: 'uid, nameUid, descriptionUid, dateCreatedUid, simpleAttributeUid, simpleAttributePermissionUid, projectUid, organizationUid',
                steps: 'uid, nameUid, descriptionUid, stepNumberUid, dateCreatedUid, processStepAssociationsUid, projectUid, organizationUid',
                subscriptions: 'uid, dateCreatedUid, dateUpdatedUid, dateExpiresUid, exRefUid, organizationUid, priceUid, statusUid, invoicesUid',
                tokens: 'uid, certificateUid, dateCreatedUid, statusUid, roleGrantUid, identityTokenUid, priorIdentityTokenUid, refreshTokenUid, accessTokenUid, codeVerifierUid, codeUid, providerUid',
                topics: 'uid, nameUid, descriptionUid, dateCreatedUid, dateUpdatedUid, alertSubscriptionsUid, messagesUid, organizationUid',
                users: 'uid, usernameUid, passwordUid, givenNameUid, surnameUid, emailUid, emailPendingUid, emailVerifiedUid, emailVerificationTokenUid, dateCreatedUid, identityUid, certificatesUid, roleGrantsUid, invitesUid, datafeedsUid, personUid',
                views: 'uid, nameUid, identifierUid, typeUid, viewGrantsUid',
                viewGrants: 'uid, roleUid, viewUid',
                works: 'uid, nameUid, numberUid, descriptionUid, dateCreatedUid, activeUid, deficiencyUid, periodUid, planWorkAssociationsUid, backlogUid, projectUid, organizationUid',
        });
    }
}

export const db = new AppDB();