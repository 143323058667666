import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from 'lionheartland';
import {Identity} from "../../dexie/db";

export class SORespIdentity implements ObjectiveProtocol {

  entityType: string = 'identity';
  uid: string;
  provider: string;
  @Type(() => SORespUser)
  user: SORespUser;

    toIdentity(): Identity {
        return {
            uid: this.uid,
            idToken: null,
            accessToken: null,
            provider: this.provider,
            userUid: this.user ? this.user.uid : null,
}

        };
}