import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSubscriptionService} from '../../core/subscription/eo-subscription';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {RAPutSubscription} from './ra-put-subscription';
import {RAPostSubscription} from './ra-post-subscription';
import {RADeleteSubscription} from './ra-delete-subscription';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CESubscription } from "./ce-subscription"


export class SubscriptionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'subscription';
  title = 'Subscription';
  ceProtocol: CEConstructor = CESubscription

  entityService: EOSubscriptionService;
  soGetClass = SOGetSubscription;
  soGet: SOGetSubscription;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSubscriptionService,
      null,
    );
    this.adapters = [
      new RAPostSubscription(this),
      new RAPutSubscription(this),
      new RADeleteSubscription(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSubscription();
    this.soGet.queryOptions.limit = this.limit;
  }


}