import { SOGetProcess } from '../process/so-get-process';
import { SOGetRole } from '../role/so-get-role';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetProcessRole implements QueryProtocol {

  constructor(
    public uid: string = null,
    public name: string = null,
    public process: SOGetProcess = null,
    public role: SOGetRole = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetProcessRole(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'process' in params ? params.process : null,
      'role' in params ? params.role : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
