import { Type } from 'class-transformer';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespSimpleAttribute } from '../simple-attribute/so-resp-simple-attribute';
import { SORespRelationshipAttribute } from '../relationship-attribute/so-resp-relationship-attribute';
import { SORespRequirement } from '../requirement/so-resp-requirement';
import { SORespProcess } from '../process/so-resp-process';
import { SORespAttributeSpec } from '../attribute-spec/so-resp-attribute-spec';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Realization} from "../../dexie/db";

export class SORespRealization implements ObjectiveProtocol {

  entityType: string = 'realization';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespEntity)
  entity: SORespEntity = null;
  @Type(() => SORespSimpleAttribute)
  simpleAttribute: SORespSimpleAttribute = null;
  @Type(() => SORespRelationshipAttribute)
  relationshipAttribute: SORespRelationshipAttribute = null;
  @Type(() => SORespRequirement)
  requirement: SORespRequirement = null;
  @Type(() => SORespProcess)
  process: SORespProcess = null;
  @Type(() => SORespAttributeSpec)
  attributeSpec: SORespAttributeSpec = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toRealization(): Realization {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            entityUid: this.entity ? this.entity.uid : null,
            simpleAttributeUid: this.simpleAttribute ? this.simpleAttribute.uid : null,
            relationshipAttributeUid: this.relationshipAttribute ? this.relationshipAttribute.uid : null,
            requirementUid: this.requirement ? this.requirement.uid : null,
            processUid: this.process ? this.process.uid : null,
            attributeSpecUid: this.attributeSpec ? this.attributeSpec.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}