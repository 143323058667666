import { Type } from 'class-transformer';
import {SORespMessage} from './so-resp-message';
import {PluralProtocol} from 'lionheartland';
import {Message} from "../../dexie/db";

export class SOPluralMessage implements PluralProtocol {
  @Type(() => SORespMessage)
  objects: SORespMessage[] = []
  nextOffset: number = null

    toMessages(): Message[] {
        return this.objects.map(message => message.toMessage());
    }
}