import { Type } from 'class-transformer';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENEntityPermissionType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {EntityPermission} from "../../dexie/db";

export class SORespEntityPermission implements ObjectiveProtocol {

  entityType: string = 'entityPermission';
  uid: string;
  name: string;
  description: string = "";
  whiteList: boolean;
  permissionType: ENEntityPermissionType;
  @Type(() => SORespEntity)
  entity: SORespEntity = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toEntityPermission(): EntityPermission {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            whiteList: this.whiteList,
            permissionType: this.permissionType,
            entityUid: this.entity ? this.entity.uid : null,
            rolePermissionsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}