import {SORespMedia} from '../../core/media/so-resp-media';
import {MediaCore} from './media.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutMediaFormFields} from './put-media-form-fields';
import { CEMediaAttributes } from './ce-media-attributes'


export class CEMediaBase extends CEBase {

    public details: CEMediaAttributes;
    public fromFields: PutMediaFormFields = new PutMediaFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: MediaCore,
        public entity: SORespMedia,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEMediaAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.size)
        this.attributes.push(this.details.links)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.attachments)

    }
}