import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostRealizationFormFields {

    public name: FormField
    public description: FormField
    public entity: FormField
    public simpleAttribute: FormField
    public relationshipAttribute: FormField
    public requirement: FormField
    public process: FormField
    public attributeSpec: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttribute = new FormField(
          'simpleAttribute',
          'Simple attribute',
          'simpleAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.relationshipAttribute = new FormField(
          'relationshipAttribute',
          'Relationship attribute',
          'relationshipAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.requirement = new FormField(
          'requirement',
          'Requirement',
          'requirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.process = new FormField(
          'process',
          'Process',
          'process',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.attributeSpec = new FormField(
          'attributeSpec',
          'Attribute spec',
          'attributeSpec',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostRealizationFormFields()

export const postRealizationFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  entity: fields.entity,
  simpleAttribute: fields.simpleAttribute,
  relationshipAttribute: fields.relationshipAttribute,
  requirement: fields.requirement,
  process: fields.process,
  attributeSpec: fields.attributeSpec,
  project: fields.project,
};