import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LionheartlandModule} from 'lionheartland';
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {AlertSubscriptionComponent} from "./dexie/components/alert-subscription/alert-subscription.component";
import {AppliedConstraintComponent} from "./dexie/components/applied-constraint/applied-constraint.component";
import {AssessmentComponent} from "./dexie/components/assessment/assessment.component";
import {AttachmentComponent} from "./dexie/components/attachment/attachment.component";
import {BacklogComponent} from "./dexie/components/backlog/backlog.component";
import {BlockComponent} from "./dexie/components/block/block.component";
import {CertificateComponent} from "./dexie/components/certificate/certificate.component";
import {ConstraintComponent} from "./dexie/components/constraint/constraint.component";
import {CustomLogicRequirementComponent} from "./dexie/components/custom-logic-requirement/custom-logic-requirement.component";
import {DatafeedComponent} from "./dexie/components/datafeed/datafeed.component";
import {DeficiencyComponent} from "./dexie/components/deficiency/deficiency.component";
import {EntityComponent} from "./dexie/components/entity/entity.component";
import {HelloComponent} from "./dexie/components/hello/hello.component";
import {IdentityComponent} from "./dexie/components/identity/identity.component";
import {IngestComponent} from "./dexie/components/ingest/ingest.component";
import {InvoiceComponent} from "./dexie/components/invoice/invoice.component";
import {InvoiceLineComponent} from "./dexie/components/invoice-line/invoice-line.component";
import {MediaComponent} from "./dexie/components/media/media.component";
import {MessageComponent} from "./dexie/components/message/message.component";
import {NotificationComponent} from "./dexie/components/notification/notification.component";
import {OrganizationComponent} from "./dexie/components/organization/organization.component";
import {OrganizationInviteComponent} from "./dexie/components/organization-invite/organization-invite.component";
import {PaymentIntentComponent} from "./dexie/components/payment-intent/payment-intent.component";
import {PeriodComponent} from "./dexie/components/period/period.component";
import {PriceComponent} from "./dexie/components/price/price.component";
import {ProcessComponent} from "./dexie/components/process/process.component";
import {ProductComponent} from "./dexie/components/product/product.component";
import {ProjectComponent} from "./dexie/components/project/project.component";
import {RealizationComponent} from "./dexie/components/realization/realization.component";
import {RelationshipComponent} from "./dexie/components/relationship/relationship.component";
import {RelationshipAttributeComponent} from "./dexie/components/relationship-attribute/relationship-attribute.component";
import {ReportComponent} from "./dexie/components/report/report.component";
import {RequirementComponent} from "./dexie/components/requirement/requirement.component";
import {RoleComponent} from "./dexie/components/role/role.component";
import {RoleGrantComponent} from "./dexie/components/role-grant/role-grant.component";
import {SesMessageComponent} from "./dexie/components/ses-message/ses-message.component";
import {SimpleAttributeComponent} from "./dexie/components/simple-attribute/simple-attribute.component";
import {SubscriptionComponent} from "./dexie/components/subscription/subscription.component";
import {TokenComponent} from "./dexie/components/token/token.component";
import {TopicComponent} from "./dexie/components/topic/topic.component";
import {UserComponent} from "./dexie/components/user/user.component";
import {ViewComponent} from "./dexie/components/view/view.component";
import {ViewGrantComponent} from "./dexie/components/view-grant/view-grant.component";
import {WorkComponent} from "./dexie/components/work/work.component";
import {EOAlertSubscriptionService} from "./core/alert-subscription/eo-alert-subscription";
import {EOAppliedConstraintService} from "./core/applied-constraint/eo-applied-constraint";
import {EOAssessmentService} from "./core/assessment/eo-assessment";
import {EOAttachmentService} from "./core/attachment/eo-attachment";
import {EOBacklogService} from "./core/backlog/eo-backlog";
import {EOBlockService} from "./core/block/eo-block";
import {EOCertificateService} from "./core/certificate/eo-certificate";
import {EOConstraintService} from "./core/constraint/eo-constraint";
import {EOCustomLogicRequirementService} from "./core/custom-logic-requirement/eo-custom-logic-requirement";
import {EODatafeedService} from "./core/datafeed/eo-datafeed";
import {EODeficiencyService} from "./core/deficiency/eo-deficiency";
import {EOEntityService} from "./core/entity/eo-entity";
import {EOHelloService} from "./core/hello/eo-hello";
import {EOIdentityService} from "./core/identity/eo-identity";
import {EOIngestService} from "./core/ingest/eo-ingest";
import {EOInvoiceService} from "./core/invoice/eo-invoice";
import {EOInvoiceLineService} from "./core/invoice-line/eo-invoice-line";
import {EOMediaService} from "./core/media/eo-media";
import {EOMessageService} from "./core/message/eo-message";
import {EONotificationService} from "./core/notification/eo-notification";
import {EOOrganizationService} from "./core/organization/eo-organization";
import {EOOrganizationInviteService} from "./core/organization-invite/eo-organization-invite";
import {EOPaymentIntentService} from "./core/payment-intent/eo-payment-intent";
import {EOPeriodService} from "./core/period/eo-period";
import {EOPriceService} from "./core/price/eo-price";
import {EOProcessService} from "./core/process/eo-process";
import {EOProductService} from "./core/product/eo-product";
import {EOProjectService} from "./core/project/eo-project";
import {EORealizationService} from "./core/realization/eo-realization";
import {EORelationshipService} from "./core/relationship/eo-relationship";
import {EORelationshipAttributeService} from "./core/relationship-attribute/eo-relationship-attribute";
import {EOReportService} from "./core/report/eo-report";
import {EORequirementService} from "./core/requirement/eo-requirement";
import {EORoleService} from "./core/role/eo-role";
import {EORoleGrantService} from "./core/role-grant/eo-role-grant";
import {EOSesMessageService} from "./core/ses-message/eo-ses-message";
import {EOSimpleAttributeService} from "./core/simple-attribute/eo-simple-attribute";
import {EOSubscriptionService} from "./core/subscription/eo-subscription";
import {EOTokenService} from "./core/token/eo-token";
import {EOTopicService} from "./core/topic/eo-topic";
import {EOUserService} from "./core/user/eo-user";
import {EOViewService} from "./core/view/eo-view";
import {EOViewGrantService} from "./core/view-grant/eo-view-grant";
import {EOWorkService} from "./core/work/eo-work";

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    LionheartlandModule,
  ],
  declarations: [
    AppComponent,
    AlertSubscriptionComponent,
    AppliedConstraintComponent,
    AssessmentComponent,
    AttachmentComponent,
    BacklogComponent,
    BlockComponent,
    CertificateComponent,
    ConstraintComponent,
    CustomLogicRequirementComponent,
    DatafeedComponent,
    DeficiencyComponent,
    EntityComponent,
    HelloComponent,
    IdentityComponent,
    IngestComponent,
    InvoiceComponent,
    InvoiceLineComponent,
    MediaComponent,
    MessageComponent,
    NotificationComponent,
    OrganizationComponent,
    OrganizationInviteComponent,
    PaymentIntentComponent,
    PeriodComponent,
    PriceComponent,
    ProcessComponent,
    ProductComponent,
    ProjectComponent,
    RealizationComponent,
    RelationshipComponent,
    RelationshipAttributeComponent,
    ReportComponent,
    RequirementComponent,
    RoleComponent,
    RoleGrantComponent,
    SesMessageComponent,
    SimpleAttributeComponent,
    SubscriptionComponent,
    TokenComponent,
    TopicComponent,
    UserComponent,
    ViewComponent,
    ViewGrantComponent,
    WorkComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}