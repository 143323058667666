import { SOGetInvoice } from '../invoice/so-get-invoice';
import {ENPaymentIntentStatus} from '../../enums';


export class SOPostPaymentIntent {

  constructor(
    public amount: number,
    public status: ENPaymentIntentStatus,
    public invoice: SOGetInvoice,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPaymentIntent(
      'amount' in params ? params.amount : null,
      'status' in params ? params.status : null,
      'invoice' in params ? params.invoice : null,
    );
  }
}
