import {SORespEntityPermission} from '../../core/entity-permission/so-resp-entity-permission';
import {EntityPermissionCore} from './entity-permission.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutEntityPermissionFormFields} from './put-entity-permission-form-fields';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetRolePermission} from '../../core/role-permission/so-get-role-permission';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEEntityPermissionAttributesBase {

    name: DetailEntry
    description: DetailEntry
    whiteList: DetailEntry
    permissionType: DetailEntry
    entity: DetailEntry
    rolePermissions: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespEntityPermission,
        app: CommanderApp,
        formFields: PutEntityPermissionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.whiteList = new DetailEntry(
            'White list',
            entity.whiteList,
            'whiteList',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.whiteList
        )

        this.permissionType = new DetailEntry(
            'Permission type',
            entity.permissionType,
            'permissionType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.permissionType
        )


        const entityCore = app.makeCore( 'entity');
        entityCore.soGet = SOGetEntity.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        entityCore.soGet.queryOptions.sortKey = 'dateCreated';
        entityCore.soGet.queryOptions.descending = true;
        let entityCeProtocol;
        if (entity.entity) {
            entityCeProtocol = createCEProtocol(
                entityCore.ceProtocol,
                parentEntityViewSpec,
                entityCore,
                entity.entity,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            entityCore
        )
        entityCore.title = heading ? heading : entityCore.title
        entityCore.listTitle = 'Entity'

        if (entity.entity) {
            displayStringFactory.reconfigure(
                entity.entity,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        entityCore.configureForDisplay(parentEntityViewSpec)

        this.entity = new DetailEntry(
          heading ? heading : entityCore.listTitle,
          entityCeProtocol ? entityCeProtocol.entityListView.fullHeading : null,
          'entity',
          BaseDetailType.pushedDetail,
          entityCore,
          EditableType.always,
          formFields.entity
        )
        if (entity.entity) {
          this.entity.ceProtocol = entityCeProtocol;
          this.entity.cachedListEntry = entityCore.makeListEntry(parentEntityViewSpec, entity.entity, 0)
          entityCore.selectedEntity = entity.entity;
        }
        this.entity.singleFieldSelect = true

        const rolePermissionsCore = app.makeCore( 'rolePermission');
        rolePermissionsCore.soGet = SOGetRolePermission.construct({
          entityPermission: new SOGetEntityPermission(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        rolePermissionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        rolePermissionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolePermissionsCore
        )
        rolePermissionsCore.title = heading ? heading : rolePermissionsCore.title
        rolePermissionsCore.listTitle = 'Role permissions'

        rolePermissionsCore.configureForDisplay(parentEntityViewSpec)

        this.rolePermissions = new DetailEntry(
          heading ? heading : rolePermissionsCore.listTitle,
          null,
          'rolePermissions',
          BaseDetailType.embeddedList,
          rolePermissionsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}