import {SORespView} from '../../core/view/so-resp-view';
import {ViewCore} from './view.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutViewFormFields} from './put-view-form-fields';
import { CEViewAttributes } from './ce-view-attributes'


export class CEViewBase extends CEBase {

    public details: CEViewAttributes;
    public fromFields: PutViewFormFields = new PutViewFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ViewCore,
        public entity: SORespView,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEViewAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.viewGrants)

    }
}