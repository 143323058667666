import { SOGetUser } from '../user/so-get-user';


export class SOPutIdentity {

  constructor(
    public uid: string,
    public provider: string = null,
    public user: SOGetUser = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutIdentity(
      'uid' in params ? params.uid : null,
      'provider' in params ? params.provider : null,
      'user' in params ? params.user : null,
    );
  }
}
