import { Type } from 'class-transformer';
import { SORespAssessmentSummary } from '../assessment-summary/so-resp-assessment-summary';
import { SORespReport } from '../report/so-resp-report';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {Assessment} from "../../dexie/db";

export class SORespAssessment implements ObjectiveProtocol {

  entityType: string = 'assessment';
  uid: string;
  name: string;
  description: string;
  totalRequirements: number;
  activeRequirements: number;
  inactiveRequirements: number;
  completedRequirements: number;
  incompleteRequirements: number;
  totalEntities: number;
  totalAttributes: number;
  simpleAttributes: number;
  relationshipAttributes: number;
  attributesWithRequirements: number;
  totalProcesses: number;
  processesWithRequirements: number;
  totalCustomLogicRequirements: number;
  completedCustomLogicRequirements: number;
  incompleteCustomLogicRequirements: number;
  totalBlocks: number;
  customBlocks: number;
  libraryBlocks: number;
  networkBlocks: number;
  blocksWithCustomLogicRequirements: number;
  totalDeficiencies: number;
  @Type(() => SORespAssessmentSummary)
  assessmentSummary: SORespAssessmentSummary;
  @Type(() => SORespReport)
  report: SORespReport = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toAssessment(): Assessment {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            totalRequirements: this.totalRequirements,
            activeRequirements: this.activeRequirements,
            inactiveRequirements: this.inactiveRequirements,
            completedRequirements: this.completedRequirements,
            incompleteRequirements: this.incompleteRequirements,
            totalEntities: this.totalEntities,
            totalAttributes: this.totalAttributes,
            simpleAttributes: this.simpleAttributes,
            relationshipAttributes: this.relationshipAttributes,
            attributesWithRequirements: this.attributesWithRequirements,
            totalProcesses: this.totalProcesses,
            processesWithRequirements: this.processesWithRequirements,
            totalCustomLogicRequirements: this.totalCustomLogicRequirements,
            completedCustomLogicRequirements: this.completedCustomLogicRequirements,
            incompleteCustomLogicRequirements: this.incompleteCustomLogicRequirements,
            totalBlocks: this.totalBlocks,
            customBlocks: this.customBlocks,
            libraryBlocks: this.libraryBlocks,
            networkBlocks: this.networkBlocks,
            blocksWithCustomLogicRequirements: this.blocksWithCustomLogicRequirements,
            totalDeficiencies: this.totalDeficiencies,
            assessmentSummaryUid: this.assessmentSummary ? this.assessmentSummary.uid : null,
            deficiencyUid: null,
            reportUid: this.report ? this.report.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}