import {SORespAlertSubscription} from '../../core/alert-subscription/so-resp-alert-subscription';
import {AlertSubscriptionCore} from './alert-subscription.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAlertSubscriptionFormFields} from './put-alert-subscription-form-fields';
import {SOGetAlertSubscription} from '../../core/alert-subscription/so-get-alert-subscription';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetUser} from '../../core/user/so-get-user';


export class CEAlertSubscriptionAttributesBase {

    name: DetailEntry
    description: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    topic: DetailEntry
    datafeed: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAlertSubscription,
        app: CommanderApp,
        formFields: PutAlertSubscriptionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const topicCore = app.makeCore( 'topic');
        topicCore.soGet = SOGetTopic.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        let topicCeProtocol;
        if (entity.topic) {
            topicCeProtocol = createCEProtocol(
                topicCore.ceProtocol,
                parentEntityViewSpec,
                topicCore,
                entity.topic,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            topicCore
        )
        topicCore.title = heading ? heading : topicCore.title
        topicCore.listTitle = 'Topic'

        if (entity.topic) {
            displayStringFactory.reconfigure(
                entity.topic,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        topicCore.configureForDisplay(parentEntityViewSpec)

        this.topic = new DetailEntry(
          heading ? heading : topicCore.listTitle,
          topicCeProtocol ? topicCeProtocol.entityListView.fullHeading : null,
          'topic',
          BaseDetailType.pushedDetail,
          topicCore,
          EditableType.always,
          formFields.topic
        )
        if (entity.topic) {
          this.topic.ceProtocol = topicCeProtocol;
          this.topic.cachedListEntry = topicCore.makeListEntry(parentEntityViewSpec, entity.topic, 0)
          topicCore.selectedEntity = entity.topic;
        }
        this.topic.singleFieldSelect = true

        const datafeedCore = app.makeCore( 'datafeed');
        datafeedCore.soGet = SOGetDatafeed.construct({
          user: app.getScope('user') ? new SOGetUser(app.getScope('user').uid) : null,
        });
        let datafeedCeProtocol;
        if (entity.datafeed) {
            datafeedCeProtocol = createCEProtocol(
                datafeedCore.ceProtocol,
                parentEntityViewSpec,
                datafeedCore,
                entity.datafeed,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            datafeedCore
        )
        datafeedCore.title = heading ? heading : datafeedCore.title
        datafeedCore.listTitle = 'Datafeed'

        if (entity.datafeed) {
            displayStringFactory.reconfigure(
                entity.datafeed,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        datafeedCore.configureForDisplay(parentEntityViewSpec)

        this.datafeed = new DetailEntry(
          heading ? heading : datafeedCore.listTitle,
          datafeedCeProtocol ? datafeedCeProtocol.entityListView.fullHeading : null,
          'datafeed',
          BaseDetailType.pushedDetail,
          datafeedCore,
          EditableType.always,
          formFields.datafeed
        )
        if (entity.datafeed) {
          this.datafeed.ceProtocol = datafeedCeProtocol;
          this.datafeed.cachedListEntry = datafeedCore.makeListEntry(parentEntityViewSpec, entity.datafeed, 0)
          datafeedCore.selectedEntity = entity.datafeed;
        }
        this.datafeed.singleFieldSelect = true
    }
}