import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutUserFormFields {

    public password: FormField
    public givenName: FormField
    public surname: FormField
    public email: FormField
    public person: FormField

    constructor() {

        this.password = new FormField(
          'password',
          'Password',
          null,
          FormFieldType.password,
          false,
        )
        this.password.inputType = 'password'
        this.givenName = new FormField(
          'givenName',
          'Given name',
          null,
          FormFieldType.text,
          false,
        )
        this.surname = new FormField(
          'surname',
          'Surname',
          null,
          FormFieldType.text,
          false,
        )
        this.email = new FormField(
          'email',
          'Email',
          null,
          FormFieldType.text,
          false,
        )
        this.person = new FormField(
          'person',
          'Person',
          'person',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutUserFormFields()

export const putUserFormFieldOptions: {[key: string]: FormField} = {
  password: fields.password,
  givenName: fields.givenName,
  surname: fields.surname,
  email: fields.email,
  person: fields.person,
};