import { SOGetTopic } from '../topic/so-get-topic';
import { SOGetDatafeed } from '../datafeed/so-get-datafeed';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAlertSubscription {

  constructor(
    public name: string,
    public topic: SOGetTopic,
    public datafeed: SOGetDatafeed,
    public organization: SOGetOrganization,
    public description: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAlertSubscription(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'topic' in params ? params.topic : null,
      'datafeed' in params ? params.datafeed : null,
      'organization' in params ? params.organization : null,
    );
  }
}
