import {SOPostInvoiceLine} from '../../core/invoice-line/so-post-invoice-line';
import {InvoiceLineCore} from './invoice-line.core';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';
import {FormField, SelectionOption} from 'lionheartland';
import {postInvoiceLineFormFieldOptions} from './post-invoice-line-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {PriceCore} from '../price/price.core';
import {SOGetPrice} from '../../core/price/so-get-price';
import {InvoiceCore} from '../invoice/invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';

export class RAPostInvoiceLine extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postInvoiceLineFormFieldOptions);

  RequestClass = SOPostInvoiceLine

  constructor(
    public entityCore: InvoiceLineCore
  ) {
    super();
    if (postInvoiceLineFormFieldOptions.price) {
      postInvoiceLineFormFieldOptions.price.makeCore = true;
    }
    if (postInvoiceLineFormFieldOptions.invoice) {
      postInvoiceLineFormFieldOptions.invoice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier(
        'price',
        SOGetPrice,
      ),
      this.getFormValueFromIdentifier(
        'invoice',
        SOGetInvoice,
      ),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}