import {SORespCertificate} from '../../core/certificate/so-resp-certificate';
import {CertificateCore} from './certificate.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutCertificateFormFields} from './put-certificate-form-fields';
import { CECertificateAttributes } from './ce-certificate-attributes'


export class CECertificateBase extends CEBase {

    public details: CECertificateAttributes;
    public fromFields: PutCertificateFormFields = new PutCertificateFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: CertificateCore,
        public entity: SORespCertificate,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CECertificateAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.user)
        this.attributes.push(this.details.tokens)

    }
}