import { SOGetPrice } from '../price/so-get-price';
import { SOGetInvoice } from '../invoice/so-get-invoice';


export class SOPutInvoiceLine {

  constructor(
    public uid: string,
    public amount: number = null,
    public quantity: number = null,
    public description: string = null,
    public price: SOGetPrice = null,
    public invoice: SOGetInvoice = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutInvoiceLine(
      'uid' in params ? params.uid : null,
      'amount' in params ? params.amount : null,
      'quantity' in params ? params.quantity : null,
      'description' in params ? params.description : null,
      'price' in params ? params.price : null,
      'invoice' in params ? params.invoice : null,
    );
  }
}
