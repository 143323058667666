import { Type } from 'class-transformer';
import { SORespCustomLogicRequirement } from '../custom-logic-requirement/so-resp-custom-logic-requirement';
import { SORespInterphace } from '../interphace/so-resp-interphace';
import { SORespDeficiency } from '../deficiency/so-resp-deficiency';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENBlockType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Block} from "../../dexie/db";

export class SORespBlock implements ObjectiveProtocol {

  entityType: string = 'block';
  uid: string;
  name: string;
  description: string = "";
  active: boolean;
  type: ENBlockType;
  @Type(() => SORespBlock)
  parent: SORespBlock = null;
  @Type(() => SORespCustomLogicRequirement)
  customLogicRequirement: SORespCustomLogicRequirement = null;
  @Type(() => SORespInterphace)
  interphace: SORespInterphace = null;
  @Type(() => SORespDeficiency)
  deficiencys: SORespDeficiency[];
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toBlock(): Block {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            active: this.active,
            type: this.type,
            blocksUid: null,
            parentUid: this.parent ? this.parent.uid : null,
            customLogicRequirementUid: this.customLogicRequirement ? this.customLogicRequirement.uid : null,
            interphaceUid: this.interphace ? this.interphace.uid : null,
            deficiencysUid: this.deficiencys ? this.deficiencys.map(deficiencys => deficiencys.uid) : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}