import {SORespUser} from '../../core/user/so-resp-user';
import {UserCore} from './user.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutUserFormFields} from './put-user-form-fields';
import { CEUserAttributes } from './ce-user-attributes'


export class CEUserBase extends CEBase {

    public details: CEUserAttributes;
    public fromFields: PutUserFormFields = new PutUserFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: UserCore,
        public entity: SORespUser,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEUserAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.username)
        this.attributes.push(this.details.givenName)
        this.attributes.push(this.details.surname)
        this.attributes.push(this.details.email)
        this.attributes.push(this.details.emailPending)
        this.attributes.push(this.details.emailVerified)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.roleGrants)
        this.attributes.push(this.details.invites)
        this.attributes.push(this.details.datafeeds)
        this.attributes.push(this.details.person)

    }
}