import {SOPostNotification} from '../../core/notification/so-post-notification';
import {NotificationCore} from './notification.core';
import {SOGetNotification} from '../../core/notification/so-get-notification';
import {FormField, SelectionOption} from 'lionheartland';
import {postNotificationFormFieldOptions} from './post-notification-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DatafeedCore} from '../datafeed/datafeed.core';
import {SOGetDatafeed} from '../../core/datafeed/so-get-datafeed';
import {MessageCore} from '../message/message.core';
import {SOGetMessage} from '../../core/message/so-get-message';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostNotification extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postNotificationFormFieldOptions);

  RequestClass = SOPostNotification

  constructor(
    public entityCore: NotificationCore
  ) {
    super();
    if (postNotificationFormFieldOptions.datafeed) {
      postNotificationFormFieldOptions.datafeed.makeCore = true;
    }
    if (postNotificationFormFieldOptions.message) {
      postNotificationFormFieldOptions.message.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'message',
        SOGetMessage,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier(
        'datafeed',
        SOGetDatafeed,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}