import { Type } from 'class-transformer';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENExportType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {ExportProject} from "../../dexie/db";

export class SORespExportProject implements ObjectiveProtocol {

  entityType: string = 'exportProject';
  uid: string;
  name: string;
  reasonForExport: string;
  exportType: ENExportType;
  downloadLink: string;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toExportProject(): ExportProject {
        return {
            uid: this.uid,
            name: this.name,
            reasonForExport: this.reasonForExport,
            exportType: this.exportType,
            dateCreated: null,
            downloadLink: this.downloadLink,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}