import { Type } from 'class-transformer';
import {SORespRelationshipAttributePermission} from './so-resp-relationship-attribute-permission';
import {PluralProtocol} from 'lionheartland';
import {RelationshipAttributePermission} from "../../dexie/db";

export class SOPluralRelationshipAttributePermission implements PluralProtocol {
  @Type(() => SORespRelationshipAttributePermission)
  objects: SORespRelationshipAttributePermission[] = []
  nextOffset: number = null

    toRelationshipAttributePermissions(): RelationshipAttributePermission[] {
        return this.objects.map(relationshipAttributePermission => relationshipAttributePermission.toRelationshipAttributePermission());
    }
}