import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENProcessStepType} from '../../enums';

export class PostProcessStepAssociationFormFields {

    public name: FormField
    public processStepType: FormField
    public process: FormField
    public step: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.processStepType = new FormField(
          'processStepType',
          'Process step type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.processStepType.enumeration = ENProcessStepType
        this.process = new FormField(
          'process',
          'Process',
          'process',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.step = new FormField(
          'step',
          'Step',
          'step',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostProcessStepAssociationFormFields()

export const postProcessStepAssociationFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  processStepType: fields.processStepType,
  process: fields.process,
  step: fields.step,
  project: fields.project,
};