import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENPaymentIntentStatus} from '../../enums';

export class PostPaymentIntentFormFields {

    public amount: FormField
    public status: FormField
    public invoice: FormField

    constructor() {

        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.status.enumeration = ENPaymentIntentStatus
        this.invoice = new FormField(
          'invoice',
          'Invoice',
          'invoice',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostPaymentIntentFormFields()

export const postPaymentIntentFormFieldOptions: {[key: string]: FormField} = {
  amount: fields.amount,
  status: fields.status,
  invoice: fields.invoice,
};