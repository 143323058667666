import { Type } from 'class-transformer';
import {SORespInvoiceLine} from './so-resp-invoice-line';
import {PluralProtocol} from 'lionheartland';
import {InvoiceLine} from "../../dexie/db";

export class SOPluralInvoiceLine implements PluralProtocol {
  @Type(() => SORespInvoiceLine)
  objects: SORespInvoiceLine[] = []
  nextOffset: number = null

    toInvoiceLines(): InvoiceLine[] {
        return this.objects.map(invoiceLine => invoiceLine.toInvoiceLine());
    }
}