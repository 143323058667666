import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORelationshipAttributePermissionService} from '../../core/relationship-attribute-permission/eo-relationship-attribute-permission';
import {SOGetRelationshipAttributePermission} from '../../core/relationship-attribute-permission/so-get-relationship-attribute-permission';
import {RAPutRelationshipAttributePermission} from './ra-put-relationship-attribute-permission';
import {RAPostRelationshipAttributePermission} from './ra-post-relationship-attribute-permission';
import {RADeleteRelationshipAttributePermission} from './ra-delete-relationship-attribute-permission';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CERelationshipAttributePermission } from "./ce-relationship-attribute-permission"


export class RelationshipAttributePermissionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipAttributePermission';
  title = 'Relationship attribute permission';
  ceProtocol: CEConstructor = CERelationshipAttributePermission

  entityService: EORelationshipAttributePermissionService;
  soGetClass = SOGetRelationshipAttributePermission;
  soGet: SOGetRelationshipAttributePermission;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORelationshipAttributePermissionService,
      null,
    );
    this.adapters = [
      new RAPostRelationshipAttributePermission(this),
      new RAPutRelationshipAttributePermission(this),
      new RADeleteRelationshipAttributePermission(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipAttributePermission();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}