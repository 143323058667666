import { Type } from 'class-transformer';
import { SORespSimpleAttribute } from '../simple-attribute/so-resp-simple-attribute';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENAttributePermissionType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {SimpleAttributePermission} from "../../dexie/db";

export class SORespSimpleAttributePermission implements ObjectiveProtocol {

  entityType: string = 'simpleAttributePermission';
  uid: string;
  name: string;
  description: string = "";
  whiteList: boolean;
  permissionType: ENAttributePermissionType;
  @Type(() => SORespSimpleAttribute)
  simpleAttribute: SORespSimpleAttribute = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toSimpleAttributePermission(): SimpleAttributePermission {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            whiteList: this.whiteList,
            permissionType: this.permissionType,
            simpleAttributeUid: this.simpleAttribute ? this.simpleAttribute.uid : null,
            simpleAttributePermissionRulesUid: null,
            rolePermissionsUid: null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}