import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOViewService} from '../../core/view/eo-view';
import {SOGetView} from '../../core/view/so-get-view';
import {RAPutView} from './ra-put-view';
import {RAPostView} from './ra-post-view';
import {RADeleteView} from './ra-delete-view';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEView } from "./ce-view"


export class ViewCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'view';
  title = 'View';
  ceProtocol: CEConstructor = CEView

  entityService: EOViewService;
  soGetClass = SOGetView;
  soGet: SOGetView;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOViewService,
      null,
    );
    this.adapters = [
      new RAPostView(this),
      new RAPutView(this),
      new RADeleteView(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetView();
    this.soGet.queryOptions.limit = this.limit;
  }


}