import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENMultiplicity} from '../../enums';


export class SOPostRelationshipAttribute {

  constructor(
    public name: string,
    public multiplicity: ENMultiplicity,
    public editPermissions: boolean,
    public entity: SOGetEntity,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public customLogicRequirement: SOGetCustomLogicRequirement = null,
    public relationship: SOGetRelationship = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipAttribute(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'multiplicity' in params ? params.multiplicity : null,
      'editPermissions' in params ? params.editPermissions : null,
      'entity' in params ? params.entity : null,
      'customLogicRequirement' in params ? params.customLogicRequirement : null,
      'relationship' in params ? params.relationship : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
