import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutEntityPermission } from './so-put-entity-permission';
import { SOGetEntityPermission } from './so-get-entity-permission';
import { SOPostEntityPermission } from './so-post-entity-permission';
import { SODeleteEntityPermission } from './so-delete-entity-permission';
import {SORespEntityPermission} from './so-resp-entity-permission';
import {SOPluralEntityPermission} from './so-plural-entity-permission';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOEntityPermissionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('entity_permission', entityCore);
  }

  /** GET EntityPermission by id. Will 404 if id not found */
  get(request: SOGetEntityPermission): Observable<SOPluralEntityPermission> {
    return this.httpClient.get<SOPluralEntityPermission>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralEntityPermission, resp)),
      tap(async (response: SOPluralEntityPermission) => {
        this.log(`fetched EntityPermission`);
        // After a successful server response, update IndexedDB
        const activities = response.toEntityPermissions(); // Convert your response to an array of EntityPermission instances
        const promises = activities.map(entityPermission => db.entityPermissions.put(entityPermission));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralEntityPermission>(`get EntityPermission`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralEntityPermission> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'entityPermission' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.entityPermissions.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "entity" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entityPermissions.where('entityUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "rolePermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entityPermissions.where('rolePermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entityPermissions.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.entityPermissions.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.entityPermissions.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.entityPermissions.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(entityPermissionsArray => this.processEntityPermissions(entityPermissionsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.entityPermissions.toArray()).pipe(
                map((entityPermissionsArray) => {
                    // Convert the array of plain objects to an array of SORespEntityPermission instances
                    const sorEntityPermissions = entityPermissionsArray.map(req => plainToClass(SORespEntityPermission, req));
                    // Create and return a new instance of SOPluralEntityPermission
                    const pluralEntityPermission = new SOPluralEntityPermission();
                    pluralEntityPermission.objects = sorEntityPermissions;
                    pluralEntityPermission.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralEntityPermission;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processEntityPermissions(entityPermissionsArray) {
        const sorEntityPermissions = entityPermissionsArray.map(req => plainToClass(SORespEntityPermission, req));
        const pluralEntityPermission = new SOPluralEntityPermission();
        pluralEntityPermission.objects = sorEntityPermissions;
        pluralEntityPermission.nextOffset = null;
        return pluralEntityPermission;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostEntityPermission): Observable<SORespEntityPermission> {
  return this.httpClient.post<SORespEntityPermission>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespEntityPermission, resp)),

    tap(async (response: SORespEntityPermission) => {
      this.log(`added EntityPermission w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.entityPermissions.add(response.toEntityPermission());
    }),
    catchError(this.handleError<SORespEntityPermission>('EntityPermission post'))
  );
}


  put(request: SOPutEntityPermission): Observable<SORespEntityPermission> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntityPermission>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespEntityPermission, resp)),
      tap(async (response: SORespEntityPermission) => {
        this.log(`edited EntityPermission w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.entityPermissions.put(response.toEntityPermission());
      }),
      catchError(this.handleError<SORespEntityPermission>('EntityPermission put'))
    );
  }


  delete(request: SODeleteEntityPermission): Observable<SORespEntityPermission> {
    return this.httpClient.delete<SORespEntityPermission>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted EntityPermission uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.entityPermissions.delete(request.uid);
      }),
      catchError(this.handleError<SORespEntityPermission>('EntityPermission delete'))
    );
  }


}
