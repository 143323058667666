import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOExportProjectService} from '../../core/export-project/eo-export-project';
import {SOGetExportProject} from '../../core/export-project/so-get-export-project';
import {RAPutExportProject} from './ra-put-export-project';
import {RAPostExportProject} from './ra-post-export-project';
import {RADeleteExportProject} from './ra-delete-export-project';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEExportProject } from "./ce-export-project"


export class ExportProjectCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'exportProject';
  title = 'Export project';
  ceProtocol: CEConstructor = CEExportProject

  entityService: EOExportProjectService;
  soGetClass = SOGetExportProject;
  soGet: SOGetExportProject;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOExportProjectService,
      null,
    );
    this.adapters = [
      new RAPostExportProject(this),
      new RAPutExportProject(this),
      new RADeleteExportProject(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetExportProject();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}