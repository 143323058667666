import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENExportType} from '../../enums';

export class PostExportProjectFormFields {

    public reasonForExport: FormField
    public exportType: FormField
    public project: FormField

    constructor() {

        this.reasonForExport = new FormField(
          'reasonForExport',
          'Reason for export',
          null,
          FormFieldType.text,
          true,
        )
        this.exportType = new FormField(
          'exportType',
          'Export type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.exportType.enumeration = ENExportType
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostExportProjectFormFields()

export const postExportProjectFormFieldOptions: {[key: string]: FormField} = {
  reasonForExport: fields.reasonForExport,
  exportType: fields.exportType,
  project: fields.project,
};