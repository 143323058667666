import {SOPutBlock} from '../../core/block/so-put-block';
import {BlockCore} from './block.core';
import {SOGetBlock} from '../../core/block/so-get-block';
import {FormField, SelectionOption} from 'lionheartland';
import {putBlockFormFieldOptions} from './put-block-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutBlock extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putBlockFormFieldOptions);

  RequestClass = SOPutBlock

  constructor(
    public entityCore: BlockCore
  ) {
    super();
    if (putBlockFormFieldOptions.parent) {
      putBlockFormFieldOptions.parent.makeCore = true;
    }
    if (putBlockFormFieldOptions.project) {
      putBlockFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier(
        'parent',
        SOGetBlock,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}