import {SORespPrice} from '../../core/price/so-resp-price';
import {PriceCore} from './price.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPriceFormFields} from './put-price-form-fields';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetProduct} from '../../core/product/so-get-product';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';


export class CEPriceAttributesBase {

    name: DetailEntry
    sku: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    exRef: DetailEntry
    amount: DetailEntry
    trailDays: DetailEntry
    interval: DetailEntry
    active: DetailEntry
    product: DetailEntry
    subscriptions: DetailEntry
    invoiceLines: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPrice,
        app: CommanderApp,
        formFields: PutPriceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.sku = new DetailEntry(
            'Sku',
            entity.sku,
            'sku',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.sku
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.exRef = new DetailEntry(
            'Ex ref',
            entity.exRef,
            'exRef',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.exRef
        )

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amount
        )

        this.trailDays = new DetailEntry(
            'Trail days',
            entity.trailDays,
            'trailDays',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.trailDays
        )

        this.interval = new DetailEntry(
            'Interval',
            entity.interval,
            'interval',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.interval
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )


        const productCore = app.makeCore( 'product');
        productCore.soGet = SOGetProduct.construct({
        });
        let productCeProtocol;
        if (entity.product) {
            productCeProtocol = createCEProtocol(
                productCore.ceProtocol,
                parentEntityViewSpec,
                productCore,
                entity.product,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productCore
        )
        productCore.title = heading ? heading : productCore.title
        productCore.listTitle = 'Product'

        if (entity.product) {
            displayStringFactory.reconfigure(
                entity.product,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        productCore.configureForDisplay(parentEntityViewSpec)

        this.product = new DetailEntry(
          heading ? heading : productCore.listTitle,
          productCeProtocol ? productCeProtocol.entityListView.fullHeading : null,
          'product',
          BaseDetailType.pushedDetail,
          productCore,
          EditableType.always,
          formFields.product
        )
        if (entity.product) {
          this.product.ceProtocol = productCeProtocol;
          this.product.cachedListEntry = productCore.makeListEntry(parentEntityViewSpec, entity.product, 0)
          productCore.selectedEntity = entity.product;
        }
        this.product.singleFieldSelect = true

        const subscriptionsCore = app.makeCore( 'subscription');
        subscriptionsCore.soGet = SOGetSubscription.construct({
          price: new SOGetPrice(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionsCore
        )
        subscriptionsCore.title = heading ? heading : subscriptionsCore.title
        subscriptionsCore.listTitle = 'Subscriptions'

        subscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.subscriptions = new DetailEntry(
          heading ? heading : subscriptionsCore.listTitle,
          null,
          'subscriptions',
          BaseDetailType.embeddedList,
          subscriptionsCore,
          EditableType.never
        )

        const invoiceLinesCore = app.makeCore( 'invoiceLine');
        invoiceLinesCore.soGet = SOGetInvoiceLine.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceLinesCore
        )
        invoiceLinesCore.title = heading ? heading : invoiceLinesCore.title
        invoiceLinesCore.listTitle = 'Invoice lines'

        invoiceLinesCore.configureForDisplay(parentEntityViewSpec)

        this.invoiceLines = new DetailEntry(
          heading ? heading : invoiceLinesCore.listTitle,
          null,
          'invoiceLines',
          BaseDetailType.embeddedList,
          invoiceLinesCore,
          EditableType.never
        )
    }
}