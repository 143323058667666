import { SOGetPlan } from '../plan/so-get-plan';
import { SOGetWork } from '../work/so-get-work';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetPlanWorkAssociation implements QueryProtocol {

  constructor(
    public uid: string = null,
    public plan: SOGetPlan = null,
    public work: SOGetWork = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetPlanWorkAssociation(
      'uid' in params ? params.uid : null,
      'plan' in params ? params.plan : null,
      'work' in params ? params.work : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
