import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENInviteStatus} from '../../enums';

export class PutOrganizationInviteFormFields {

    public status: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          false,
          true,
          ENInviteStatus.sent
        )
        this.status.enumeration = ENInviteStatus

    }
}

const fields = new PutOrganizationInviteFormFields()

export const putOrganizationInviteFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
};