import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutTopic } from './so-put-topic';
import { SOGetTopic } from './so-get-topic';
import { SOPostTopic } from './so-post-topic';
import { SODeleteTopic } from './so-delete-topic';
import {SORespTopic} from './so-resp-topic';
import {SOPluralTopic} from './so-plural-topic';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOTopicService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('topic', entityCore);
  }

  /** GET Topic by id. Will 404 if id not found */
  get(request: SOGetTopic): Observable<SOPluralTopic> {
    return this.httpClient.get<SOPluralTopic>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralTopic, resp)),
      tap(async (response: SOPluralTopic) => {
        this.log(`fetched Topic`);
        // After a successful server response, update IndexedDB
        const activities = response.toTopics(); // Convert your response to an array of Topic instances
        const promises = activities.map(topic => db.topics.put(topic));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralTopic>(`get Topic`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralTopic> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'topic' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.topics.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(topicsArray => this.processTopics(topicsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "alertSubscriptions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.topics.where('alertSubscriptionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(topicsArray => this.processTopics(topicsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "messages" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.topics.where('messagesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(topicsArray => this.processTopics(topicsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.topics.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(topicsArray => this.processTopics(topicsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.topics.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(topicsArray => this.processTopics(topicsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.topics.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(topicsArray => this.processTopics(topicsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.topics.toArray()).pipe(
                map((topicsArray) => {
                    // Convert the array of plain objects to an array of SORespTopic instances
                    const sorTopics = topicsArray.map(req => plainToClass(SORespTopic, req));
                    // Create and return a new instance of SOPluralTopic
                    const pluralTopic = new SOPluralTopic();
                    pluralTopic.objects = sorTopics;
                    pluralTopic.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralTopic;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processTopics(topicsArray) {
        const sorTopics = topicsArray.map(req => plainToClass(SORespTopic, req));
        const pluralTopic = new SOPluralTopic();
        pluralTopic.objects = sorTopics;
        pluralTopic.nextOffset = null;
        return pluralTopic;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostTopic): Observable<SORespTopic> {
  return this.httpClient.post<SORespTopic>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespTopic, resp)),

    tap(async (response: SORespTopic) => {
      this.log(`added Topic w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.topics.add(response.toTopic());
    }),
    catchError(this.handleError<SORespTopic>('Topic post'))
  );
}


  put(request: SOPutTopic): Observable<SORespTopic> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTopic>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespTopic, resp)),
      tap(async (response: SORespTopic) => {
        this.log(`edited Topic w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.topics.put(response.toTopic());
      }),
      catchError(this.handleError<SORespTopic>('Topic put'))
    );
  }


  delete(request: SODeleteTopic): Observable<SORespTopic> {
    return this.httpClient.delete<SORespTopic>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Topic uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.topics.delete(request.uid);
      }),
      catchError(this.handleError<SORespTopic>('Topic delete'))
    );
  }


}
