import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostUserFormFields {

    public username: FormField
    public password: FormField
    public givenName: FormField
    public surname: FormField
    public email: FormField
    public person: FormField

    constructor() {

        this.username = new FormField(
          'username',
          'Username',
          null,
          FormFieldType.text,
          true,
        )
        this.password = new FormField(
          'password',
          'Password',
          null,
          FormFieldType.password,
          true,
        )
        this.password.inputType = 'password'
        this.givenName = new FormField(
          'givenName',
          'Given name',
          null,
          FormFieldType.text,
          true,
        )
        this.surname = new FormField(
          'surname',
          'Surname',
          null,
          FormFieldType.text,
          true,
        )
        this.email = new FormField(
          'email',
          'Email',
          null,
          FormFieldType.text,
          true,
        )
        this.person = new FormField(
          'person',
          'Person',
          'person',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostUserFormFields()

export const postUserFormFieldOptions: {[key: string]: FormField} = {
  username: fields.username,
  password: fields.password,
  givenName: fields.givenName,
  surname: fields.surname,
  email: fields.email,
  person: fields.person,
};