import {SORespPlan} from '../../core/plan/so-resp-plan';
import {PlanCore} from './plan.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPlanFormFields} from './put-plan-form-fields';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {SOGetPeriod} from '../../core/period/so-get-period';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEPlanAttributesBase {

    name: DetailEntry
    period: DetailEntry
    planWorkAssociations: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPlan,
        app: CommanderApp,
        formFields: PutPlanFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )


        const periodCore = app.makeCore( 'period');
        periodCore.soGet = SOGetPeriod.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        periodCore.soGet.queryOptions.sortKey = 'dateCreated';
        periodCore.soGet.queryOptions.descending = true;
        let periodCeProtocol;
        if (entity.period) {
            periodCeProtocol = createCEProtocol(
                periodCore.ceProtocol,
                parentEntityViewSpec,
                periodCore,
                entity.period,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            periodCore
        )
        periodCore.title = heading ? heading : periodCore.title
        periodCore.listTitle = 'Period'

        if (entity.period) {
            displayStringFactory.reconfigure(
                entity.period,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        periodCore.configureForDisplay(parentEntityViewSpec)

        this.period = new DetailEntry(
          heading ? heading : periodCore.listTitle,
          periodCeProtocol ? periodCeProtocol.entityListView.fullHeading : null,
          'period',
          BaseDetailType.pushedDetail,
          periodCore,
          EditableType.always,
          formFields.period
        )
        if (entity.period) {
          this.period.ceProtocol = periodCeProtocol;
          this.period.cachedListEntry = periodCore.makeListEntry(parentEntityViewSpec, entity.period, 0)
          periodCore.selectedEntity = entity.period;
        }
        this.period.singleFieldSelect = true

        const planWorkAssociationsCore = app.makeCore( 'planWorkAssociation');
        planWorkAssociationsCore.soGet = SOGetPlanWorkAssociation.construct({
          plan: new SOGetPlan(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        planWorkAssociationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        planWorkAssociationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            planWorkAssociationsCore
        )
        planWorkAssociationsCore.title = heading ? heading : planWorkAssociationsCore.title
        planWorkAssociationsCore.listTitle = 'Plan work associations'

        planWorkAssociationsCore.configureForDisplay(parentEntityViewSpec)

        this.planWorkAssociations = new DetailEntry(
          heading ? heading : planWorkAssociationsCore.listTitle,
          null,
          'planWorkAssociations',
          BaseDetailType.embeddedList,
          planWorkAssociationsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}