import {SORespSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-resp-simple-attribute-permission-rule';
import {SimpleAttributePermissionRuleCore} from './simple-attribute-permission-rule.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutSimpleAttributePermissionRuleFormFields} from './put-simple-attribute-permission-rule-form-fields';
import { CESimpleAttributePermissionRuleAttributes } from './ce-simple-attribute-permission-rule-attributes'


export class CESimpleAttributePermissionRuleBase extends CEBase {

    public details: CESimpleAttributePermissionRuleAttributes;
    public fromFields: PutSimpleAttributePermissionRuleFormFields = new PutSimpleAttributePermissionRuleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SimpleAttributePermissionRuleCore,
        public entity: SORespSimpleAttributePermissionRule,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESimpleAttributePermissionRuleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.simpleAttribute)
        this.attributes.push(this.details.simpleAttributePermission)
        this.attributes.push(this.details.project)

    }
}