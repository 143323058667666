import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { SORespRole } from '../role/so-resp-role';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENRoleGrantStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {RoleGrant} from "../../dexie/db";

export class SORespRoleGrant implements ObjectiveProtocol {

  entityType: string = 'roleGrant';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  isDefault: boolean = false;
  status: ENRoleGrantStatus;
  @Type(() => SORespUser)
  user: SORespUser;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespOrganization)
  organization: SORespOrganization = null;

    toRoleGrant(): RoleGrant {
        return {
            uid: this.uid,
            dateCreated: this.dateCreated,
            isDefault: this.isDefault,
            status: this.status,
            userUid: this.user ? this.user.uid : null,
            roleUid: this.role ? this.role.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
            tokensUid: null,
}

        };
}