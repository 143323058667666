import {ENViewType} from '../../enums';


export class SOPutView {

  constructor(
    public uid: string,
    public name: string = null,
    public identifier: string = null,
    public type: ENViewType = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutView(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'type' in params ? params.type : null,
    );
  }
}
