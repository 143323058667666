import {SORespAlertSubscription} from '../../core/alert-subscription/so-resp-alert-subscription';
import {AlertSubscriptionCore} from './alert-subscription.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAlertSubscriptionFormFields} from './put-alert-subscription-form-fields';
import { CEAlertSubscriptionAttributes } from './ce-alert-subscription-attributes'


export class CEAlertSubscriptionBase extends CEBase {

    public details: CEAlertSubscriptionAttributes;
    public fromFields: PutAlertSubscriptionFormFields = new PutAlertSubscriptionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AlertSubscriptionCore,
        public entity: SORespAlertSubscription,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAlertSubscriptionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateUpdated)
        this.attributes.push(this.details.topic)
        this.attributes.push(this.details.datafeed)

    }
}