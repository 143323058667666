import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetRequirement } from '../requirement/so-get-requirement';
import { SOGetProcess } from '../process/so-get-process';
import { SOGetAttributeSpec } from '../attribute-spec/so-get-attribute-spec';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRealization {

  constructor(
    public name: string,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public entity: SOGetEntity = null,
    public simpleAttribute: SOGetSimpleAttribute = null,
    public relationshipAttribute: SOGetRelationshipAttribute = null,
    public requirement: SOGetRequirement = null,
    public process: SOGetProcess = null,
    public attributeSpec: SOGetAttributeSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRealization(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'entity' in params ? params.entity : null,
      'simpleAttribute' in params ? params.simpleAttribute : null,
      'relationshipAttribute' in params ? params.relationshipAttribute : null,
      'requirement' in params ? params.requirement : null,
      'process' in params ? params.process : null,
      'attributeSpec' in params ? params.attributeSpec : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
