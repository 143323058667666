import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENRoleGrantStatus} from '../../enums';

export class PutRoleGrantFormFields {

    public isDefault: FormField
    public status: FormField

    constructor() {

        this.isDefault = new FormField(
          'isDefault',
          'Is default',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.status.enumeration = ENRoleGrantStatus

    }
}

const fields = new PutRoleGrantFormFields()

export const putRoleGrantFormFieldOptions: {[key: string]: FormField} = {
  isDefault: fields.isDefault,
  status: fields.status,
};