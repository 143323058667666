import {SORespMedia} from '../../core/media/so-resp-media';
import {MediaCore} from './media.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutMediaFormFields} from './put-media-form-fields';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEMediaAttributesBase {

    name: DetailEntry
    description: DetailEntry
    size: DetailEntry
    links: DetailEntry
    dateCreated: DetailEntry
    attachments: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespMedia,
        app: CommanderApp,
        formFields: PutMediaFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.size = new DetailEntry(
            'Size',
            entity.size,
            'size',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.links = new DetailEntry(
            'Links',
            entity.links,
            'links',
            BaseDetailType.file,
            null,
            EditableType.never,
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const attachmentsCore = app.makeCore( 'attachment');
        attachmentsCore.soGet = SOGetAttachment.construct({
          media: new SOGetMedia(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            attachmentsCore
        )
        attachmentsCore.title = heading ? heading : attachmentsCore.title
        attachmentsCore.listTitle = 'Items'
        attachmentsCore.listSpecType = ListSpecType.thumbList

        attachmentsCore.configureForDisplay(parentEntityViewSpec)

        this.attachments = new DetailEntry(
          heading ? heading : attachmentsCore.listTitle,
          null,
          'attachments',
          BaseDetailType.embeddedList,
          attachmentsCore,
          EditableType.never
        )
    }
}