import {SOPostCustomLogicRequirement} from '../../core/custom-logic-requirement/so-post-custom-logic-requirement';
import {CustomLogicRequirementCore} from './custom-logic-requirement.core';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {FormField, SelectionOption} from 'lionheartland';
import {postCustomLogicRequirementFormFieldOptions} from './post-custom-logic-requirement-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {RelationshipAttributeCore} from '../relationship-attribute/relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostCustomLogicRequirement extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCustomLogicRequirementFormFieldOptions);

  RequestClass = SOPostCustomLogicRequirement

  constructor(
    public entityCore: CustomLogicRequirementCore
  ) {
    super();
    if (postCustomLogicRequirementFormFieldOptions.parent) {
      postCustomLogicRequirementFormFieldOptions.parent.makeCore = true;
    }
    if (postCustomLogicRequirementFormFieldOptions.simpleAttributes) {
      postCustomLogicRequirementFormFieldOptions.simpleAttributes.makeCore = true;
    }
    if (postCustomLogicRequirementFormFieldOptions.relationshipAttributes) {
      postCustomLogicRequirementFormFieldOptions.relationshipAttributes.makeCore = true;
    }
    if (postCustomLogicRequirementFormFieldOptions.project) {
      postCustomLogicRequirementFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier('currentStatus'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'parent',
        SOGetCustomLogicRequirement,
      ),
      [this.getFormValueFromIdentifier('simpleAttributes', SOGetSimpleAttribute)],
      [this.getFormValueFromIdentifier('relationshipAttributes', SOGetRelationshipAttribute)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}