import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRoleGrant } from './so-put-role-grant';
import { SOGetRoleGrant } from './so-get-role-grant';
import { SOPostRoleGrant } from './so-post-role-grant';
import { SODeleteRoleGrant } from './so-delete-role-grant';
import {SORespRoleGrant} from './so-resp-role-grant';
import {SOPluralRoleGrant} from './so-plural-role-grant';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORoleGrantService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('role_grant', entityCore);
  }

  /** GET RoleGrant by id. Will 404 if id not found */
  get(request: SOGetRoleGrant): Observable<SOPluralRoleGrant> {
    return this.httpClient.get<SOPluralRoleGrant>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRoleGrant, resp)),
      tap(async (response: SOPluralRoleGrant) => {
        this.log(`fetched RoleGrant`);
        // After a successful server response, update IndexedDB
        const activities = response.toRoleGrants(); // Convert your response to an array of RoleGrant instances
        const promises = activities.map(roleGrant => db.roleGrants.put(roleGrant));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRoleGrant>(`get RoleGrant`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRoleGrant> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'roleGrant' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.roleGrants.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "user" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roleGrants.where('userUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "role" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roleGrants.where('roleUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roleGrants.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "tokens" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roleGrants.where('tokensUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.roleGrants.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.roleGrants.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(roleGrantsArray => this.processRoleGrants(roleGrantsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.roleGrants.toArray()).pipe(
                map((roleGrantsArray) => {
                    // Convert the array of plain objects to an array of SORespRoleGrant instances
                    const sorRoleGrants = roleGrantsArray.map(req => plainToClass(SORespRoleGrant, req));
                    // Create and return a new instance of SOPluralRoleGrant
                    const pluralRoleGrant = new SOPluralRoleGrant();
                    pluralRoleGrant.objects = sorRoleGrants;
                    pluralRoleGrant.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRoleGrant;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRoleGrants(roleGrantsArray) {
        const sorRoleGrants = roleGrantsArray.map(req => plainToClass(SORespRoleGrant, req));
        const pluralRoleGrant = new SOPluralRoleGrant();
        pluralRoleGrant.objects = sorRoleGrants;
        pluralRoleGrant.nextOffset = null;
        return pluralRoleGrant;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRoleGrant): Observable<SORespRoleGrant> {
  return this.httpClient.post<SORespRoleGrant>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRoleGrant, resp)),

    tap(async (response: SORespRoleGrant) => {
      this.log(`added RoleGrant w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.roleGrants.add(response.toRoleGrant());
    }),
    catchError(this.handleError<SORespRoleGrant>('RoleGrant post'))
  );
}


  put(request: SOPutRoleGrant): Observable<SORespRoleGrant> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRoleGrant>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRoleGrant, resp)),
      tap(async (response: SORespRoleGrant) => {
        this.log(`edited RoleGrant w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.roleGrants.put(response.toRoleGrant());
      }),
      catchError(this.handleError<SORespRoleGrant>('RoleGrant put'))
    );
  }


  delete(request: SODeleteRoleGrant): Observable<SORespRoleGrant> {
    return this.httpClient.delete<SORespRoleGrant>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted RoleGrant uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.roleGrants.delete(request.uid);
      }),
      catchError(this.handleError<SORespRoleGrant>('RoleGrant delete'))
    );
  }


}
