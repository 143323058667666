import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODeficiencyService} from '../../core/deficiency/eo-deficiency';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {RAPutDeficiency} from './ra-put-deficiency';
import {RAPostDeficiency} from './ra-post-deficiency';
import {RADeleteDeficiency} from './ra-delete-deficiency';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEDeficiency } from "./ce-deficiency"


export class DeficiencyCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'deficiency';
  title = 'Deficiency';
  ceProtocol: CEConstructor = CEDeficiency

  entityService: EODeficiencyService;
  soGetClass = SOGetDeficiency;
  soGet: SOGetDeficiency;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODeficiencyService,
      null,
    );
    this.adapters = [
      new RAPostDeficiency(this),
      new RAPutDeficiency(this),
      new RADeleteDeficiency(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDeficiency();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}