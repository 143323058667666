import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';
import {Product} from "../../dexie/db";

export class SORespProduct implements ObjectiveProtocol {

  entityType: string = 'product';
  uid: string;
  name: string;
  sku: string = '';
  exRef: string = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  active: boolean = true;

    toProduct(): Product {
        return {
            uid: this.uid,
            name: this.name,
            sku: this.sku,
            exRef: null,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            active: this.active,
            appliedConstraintsUid: null,
            pricesUid: null,
}

        };
}