import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPlanService} from '../../core/plan/eo-plan';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {RAPutPlan} from './ra-put-plan';
import {RAPostPlan} from './ra-post-plan';
import {RADeletePlan} from './ra-delete-plan';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEPlan } from "./ce-plan"


export class PlanCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'plan';
  title = 'Plan';
  ceProtocol: CEConstructor = CEPlan

  entityService: EOPlanService;
  soGetClass = SOGetPlan;
  soGet: SOGetPlan;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPlanService,
      null,
    );
    this.adapters = [
      new RAPostPlan(this),
      new RAPutPlan(this),
      new RADeletePlan(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPlan();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}