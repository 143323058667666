import {SOPostEntity} from '../../core/entity/so-post-entity';
import {EntityCore} from './entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {FormField, SelectionOption} from 'lionheartland';
import {postEntityFormFieldOptions} from './post-entity-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostEntity extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postEntityFormFieldOptions);

  RequestClass = SOPostEntity

  constructor(
    public entityCore: EntityCore
  ) {
    super();
    if (postEntityFormFieldOptions.project) {
      postEntityFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('editPermissions'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}