import {SORespInvoice} from '../../core/invoice/so-resp-invoice';
import {InvoiceCore} from './invoice.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutInvoiceFormFields} from './put-invoice-form-fields';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';


export class CEInvoiceAttributesBase {

    ref: DetailEntry
    exRef: DetailEntry
    amountDue: DetailEntry
    amountPaid: DetailEntry
    amountRemaining: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    dateDue: DetailEntry
    periodStart: DetailEntry
    periodEnd: DetailEntry
    paid: DetailEntry
    subscription: DetailEntry
    paymentIntents: DetailEntry
    invoiceLines: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespInvoice,
        app: CommanderApp,
        formFields: PutInvoiceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.ref = new DetailEntry(
            'Ref',
            entity.ref,
            'ref',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.ref
        )

        this.exRef = new DetailEntry(
            'Ex ref',
            entity.exRef,
            'exRef',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.exRef
        )

        this.amountDue = new DetailEntry(
            'Amount due',
            entity.amountDue,
            'amountDue',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amountDue
        )

        this.amountPaid = new DetailEntry(
            'Amount paid',
            entity.amountPaid,
            'amountPaid',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amountPaid
        )

        this.amountRemaining = new DetailEntry(
            'Amount remaining',
            entity.amountRemaining,
            'amountRemaining',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amountRemaining
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateDue = new DetailEntry(
            'Date due',
            app.activeUserService.time.dateAsString(entity.dateDue),
            'dateDue',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateDue
        )

        this.periodStart = new DetailEntry(
            'Period start',
            app.activeUserService.time.dateAsString(entity.periodStart),
            'periodStart',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.periodStart
        )

        this.periodEnd = new DetailEntry(
            'Period end',
            app.activeUserService.time.dateAsString(entity.periodEnd),
            'periodEnd',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.periodEnd
        )

        this.paid = new DetailEntry(
            'Paid',
            entity.paid,
            'paid',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.paid
        )


        const subscriptionCore = app.makeCore( 'subscription');
        subscriptionCore.soGet = SOGetSubscription.construct({
        });
        let subscriptionCeProtocol;
        if (entity.subscription) {
            subscriptionCeProtocol = createCEProtocol(
                subscriptionCore.ceProtocol,
                parentEntityViewSpec,
                subscriptionCore,
                entity.subscription,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionCore
        )
        subscriptionCore.title = heading ? heading : subscriptionCore.title
        subscriptionCore.listTitle = 'Subscription'

        if (entity.subscription) {
            displayStringFactory.reconfigure(
                entity.subscription,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        subscriptionCore.configureForDisplay(parentEntityViewSpec)

        this.subscription = new DetailEntry(
          heading ? heading : subscriptionCore.listTitle,
          subscriptionCeProtocol ? subscriptionCeProtocol.entityListView.fullHeading : null,
          'subscription',
          BaseDetailType.pushedDetail,
          subscriptionCore,
          EditableType.always,
          formFields.subscription
        )
        if (entity.subscription) {
          this.subscription.ceProtocol = subscriptionCeProtocol;
          this.subscription.cachedListEntry = subscriptionCore.makeListEntry(parentEntityViewSpec, entity.subscription, 0)
          subscriptionCore.selectedEntity = entity.subscription;
        }
        this.subscription.singleFieldSelect = true

        const paymentIntentsCore = app.makeCore( 'paymentIntent');
        paymentIntentsCore.soGet = SOGetPaymentIntent.construct({
          invoice: new SOGetInvoice(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            paymentIntentsCore
        )
        paymentIntentsCore.title = heading ? heading : paymentIntentsCore.title
        paymentIntentsCore.listTitle = 'Payment intents'

        paymentIntentsCore.configureForDisplay(parentEntityViewSpec)

        this.paymentIntents = new DetailEntry(
          heading ? heading : paymentIntentsCore.listTitle,
          null,
          'paymentIntents',
          BaseDetailType.embeddedList,
          paymentIntentsCore,
          EditableType.never
        )

        const invoiceLinesCore = app.makeCore( 'invoiceLine');
        invoiceLinesCore.soGet = SOGetInvoiceLine.construct({
          invoice: new SOGetInvoice(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceLinesCore
        )
        invoiceLinesCore.title = heading ? heading : invoiceLinesCore.title
        invoiceLinesCore.listTitle = 'Invoice lines'

        invoiceLinesCore.configureForDisplay(parentEntityViewSpec)

        this.invoiceLines = new DetailEntry(
          heading ? heading : invoiceLinesCore.listTitle,
          null,
          'invoiceLines',
          BaseDetailType.embeddedList,
          invoiceLinesCore,
          EditableType.never
        )
    }
}