import {SORespAssessmentSummary} from '../../core/assessment-summary/so-resp-assessment-summary';
import {AssessmentSummaryCore} from './assessment-summary.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAssessmentSummaryFormFields} from './put-assessment-summary-form-fields';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEAssessmentSummaryAttributesBase {

    name: DetailEntry
    description: DetailEntry
    totalRequirements: DetailEntry
    completedRequirements: DetailEntry
    draftRequirements: DetailEntry
    proposedRequirements: DetailEntry
    inReviewRequirements: DetailEntry
    acceptedRequirements: DetailEntry
    rejectedRequirements: DetailEntry
    totalCustomLogicRequirements: DetailEntry
    completedCustomLogicRequirements: DetailEntry
    draftCustomLogicRequirements: DetailEntry
    proposedCustomLogicRequirements: DetailEntry
    inReviewCustomLogicRequirements: DetailEntry
    acceptedCustomLogicRequirements: DetailEntry
    rejectedCustomLogicRequirements: DetailEntry
    totalAttributes: DetailEntry
    attributesWithRequirements: DetailEntry
    attributesWithoutRequirements: DetailEntry
    totalProcesses: DetailEntry
    processesWithRequirements: DetailEntry
    processesWithoutRequirements: DetailEntry
    totalBlocks: DetailEntry
    blocksWithCustomLogicRequirements: DetailEntry
    blocksWithoutCustomLogicRequirements: DetailEntry
    assessment: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAssessmentSummary,
        app: CommanderApp,
        formFields: PutAssessmentSummaryFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalRequirements = new DetailEntry(
            'Total requirements',
            entity.totalRequirements,
            'totalRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.completedRequirements = new DetailEntry(
            'Completed requirements',
            entity.completedRequirements,
            'completedRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.draftRequirements = new DetailEntry(
            'Draft requirements',
            entity.draftRequirements,
            'draftRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.proposedRequirements = new DetailEntry(
            'Proposed requirements',
            entity.proposedRequirements,
            'proposedRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.inReviewRequirements = new DetailEntry(
            'In review requirements',
            entity.inReviewRequirements,
            'inReviewRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.acceptedRequirements = new DetailEntry(
            'Accepted requirements',
            entity.acceptedRequirements,
            'acceptedRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.rejectedRequirements = new DetailEntry(
            'Rejected requirements',
            entity.rejectedRequirements,
            'rejectedRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalCustomLogicRequirements = new DetailEntry(
            'Total custom logic requirements',
            entity.totalCustomLogicRequirements,
            'totalCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.completedCustomLogicRequirements = new DetailEntry(
            'Completed custom logic requirements',
            entity.completedCustomLogicRequirements,
            'completedCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.draftCustomLogicRequirements = new DetailEntry(
            'Draft custom logic requirements',
            entity.draftCustomLogicRequirements,
            'draftCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.proposedCustomLogicRequirements = new DetailEntry(
            'Proposed custom logic requirements',
            entity.proposedCustomLogicRequirements,
            'proposedCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.inReviewCustomLogicRequirements = new DetailEntry(
            'In review custom logic requirements',
            entity.inReviewCustomLogicRequirements,
            'inReviewCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.acceptedCustomLogicRequirements = new DetailEntry(
            'Accepted custom logic requirements',
            entity.acceptedCustomLogicRequirements,
            'acceptedCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.rejectedCustomLogicRequirements = new DetailEntry(
            'Rejected custom logic requirements',
            entity.rejectedCustomLogicRequirements,
            'rejectedCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalAttributes = new DetailEntry(
            'Total attributes',
            entity.totalAttributes,
            'totalAttributes',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.attributesWithRequirements = new DetailEntry(
            'Attributes with requirements',
            entity.attributesWithRequirements,
            'attributesWithRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.attributesWithoutRequirements = new DetailEntry(
            'Attributes without requirements',
            entity.attributesWithoutRequirements,
            'attributesWithoutRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalProcesses = new DetailEntry(
            'Total processes',
            entity.totalProcesses,
            'totalProcesses',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.processesWithRequirements = new DetailEntry(
            'Processes with requirements',
            entity.processesWithRequirements,
            'processesWithRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.processesWithoutRequirements = new DetailEntry(
            'Processes without requirements',
            entity.processesWithoutRequirements,
            'processesWithoutRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalBlocks = new DetailEntry(
            'Total blocks',
            entity.totalBlocks,
            'totalBlocks',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.blocksWithCustomLogicRequirements = new DetailEntry(
            'Blocks with custom logic requirements',
            entity.blocksWithCustomLogicRequirements,
            'blocksWithCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.blocksWithoutCustomLogicRequirements = new DetailEntry(
            'Blocks without custom logic requirements',
            entity.blocksWithoutCustomLogicRequirements,
            'blocksWithoutCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const assessmentCore = app.makeCore( 'assessment');
        assessmentCore.soGet = SOGetAssessment.construct({
          assessmentSummary: new SOGetAssessmentSummary(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        assessmentCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentCore
        )
        assessmentCore.title = heading ? heading : assessmentCore.title
        assessmentCore.listTitle = 'Assessment'

        assessmentCore.configureForDisplay(parentEntityViewSpec)

        this.assessment = new DetailEntry(
          heading ? heading : assessmentCore.listTitle,
          null,
          'assessment',
          BaseDetailType.pushedDetail,
          assessmentCore,
          EditableType.always,
          formFields.assessment
        )
        this.assessment.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}