import { Type } from 'class-transformer';
import { SORespSimpleAttribute } from '../simple-attribute/so-resp-simple-attribute';
import { SORespSimpleAttributePermission } from '../simple-attribute-permission/so-resp-simple-attribute-permission';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {SimpleAttributePermissionRule} from "../../dexie/db";

export class SORespSimpleAttributePermissionRule implements ObjectiveProtocol {

  entityType: string = 'simpleAttributePermissionRule';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => SORespSimpleAttribute)
  simpleAttribute: SORespSimpleAttribute = null;
  @Type(() => SORespSimpleAttributePermission)
  simpleAttributePermission: SORespSimpleAttributePermission = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toSimpleAttributePermissionRule(): SimpleAttributePermissionRule {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            simpleAttributeUid: this.simpleAttribute ? this.simpleAttribute.uid : null,
            simpleAttributePermissionUid: this.simpleAttributePermission ? this.simpleAttributePermission.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}