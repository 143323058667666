import { SOGetRole } from '../role/so-get-role';
import { SOGetView } from '../view/so-get-view';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetViewGrant implements QueryProtocol {

  constructor(
    public uid: string = null,
    public role: SOGetRole = null,
    public view: SOGetView = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetViewGrant(
      'uid' in params ? params.uid : null,
      'role' in params ? params.role : null,
      'view' in params ? params.view : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
