import {SOPutAppliedConstraint} from '../../core/applied-constraint/so-put-applied-constraint';
import {AppliedConstraintCore} from './applied-constraint.core';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {FormField, SelectionOption} from 'lionheartland';
import {putAppliedConstraintFormFieldOptions} from './put-applied-constraint-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutAppliedConstraint extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAppliedConstraintFormFieldOptions);

  RequestClass = SOPutAppliedConstraint

  constructor(
    public entityCore: AppliedConstraintCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('quantity'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}