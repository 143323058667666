import {SORespConstraint} from '../../core/constraint/so-resp-constraint';
import {ConstraintCore} from './constraint.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutConstraintFormFields} from './put-constraint-form-fields';
import { CEConstraintAttributes } from './ce-constraint-attributes'


export class CEConstraintBase extends CEBase {

    public details: CEConstraintAttributes;
    public fromFields: PutConstraintFormFields = new PutConstraintFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ConstraintCore,
        public entity: SORespConstraint,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEConstraintAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.appliedConstraints)

    }
}