import {SOPostSimpleAttribute} from '../../core/simple-attribute/so-post-simple-attribute';
import {SimpleAttributeCore} from './simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {FormField, SelectionOption} from 'lionheartland';
import {postSimpleAttributeFormFieldOptions} from './post-simple-attribute-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {EntityCore} from '../entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {CustomLogicRequirementCore} from '../custom-logic-requirement/custom-logic-requirement.core';
import {SOGetCustomLogicRequirement} from '../../core/custom-logic-requirement/so-get-custom-logic-requirement';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostSimpleAttribute extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSimpleAttributeFormFieldOptions);

  RequestClass = SOPostSimpleAttribute

  constructor(
    public entityCore: SimpleAttributeCore
  ) {
    super();
    if (postSimpleAttributeFormFieldOptions.entity) {
      postSimpleAttributeFormFieldOptions.entity.makeCore = true;
    }
    if (postSimpleAttributeFormFieldOptions.customLogicRequirement) {
      postSimpleAttributeFormFieldOptions.customLogicRequirement.makeCore = true;
    }
    if (postSimpleAttributeFormFieldOptions.project) {
      postSimpleAttributeFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('editPermissions'),
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('attributeType'),
      this.getFormValueFromIdentifier(
        'customLogicRequirement',
        SOGetCustomLogicRequirement,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}