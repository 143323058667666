import { SOGetUser } from '../user/so-get-user';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostPerson {

  constructor(
    public name: string,
    public organization: SOGetOrganization,
    public description: string = "",
    public relation: string = "",
    public user: SOGetUser = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPerson(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'relation' in params ? params.relation : null,
      'user' in params ? params.user : null,
      'organization' in params ? params.organization : null,
    );
  }
}
