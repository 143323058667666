import { SOGetPlan } from '../plan/so-get-plan';
import { SOGetWork } from '../work/so-get-work';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENWeeklyReportSection} from '../../enums';


export class SOPutPlanWorkAssociation {

  constructor(
    public uid: string,
    public name: string = null,
    public reportSection: ENWeeklyReportSection = null,
    public plan: SOGetPlan = null,
    public work: SOGetWork = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPlanWorkAssociation(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'reportSection' in params ? params.reportSection : null,
      'plan' in params ? params.plan : null,
      'work' in params ? params.work : null,
      'organization' in params ? params.organization : null,
    );
  }
}
