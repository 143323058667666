import { SOGetRole } from '../role/so-get-role';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostOrganizationInvite {

  constructor(
    public email: string,
    public role: SOGetRole,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOrganizationInvite(
      'email' in params ? params.email : null,
      'role' in params ? params.role : null,
      'organization' in params ? params.organization : null,
    );
  }
}
