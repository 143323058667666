import {SORespAppliedConstraint} from '../../core/applied-constraint/so-resp-applied-constraint';
import {AppliedConstraintCore} from './applied-constraint.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAppliedConstraintFormFields} from './put-applied-constraint-form-fields';
import { CEAppliedConstraintAttributes } from './ce-applied-constraint-attributes'


export class CEAppliedConstraintBase extends CEBase {

    public details: CEAppliedConstraintAttributes;
    public fromFields: PutAppliedConstraintFormFields = new PutAppliedConstraintFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AppliedConstraintCore,
        public entity: SORespAppliedConstraint,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAppliedConstraintAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.constraint)
        this.attributes.push(this.details.product)

    }
}