import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSimpleAttributeType} from '../../enums';

export class PutSimpleAttributeFormFields {

    public name: FormField
    public description: FormField
    public attributeType: FormField
    public editPermissions: FormField
    public simpleAttributePermissionRules: FormField
    public entity: FormField
    public customLogicRequirement: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.attributeType = new FormField(
          'attributeType',
          'Attribute type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.attributeType.enumeration = ENSimpleAttributeType
        this.editPermissions = new FormField(
          'editPermissions',
          'Edit permissions',
          null,
          FormFieldType.boolean,
          false,
        )
        this.simpleAttributePermissionRules = new FormField(
          'simpleAttributePermissionRules',
          'Simple attribute permission rules',
          'simpleAttributePermissionRule',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.customLogicRequirement = new FormField(
          'customLogicRequirement',
          'Custom logic requirement',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutSimpleAttributeFormFields()

export const putSimpleAttributeFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  attributeType: fields.attributeType,
  editPermissions: fields.editPermissions,
  simpleAttributePermissionRules: fields.simpleAttributePermissionRules,
  entity: fields.entity,
  customLogicRequirement: fields.customLogicRequirement,
  project: fields.project,
};