import {SORespPerson} from '../../core/person/so-resp-person';
import {PersonCore} from './person.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPersonFormFields} from './put-person-form-fields';
import {SOGetPerson} from '../../core/person/so-get-person';
import {SOGetAssignedProject} from '../../core/assigned-project/so-get-assigned-project';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEPersonAttributesBase {

    name: DetailEntry
    description: DetailEntry
    relation: DetailEntry
    assignedProjects: DetailEntry
    user: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPerson,
        app: CommanderApp,
        formFields: PutPersonFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.relation = new DetailEntry(
            'Relation',
            entity.relation,
            'relation',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.relation
        )


        const assignedProjectsCore = app.makeCore( 'assignedProject');
        assignedProjectsCore.soGet = SOGetAssignedProject.construct({
          person: new SOGetPerson(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assignedProjectsCore
        )
        assignedProjectsCore.title = heading ? heading : assignedProjectsCore.title
        assignedProjectsCore.listTitle = 'Assigned projects'

        assignedProjectsCore.configureForDisplay(parentEntityViewSpec)

        this.assignedProjects = new DetailEntry(
          heading ? heading : assignedProjectsCore.listTitle,
          null,
          'assignedProjects',
          BaseDetailType.embeddedList,
          assignedProjectsCore,
          EditableType.never
        )

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.always,
          formFields.user
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true
    }
}