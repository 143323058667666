import {SORespIdentity} from '../../core/identity/so-resp-identity';
import {IdentityCore} from './identity.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutIdentityFormFields} from './put-identity-form-fields';
import { CEIdentityAttributes } from './ce-identity-attributes'


export class CEIdentityBase extends CEBase {

    public details: CEIdentityAttributes;
    public fromFields: PutIdentityFormFields = new PutIdentityFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: IdentityCore,
        public entity: SORespIdentity,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEIdentityAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.provider)
        this.attributes.push(this.details.user)

    }
}