import {SORespRoleGrant} from '../../core/role-grant/so-resp-role-grant';
import {RoleGrantCore} from './role-grant.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRoleGrantFormFields} from './put-role-grant-form-fields';
import { CERoleGrantAttributes } from './ce-role-grant-attributes'


export class CERoleGrantBase extends CEBase {

    public details: CERoleGrantAttributes;
    public fromFields: PutRoleGrantFormFields = new PutRoleGrantFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RoleGrantCore,
        public entity: SORespRoleGrant,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERoleGrantAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.isDefault)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.user)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.organization)
        this.attributes.push(this.details.tokens)

    }
}