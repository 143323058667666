import { Type } from 'class-transformer';
import { SORespRoleGrant } from '../role-grant/so-resp-role-grant';
import {ENTokenStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {Token} from "../../dexie/db";

export class SORespToken implements ObjectiveProtocol {

  entityType: string = 'token';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  status: ENTokenStatus = null;
  @Type(() => SORespRoleGrant)
  roleGrant: SORespRoleGrant;

    toToken(): Token {
        return {
            uid: this.uid,
            certificateUid: null,
            dateCreated: this.dateCreated,
            status: null,
            roleGrantUid: this.roleGrant ? this.roleGrant.uid : null,
            identityToken: null,
            priorIdentityToken: null,
            refreshToken: null,
            accessToken: null,
            codeVerifier: null,
            code: null,
            provider: null,
}

        };
}