import { Type } from 'class-transformer';
import {SORespConstraint} from './so-resp-constraint';
import {PluralProtocol} from 'lionheartland';
import {Constraint} from "../../dexie/db";

export class SOPluralConstraint implements PluralProtocol {
  @Type(() => SORespConstraint)
  objects: SORespConstraint[] = []
  nextOffset: number = null

    toConstraints(): Constraint[] {
        return this.objects.map(constraint => constraint.toConstraint());
    }
}