import { SOGetUser } from '../user/so-get-user';


export class SOPostCertificate {

  constructor(
    public user: SOGetUser,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCertificate(
      'user' in params ? params.user : null,
    );
  }
}
