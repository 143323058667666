import {SbNavItem} from 'lionheartland';
import {NavItemLocation} from 'lionheartland';

export const DEFAULT_NAV_ITEMS = [
    new SbNavItem(
        'alertSubscription',
        'alertSubscription',
        '/alert_subscription',
        'View your alert_subscriptions',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'appliedConstraint',
        'appliedConstraint',
        '/applied_constraint',
        'View your applied_constraints',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'assessment',
        'assessment',
        '/assessment',
        'View your assessments',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'assessmentSummary',
        'assessmentSummary',
        '/assessment_summary',
        'View your assessment_summarys',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'assignedProject',
        'assignedProject',
        '/assigned_project',
        'View your assigned_projects',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'attachment',
        'attachment',
        '/attachment',
        'View your attachments',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'attributeSpec',
        'attributeSpec',
        '/attribute_spec',
        'View your attribute_specs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'backlog',
        'backlog',
        '/backlog',
        'View your backlogs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'block',
        'block',
        '/block',
        'View your blocks',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'certificate',
        'certificate',
        '/certificate',
        'View your certificates',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'constraint',
        'constraint',
        '/constraint',
        'View your constraints',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'customLogicRequirement',
        'customLogicRequirement',
        '/custom_logic_requirement',
        'View your custom_logic_requirements',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'datafeed',
        'datafeed',
        '/datafeed',
        'View your datafeeds',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'deficiency',
        'deficiency',
        '/deficiency',
        'View your deficiencys',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'entity',
        'entity',
        '/entity',
        'View your entitys',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'entityPermission',
        'entityPermission',
        '/entity_permission',
        'View your entity_permissions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'exportProject',
        'exportProject',
        '/export_project',
        'View your export_projects',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'hello',
        'hello',
        '/hello',
        'View your hellos',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'identity',
        'identity',
        '/identity',
        'View your identitys',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'ingest',
        'ingest',
        '/ingest',
        'View your ingests',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'interphace',
        'interphace',
        '/interphace',
        'View your interphaces',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'invoice',
        'invoice',
        '/invoice',
        'View your invoices',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'invoiceLine',
        'invoiceLine',
        '/invoice_line',
        'View your invoice_lines',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'media',
        'media',
        '/media',
        'View your medias',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'message',
        'message',
        '/message',
        'View your messages',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'notification',
        'notification',
        '/notification',
        'View your notifications',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'organization',
        'organization',
        '/organization',
        'View your organizations',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'organizationInvite',
        'organizationInvite',
        '/organization_invite',
        'View your organization_invites',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'paymentIntent',
        'paymentIntent',
        '/payment_intent',
        'View your payment_intents',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'period',
        'period',
        '/period',
        'View your periods',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'person',
        'person',
        '/person',
        'View your persons',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'plan',
        'plan',
        '/plan',
        'View your plans',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'planWorkAssociation',
        'planWorkAssociation',
        '/plan_work_association',
        'View your plan_work_associations',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'price',
        'price',
        '/price',
        'View your prices',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'process',
        'process',
        '/process',
        'View your processs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'processRole',
        'processRole',
        '/process_role',
        'View your process_roles',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'processStepAssociation',
        'processStepAssociation',
        '/process_step_association',
        'View your process_step_associations',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'product',
        'product',
        '/product',
        'View your products',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'project',
        'project',
        '/project',
        'View your projects',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'realization',
        'realization',
        '/realization',
        'View your realizations',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'relationship',
        'relationship',
        '/relationship',
        'View your relationships',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'relationshipAttribute',
        'relationshipAttribute',
        '/relationship_attribute',
        'View your relationship_attributes',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'relationshipAttributePermission',
        'relationshipAttributePermission',
        '/relationship_attribute_permission',
        'View your relationship_attribute_permissions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'relationshipAttributePermissionRule',
        'relationshipAttributePermissionRule',
        '/relationship_attribute_permission_rule',
        'View your relationship_attribute_permission_rules',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'report',
        'report',
        '/report',
        'View your reports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'requirement',
        'requirement',
        '/requirement',
        'View your requirements',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'role',
        'role',
        '/role',
        'View your roles',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'roleGrant',
        'roleGrant',
        '/role_grant',
        'View your role_grants',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'rolePermission',
        'rolePermission',
        '/role_permission',
        'View your role_permissions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'sesMessage',
        'sesMessage',
        '/ses_message',
        'View your ses_messages',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'simpleAttribute',
        'simpleAttribute',
        '/simple_attribute',
        'View your simple_attributes',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'simpleAttributePermission',
        'simpleAttributePermission',
        '/simple_attribute_permission',
        'View your simple_attribute_permissions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'simpleAttributePermissionRule',
        'simpleAttributePermissionRule',
        '/simple_attribute_permission_rule',
        'View your simple_attribute_permission_rules',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'step',
        'step',
        '/step',
        'View your steps',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'subscription',
        'subscription',
        '/subscription',
        'View your subscriptions',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'token',
        'token',
        '/token',
        'View your tokens',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'topic',
        'topic',
        '/topic',
        'View your topics',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'user',
        'user',
        '/user',
        'View your users',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'view',
        'view',
        '/view',
        'View your views',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'viewGrant',
        'viewGrant',
        '/view_grant',
        'View your view_grants',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'work',
        'work',
        '/work',
        'View your works',
        NavItemLocation.main,
    ),
];