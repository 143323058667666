import { Type } from 'class-transformer';
import {SORespExportProject} from './so-resp-export-project';
import {PluralProtocol} from 'lionheartland';
import {ExportProject} from "../../dexie/db";

export class SOPluralExportProject implements PluralProtocol {
  @Type(() => SORespExportProject)
  objects: SORespExportProject[] = []
  nextOffset: number = null

    toExportProjects(): ExportProject[] {
        return this.objects.map(exportProject => exportProject.toExportProject());
    }
}