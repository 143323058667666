import {SOPutEntityPermission} from '../../core/entity-permission/so-put-entity-permission';
import {EntityPermissionCore} from './entity-permission.core';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {FormField, SelectionOption} from 'lionheartland';
import {putEntityPermissionFormFieldOptions} from './put-entity-permission-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {EntityCore} from '../entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutEntityPermission extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEntityPermissionFormFieldOptions);

  RequestClass = SOPutEntityPermission

  constructor(
    public entityCore: EntityPermissionCore
  ) {
    super();
    if (putEntityPermissionFormFieldOptions.entity) {
      putEntityPermissionFormFieldOptions.entity.makeCore = true;
    }
    if (putEntityPermissionFormFieldOptions.project) {
      putEntityPermissionFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('whiteList'),
      this.getFormValueFromIdentifier('permissionType'),
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}