import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutPrice } from './so-put-price';
import { SOGetPrice } from './so-get-price';
import { SOPostPrice } from './so-post-price';
import { SODeletePrice } from './so-delete-price';
import {SORespPrice} from './so-resp-price';
import {SOPluralPrice} from './so-plural-price';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOPriceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('price', entityCore);
  }

  /** GET Price by id. Will 404 if id not found */
  get(request: SOGetPrice): Observable<SOPluralPrice> {
    return this.httpClient.get<SOPluralPrice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralPrice, resp)),
      tap(async (response: SOPluralPrice) => {
        this.log(`fetched Price`);
        // After a successful server response, update IndexedDB
        const activities = response.toPrices(); // Convert your response to an array of Price instances
        const promises = activities.map(price => db.prices.put(price));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralPrice>(`get Price`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralPrice> {
        console.log("GETCACHE", cacheQuery);
            console.log("No Organization Attribute for Cache Query")
            return from(db.prices.toArray()).pipe(
                map((pricesArray) => {
                    // Convert the array of plain objects to an array of SORespPrice instances
                    const sorPrices = pricesArray.map(req => plainToClass(SORespPrice, req));
                    // Create and return a new instance of SOPluralPrice
                    const pluralPrice = new SOPluralPrice();
                    pluralPrice.objects = sorPrices;
                    pluralPrice.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralPrice;
                }),
                catchError(error => this.handleQueryError(error))
            );
    }

    private processPrices(pricesArray) {
        const sorPrices = pricesArray.map(req => plainToClass(SORespPrice, req));
        const pluralPrice = new SOPluralPrice();
        pluralPrice.objects = sorPrices;
        pluralPrice.nextOffset = null;
        return pluralPrice;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostPrice): Observable<SORespPrice> {
  return this.httpClient.post<SORespPrice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespPrice, resp)),

    tap(async (response: SORespPrice) => {
      this.log(`added Price w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.prices.add(response.toPrice());
    }),
    catchError(this.handleError<SORespPrice>('Price post'))
  );
}


  put(request: SOPutPrice): Observable<SORespPrice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPrice>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespPrice, resp)),
      tap(async (response: SORespPrice) => {
        this.log(`edited Price w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.prices.put(response.toPrice());
      }),
      catchError(this.handleError<SORespPrice>('Price put'))
    );
  }


  delete(request: SODeletePrice): Observable<SORespPrice> {
    return this.httpClient.delete<SORespPrice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Price uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.prices.delete(request.uid);
      }),
      catchError(this.handleError<SORespPrice>('Price delete'))
    );
  }


}
