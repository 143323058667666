import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAppliedConstraintFormFields {

    public constraint: FormField
    public product: FormField
    public quantity: FormField

    constructor() {

        this.constraint = new FormField(
          'constraint',
          'Constraint',
          'constraint',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.product = new FormField(
          'product',
          'Product',
          'product',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostAppliedConstraintFormFields()

export const postAppliedConstraintFormFieldOptions: {[key: string]: FormField} = {
  constraint: fields.constraint,
  product: fields.product,
  quantity: fields.quantity,
};