export enum ENAttributePermissionType {
  read = 'read',
  write = 'write',
}


export enum ENBlockType {
  library = 'library',
  network = 'network',
  custom = 'custom',
}


export enum ENCommanderType {
  asset = 'asset',
  requirement = 'requirement',
  report = 'report',
  deficiency = 'deficiency',
  work = 'work',
}


export enum ENDuration {
  month = 'month',
  eon = 'eon',
}


export enum ENEntityPermissionType {
  create = 'create',
  read = 'read',
  write = 'write',
  delete = 'delete',
}


export enum ENExportType {
  markdown = 'markdown',
  json = 'json',
}


export enum ENInterphaceType {
  post = 'post',
  put = 'put',
  get = 'get',
  delete = 'delete',
}


export enum ENInterval {
  month = 'month',
  year = 'year',
  eon = 'eon',
}


export enum ENInviteStatus {
  sent = 'sent',
  accepted = 'accepted',
  denied = 'denied',
  ignored = 'ignored',
}


export enum ENMediaStatus {
  pending = 'pending',
  uploaded = 'uploaded',
}


export enum ENMediaTypePrimary {
  img = 'img',
  video = 'video',
  pdf = 'pdf',
}


export enum ENMediaTypeSecondary {
  jpeg = 'jpeg',
  png = 'png',
}


export enum ENMessageStatus {
  read = 'read',
  unread = 'unread',
}


export enum ENMultiplicity {
  zeroToOne = 'zeroToOne',
  one = 'one',
  zeroToMany = 'zeroToMany',
  oneToMany = 'oneToMany',
}


export enum ENPaymentIntentStatus {
  complete = 'complete',
}


export enum ENPeriodType {
  year = 'year',
  quarter = 'quarter',
  month = 'month',
  week = 'week',
  day = 'day',
}


export enum ENProcessStepType {
  runStep = 'runStep',
  runProcess = 'runProcess',
}


export enum ENRequirementStatus {
  draft = 'draft',
  proposed = 'proposed',
  inReview = 'inReview',
  accepted = 'accepted',
  rejected = 'rejected',
  complete = 'complete',
}


export enum ENRoleGrantStatus {
  active = 'active',
  inActive = 'inActive',
  deleted = 'deleted',
}


export enum ENSesMessageType {
  bounce = 'bounce',
  complaint = 'complaint',
  delivery = 'delivery',
}


export enum ENSeverity {
  low = 'low',
  medium = 'medium',
  high = 'high',
  critical = 'critical',
}


export enum ENSimpleAttributeType {
  string = 'string',
  int = 'int',
  decimal = 'decimal',
  boolean = 'boolean',
  datetime = 'datetime',
  enum = 'enum',
  dict = 'dict',
}


export enum ENSubscriptionStatus {
  active = 'active',
  trialing = 'trialing',
  canceled = 'canceled',
  pastDue = 'pastDue',
  unpaid = 'unpaid',
}


export enum ENTokenStatus {
  available = 'available',
  deleted = 'deleted',
}


export enum ENViewType {
  primary = 'primary',
  secondary = 'secondary',
  hidden = 'hidden',
}


export enum ENWeeklyReportSection {
  resolution = 'resolution',
  resolved = 'resolved',
  newResolved = 'newResolved',
  newUnresolved = 'newUnresolved',
  discussion = 'discussion',
  staged = 'staged',
  backlog = 'backlog',
}


