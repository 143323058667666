import { SOGetInterphace } from '../interphace/so-get-interphace';
import { SOGetSimpleAttribute } from '../simple-attribute/so-get-simple-attribute';
import { SOGetRelationshipAttribute } from '../relationship-attribute/so-get-relationship-attribute';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAttributeSpec {

  constructor(
    public name: string,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public description: string = "",
    public interphace: SOGetInterphace = null,
    public simpleAttribute: SOGetSimpleAttribute = null,
    public relationshipAttribute: SOGetRelationshipAttribute = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAttributeSpec(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'interphace' in params ? params.interphace : null,
      'simpleAttribute' in params ? params.simpleAttribute : null,
      'relationshipAttribute' in params ? params.relationshipAttribute : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
