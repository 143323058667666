import {SORespRequirement} from '../../core/requirement/so-resp-requirement';
import {RequirementCore} from './requirement.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRequirementFormFields} from './put-requirement-form-fields';
import {SOGetRequirement} from '../../core/requirement/so-get-requirement';
import {SOGetRealization} from '../../core/realization/so-get-realization';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CERequirementAttributesBase {

    name: DetailEntry
    description: DetailEntry
    active: DetailEntry
    currentStatus: DetailEntry
    requirements: DetailEntry
    parent: DetailEntry
    realizations: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRequirement,
        app: CommanderApp,
        formFields: PutRequirementFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )

        this.currentStatus = new DetailEntry(
            'Current status',
            entity.currentStatus,
            'currentStatus',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.currentStatus
        )


        const requirementsCore = app.makeCore( 'requirement');
        requirementsCore.soGet = SOGetRequirement.construct({
          parent: new SOGetRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        requirementsCore.soGet.queryOptions.sortKey = 'dateCreated';
        requirementsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            requirementsCore
        )
        requirementsCore.title = heading ? heading : requirementsCore.title
        requirementsCore.listTitle = 'Requirements'

        requirementsCore.configureForDisplay(parentEntityViewSpec)

        this.requirements = new DetailEntry(
          heading ? heading : requirementsCore.listTitle,
          null,
          'requirements',
          BaseDetailType.embeddedList,
          requirementsCore,
          EditableType.never
        )

        const parentCore = app.makeCore( 'requirement');
        parentCore.soGet = SOGetRequirement.construct({
          parent: new SOGetRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        parentCore.soGet.queryOptions.sortKey = 'dateCreated';
        parentCore.soGet.queryOptions.descending = true;
        let parentCeProtocol;
        if (entity.parent) {
            parentCeProtocol = createCEProtocol(
                parentCore.ceProtocol,
                parentEntityViewSpec,
                parentCore,
                entity.parent,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            parentCore
        )
        parentCore.title = heading ? heading : parentCore.title
        parentCore.listTitle = 'Parent'

        if (entity.parent) {
            displayStringFactory.reconfigure(
                entity.parent,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        parentCore.configureForDisplay(parentEntityViewSpec)

        this.parent = new DetailEntry(
          heading ? heading : parentCore.listTitle,
          parentCeProtocol ? parentCeProtocol.entityListView.fullHeading : null,
          'parent',
          BaseDetailType.pushedDetail,
          parentCore,
          EditableType.always,
          formFields.parent
        )
        if (entity.parent) {
          this.parent.ceProtocol = parentCeProtocol;
          this.parent.cachedListEntry = parentCore.makeListEntry(parentEntityViewSpec, entity.parent, 0)
          parentCore.selectedEntity = entity.parent;
        }
        this.parent.singleFieldSelect = true

        const realizationsCore = app.makeCore( 'realization');
        realizationsCore.soGet = SOGetRealization.construct({
          requirement: new SOGetRequirement(entity.uid),
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        realizationsCore.soGet.queryOptions.sortKey = 'dateCreated';
        realizationsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            realizationsCore
        )
        realizationsCore.title = heading ? heading : realizationsCore.title
        realizationsCore.listTitle = 'Realizations'

        realizationsCore.configureForDisplay(parentEntityViewSpec)

        this.realizations = new DetailEntry(
          heading ? heading : realizationsCore.listTitle,
          null,
          'realizations',
          BaseDetailType.embeddedList,
          realizationsCore,
          EditableType.never
        )

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}