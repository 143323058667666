import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutPeriod } from './so-put-period';
import { SOGetPeriod } from './so-get-period';
import { SOPostPeriod } from './so-post-period';
import { SODeletePeriod } from './so-delete-period';
import {SORespPeriod} from './so-resp-period';
import {SOPluralPeriod} from './so-plural-period';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOPeriodService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('period', entityCore);
  }

  /** GET Period by id. Will 404 if id not found */
  get(request: SOGetPeriod): Observable<SOPluralPeriod> {
    return this.httpClient.get<SOPluralPeriod>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralPeriod, resp)),
      tap(async (response: SOPluralPeriod) => {
        this.log(`fetched Period`);
        // After a successful server response, update IndexedDB
        const activities = response.toPeriods(); // Convert your response to an array of Period instances
        const promises = activities.map(period => db.periods.put(period));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralPeriod>(`get Period`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralPeriod> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'period' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.periods.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "plans" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('plansUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "works" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('worksUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "reports" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('reportsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "periods" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('periodsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "parent" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.periods.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(periodsArray => this.processPeriods(periodsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.periods.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(periodsArray => this.processPeriods(periodsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.periods.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(periodsArray => this.processPeriods(periodsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.periods.toArray()).pipe(
                map((periodsArray) => {
                    // Convert the array of plain objects to an array of SORespPeriod instances
                    const sorPeriods = periodsArray.map(req => plainToClass(SORespPeriod, req));
                    // Create and return a new instance of SOPluralPeriod
                    const pluralPeriod = new SOPluralPeriod();
                    pluralPeriod.objects = sorPeriods;
                    pluralPeriod.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralPeriod;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processPeriods(periodsArray) {
        const sorPeriods = periodsArray.map(req => plainToClass(SORespPeriod, req));
        const pluralPeriod = new SOPluralPeriod();
        pluralPeriod.objects = sorPeriods;
        pluralPeriod.nextOffset = null;
        return pluralPeriod;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostPeriod): Observable<SORespPeriod> {
  return this.httpClient.post<SORespPeriod>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespPeriod, resp)),

    tap(async (response: SORespPeriod) => {
      this.log(`added Period w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.periods.add(response.toPeriod());
    }),
    catchError(this.handleError<SORespPeriod>('Period post'))
  );
}


  put(request: SOPutPeriod): Observable<SORespPeriod> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPeriod>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespPeriod, resp)),
      tap(async (response: SORespPeriod) => {
        this.log(`edited Period w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.periods.put(response.toPeriod());
      }),
      catchError(this.handleError<SORespPeriod>('Period put'))
    );
  }


  delete(request: SODeletePeriod): Observable<SORespPeriod> {
    return this.httpClient.delete<SORespPeriod>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Period uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.periods.delete(request.uid);
      }),
      catchError(this.handleError<SORespPeriod>('Period delete'))
    );
  }


}
