import { Type } from 'class-transformer';
import {SORespRelationshipAttributePermissionRule} from './so-resp-relationship-attribute-permission-rule';
import {PluralProtocol} from 'lionheartland';
import {RelationshipAttributePermissionRule} from "../../dexie/db";

export class SOPluralRelationshipAttributePermissionRule implements PluralProtocol {
  @Type(() => SORespRelationshipAttributePermissionRule)
  objects: SORespRelationshipAttributePermissionRule[] = []
  nextOffset: number = null

    toRelationshipAttributePermissionRules(): RelationshipAttributePermissionRule[] {
        return this.objects.map(relationshipAttributePermissionRule => relationshipAttributePermissionRule.toRelationshipAttributePermissionRule());
    }
}