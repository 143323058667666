import { Type } from 'class-transformer';
import {SORespNotification} from './so-resp-notification';
import {PluralProtocol} from 'lionheartland';
import {Notification} from "../../dexie/db";

export class SOPluralNotification implements PluralProtocol {
  @Type(() => SORespNotification)
  objects: SORespNotification[] = []
  nextOffset: number = null

    toNotifications(): Notification[] {
        return this.objects.map(notification => notification.toNotification());
    }
}