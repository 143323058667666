import {SORespAssessmentSummary} from '../../core/assessment-summary/so-resp-assessment-summary';
import {AssessmentSummaryCore} from './assessment-summary.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAssessmentSummaryFormFields} from './put-assessment-summary-form-fields';
import { CEAssessmentSummaryAttributes } from './ce-assessment-summary-attributes'


export class CEAssessmentSummaryBase extends CEBase {

    public details: CEAssessmentSummaryAttributes;
    public fromFields: PutAssessmentSummaryFormFields = new PutAssessmentSummaryFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AssessmentSummaryCore,
        public entity: SORespAssessmentSummary,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAssessmentSummaryAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.totalRequirements)
        this.attributes.push(this.details.completedRequirements)
        this.attributes.push(this.details.draftRequirements)
        this.attributes.push(this.details.proposedRequirements)
        this.attributes.push(this.details.inReviewRequirements)
        this.attributes.push(this.details.acceptedRequirements)
        this.attributes.push(this.details.rejectedRequirements)
        this.attributes.push(this.details.totalCustomLogicRequirements)
        this.attributes.push(this.details.completedCustomLogicRequirements)
        this.attributes.push(this.details.draftCustomLogicRequirements)
        this.attributes.push(this.details.proposedCustomLogicRequirements)
        this.attributes.push(this.details.inReviewCustomLogicRequirements)
        this.attributes.push(this.details.acceptedCustomLogicRequirements)
        this.attributes.push(this.details.rejectedCustomLogicRequirements)
        this.attributes.push(this.details.totalAttributes)
        this.attributes.push(this.details.attributesWithRequirements)
        this.attributes.push(this.details.attributesWithoutRequirements)
        this.attributes.push(this.details.totalProcesses)
        this.attributes.push(this.details.processesWithRequirements)
        this.attributes.push(this.details.processesWithoutRequirements)
        this.attributes.push(this.details.totalBlocks)
        this.attributes.push(this.details.blocksWithCustomLogicRequirements)
        this.attributes.push(this.details.blocksWithoutCustomLogicRequirements)
        this.attributes.push(this.details.assessment)
        this.attributes.push(this.details.project)

    }
}