import { Type } from 'class-transformer';
import {SORespPerson} from './so-resp-person';
import {PluralProtocol} from 'lionheartland';
import {Person} from "../../dexie/db";

export class SOPluralPerson implements PluralProtocol {
  @Type(() => SORespPerson)
  objects: SORespPerson[] = []
  nextOffset: number = null

    toPersons(): Person[] {
        return this.objects.map(person => person.toPerson());
    }
}