import { Type } from 'class-transformer';
import { SORespTopic } from '../topic/so-resp-topic';
import { SORespDatafeed } from '../datafeed/so-resp-datafeed';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {AlertSubscription} from "../../dexie/db";

export class SORespAlertSubscription implements ObjectiveProtocol {

  entityType: string = 'alertSubscription';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => SORespTopic)
  topic: SORespTopic;
  @Type(() => SORespDatafeed)
  datafeed: SORespDatafeed;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toAlertSubscription(): AlertSubscription {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            topicUid: this.topic ? this.topic.uid : null,
            datafeedUid: this.datafeed ? this.datafeed.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}