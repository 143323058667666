import { Type } from 'class-transformer';
import {SORespMedia} from './so-resp-media';
import {PluralProtocol} from 'lionheartland';
import {Media} from "../../dexie/db";

export class SOPluralMedia implements PluralProtocol {
  @Type(() => SORespMedia)
  objects: SORespMedia[] = []
  nextOffset: number = null

    toMedias(): Media[] {
        return this.objects.map(media => media.toMedia());
    }
}