import {DEFAULT_ROUTES} from "./app-default-routes";

export const ALL_ROUTES = [];

for (const route of DEFAULT_ROUTES) {
    ALL_ROUTES.push(
        route
    )
}

ALL_ROUTES.push(

)