import { Type } from 'class-transformer';
import {SORespDeficiency} from './so-resp-deficiency';
import {PluralProtocol} from 'lionheartland';
import {Deficiency} from "../../dexie/db";

export class SOPluralDeficiency implements PluralProtocol {
  @Type(() => SORespDeficiency)
  objects: SORespDeficiency[] = []
  nextOffset: number = null

    toDeficiencys(): Deficiency[] {
        return this.objects.map(deficiency => deficiency.toDeficiency());
    }
}