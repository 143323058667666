import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOEntityPermissionService} from '../../core/entity-permission/eo-entity-permission';
import {SOGetEntityPermission} from '../../core/entity-permission/so-get-entity-permission';
import {RAPutEntityPermission} from './ra-put-entity-permission';
import {RAPostEntityPermission} from './ra-post-entity-permission';
import {RADeleteEntityPermission} from './ra-delete-entity-permission';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEEntityPermission } from "./ce-entity-permission"


export class EntityPermissionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityPermission';
  title = 'Entity permission';
  ceProtocol: CEConstructor = CEEntityPermission

  entityService: EOEntityPermissionService;
  soGetClass = SOGetEntityPermission;
  soGet: SOGetEntityPermission;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOEntityPermissionService,
      null,
    );
    this.adapters = [
      new RAPostEntityPermission(this),
      new RAPutEntityPermission(this),
      new RADeleteEntityPermission(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityPermission();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}