import {SOPostAttributeSpec} from '../../core/attribute-spec/so-post-attribute-spec';
import {AttributeSpecCore} from './attribute-spec.core';
import {SOGetAttributeSpec} from '../../core/attribute-spec/so-get-attribute-spec';
import {FormField, SelectionOption} from 'lionheartland';
import {postAttributeSpecFormFieldOptions} from './post-attribute-spec-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {InterphaceCore} from '../interphace/interphace.core';
import {SOGetInterphace} from '../../core/interphace/so-get-interphace';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {RelationshipAttributeCore} from '../relationship-attribute/relationship-attribute.core';
import {SOGetRelationshipAttribute} from '../../core/relationship-attribute/so-get-relationship-attribute';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAttributeSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAttributeSpecFormFieldOptions);

  RequestClass = SOPostAttributeSpec

  constructor(
    public entityCore: AttributeSpecCore
  ) {
    super();
    if (postAttributeSpecFormFieldOptions.interphace) {
      postAttributeSpecFormFieldOptions.interphace.makeCore = true;
    }
    if (postAttributeSpecFormFieldOptions.simpleAttribute) {
      postAttributeSpecFormFieldOptions.simpleAttribute.makeCore = true;
    }
    if (postAttributeSpecFormFieldOptions.relationshipAttribute) {
      postAttributeSpecFormFieldOptions.relationshipAttribute.makeCore = true;
    }
    if (postAttributeSpecFormFieldOptions.project) {
      postAttributeSpecFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'interphace',
        SOGetInterphace,
      ),
      this.getFormValueFromIdentifier(
        'simpleAttribute',
        SOGetSimpleAttribute,
      ),
      this.getFormValueFromIdentifier(
        'relationshipAttribute',
        SOGetRelationshipAttribute,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}