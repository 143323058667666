import { SOGetPeriod } from '../period/so-get-period';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENPeriodType} from '../../enums';


export class SOPostPeriod {

  constructor(
    public name: string,
    public numeral: number,
    public type: ENPeriodType,
    public project: SOGetProject,
    public organization: SOGetOrganization,
    public parent: SOGetPeriod = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPeriod(
      'name' in params ? params.name : null,
      'numeral' in params ? params.numeral : null,
      'type' in params ? params.type : null,
      'parent' in params ? params.parent : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
