import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostWorkFormFields {

    public name: FormField
    public description: FormField
    public active: FormField
    public deficiency: FormField
    public period: FormField
    public backlog: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          true,
        )
        this.deficiency = new FormField(
          'deficiency',
          'Deficiency',
          'deficiency',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.period = new FormField(
          'period',
          'Period',
          'period',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.backlog = new FormField(
          'backlog',
          'Backlog',
          'backlog',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostWorkFormFields()

export const postWorkFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  active: fields.active,
  deficiency: fields.deficiency,
  period: fields.period,
  backlog: fields.backlog,
  project: fields.project,
};