import {SORespStep} from '../../core/step/so-resp-step';
import {StepCore} from './step.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutStepFormFields} from './put-step-form-fields';
import { CEStepAttributes } from './ce-step-attributes'


export class CEStepBase extends CEBase {

    public details: CEStepAttributes;
    public fromFields: PutStepFormFields = new PutStepFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: StepCore,
        public entity: SORespStep,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEStepAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.stepNumber)
        this.attributes.push(this.details.processStepAssociations)
        this.attributes.push(this.details.project)

    }
}