import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';
import {Datafeed} from "../../dexie/db";

export class SORespDatafeed implements ObjectiveProtocol {

  entityType: string = 'datafeed';
  uid: string;
  name: string;
  description: string = "";
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;

    toDatafeed(): Datafeed {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            notificationsUid: null,
            alertSubscriptionsUid: null,
            userUid: null,
}

        };
}