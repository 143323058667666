import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSesMessageService} from '../../core/ses-message/eo-ses-message';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';
import {RAPutSesMessage} from './ra-put-ses-message';
import {RAPostSesMessage} from './ra-post-ses-message';
import {RADeleteSesMessage} from './ra-delete-ses-message';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CESesMessage } from "./ce-ses-message"


export class SesMessageCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'sesMessage';
  title = 'Ses message';
  ceProtocol: CEConstructor = CESesMessage

  entityService: EOSesMessageService;
  soGetClass = SOGetSesMessage;
  soGet: SOGetSesMessage;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSesMessageService,
      null,
    );
    this.adapters = [
      new RAPostSesMessage(this),
      new RAPutSesMessage(this),
      new RADeleteSesMessage(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSesMessage();
    this.soGet.queryOptions.limit = this.limit;
  }


}