import { Type } from 'class-transformer';
import { SORespPerson } from '../person/so-resp-person';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';
import {AssignedProject} from "../../dexie/db";

export class SORespAssignedProject implements ObjectiveProtocol {

  entityType: string = 'assignedProject';
  uid: string;
  name: string;
  @Type(() => SORespPerson)
  person: SORespPerson;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toAssignedProject(): AssignedProject {
        return {
            uid: this.uid,
            name: this.name,
            personUid: this.person ? this.person.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}