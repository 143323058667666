import {SOPostSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-post-simple-attribute-permission-rule';
import {SimpleAttributePermissionRuleCore} from './simple-attribute-permission-rule.core';
import {SOGetSimpleAttributePermissionRule} from '../../core/simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import {FormField, SelectionOption} from 'lionheartland';
import {postSimpleAttributePermissionRuleFormFieldOptions} from './post-simple-attribute-permission-rule-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {SimpleAttributeCore} from '../simple-attribute/simple-attribute.core';
import {SOGetSimpleAttribute} from '../../core/simple-attribute/so-get-simple-attribute';
import {SimpleAttributePermissionCore} from '../simple-attribute-permission/simple-attribute-permission.core';
import {SOGetSimpleAttributePermission} from '../../core/simple-attribute-permission/so-get-simple-attribute-permission';
import {ProjectCore} from '../project/project.core';
import {SOGetProject} from '../../core/project/so-get-project';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostSimpleAttributePermissionRule extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSimpleAttributePermissionRuleFormFieldOptions);

  RequestClass = SOPostSimpleAttributePermissionRule

  constructor(
    public entityCore: SimpleAttributePermissionRuleCore
  ) {
    super();
    if (postSimpleAttributePermissionRuleFormFieldOptions.simpleAttribute) {
      postSimpleAttributePermissionRuleFormFieldOptions.simpleAttribute.makeCore = true;
    }
    if (postSimpleAttributePermissionRuleFormFieldOptions.simpleAttributePermission) {
      postSimpleAttributePermissionRuleFormFieldOptions.simpleAttributePermission.makeCore = true;
    }
    if (postSimpleAttributePermissionRuleFormFieldOptions.project) {
      postSimpleAttributePermissionRuleFormFieldOptions.project.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'project',
        SOGetProject,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'simpleAttribute',
        SOGetSimpleAttribute,
      ),
      this.getFormValueFromIdentifier(
        'simpleAttributePermission',
        SOGetSimpleAttributePermission,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}