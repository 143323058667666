import { Type } from 'class-transformer';
import {SORespIdentity} from './so-resp-identity';
import {PluralProtocol} from 'lionheartland';
import {Identity} from "../../dexie/db";

export class SOPluralIdentity implements PluralProtocol {
  @Type(() => SORespIdentity)
  objects: SORespIdentity[] = []
  nextOffset: number = null

    toIdentitys(): Identity[] {
        return this.objects.map(identity => identity.toIdentity());
    }
}