import { SOGetAssessment } from '../assessment/so-get-assessment';
import { SOGetBlock } from '../block/so-get-block';
import { SOGetProcess } from '../process/so-get-process';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENSeverity} from '../../enums';


export class SOPutDeficiency {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public active: boolean = null,
    public severity: ENSeverity = null,
    public assessment: SOGetAssessment = null,
    public block: SOGetBlock = null,
    public process: SOGetProcess = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDeficiency(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'active' in params ? params.active : null,
      'severity' in params ? params.severity : null,
      'assessment' in params ? params.assessment : null,
      'block' in params ? params.block : null,
      'process' in params ? params.process : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
