import { Type } from 'class-transformer';
import {SORespUser} from './so-resp-user';
import {PluralProtocol} from 'lionheartland';
import {User} from "../../dexie/db";

export class SOPluralUser implements PluralProtocol {
  @Type(() => SORespUser)
  objects: SORespUser[] = []
  nextOffset: number = null

    toUsers(): User[] {
        return this.objects.map(user => user.toUser());
    }
}