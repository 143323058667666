import {SORespRequirement} from '../../core/requirement/so-resp-requirement';
import {RequirementCore} from './requirement.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutRequirementFormFields} from './put-requirement-form-fields';
import { CERequirementAttributes } from './ce-requirement-attributes'


export class CERequirementBase extends CEBase {

    public details: CERequirementAttributes;
    public fromFields: PutRequirementFormFields = new PutRequirementFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RequirementCore,
        public entity: SORespRequirement,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERequirementAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.currentStatus)
        this.attributes.push(this.details.requirements)
        this.attributes.push(this.details.parent)
        this.attributes.push(this.details.realizations)
        this.attributes.push(this.details.project)

    }
}