import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOCertificateService} from '../../core/certificate/eo-certificate';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {RAPutCertificate} from './ra-put-certificate';
import {RAPostCertificate} from './ra-post-certificate';
import {RADeleteCertificate} from './ra-delete-certificate';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CECertificate } from "./ce-certificate"


export class CertificateCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'certificate';
  title = 'Certificate';
  ceProtocol: CEConstructor = CECertificate

  entityService: EOCertificateService;
  soGetClass = SOGetCertificate;
  soGet: SOGetCertificate;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOCertificateService,
      null,
    );
    this.adapters = [
      new RAPostCertificate(this),
      new RAPutCertificate(this),
      new RADeleteCertificate(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCertificate();
    this.soGet.queryOptions.limit = this.limit;
  }


}