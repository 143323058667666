import { SOGetSimpleAttributePermissionRule } from '../simple-attribute-permission-rule/so-get-simple-attribute-permission-rule';
import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetCustomLogicRequirement } from '../custom-logic-requirement/so-get-custom-logic-requirement';
import { SOGetProject } from '../project/so-get-project';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENSimpleAttributeType} from '../../enums';


export class SOPutSimpleAttribute {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public attributeType: ENSimpleAttributeType = null,
    public editPermissions: boolean = null,
    public simpleAttributePermissionRules: SOGetSimpleAttributePermissionRule[] = null,
    public entity: SOGetEntity = null,
    public customLogicRequirement: SOGetCustomLogicRequirement = null,
    public project: SOGetProject = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSimpleAttribute(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'attributeType' in params ? params.attributeType : null,
      'editPermissions' in params ? params.editPermissions : null,
      'simpleAttributePermissionRules' in params ? params.simpleAttributePermissionRules : null,
      'entity' in params ? params.entity : null,
      'customLogicRequirement' in params ? params.customLogicRequirement : null,
      'project' in params ? params.project : null,
      'organization' in params ? params.organization : null,
    );
  }
}
