import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAssessmentSummaryService} from '../../core/assessment-summary/eo-assessment-summary';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {RAPutAssessmentSummary} from './ra-put-assessment-summary';
import {RAPostAssessmentSummary} from './ra-post-assessment-summary';
import {RADeleteAssessmentSummary} from './ra-delete-assessment-summary';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import { CEAssessmentSummary } from "./ce-assessment-summary"


export class AssessmentSummaryCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'assessmentSummary';
  title = 'Assessment summary';
  ceProtocol: CEConstructor = CEAssessmentSummary

  entityService: EOAssessmentSummaryService;
  soGetClass = SOGetAssessmentSummary;
  soGet: SOGetAssessmentSummary;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAssessmentSummaryService,
      null,
    );
    this.adapters = [
      new RAPostAssessmentSummary(this),
      new RAPutAssessmentSummary(this),
      new RADeleteAssessmentSummary(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAssessmentSummary();
    if (this.activeUserService.getScope('organization')) {
        this.soGet.organization = new SOGetOrganization(
          this.activeUserService.getScope('organization').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}