import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAlertSubscriptionFormFields {

    public name: FormField
    public description: FormField
    public topic: FormField
    public datafeed: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.topic = new FormField(
          'topic',
          'Topic',
          'topic',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.datafeed = new FormField(
          'datafeed',
          'Datafeed',
          'datafeed',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutAlertSubscriptionFormFields()

export const putAlertSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  topic: fields.topic,
  datafeed: fields.datafeed,
};