import {SORespAttachment} from '../../core/attachment/so-resp-attachment';
import {AttachmentCore} from './attachment.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAttachmentFormFields} from './put-attachment-form-fields';
import { CEAttachmentAttributes } from './ce-attachment-attributes'


export class CEAttachmentBase extends CEBase {

    public details: CEAttachmentAttributes;
    public fromFields: PutAttachmentFormFields = new PutAttachmentFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AttachmentCore,
        public entity: SORespAttachment,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAttachmentAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.media)

    }
}