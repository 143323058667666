import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOIdentityService} from '../../core/identity/eo-identity';
import {SOGetIdentity} from '../../core/identity/so-get-identity';
import {RAPutIdentity} from './ra-put-identity';
import {RAPostIdentity} from './ra-post-identity';
import {RADeleteIdentity} from './ra-delete-identity';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEIdentity } from "./ce-identity"


export class IdentityCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'identity';
  title = 'Identity';
  ceProtocol: CEConstructor = CEIdentity

  entityService: EOIdentityService;
  soGetClass = SOGetIdentity;
  soGet: SOGetIdentity;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOIdentityService,
      null,
    );
    this.adapters = [
      new RAPostIdentity(this),
      new RAPutIdentity(this),
      new RADeleteIdentity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetIdentity();
    this.soGet.queryOptions.limit = this.limit;
  }


}