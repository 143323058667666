import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENWeeklyReportSection} from '../../enums';

export class PutPlanWorkAssociationFormFields {

    public name: FormField
    public reportSection: FormField
    public plan: FormField
    public work: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.reportSection = new FormField(
          'reportSection',
          'Report section',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.reportSection.enumeration = ENWeeklyReportSection
        this.plan = new FormField(
          'plan',
          'Plan',
          'plan',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.work = new FormField(
          'work',
          'Work',
          'work',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutPlanWorkAssociationFormFields()

export const putPlanWorkAssociationFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  reportSection: fields.reportSection,
  plan: fields.plan,
  work: fields.work,
};