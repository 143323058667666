import {SORespPlanWorkAssociation} from '../../core/plan-work-association/so-resp-plan-work-association';
import {PlanWorkAssociationCore} from './plan-work-association.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutPlanWorkAssociationFormFields} from './put-plan-work-association-form-fields';
import {SOGetPlanWorkAssociation} from '../../core/plan-work-association/so-get-plan-work-association';
import {SOGetPlan} from '../../core/plan/so-get-plan';
import {SOGetWork} from '../../core/work/so-get-work';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEPlanWorkAssociationAttributesBase {

    name: DetailEntry
    reportSection: DetailEntry
    plan: DetailEntry
    work: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPlanWorkAssociation,
        app: CommanderApp,
        formFields: PutPlanWorkAssociationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.reportSection = new DetailEntry(
            'Report section',
            entity.reportSection,
            'reportSection',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.reportSection
        )


        const planCore = app.makeCore( 'plan');
        planCore.soGet = SOGetPlan.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        planCore.soGet.queryOptions.sortKey = 'dateCreated';
        planCore.soGet.queryOptions.descending = true;
        let planCeProtocol;
        if (entity.plan) {
            planCeProtocol = createCEProtocol(
                planCore.ceProtocol,
                parentEntityViewSpec,
                planCore,
                entity.plan,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            planCore
        )
        planCore.title = heading ? heading : planCore.title
        planCore.listTitle = 'Plan'

        if (entity.plan) {
            displayStringFactory.reconfigure(
                entity.plan,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        planCore.configureForDisplay(parentEntityViewSpec)

        this.plan = new DetailEntry(
          heading ? heading : planCore.listTitle,
          planCeProtocol ? planCeProtocol.entityListView.fullHeading : null,
          'plan',
          BaseDetailType.pushedDetail,
          planCore,
          EditableType.always,
          formFields.plan
        )
        if (entity.plan) {
          this.plan.ceProtocol = planCeProtocol;
          this.plan.cachedListEntry = planCore.makeListEntry(parentEntityViewSpec, entity.plan, 0)
          planCore.selectedEntity = entity.plan;
        }
        this.plan.singleFieldSelect = true

        const workCore = app.makeCore( 'work');
        workCore.soGet = SOGetWork.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        workCore.soGet.queryOptions.sortKey = 'dateCreated';
        workCore.soGet.queryOptions.descending = true;
        let workCeProtocol;
        if (entity.work) {
            workCeProtocol = createCEProtocol(
                workCore.ceProtocol,
                parentEntityViewSpec,
                workCore,
                entity.work,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            workCore
        )
        workCore.title = heading ? heading : workCore.title
        workCore.listTitle = 'Work'

        if (entity.work) {
            displayStringFactory.reconfigure(
                entity.work,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        workCore.configureForDisplay(parentEntityViewSpec)

        this.work = new DetailEntry(
          heading ? heading : workCore.listTitle,
          workCeProtocol ? workCeProtocol.entityListView.fullHeading : null,
          'work',
          BaseDetailType.pushedDetail,
          workCore,
          EditableType.always,
          formFields.work
        )
        if (entity.work) {
          this.work.ceProtocol = workCeProtocol;
          this.work.cachedListEntry = workCore.makeListEntry(parentEntityViewSpec, entity.work, 0)
          workCore.selectedEntity = entity.work;
        }
        this.work.singleFieldSelect = true
    }
}