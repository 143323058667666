import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostInvoiceLineFormFields {

    public amount: FormField
    public quantity: FormField
    public dateCreated: FormField
    public description: FormField
    public price: FormField
    public invoice: FormField

    constructor() {

        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.price = new FormField(
          'price',
          'Price',
          'price',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.invoice = new FormField(
          'invoice',
          'Invoice',
          'invoice',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostInvoiceLineFormFields()

export const postInvoiceLineFormFieldOptions: {[key: string]: FormField} = {
  amount: fields.amount,
  quantity: fields.quantity,
  dateCreated: fields.dateCreated,
  description: fields.description,
  price: fields.price,
  invoice: fields.invoice,
};