import {SORespMessage} from '../../core/message/so-resp-message';
import {MessageCore} from './message.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutMessageFormFields} from './put-message-form-fields';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetNotification} from '../../core/notification/so-get-notification';
import {SOGetTopic} from '../../core/topic/so-get-topic';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEMessageAttributesBase {

    name: DetailEntry
    description: DetailEntry
    dateCreated: DetailEntry
    dateUpdated: DetailEntry
    notifications: DetailEntry
    topic: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespMessage,
        app: CommanderApp,
        formFields: PutMessageFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateUpdated = new DetailEntry(
            'Date updated',
            app.activeUserService.time.dateAsString(entity.dateUpdated),
            'dateUpdated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const notificationsCore = app.makeCore( 'notification');
        notificationsCore.soGet = SOGetNotification.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            notificationsCore
        )
        notificationsCore.title = heading ? heading : notificationsCore.title
        notificationsCore.listTitle = 'Notifications'

        notificationsCore.configureForDisplay(parentEntityViewSpec)

        this.notifications = new DetailEntry(
          heading ? heading : notificationsCore.listTitle,
          null,
          'notifications',
          BaseDetailType.embeddedList,
          notificationsCore,
          EditableType.never
        )

        const topicCore = app.makeCore( 'topic');
        topicCore.soGet = SOGetTopic.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            topicCore
        )
        topicCore.title = heading ? heading : topicCore.title
        topicCore.listTitle = 'Topic'

        topicCore.configureForDisplay(parentEntityViewSpec)

        this.topic = new DetailEntry(
          heading ? heading : topicCore.listTitle,
          null,
          'topic',
          BaseDetailType.pushedDetail,
          topicCore,
          EditableType.never
        )
        this.topic.singleFieldSelect = true
    }
}