import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRealization } from './so-put-realization';
import { SOGetRealization } from './so-get-realization';
import { SOPostRealization } from './so-post-realization';
import { SODeleteRealization } from './so-delete-realization';
import {SORespRealization} from './so-resp-realization';
import {SOPluralRealization} from './so-plural-realization';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORealizationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('realization', entityCore);
  }

  /** GET Realization by id. Will 404 if id not found */
  get(request: SOGetRealization): Observable<SOPluralRealization> {
    return this.httpClient.get<SOPluralRealization>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRealization, resp)),
      tap(async (response: SOPluralRealization) => {
        this.log(`fetched Realization`);
        // After a successful server response, update IndexedDB
        const activities = response.toRealizations(); // Convert your response to an array of Realization instances
        const promises = activities.map(realization => db.realizations.put(realization));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRealization>(`get Realization`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRealization> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'realization' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.realizations.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "entity" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('entityUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "simpleAttribute" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('simpleAttributeUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "relationshipAttribute" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('relationshipAttributeUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "requirement" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('requirementUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "process" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('processUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "attributeSpec" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('attributeSpecUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.realizations.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(realizationsArray => this.processRealizations(realizationsArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.realizations.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(realizationsArray => this.processRealizations(realizationsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.realizations.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(realizationsArray => this.processRealizations(realizationsArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.realizations.toArray()).pipe(
                map((realizationsArray) => {
                    // Convert the array of plain objects to an array of SORespRealization instances
                    const sorRealizations = realizationsArray.map(req => plainToClass(SORespRealization, req));
                    // Create and return a new instance of SOPluralRealization
                    const pluralRealization = new SOPluralRealization();
                    pluralRealization.objects = sorRealizations;
                    pluralRealization.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRealization;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRealizations(realizationsArray) {
        const sorRealizations = realizationsArray.map(req => plainToClass(SORespRealization, req));
        const pluralRealization = new SOPluralRealization();
        pluralRealization.objects = sorRealizations;
        pluralRealization.nextOffset = null;
        return pluralRealization;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRealization): Observable<SORespRealization> {
  return this.httpClient.post<SORespRealization>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRealization, resp)),

    tap(async (response: SORespRealization) => {
      this.log(`added Realization w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.realizations.add(response.toRealization());
    }),
    catchError(this.handleError<SORespRealization>('Realization post'))
  );
}


  put(request: SOPutRealization): Observable<SORespRealization> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRealization>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRealization, resp)),
      tap(async (response: SORespRealization) => {
        this.log(`edited Realization w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.realizations.put(response.toRealization());
      }),
      catchError(this.handleError<SORespRealization>('Realization put'))
    );
  }


  delete(request: SODeleteRealization): Observable<SORespRealization> {
    return this.httpClient.delete<SORespRealization>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Realization uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.realizations.delete(request.uid);
      }),
      catchError(this.handleError<SORespRealization>('Realization delete'))
    );
  }


}
