import { Type } from 'class-transformer';
import { SORespRelationshipAttribute } from '../relationship-attribute/so-resp-relationship-attribute';
import { SORespRelationshipAttributePermission } from '../relationship-attribute-permission/so-resp-relationship-attribute-permission';
import { SORespProject } from '../project/so-resp-project';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENAttributePermissionType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';
import {RelationshipAttributePermissionRule} from "../../dexie/db";

export class SORespRelationshipAttributePermissionRule implements ObjectiveProtocol {

  entityType: string = 'relationshipAttributePermissionRule';
  uid: string;
  name: string;
  description: string = "";
  whiteList: boolean;
  permissionType: ENAttributePermissionType;
  @Type(() => SORespRelationshipAttribute)
  relationshipAttribute: SORespRelationshipAttribute = null;
  @Type(() => SORespRelationshipAttributePermission)
  relationshipAttributePermission: SORespRelationshipAttributePermission = null;
  @Type(() => SORespProject)
  project: SORespProject;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;

    toRelationshipAttributePermissionRule(): RelationshipAttributePermissionRule {
        return {
            uid: this.uid,
            name: this.name,
            description: this.description,
            dateCreated: null,
            whiteList: this.whiteList,
            permissionType: this.permissionType,
            relationshipAttributeUid: this.relationshipAttribute ? this.relationshipAttribute.uid : null,
            relationshipAttributePermissionUid: this.relationshipAttributePermission ? this.relationshipAttributePermission.uid : null,
            projectUid: this.project ? this.project.uid : null,
            organizationUid: this.organization ? this.organization.uid : null,
}

        };
}