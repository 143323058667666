import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutPlan } from './so-put-plan';
import { SOGetPlan } from './so-get-plan';
import { SOPostPlan } from './so-post-plan';
import { SODeletePlan } from './so-delete-plan';
import {SORespPlan} from './so-resp-plan';
import {SOPluralPlan} from './so-plural-plan';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOPlanService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('plan', entityCore);
  }

  /** GET Plan by id. Will 404 if id not found */
  get(request: SOGetPlan): Observable<SOPluralPlan> {
    return this.httpClient.get<SOPluralPlan>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralPlan, resp)),
      tap(async (response: SOPluralPlan) => {
        this.log(`fetched Plan`);
        // After a successful server response, update IndexedDB
        const activities = response.toPlans(); // Convert your response to an array of Plan instances
        const promises = activities.map(plan => db.plans.put(plan));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralPlan>(`get Plan`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralPlan> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'plan' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.plans.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(plansArray => this.processPlans(plansArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "period" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.plans.where('periodUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(plansArray => this.processPlans(plansArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "planWorkAssociations" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.plans.where('planWorkAssociationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(plansArray => this.processPlans(plansArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "project" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.plans.where('projectUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(plansArray => this.processPlans(plansArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.plans.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(plansArray => this.processPlans(plansArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.plans.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(plansArray => this.processPlans(plansArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.plans.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(plansArray => this.processPlans(plansArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.plans.toArray()).pipe(
                map((plansArray) => {
                    // Convert the array of plain objects to an array of SORespPlan instances
                    const sorPlans = plansArray.map(req => plainToClass(SORespPlan, req));
                    // Create and return a new instance of SOPluralPlan
                    const pluralPlan = new SOPluralPlan();
                    pluralPlan.objects = sorPlans;
                    pluralPlan.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralPlan;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processPlans(plansArray) {
        const sorPlans = plansArray.map(req => plainToClass(SORespPlan, req));
        const pluralPlan = new SOPluralPlan();
        pluralPlan.objects = sorPlans;
        pluralPlan.nextOffset = null;
        return pluralPlan;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostPlan): Observable<SORespPlan> {
  return this.httpClient.post<SORespPlan>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespPlan, resp)),

    tap(async (response: SORespPlan) => {
      this.log(`added Plan w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.plans.add(response.toPlan());
    }),
    catchError(this.handleError<SORespPlan>('Plan post'))
  );
}


  put(request: SOPutPlan): Observable<SORespPlan> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPlan>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespPlan, resp)),
      tap(async (response: SORespPlan) => {
        this.log(`edited Plan w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.plans.put(response.toPlan());
      }),
      catchError(this.handleError<SORespPlan>('Plan put'))
    );
  }


  delete(request: SODeletePlan): Observable<SORespPlan> {
    return this.httpClient.delete<SORespPlan>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Plan uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.plans.delete(request.uid);
      }),
      catchError(this.handleError<SORespPlan>('Plan delete'))
    );
  }


}
