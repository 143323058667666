import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutRole } from './so-put-role';
import { SOGetRole } from './so-get-role';
import { SOPostRole } from './so-post-role';
import { SODeleteRole } from './so-delete-role';
import {SORespRole} from './so-resp-role';
import {SOPluralRole} from './so-plural-role';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EORoleService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('role', entityCore);
  }

  /** GET Role by id. Will 404 if id not found */
  get(request: SOGetRole): Observable<SOPluralRole> {
    return this.httpClient.get<SOPluralRole>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralRole, resp)),
      tap(async (response: SOPluralRole) => {
        this.log(`fetched Role`);
        // After a successful server response, update IndexedDB
        const activities = response.toRoles(); // Convert your response to an array of Role instances
        const promises = activities.map(role => db.roles.put(role));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralRole>(`get Role`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralRole> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'role' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.roles.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "roleGrants" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roles.where('roleGrantsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "viewGrants" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roles.where('viewGrantsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "invites" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roles.where('invitesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "rolePermissions" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roles.where('rolePermissionsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "processRoles" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roles.where('processRolesUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.roles.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(rolesArray => this.processRoles(rolesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.roles.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(rolesArray => this.processRoles(rolesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.roles.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(rolesArray => this.processRoles(rolesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.roles.toArray()).pipe(
                map((rolesArray) => {
                    // Convert the array of plain objects to an array of SORespRole instances
                    const sorRoles = rolesArray.map(req => plainToClass(SORespRole, req));
                    // Create and return a new instance of SOPluralRole
                    const pluralRole = new SOPluralRole();
                    pluralRole.objects = sorRoles;
                    pluralRole.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralRole;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processRoles(rolesArray) {
        const sorRoles = rolesArray.map(req => plainToClass(SORespRole, req));
        const pluralRole = new SOPluralRole();
        pluralRole.objects = sorRoles;
        pluralRole.nextOffset = null;
        return pluralRole;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostRole): Observable<SORespRole> {
  return this.httpClient.post<SORespRole>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespRole, resp)),

    tap(async (response: SORespRole) => {
      this.log(`added Role w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.roles.add(response.toRole());
    }),
    catchError(this.handleError<SORespRole>('Role post'))
  );
}


  put(request: SOPutRole): Observable<SORespRole> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRole>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespRole, resp)),
      tap(async (response: SORespRole) => {
        this.log(`edited Role w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.roles.put(response.toRole());
      }),
      catchError(this.handleError<SORespRole>('Role put'))
    );
  }


  delete(request: SODeleteRole): Observable<SORespRole> {
    return this.httpClient.delete<SORespRole>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Role uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.roles.delete(request.uid);
      }),
      catchError(this.handleError<SORespRole>('Role delete'))
    );
  }


}
