import {SORespOrganization} from '../../core/organization/so-resp-organization';
import {OrganizationCore} from './organization.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutOrganizationFormFields} from './put-organization-form-fields';
import { CEOrganizationAttributes } from './ce-organization-attributes'


export class CEOrganizationBase extends CEBase {

    public details: CEOrganizationAttributes;
    public fromFields: PutOrganizationFormFields = new PutOrganizationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: OrganizationCore,
        public entity: SORespOrganization,
        public app: CommanderApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEOrganizationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.exRefPaymentsLink)
        this.attributes.push(this.details.subscriptions)
        this.attributes.push(this.details.roleGrants)
        this.attributes.push(this.details.medias)
        this.attributes.push(this.details.invites)
        this.attributes.push(this.details.ingests)
        this.attributes.push(this.details.attachments)
        this.attributes.push(this.details.alertSubscriptions)
        this.attributes.push(this.details.messages)
        this.attributes.push(this.details.notifications)
        this.attributes.push(this.details.topics)
        this.attributes.push(this.details.assessment)
        this.attributes.push(this.details.assessmentSummary)
        this.attributes.push(this.details.block)
        this.attributes.push(this.details.deficiency)
        this.attributes.push(this.details.period)
        this.attributes.push(this.details.report)
        this.attributes.push(this.details.requirement)
        this.attributes.push(this.details.customLogicRequirement)
        this.attributes.push(this.details.work)
        this.attributes.push(this.details.realization)
        this.attributes.push(this.details.entity)
        this.attributes.push(this.details.relationship)
        this.attributes.push(this.details.relationshipAttribute)
        this.attributes.push(this.details.simpleAttribute)
        this.attributes.push(this.details.process)
        this.attributes.push(this.details.project)
        this.attributes.push(this.details.interphace)
        this.attributes.push(this.details.backlog)
        this.attributes.push(this.details.attributeSpec)
        this.attributes.push(this.details.exportProject)
        this.attributes.push(this.details.simpleAttributePermission)
        this.attributes.push(this.details.simpleAttributePermissionRule)
        this.attributes.push(this.details.relationshipAttributePermission)
        this.attributes.push(this.details.relationshipAttributePermissionRule)
        this.attributes.push(this.details.entityPermission)
        this.attributes.push(this.details.plan)
        this.attributes.push(this.details.person)
        this.attributes.push(this.details.assignedProject)
        this.attributes.push(this.details.planWorkAssociation)
        this.attributes.push(this.details.rolePermission)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.processRole)
        this.attributes.push(this.details.step)
        this.attributes.push(this.details.processStepAssociation)

    }
}