import { Type } from 'class-transformer';
import {SORespProcessStepAssociation} from './so-resp-process-step-association';
import {PluralProtocol} from 'lionheartland';
import {ProcessStepAssociation} from "../../dexie/db";

export class SOPluralProcessStepAssociation implements PluralProtocol {
  @Type(() => SORespProcessStepAssociation)
  objects: SORespProcessStepAssociation[] = []
  nextOffset: number = null

    toProcessStepAssociations(): ProcessStepAssociation[] {
        return this.objects.map(processStepAssociation => processStepAssociation.toProcessStepAssociation());
    }
}