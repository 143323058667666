import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSimpleAttributeType} from '../../enums';

export class PostSimpleAttributeFormFields {

    public name: FormField
    public description: FormField
    public attributeType: FormField
    public editPermissions: FormField
    public entity: FormField
    public customLogicRequirement: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.attributeType = new FormField(
          'attributeType',
          'Attribute type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.attributeType.enumeration = ENSimpleAttributeType
        this.editPermissions = new FormField(
          'editPermissions',
          'Edit permissions',
          null,
          FormFieldType.boolean,
          true,
        )
        this.entity = new FormField(
          'entity',
          'Entity',
          'entity',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.customLogicRequirement = new FormField(
          'customLogicRequirement',
          'Custom logic requirement',
          'customLogicRequirement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSimpleAttributeFormFields()

export const postSimpleAttributeFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  attributeType: fields.attributeType,
  editPermissions: fields.editPermissions,
  entity: fields.entity,
  customLogicRequirement: fields.customLogicRequirement,
  project: fields.project,
};