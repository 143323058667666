import {SORespExportProject} from '../../core/export-project/so-resp-export-project';
import {ExportProjectCore} from './export-project.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutExportProjectFormFields} from './put-export-project-form-fields';
import {SOGetExportProject} from '../../core/export-project/so-get-export-project';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEExportProjectAttributesBase {

    name: DetailEntry
    reasonForExport: DetailEntry
    exportType: DetailEntry
    downloadLink: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespExportProject,
        app: CommanderApp,
        formFields: PutExportProjectFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.reasonForExport = new DetailEntry(
            'Reason for export',
            entity.reasonForExport,
            'reasonForExport',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.reasonForExport
        )

        this.exportType = new DetailEntry(
            'Export type',
            entity.exportType,
            'exportType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.exportType
        )

        this.downloadLink = new DetailEntry(
            'Download link',
            entity.downloadLink,
            'downloadLink',
            BaseDetailType.link,
            null,
            EditableType.never,
        )


        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}