import { SOGetDatafeed } from '../datafeed/so-get-datafeed';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENMessageStatus} from '../../enums';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetNotification implements QueryProtocol {

  constructor(
    public uid: string = null,
    public description: string = null,
    public status: ENMessageStatus[] = null,
    public datafeed: SOGetDatafeed = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetNotification(
      'uid' in params ? params.uid : null,
      'description' in params ? params.description : null,
      'status' in params ? params.status : null,
      'datafeed' in params ? params.datafeed : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
