import { Type } from 'class-transformer';
import {SORespToken} from './so-resp-token';
import {PluralProtocol} from 'lionheartland';
import {Token} from "../../dexie/db";

export class SOPluralToken implements PluralProtocol {
  @Type(() => SORespToken)
  objects: SORespToken[] = []
  nextOffset: number = null

    toTokens(): Token[] {
        return this.objects.map(token => token.toToken());
    }
}