import { Type } from 'class-transformer';
import { SORespSubscription } from '../subscription/so-resp-subscription';
import {ObjectiveProtocol} from 'lionheartland';
import {Invoice} from "../../dexie/db";

export class SORespInvoice implements ObjectiveProtocol {

  entityType: string = 'invoice';
  uid: string;
  ref: string;
  exRef: string = null;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateDue: Date;
  @Type(() => Date)
  periodStart: Date;
  @Type(() => Date)
  periodEnd: Date;
  paid: boolean = false;
  @Type(() => SORespSubscription)
  subscription: SORespSubscription;

    toInvoice(): Invoice {
        return {
            uid: this.uid,
            ref: this.ref,
            exRef: null,
            amountDue: this.amountDue,
            amountPaid: this.amountPaid,
            amountRemaining: this.amountRemaining,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            dateDue: this.dateDue,
            periodStart: this.periodStart,
            periodEnd: this.periodEnd,
            paid: this.paid,
            subscriptionUid: this.subscription ? this.subscription.uid : null,
            paymentIntentsUid: null,
            invoiceLinesUid: null,
}

        };
}