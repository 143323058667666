import {SORespAssessment} from '../../core/assessment/so-resp-assessment';
import {AssessmentCore} from './assessment.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { CommanderApp } from "../../land-app";
import {PutAssessmentFormFields} from './put-assessment-form-fields';
import {SOGetAssessment} from '../../core/assessment/so-get-assessment';
import {SOGetAssessmentSummary} from '../../core/assessment-summary/so-get-assessment-summary';
import {SOGetDeficiency} from '../../core/deficiency/so-get-deficiency';
import {SOGetReport} from '../../core/report/so-get-report';
import {SOGetProject} from '../../core/project/so-get-project';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEAssessmentAttributesBase {

    name: DetailEntry
    description: DetailEntry
    totalRequirements: DetailEntry
    activeRequirements: DetailEntry
    inactiveRequirements: DetailEntry
    completedRequirements: DetailEntry
    incompleteRequirements: DetailEntry
    totalEntities: DetailEntry
    totalAttributes: DetailEntry
    simpleAttributes: DetailEntry
    relationshipAttributes: DetailEntry
    attributesWithRequirements: DetailEntry
    totalProcesses: DetailEntry
    processesWithRequirements: DetailEntry
    totalCustomLogicRequirements: DetailEntry
    completedCustomLogicRequirements: DetailEntry
    incompleteCustomLogicRequirements: DetailEntry
    totalBlocks: DetailEntry
    customBlocks: DetailEntry
    libraryBlocks: DetailEntry
    networkBlocks: DetailEntry
    blocksWithCustomLogicRequirements: DetailEntry
    totalDeficiencies: DetailEntry
    assessmentSummary: DetailEntry
    deficiency: DetailEntry
    report: DetailEntry
    project: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAssessment,
        app: CommanderApp,
        formFields: PutAssessmentFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalRequirements = new DetailEntry(
            'Total requirements',
            entity.totalRequirements,
            'totalRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.activeRequirements = new DetailEntry(
            'Active requirements',
            entity.activeRequirements,
            'activeRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.inactiveRequirements = new DetailEntry(
            'Inactive requirements',
            entity.inactiveRequirements,
            'inactiveRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.completedRequirements = new DetailEntry(
            'Completed requirements',
            entity.completedRequirements,
            'completedRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.incompleteRequirements = new DetailEntry(
            'Incomplete requirements',
            entity.incompleteRequirements,
            'incompleteRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalEntities = new DetailEntry(
            'Total entities',
            entity.totalEntities,
            'totalEntities',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalAttributes = new DetailEntry(
            'Total attributes',
            entity.totalAttributes,
            'totalAttributes',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.simpleAttributes = new DetailEntry(
            'Simple attributes',
            entity.simpleAttributes,
            'simpleAttributes',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.relationshipAttributes = new DetailEntry(
            'Relationship attributes',
            entity.relationshipAttributes,
            'relationshipAttributes',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.attributesWithRequirements = new DetailEntry(
            'Attributes with requirements',
            entity.attributesWithRequirements,
            'attributesWithRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalProcesses = new DetailEntry(
            'Total processes',
            entity.totalProcesses,
            'totalProcesses',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.processesWithRequirements = new DetailEntry(
            'Processes with requirements',
            entity.processesWithRequirements,
            'processesWithRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalCustomLogicRequirements = new DetailEntry(
            'Total custom logic requirements',
            entity.totalCustomLogicRequirements,
            'totalCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.completedCustomLogicRequirements = new DetailEntry(
            'Completed custom logic requirements',
            entity.completedCustomLogicRequirements,
            'completedCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.incompleteCustomLogicRequirements = new DetailEntry(
            'Incomplete custom logic requirements',
            entity.incompleteCustomLogicRequirements,
            'incompleteCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalBlocks = new DetailEntry(
            'Total blocks',
            entity.totalBlocks,
            'totalBlocks',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.customBlocks = new DetailEntry(
            'Custom blocks',
            entity.customBlocks,
            'customBlocks',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.libraryBlocks = new DetailEntry(
            'Library blocks',
            entity.libraryBlocks,
            'libraryBlocks',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.networkBlocks = new DetailEntry(
            'Network blocks',
            entity.networkBlocks,
            'networkBlocks',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.blocksWithCustomLogicRequirements = new DetailEntry(
            'Blocks with custom logic requirements',
            entity.blocksWithCustomLogicRequirements,
            'blocksWithCustomLogicRequirements',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.totalDeficiencies = new DetailEntry(
            'Total deficiencies',
            entity.totalDeficiencies,
            'totalDeficiencies',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const assessmentSummaryCore = app.makeCore( 'assessmentSummary');
        assessmentSummaryCore.soGet = SOGetAssessmentSummary.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        assessmentSummaryCore.soGet.queryOptions.sortKey = 'dateCreated';
        assessmentSummaryCore.soGet.queryOptions.descending = true;
        let assessmentSummaryCeProtocol;
        if (entity.assessmentSummary) {
            assessmentSummaryCeProtocol = createCEProtocol(
                assessmentSummaryCore.ceProtocol,
                parentEntityViewSpec,
                assessmentSummaryCore,
                entity.assessmentSummary,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            assessmentSummaryCore
        )
        assessmentSummaryCore.title = heading ? heading : assessmentSummaryCore.title
        assessmentSummaryCore.listTitle = 'Assessment summary'

        if (entity.assessmentSummary) {
            displayStringFactory.reconfigure(
                entity.assessmentSummary,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        assessmentSummaryCore.configureForDisplay(parentEntityViewSpec)

        this.assessmentSummary = new DetailEntry(
          heading ? heading : assessmentSummaryCore.listTitle,
          assessmentSummaryCeProtocol ? assessmentSummaryCeProtocol.entityListView.fullHeading : null,
          'assessmentSummary',
          BaseDetailType.pushedDetail,
          assessmentSummaryCore,
          EditableType.never
        )
        if (entity.assessmentSummary) {
          this.assessmentSummary.ceProtocol = assessmentSummaryCeProtocol;
          this.assessmentSummary.cachedListEntry = assessmentSummaryCore.makeListEntry(parentEntityViewSpec, entity.assessmentSummary, 0)
          assessmentSummaryCore.selectedEntity = entity.assessmentSummary;
        }
        this.assessmentSummary.singleFieldSelect = true

        const deficiencyCore = app.makeCore( 'deficiency');
        deficiencyCore.soGet = SOGetDeficiency.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        deficiencyCore.soGet.queryOptions.sortKey = 'dateCreated';
        deficiencyCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deficiencyCore
        )
        deficiencyCore.title = heading ? heading : deficiencyCore.title
        deficiencyCore.listTitle = 'Deficiency'

        deficiencyCore.configureForDisplay(parentEntityViewSpec)

        this.deficiency = new DetailEntry(
          heading ? heading : deficiencyCore.listTitle,
          null,
          'deficiency',
          BaseDetailType.embeddedList,
          deficiencyCore,
          EditableType.never
        )

        const reportCore = app.makeCore( 'report');
        reportCore.soGet = SOGetReport.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        reportCore.soGet.queryOptions.sortKey = 'dateCreated';
        reportCore.soGet.queryOptions.descending = true;
        let reportCeProtocol;
        if (entity.report) {
            reportCeProtocol = createCEProtocol(
                reportCore.ceProtocol,
                parentEntityViewSpec,
                reportCore,
                entity.report,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reportCore
        )
        reportCore.title = heading ? heading : reportCore.title
        reportCore.listTitle = 'Report'

        if (entity.report) {
            displayStringFactory.reconfigure(
                entity.report,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        reportCore.configureForDisplay(parentEntityViewSpec)

        this.report = new DetailEntry(
          heading ? heading : reportCore.listTitle,
          reportCeProtocol ? reportCeProtocol.entityListView.fullHeading : null,
          'report',
          BaseDetailType.pushedDetail,
          reportCore,
          EditableType.never
        )
        if (entity.report) {
          this.report.ceProtocol = reportCeProtocol;
          this.report.cachedListEntry = reportCore.makeListEntry(parentEntityViewSpec, entity.report, 0)
          reportCore.selectedEntity = entity.report;
        }
        this.report.singleFieldSelect = true

        const projectCore = app.makeCore( 'project');
        projectCore.soGet = SOGetProject.construct({
          organization: app.getScope('organization') ? new SOGetOrganization(app.getScope('organization').uid) : null,
        });
        projectCore.soGet.queryOptions.sortKey = 'dateCreated';
        projectCore.soGet.queryOptions.descending = true;
        let projectCeProtocol;
        if (entity.project) {
            projectCeProtocol = createCEProtocol(
                projectCore.ceProtocol,
                parentEntityViewSpec,
                projectCore,
                entity.project,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            projectCore
        )
        projectCore.title = heading ? heading : projectCore.title
        projectCore.listTitle = 'Project'

        if (entity.project) {
            displayStringFactory.reconfigure(
                entity.project,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        projectCore.configureForDisplay(parentEntityViewSpec)

        this.project = new DetailEntry(
          heading ? heading : projectCore.listTitle,
          projectCeProtocol ? projectCeProtocol.entityListView.fullHeading : null,
          'project',
          BaseDetailType.pushedDetail,
          projectCore,
          EditableType.always,
          formFields.project
        )
        if (entity.project) {
          this.project.ceProtocol = projectCeProtocol;
          this.project.cachedListEntry = projectCore.makeListEntry(parentEntityViewSpec, entity.project, 0)
          projectCore.selectedEntity = entity.project;
        }
        this.project.singleFieldSelect = true
    }
}