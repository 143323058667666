import { Injectable } from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {db} from "../../dexie/db";

import { SOPutMessage } from './so-put-message';
import { SOGetMessage } from './so-get-message';
import { SOPostMessage } from './so-post-message';
import { SODeleteMessage } from './so-delete-message';
import {SORespMessage} from './so-resp-message';
import {SOPluralMessage} from './so-plural-message';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";

export class EOMessageService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super('message', entityCore);
  }

  /** GET Message by id. Will 404 if id not found */
  get(request: SOGetMessage): Observable<SOPluralMessage> {
    return this.httpClient.get<SOPluralMessage>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => plainToClass(SOPluralMessage, resp)),
      tap(async (response: SOPluralMessage) => {
        this.log(`fetched Message`);
        // After a successful server response, update IndexedDB
        const activities = response.toMessages(); // Convert your response to an array of Message instances
        const promises = activities.map(message => db.messages.put(message));
        await Promise.all(promises); // Wait for all the IndexedDB update operations to complete
      }),
      catchError(this.handleError<SOPluralMessage>(`get Message`))
    );
  }

    /** GETCACHE for initial display with CacheQueryProtocol */
    getCache(cacheQuery: any): Observable<SOPluralMessage> {
        console.log("GETCACHE", cacheQuery);

        if (cacheQuery.filterKey === 'message' && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on parent and organization")
            return from(db.messages.where('parentUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(messagesArray => this.processMessages(messagesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }
        else if (cacheQuery.filterKey + "s" == "notifications" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.messages.where('notificationsUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(messagesArray => this.processMessages(messagesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "topic" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.messages.where('topicUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(messagesArray => this.processMessages(messagesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterKey + "s" == "organization" && cacheQuery.filterValue && cacheQuery.filterOrganization)
        {
            console.log("cache filtering based on relationship and organization")
            return from(db.messages.where('organizationUid').equals(cacheQuery.filterValue).and(item => item.organizationUid === cacheQuery.filterOrganization.uid).toArray()).pipe(
                map(messagesArray => this.processMessages(messagesArray)),
                catchError(error => this.handleQueryError(error))
            );
        }        else if (cacheQuery.filterOrganization || cacheQuery.organization.uid)
        {
            console.log("tripped organization for cache query")
            if ( cacheQuery?.filterOrganization != null )
            {
                console.log("cache filtering based on organization CQP", cacheQuery)
                return from(db.messages.where('organizationUid').equals(cacheQuery.filterOrganization).toArray()).pipe(
                    map(messagesArray => this.processMessages(messagesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else if ( cacheQuery?.organization?.uid != null )
            {
                console.log("UID:", cacheQuery.organization.uid)
                console.log("cache filtering based on organization SOGet")
                return from(db.messages.where('organizationUid').equals(cacheQuery.organization.uid).toArray()).pipe(
                    map(messagesArray => this.processMessages(messagesArray)),
                    catchError(error => this.handleQueryError(error))
                );
            }
            else
            {
                console.log("null organization")
            }
        }
        else
        {
            console.log("cache no filter")
            return from(db.messages.toArray()).pipe(
                map((messagesArray) => {
                    // Convert the array of plain objects to an array of SORespMessage instances
                    const sorMessages = messagesArray.map(req => plainToClass(SORespMessage, req));
                    // Create and return a new instance of SOPluralMessage
                    const pluralMessage = new SOPluralMessage();
                    pluralMessage.objects = sorMessages;
                    pluralMessage.nextOffset = null; // Assuming there is no pagination in cache
                    return pluralMessage;
                }),
                catchError(error => this.handleQueryError(error))
            );
        }
    }

    private processMessages(messagesArray) {
        const sorMessages = messagesArray.map(req => plainToClass(SORespMessage, req));
        const pluralMessage = new SOPluralMessage();
        pluralMessage.objects = sorMessages;
        pluralMessage.nextOffset = null;
        return pluralMessage;
    }

    private handleQueryError(error) {
        console.error('Error in query:', error);
        return throwError(error); // Return an observable error
    }

post(request: SOPostMessage): Observable<SORespMessage> {
  return this.httpClient.post<SORespMessage>(this.coreUrl + this.route, request, this.httpOptions).pipe(
    map(resp => plainToClass(SORespMessage, resp)),

    tap(async (response: SORespMessage) => {
      this.log(`added Message w/ id=${response.uid}`);
      // After a successful server response, update IndexedDB
      console.log("here", response);
      console.log("here request", request);
      await db.messages.add(response.toMessage());
    }),
    catchError(this.handleError<SORespMessage>('Message post'))
  );
}


  put(request: SOPutMessage): Observable<SORespMessage> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespMessage>(uri, request, this.httpOptions).pipe(
      map(resp => plainToClass(SORespMessage, resp)),
      tap(async (response: SORespMessage) => {
        this.log(`edited Message w/ uid=${uid}`);
        // After a successful server response, update IndexedDB
        await db.messages.put(response.toMessage());
      }),
      catchError(this.handleError<SORespMessage>('Message put'))
    );
  }


  delete(request: SODeleteMessage): Observable<SORespMessage> {
    return this.httpClient.delete<SORespMessage>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(async _ => {
        this.log(`deleted Message uid=${request.uid}`);
        // After a successful server response, update IndexedDB
        await db.messages.delete(request.uid);
      }),
      catchError(this.handleError<SORespMessage>('Message delete'))
    );
  }


}
