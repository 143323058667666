import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostSimpleAttributePermissionRuleFormFields {

    public name: FormField
    public description: FormField
    public simpleAttribute: FormField
    public simpleAttributePermission: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.simpleAttribute = new FormField(
          'simpleAttribute',
          'Simple attribute',
          'simpleAttribute',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.simpleAttributePermission = new FormField(
          'simpleAttributePermission',
          'Simple attribute permission',
          'simpleAttributePermission',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSimpleAttributePermissionRuleFormFields()

export const postSimpleAttributePermissionRuleFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  simpleAttribute: fields.simpleAttribute,
  simpleAttributePermission: fields.simpleAttributePermission,
  project: fields.project,
};