import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSeverity} from '../../enums';

export class PostDeficiencyFormFields {

    public name: FormField
    public description: FormField
    public active: FormField
    public severity: FormField
    public assessment: FormField
    public block: FormField
    public process: FormField
    public project: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          true,
        )
        this.severity = new FormField(
          'severity',
          'Severity',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.severity.enumeration = ENSeverity
        this.assessment = new FormField(
          'assessment',
          'Assessment',
          'assessment',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.block = new FormField(
          'block',
          'Block',
          'block',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.process = new FormField(
          'process',
          'Process',
          'process',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.project = new FormField(
          'project',
          'Project',
          'project',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDeficiencyFormFields()

export const postDeficiencyFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  active: fields.active,
  severity: fields.severity,
  assessment: fields.assessment,
  block: fields.block,
  process: fields.process,
  project: fields.project,
};